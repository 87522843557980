import ShopifyIcon from "assets/images/ShopifyIcon.svg";
import { useState } from "react";
import DetailModal from "./DetailModal";
import numberWithCommas from "utils/commaseperatedfunction";

const ApparelShoppingCard = ({ item}: any) => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => {
    setShowModal(true);
  };
  
  return (
    <>
      <div className=" bg-white rounded-[0.76rem] overflow-hidden">
        <img
          className="h-[29rem] w-full text-center text-[3rem] font-bold object-cover"
          src={item?.image ? item?.image : null}
          alt="ApparelImage"
          onClick={handleShow}
        />
        <div className="py-[2.2rem] px-[2rem]">
          <div className="flex justify-between items-center">
            <h1
              onClick={handleShow}
              className="font-bold capitalize text-[3rem] text-[#2165A2] leading-[3.6rem] whitespace-nowrap w-[26rem] text-ellipsis overflow-hidden"
            >
              {item?.title}
            </h1>
            <h1 className="font-semibold text-right min-w-[10rem] truncate text-[3rem] text-[#2165A2] leading-[3.6rem]">
              ${numberWithCommas(item?.price)}
            </h1>
          </div>
          <h1 className="description text-[2rem] text-[#333333] pt-5 h-[10rem]">
            {item?.description}
          </h1>
          <div>
            <a
              href={item?.link}
              target="_blank"
              rel="noreferrer"
              className="bg-primary_blue text-white text-center text-[2rem] font-medium rounded-[0.9rem] h-[5.6rem] mt-[2rem] px-[2.1rem] flex items-center justify-center gap-2"
            >
              Buy with Shopify
              <img
                className="w-[2.3rem] h-[2.6rem]"
                src={ShopifyIcon}
                alt="Shopify Icon"
              />
            </a>
          </div>
        </div>
      </div>
      {showModal && (
        <DetailModal
          item={item}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

export default ApparelShoppingCard;
