import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const LegacyForm = ({
  category,
  label,
  nextHandler,
  isLoading,
  setFormData,
  page,
  setPage,
  formData,
  index,
}: any) => {
  const {
    register,
    handleSubmit,
    setValue,
  } = useForm({
  });
  const isLastStep = index === 6;
  const handleChange = (e: any) => {
    const { name: valName, value } = e.target;
    setValue(valName, value);

  };

  const onSubmit = (data: any) => {
    const temp = { ...data, category, heading: label }
    const tempArray = [...formData?.filter(
      (item: any) => item?.category !== category
    ), temp]
    setFormData([...formData?.filter(
      (item: any) => item?.category !== category
    ), temp])
    nextHandler(tempArray);
  };
  function handleBack() {
    setPage(page - 1)
  }
  useEffect(() => {
    const value = formData?.find((item: any) => category === item?.category);
    const filterValue = value?.description
    setValue("description", filterValue)
  }, [formData, category, page])


  return (
    <form className="flex flex-col gap-4">
      <p className="text-[3rem] sm:text-[5rem] leading-[5.9rem] font-medium mb-4 mt-12 text-[#333333]">
        {label}
      </p>
      <div className="mb-4">
        <textarea
          rows={7}
          placeholder="Enter Description"
          className="w-full rounded-xl border-2 px-6 py-6 resize-none border-[#333333] text-[2.8rem] leading-[2.8rem] outline-none focus:outline-double"
          {...register("description", {
            onChange: (e: any) => handleChange(e),
          })}
        >
        </textarea>
      </div>
      <div className="text-end text-[2.2rem] font-semibold">{`${page + 1}/${7}`}</div>
      <div className="flex gap-[1.6rem]">
        <button
          // type="button"
          style={{ display: page !== 0 ? "block" : "none" }}
          onClick={handleBack}
          className="w-full text-[#fff] py-[1.45rem] bg-primary_blue font-normal leading-[4.4rem] text-[3.78562rem] sm:text-[3.78562rem] rounded-[1.07463rem] "
        >
          {"Back"}
        </button>
        <button
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
          className="w-full text-[#fff] py-[1.45rem] bg-primary_blue font-normal leading-[4.4rem] text-[3.78562rem] sm:text-[3.78562rem] rounded-[1.07463rem]"
        >
          {isLoading ? "Loading..." : isLastStep ? "Submit" : "Next"}
        </button>

      </div>
    </form>
  );
};

export default React.memo(LegacyForm);
