import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndpoint from "config/environment";

export const generalServerApi = createApi({
  reducerPath: "generalServerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as any).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: ["Events", "Notifications"],

  endpoints: (builder) => ({
    getAllCourses: builder.query({
      query: () => "resources/courses",
    }),

    getCourseById: builder.query({
      query: (id) => `resources/courses/${id}`,
    }),

    // Get All Events
    getAllEvents: builder.query({
      query: () => "resources/events",
      providesTags: ["Events"],
    }),

    // Get Single Events
    getSingleEvents: builder.query({
      query: (id) => `resources/events/${id}`,
      providesTags: ["Events"],
    }),

    // Get All Apparels
    getAllApparels: builder.query({
      query: () => "/resources/apparel",
    }),

    getYoutubeVideos: builder.query({
      query: ({ pageToken }) =>
        `resources/youtube-videos?perPage=12&pageToken=${pageToken ? pageToken : ""}`,
    }),
    getPodcasts: builder.query({
      query: () => `/resources/podcast`,
    }),
    // get Notifications
    getNotifications: builder.query({
      query: ({userId, role}) => `notification/${userId}?role=${role}`,
      providesTags: ["Notifications"],
    }),
    // mark Notifications
    markNotifications: builder.mutation({
      query: (payload) => ({
        url: "notifications",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Notifications"],
    }),
    // mark all Notifications
    markAllNotifications: builder.mutation({
      query: (payload) => ({
        url: "notifications/mark",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Notifications"],
    }),
    // get Notifications Count
    getNotificationsCount: builder.query({
      query: ({id, role}) => `notification-count/${id}?role=${role}`,
      // providesTags: ["Notifications"],
    }),
    //get Banner By Type

    getBannerByType: builder.query({
      query: ({ id, role }) => `${role}/banner/${id}`,
    }),
    getVideoUrl: builder.query({
      query: (id) => `resources/video/${id}`,
    }),
     //Get profile by Id
     getProfileById: builder.query({
      query: (id) => `user/${id}`,
      // providesTags: ["Profile"],
    }),

    //GET MEETING DETAILS
    getMeetingDetails: builder.query({
      query: (id) => `zoom/meeting/${id}`,
    }),
  }),
});

export const {
  useGetAllCoursesQuery,
  useGetAllEventsQuery,
  useGetAllApparelsQuery,
  useGetSingleEventsQuery,
  useGetYoutubeVideosQuery,
  useGetPodcastsQuery,
  useGetNotificationsQuery,
  useMarkNotificationsMutation,
  useGetNotificationsCountQuery,
  useGetBannerByTypeQuery,
  useGetVideoUrlQuery,
  useGetProfileByIdQuery,
  useGetCourseByIdQuery,
  useLazyGetMeetingDetailsQuery,
  useMarkAllNotificationsMutation,
} = generalServerApi;
