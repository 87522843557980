import dayjs from "dayjs";
import { useState } from "react";
import Modal from "./Modal";

interface NoteProps {
  index: number;
  date: string;
  note: string;
  title?: string;
}

const NotesCard = ({ index, date, note, title }: NoteProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const splitData = note?.split("\n");
  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        note={note}
        date={date}
        title={title}
      />
      <div
        className="flex mb-8 rounded-[0.57rem] overflow-hidden cursor-pointer w-full"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <h3 className="flex items-center align-center h-full font-medium text-[2.9rem] bg-primary_blue text-white py-[1.3rem] px-[1.3rem]">
            {index + 1}
          </h3>
        </div>
        <div className="flex bg-[#ffffff] flex-col tablet:flex-row py-[3.1rem] px-[3rem] w-full gap-10 justify-between items-start tablet:items-center">
          <div className="">
            {title && (
              <h2 className="font-bold text-[2rem] leading-[3.1rem] text-primary_blue mb-[0.7rem]">
                {title}
              </h2>
            )}
            <div id="notes">
              <ul className="font-medium text-[2rem] leading-[2.4rem] text-[#333333]" style={{wordBreak: "break-word"}}>
                {splitData?.map((item: any, idx:number) => {
                  if (item) {
                    return <li key={idx}>{item}</li>
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="">
            <h4 className="font-semibold text-[1.8rem] text-[#333333]">
              {dayjs(date).format("MM/DD/YYYY")}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotesCard;
