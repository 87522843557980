import ActionItem from "./actionItem";

const ClientActionItem = ({ data,refetch }: any) => {
  return (
    <div>
      {data?.length == 0 ? (
        <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
          <p className="text-4xl sm:text-5xl text-primary_blue">
            No Action Items Found
          </p>
        </div>
      ) : (
        data?.map((item: any) => {
          return <ActionItem key={item.id} item={item} refetch={refetch} />;
        })
      )}
    </div>
  );
};

export default ClientActionItem;
