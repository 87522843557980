import { useState } from "react";
import AuthLayout from "layouts/AuthLayout";
import PrimaryButton from "components/common/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "components/common/TextInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createPassword } from "apis/auth-apis";

interface IFormInputs {
  password: string;
  confirmPassword: string;
}

const schema = yup.object({
  password: yup.string().required("Password required").min(6),
  confirmPassword: yup
    .string()
    .required("Password required")
    .min(6)
    .oneOf([yup.ref("password")], "Passwords do not match"),
});

const CreatePassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { otp } = useParams();
  const value = otp && otp;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFormInputs) => {
    createPassword(
      value,
      data.password,
      data.confirmPassword,
      setLoading,
      navigate
    );
  };

  return (
    <AuthLayout>
      <div className="bg-white rounded-2xl px-24 sm:px-36 py-28 auth_box">
        <h1 className="text-5xl font-bold mb-12">Create new password</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="mb-4">
            <Controller
              control={control}
              name="password"
              render={() => (
                <TextInput
                  register={register}
                  name="password"
                  type="password"
                  label="New Password"
                  palceholder="Enter New Password"
                />
              )}
            />

            {errors.password && (
              <p className="error">{errors.password?.message}</p>
            )}
          </div>
          <div className="mb-12">
            <TextInput
              register={register}
              name="confirmPassword"
              type="password"
              label="Confirm New Password"
              palceholder="Enter Confirm Password"
            />
            {errors.confirmPassword && (
              <p className="error">{errors.confirmPassword?.message}</p>
            )}
          </div>
          <PrimaryButton text="Done" type="submit" color="primary" loading={loading} />
        </form>
      </div>
    </AuthLayout>
  );
};

export default CreatePassword;
