import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowDown from "assets/images/ArrowDown.svg";
import Calendar from "assets/images/Calendar.svg";
import CrossIcon from "assets/icons/cross-white.svg";
import { toast } from "react-toastify";

const FormCalender = ({ startDate, setStartDate, endDate, setEndDate, handleClear }: any) => {
  if (endDate < startDate) {
    toast.warn("Select end date greater than start date")
  }
  return (
    <div className="flex flex-row tablet:flex-row gap-[1rem]">
      <div className="flex items-center mr-[0rem] tablet:mr-[2.7rem]">
        <h5 className="font-bold text-[1.4rem] leading[2.1rem] mr-[1.2rem] mt-[1rem] sm:mr-[2.2rem] text-white">
          From
        </h5>
        <div className="relative">
          <img
            className="absolute left-[1.2rem] sm:left-[1.4rem] top-[2rem] sm:top-[2.2rem]"
            src={Calendar}
            alt="Calendar"
          />
          <img
            className="absolute right-[2rem] top-[3rem]"
            src={ArrowDown}
            alt="ArrowDown"
          />
          <DatePicker
            className="input-form-calendar mt-0"
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-end">
        <h5 className="font-bold text-[1.4rem] leading[2.1rem] mt-[1rem] mr-[1.2rem] sm:mr-[2.2rem] text-white">
          To
        </h5>
        <div className="relative ">
          <img
            className="absolute left-[1.2rem] sm:left-[1.4rem] top-[2rem] sm:top-[2.2rem]"
            src={Calendar}
            alt="Calendar"
          />
          <img
            className="absolute right-[2rem] top-[3rem]"
            src={ArrowDown}
            alt="ArrowDown"
          />
          <DatePicker
            className="input-form-calendar "
            selected={endDate}
            onChange={(date: any) => setEndDate(date)}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>
        <div
          onClick={handleClear}
          className="w-[3.4rem] h-[3.4rem] cursor-pointer ml-[2rem] "
        >
          <img src={CrossIcon} alt="CrossIcon" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

export default FormCalender;
