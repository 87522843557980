const SwotTabContent = ({ content }: any) => {
  return (
    <>
      {Object.keys(content)?.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className="py-[2.1rem] px-[3rem] border-b-2 border-[#C1C1C1] rounded-[0.4rem] w-full"
          >
            <>
              <h5 className="text-primary_blue capitalize font-semibold text-[2rem]  leading-[2.3rem] mb-2 ">
                {item}
              </h5>
              <ul>
                {content[item]?.map((item: any) => {
                  if (item) {
                    return <li key={item} className="text-[#333333] text-[2.4rem] leading-[3rem] flex gap-4 items-center">
                      <span className="inline-block w-[5px] h-[5px] rounded bg-primary_blue"></span>
                      <span>{item}</span>
                    </li>
                  }
                })}
              </ul>
            </>
          </div>
        );
      })}
    </>
  );
};

export default SwotTabContent;
