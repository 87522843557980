import React, { Fragment, useEffect, useState } from "react";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/HeroImage.png";
import { Tab } from "@headlessui/react";
import Challenges from "./Challenges";
import ActionItems from "./ActionItems";
import ChampionDay from "./Championships";
import { useDispatch, useSelector } from "react-redux";
import { setTabIndex } from "store/slices/client.slice";
import { selectTabIndex } from "store/selectors";
import { useGetLegacyStatementsQuery } from "apis/client";
import LegacyStatementModal from "components/LegacyStatementModal";
import { getClient } from "apis/client-apis";
import { useLocation } from "react-router-dom";

const Home = () => {
  const [isChampionLegacy, setisChampionLegacy] = useState(false);
  const userId = useSelector((state: any) => state?.auth?.user?.id);

  useEffect(() => {
    getClient(userId).then((res) => {
      setisChampionLegacy(res?.data?.user?.isChampionLegacy);
    });
  }, [userId]);

  const { display, legacyDate: date } = useSelector(
    (state: any) => state?.auth
  );

  const { data: legacyData } = useGetLegacyStatementsQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  const answers = legacyData?.data?.Legacy?.filter((item: any) => item?.description);
  const [isOpen, setIsOpen] = useState(display);

  const tabsContent = {
    buttons: ["Championship Day", "Action Items", "My Challenges"],
    panels: [<ChampionDay />, <ActionItems />, <Challenges />],
  };

  const dispatch = useDispatch();
  const tabValue = useSelector(selectTabIndex);
  const checkForLegacyPopup = answers?.length && isChampionLegacy === true;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const actionType = searchParams.get('action');

  useEffect(() => {
    if (actionType === "challenge") {
      dispatch(setTabIndex(2));
    } else if (actionType === "action") {
      dispatch(setTabIndex(1));
    }
  }, [actionType]);
  
  return (
    <Layout>
      <Hero image={ClientHeroImage} isScoreMeter />
      {checkForLegacyPopup && (
        <LegacyStatementModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          display={display}
          date={date}
          data={answers}
        />
      )}
      <div className="container mx-auto py-[4.1rem] px-10 desktop:px-10">
        <div>
          <Tab.Group selectedIndex={tabValue}>
            <Tab.List className="w-full flex justify-between">
              {tabsContent.buttons.map((item: any, index: any) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      onClick={() => {
                        dispatch(setTabIndex(index));
                      }}
                      className={`text-[1.8rem] sm:text-[1.6rem] tablet:text-[3.27rem] font-medium focus:outline-none ${
                        selected ? "text-primary_light" : "text-white"
                      }`}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              {tabsContent.panels.map((item: any, index: any) => (
                <Tab.Panel key={index}>{item}</Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
