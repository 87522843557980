import { useEffect } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import HomeBanner from "assets/images/HomeBanner.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowDown from "assets/images/ArrowDown.svg";
import Calendar from "assets/images/Calendar.svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Listbox } from "@headlessui/react";
import { useCreateGroupCallMutation, useGetCoachesQuery, useGetClientsListQuery } from "apis/coach";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toast } from "react-toastify";
import Select from 'react-select';
import CloseIcon from "assets/icons/close-white.svg";
import PrimaryButton from "components/common/PrimaryButton";

dayjs.extend(customParseFormat);

interface IFormInputs {
  date: Date;
  endDate?: Date;
  recurrence: string;
  repeatEvery: string;
  occurrences?: string;
  sub_coaches?: string;
  duration: number
  title: string;
}

const schema = yup.object().shape({
  date: yup.date().required("Required").nullable(),
  endDate: yup.date().nullable().notRequired(),
  repeatEvery: yup.string().required("Required"),
  recurrence: yup.string().required("Required"),
  occurrences: yup.string(),
  sub_coaches: yup.string().nullable().notRequired(),
  title: yup.string().required("Required"),
  duration: yup.number().required("Required")
});

const duration = [30, 45, 60, 90];

const GroupCall = () => {
  const navigate = useNavigate();
  const [createGroupCall, { isLoading }] = useCreateGroupCallMutation();
  const { data: allCoaches, isSuccess: isCoachSuccess } = useGetCoachesQuery(1, { refetchOnMountOrArgChange: true });
  const { data: allClients, isSuccess: isClientSuccess } = useGetClientsListQuery(1, { refetchOnMountOrArgChange: true });
  const [coaches, setCoaches] = useState<any>([]);
  const [clients, setClients] = useState([]);
  const [selectedCoaches, setSelectedCoaches] = useState<any>([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [subCoach, setSubCoach] = useState<any>('');

  const autoReccuranceEndDate = new Date(Date.now() + 86400000);
  const [selectedDuration, setSelectedDuration] = useState(60);

  useEffect(() => {
    if (isCoachSuccess) {
      const coaches = allCoaches?.data?.coaches?.map((coach: any) => {
        return {
          id: coach?.id,
          label: coach?.full_name,
          value: coach?.id,
          email: coach?.email
        }
      });

      setCoaches(coaches)
    }

    if (isClientSuccess) {
      const clients = allClients?.data?.users?.map((client: any) => {
        return {
          id: client?.id,
          label: client?.full_name,
          value: client?.id,
          email: client?.email
        }
      });

      setClients(clients)
    }
  }, [isCoachSuccess, isClientSuccess])

  const {
    handleSubmit,
    control,
    register,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      date: new Date(dayjs().format("YYYY-MM-DDTHH:mm")),
      duration: 60,
      repeatEvery: "1",
      occurrences: "1",
      recurrence: "Daily",
      endDate: autoReccuranceEndDate,
      sub_coaches: "",
      title: "",
    },
  });

  function isTimeValid(input: any) {
    const modifiedTime: string = dayjs().subtract(1, "minute").format();

    const inputTime = dayjs(input);

    if (inputTime.isBefore(modifiedTime)) {
      return false;
    } else {
      return true;
    }
  }

  const onSubmit = async (data: IFormInputs) => {
    const response = isTimeValid(data.date);
    if (!response) {
      toast.error("Please schedule call in future time");
      return;
    }

    if (!selectedClients?.length && !selectedCoaches?.length) {
      toast.error("Atleast one of Coach or Client is required");
      return;
    }

    const members = [...selectedClients, ...selectedCoaches];

    const subCoachId = subCoach?.id;
    const payload = {
      title: data.title,
      date: dayjs(data.date).format(),
      duration: data.duration,
      endDate: dayjs(data.date).format("YYYY-MM-DD"),
      sub_coaches: subCoachId ? [subCoachId] : null,
      members: members,
      callType: "group"
    };

    await createGroupCall(payload)
      .unwrap()
      .then((res: any) => {
        navigate(`/coach/video-calls`);
        toast.success(res?.message);
      })
      .catch((error: any) => {
        toast.error(error?.data?.message);
      });
  };

  const selectCoachesHandler = (value: any) => {
    setSelectedCoaches(value);
  }

  const removeCoachHandler = (option: any) => {
    const updatedOptions = selectedCoaches?.filter(
      (selectedOption: any) => selectedOption.value !== option?.value
    );
    setSelectedCoaches(updatedOptions);
  }

  const selectClientHandler = (value: any) => {
    setSelectedClients(value);
  }

  const removeClientHandler = (option: any) => {
    const updatedOptions = selectedClients?.filter(
      (selectedOption: any) => selectedOption.value !== option?.value
    );
    setSelectedClients(updatedOptions);
  }

  const selectSubCoachHandler = (option: any) => {
    const temp = selectedCoaches?.filter((coach: any) => coach?.id !== option?.id);
    setSelectedCoaches(temp);
    setSubCoach(option);
  }

  const CustomMultiValue = () => {
    return null; // Return null to hide the selected options as chips
  };

  return (
    <Layout>
      <Hero image={HomeBanner} />
      <div className="container mx-auto py-16 px-10 desktop:px-10 pb-[30rem]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="text-white text-center lg:text-left text-[3.6rem] leading-none font-medium mt-0">
            Schedule Group Call
          </h1>

          <div className="flex flex-col  gap-6 sm:flex-row mt-[7.6rem] items-start">
            <h3 className="text-[2.7rem] text-white font-bold min-w-[20%]">Title</h3>
            <div className="w-[40rem] bg  h-[6rem] border-[0.2rem] border-[#FFFFFF] rounded-[1rem]">
              <input
                {...register("title")}
                style={{ background: "transparent" }}
                type="text"
                className="w-full !text-[2.8rem] !bg-transparent h-full font-normal text-[#FFFFFF] placeholder:text-[2.2rem]  focus:outline-none px-8"
                placeholder="Title"
              />
              {errors.title && (
                <p className="error">{errors?.title?.message}</p>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-6 sm:flex-row mt-[6rem] items-start">
            <h3 className="text-[2.7rem] text-white font-bold min-w-[20%]">Date</h3>

            <div className="relative">
              <img
                className="absolute w-[2.4rem] h-[2.4rem] !left-[2rem] sm:left-[1.4rem] !top-[1.8rem] sm:top-[2.2rem]"
                src={Calendar}
                alt="Calendar"
              />
              <img
                className="absolute right-[2rem] !top-[2.6rem]"
                src={ArrowDown}
                alt="ArrowDown"
              />
              <Controller
                control={control}
                name="date"
                defaultValue={new Date()}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <DatePicker
                      className="input-form-calendar !rounded-[1rem] !mt-0 !text-[2.8rem] !max-w-[40rem] !px-20 !h-[6rem]"
                      selected={value}
                      onChange={(date) => {
                        onChange(date);
                      }}
                      showTimeInput
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      name={name}
                      minDate={new Date()}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  );
                }}
              />
              {errors.date && <p className="error">{errors?.date?.message}</p>}
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-6 mt-[4.8rem] items-baseline">
            <h3 className="text-[2.7rem] text-white font-bold min-w-[20%]">Duration</h3>
            <div className="w-[22.7rem] h-[6rem]">
              <Listbox
                defaultValue={[]}
                onChange={(selected: any) => {
                  setSelectedDuration(selected);
                  setValue("duration", selected);
                  setError(
                    "duration",
                    { type: "focus" },
                    { shouldFocus: true }
                  );
                }}
              >
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block text-[2.6rem]">
                      {selectedDuration} min
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                      <img className="w-[1.6rem]" src={ArrowDown}></img>
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="z-50 absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {duration?.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        className="cursor-pointer text-center h-[4rem]"
                        value={option}
                        {...register("duration")}
                      >
                        {({ selected }) => {
                          return (
                            <>
                              <p
                                className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center   ${selected
                                  ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                  : "text-[#424242]"
                                  }`}
                              >
                                {option} min
                              </p>
                            </>
                          );
                        }}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
              {errors.recurrence && (
                <p className="error">{errors.recurrence?.message}</p>
              )}
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-6 mt-[4.8rem] items-baseline">
            <h3 className="text-[2.7rem] text-white font-bold min-w-[20%]">Select Coaches</h3>
            <div>
              <div className="w-[40rem]">
                <Select
                  closeMenuOnSelect={false}
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedCoaches}
                  onChange={(val) => selectCoachesHandler(val)}
                  isClearable={false}
                  isSearchable
                  name="coach"
                  options={coaches}
                  placeholder="Coaches"
                  isMulti
                  components={{ MultiValue: CustomMultiValue }}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: 'transparent',
                      borderColor: '#fff',
                      height: "50px",
                      boxShadow: "none",
                      fontSize: "22px",
                      color: '#fff',
                      paddingLeft: '10px',
                      borderWidth: '2px',
                      borderRadius: "10px",
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      color: '#fff'
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: '#fff'
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: '#fff'
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isSelected ? "#1e75c21f" : "#fff",
                      color: state.isSelected ? "#2165a2" : "#333333",
                      fontSize: "18px"
                    }),
                    noOptionsMessage: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: '20px'
                    })
                  }}
                />
              </div>

              <div>
                {selectedCoaches?.length && (
                  <div className="mt-[2rem] flex flex-wrap gap-4">
                    {selectedCoaches?.map((coach: any, idx: number) => (
                      <span key={idx} className="bg-[#374151] text-white text-4xl px-5 py-1 border border-[#fff] rounded-[8px] flex items-baseline gap-3 w-max">
                        {coach?.label}
                        <img onClick={() => removeCoachHandler(coach)} src={CloseIcon} className="w-[16px] h-[16px] cursor-pointer"></img>
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-6 mt-[4.8rem] items-baseline">
            <h3 className="text-[2.7rem] text-white font-bold min-w-[20%]">Select Clients</h3>
            <div>
              <div className="w-[40rem]">
                <Select
                  closeMenuOnSelect={false}
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedClients}
                  onChange={(val) => selectClientHandler(val)}
                  isClearable={false}
                  isSearchable
                  name="timezone"
                  options={clients}
                  placeholder="Clients"
                  isMulti
                  components={{ MultiValue: CustomMultiValue }}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: 'transparent',
                      borderColor: '#fff',
                      height: "50px",
                      boxShadow: "none",
                      fontSize: "22px",
                      color: '#fff',
                      paddingLeft: '10px',
                      borderWidth: '2px',
                      borderRadius: "10px",
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      color: '#fff'
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: '#fff'
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: '#fff'
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isSelected ? "#1e75c21f" : "#fff",
                      color: state.isSelected ? "#2165a2" : "#333333",
                      fontSize: "18px"
                    }),
                    noOptionsMessage: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: '20px'
                    })
                  }}
                />
              </div>
              <div>
                <div className="mt-[2rem] flex flex-wrap gap-4">
                  {selectedClients?.map((coach: any, idx: number) => (
                    <span key={idx} className="bg-[#374151] text-white text-4xl px-5 py-1 border border-[#fff] rounded-[8px] flex items-baseline gap-3 w-max">
                      {coach?.label}
                      <img onClick={() => removeClientHandler(coach)} src={CloseIcon} className="w-[16px] cursor-pointer"></img>
                    </span>
                  ))}
                </div>
              </div>

            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-6 mt-[4.8rem] items-baseline">
            <h3 className="text-[2.7rem] text-white font-bold min-w-[20%]">Substitute Hosts</h3>
            <div className="w-[40rem]">
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={subCoach}
                onChange={(val) => selectSubCoachHandler(val)}
                isClearable
                isSearchable
                name="timezone"
                options={coaches}
                placeholder="Sub Host"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: 'transparent',
                    borderColor: '#fff',
                    height: "50px",
                    boxShadow: "none",
                    fontSize: "22px",
                    color: '#fff',
                    paddingLeft: '10px',
                    borderWidth: '2px',
                    borderRadius: "10px",
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    color: '#fff'
                  }),
                  singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: '#fff'
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: '#fff'
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isSelected ? "#1e75c21f" : "#fff",
                    color: state.isSelected ? "#2165a2" : "#333333",
                    fontSize: "18px"
                  }),
                  noOptionsMessage: (baseStyles) => ({
                    ...baseStyles,
                    fontSize: '20px'
                  })
                }}
              />
            </div>
          </div>

          <div className="mt-[6rem]">
            <div className="text-center">
              <PrimaryButton
                loading={isLoading}
                color="primary"
                text="Schedule"
                type="submit"
                width="w-full sm:!w-[52.7rem] tablet:w-[22.8rem]"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default GroupCall;
