import { useState } from "react";
import TimeLineTabs from "../components/TimeLineTabs";
import { useGetClientCheckInsQuery } from "apis/coach";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import apiEndpoint from "config/environment";
import dayjs from "dayjs";
import { useGetActiveCheckinFormQuery } from "apis/client";

const WeeklyCheckInTab = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false);

  const { isError } = useGetActiveCheckinFormQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  const { data } = useGetClientCheckInsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const answers = data?.data;
  const userTimezone = dayjs.tz.guess();

  function getPDF() {
    return axios.get(`${apiEndpoint}/coach/checkin/${id}/pdf-download?timezone=${userTimezone}`, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }
    })
  }

  const date = dayjs().format("MM_DD_YYYY_hh_mm");
  const fileName = `CheckIn_${date}`

  const handleDownload = async () => {
    setLoading(true);
    getPDF() // API call
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName;
        link.click()
        toast.success("PDF Downloaded Successfully")
        setLoading(false);
      })
      .catch(() => {
        toast.error("Error Generating PDF");
        setLoading(false);
      })
  }

  return (
    <div>
      {isError ? (
        <>
          <p className="break-normal bg-white px-[3rem] py-[2rem] rounded-[1rem] text-[2rem] text-[red] mt-10">
            Oops! It looks like there are no active forms for you to fill out at this time. Please check back later or contact customer support if you have any questions
          </p>
        </>
      ) : (
        <>
          {(answers?.length > 0) ? (
            <>
              <div className="mt-10">
                <button
                  onClick={handleDownload}
                  className="flex items-center bg-[#2165A2] px-12 py-8 gap-3 rounded-2xl mb-10 ml-auto disabled:bg-opacity-70"
                  disabled={loading}
                >
                  <span className="text-white text-5xl font-medium">
                    {loading ? "Downloading PDF..." : "Download PDF"}
                  </span>
                </button>
                <TimeLineTabs answers={answers} />
              </div>
            </>
          ) : (
            <div className="bg-[#fff] px-5 py-10 rounded-lg mt-[5rem]">
              <p className="text-4xl text-center text-primary_blue">No Check-In Form Answers Found</p>
            </div>
          )
          }
        </>
      )}

    </div>
  );
};

export default WeeklyCheckInTab;
