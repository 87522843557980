import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useState } from "react";
import Modal from "./Modal";
import dayjs from "dayjs";

const localizer = momentLocalizer(moment);

const MyCalendar = ({
  myEventsList,
  deleteScheduleCall,
  handleNavigate,
  date,
  inProgressEvent
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();

  const OnSelectEvent = (event: any) => {
    setIsOpen(true);
    setSelectedEvent(event);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedEvent={selectedEvent}
        deleteScheduleCall={deleteScheduleCall}
        inProgressEvent={inProgressEvent}
      />
      
      <Calendar
        defaultView={Views.MONTH}
        views={['month']}
        // view={Views.MONTH}
        localizer={localizer}
        showAllEvents={true}
        toolbar={false}
        events={myEventsList || []}
        onSelectEvent={OnSelectEvent}
        // startAccessor="date"
        // endAccessor="endDate"
        date={date}
        style={{ height: 700 }}
        onNavigate={handleNavigate}
        components={{
          month: {
            event: (props: any) => (
              <div>
                {props?.event?.callType === "call" ? (
                  <h5 className="truncate">Call with {props?.event?.user?.full_name}</h5>
                ) : (
                  <h5 className="truncate">Group Call</h5>
                )}
                <h6>{dayjs(props?.event?.date).format("hh:mm A")}</h6>
              </div>
            ),
          },
        }}
      />
    </>
  );
};
export default MyCalendar;
