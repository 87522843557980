import CloseIcon from "assets/icons/cross-red.svg";
export default function index({ onClick }: any) {
  return (
    <div>
      <div className="bg-gray-100 h-screen">
        <div className="bg-white p-6  md:mx-auto">
          <div className="text-center mx-auto w-16 mb-3">
            <img src={CloseIcon} alt="close icon" className="h-[4em] " />
          </div>
          <div className="text-center">
            <h3 className="md:text-3xl text-base text-gray-900 font-semibold text-center">
              Error
            </h3>
            <p className="text-gray-600 mt-3 md:text-xl">Access Denied</p>

            <div className="py-10 text-center">
              <button
                type="button"
                onClick={onClick}
                className="text-white rounded-[1rem] bg-[#2165a2] border-solid border-[white]  !mt-0 border-[0.2rem] text-[1.4rem] font-medium w-[16rem] h-[3.6rem]"
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
