import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import ScheduledCallsCard from "components/ScheduledCallsCard";

import {
  useGetHuddleCallsQuery,
  useGetSubstituteCoachCallsQuery,
} from "apis/coach";

const CoachScheduledCalls = () => {
  const { data: huddleCall } = useGetHuddleCallsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const { data: SubstituteCalls } = useGetSubstituteCoachCallsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const scheduleCallsAdmin = huddleCall?.data && huddleCall?.data?.calls?.map((call: any) => {
    const temp = new Date(call.date).getTime() / 1000;
    return {
      ...call,
      filterDate: temp
    }
  })?.sort((a: any, b: any) => a?.filterDate - b?.filterDate) || []

  const SubstituteCoachCalls = SubstituteCalls?.data && SubstituteCalls?.data?.calls?.map((call: any) => {
    const temp = new Date(call.date).getTime() / 1000;
    return {
      ...call,
      filterDate: temp
    }
  })?.sort((a: any, b: any) => a?.filterDate - b?.filterDate) || [];

  const inProgressCall = false;
  const inProgressHuddle = false;

  return (
    <Layout>
      <Hero image={ClientHeroImage} heading="Scheduled Huddle Calls" />
      <div className="container mx-auto py-24 px-10 desktop:px-10">
        {scheduleCallsAdmin?.length === 0 && (
          <div className="bg-white px-4 py-14 mt-[3rem] rounded-md">
            <h2 className="text-primary_blue text-center text-4xl sm:text-5xl font-bold">
              You Have No Scheduled Calls.
            </h2>
          </div>
        )}
        {scheduleCallsAdmin?.length && (
          <div>
            <div className="grid grid-cols-1 gap-11 lg:grid-cols-3 md:grid-cols-2 mt-[1rem]">
              {scheduleCallsAdmin?.map((item: any) => {
                return (
                  <div key={item?.id}>
                    <ScheduledCallsCard
                      dateCreated={item?.date}
                      roomId={item?.roomId}
                      btntext="Start"
                      callType={"huddle"}
                      title={item?.title}
                      duration={item?.duration}
                      joinUrl={item?.join_url}
                      meetingId={item?.meeting_id}
                      coachName={item?.coach?.full_name}
                      action="start"
                      startUrl={item?.start_url}
                      occurrance_start={item?.occurrance_start}
                      startTime={item?.startTime}
                      inProgressCall={inProgressHuddle}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="container mx-auto py-24 px-10 desktop:px-10">
        <h1 className="text-white text-[3rem] sm:text-[4rem] text-center lg:text-left leading-none font-medium">
          Scheduled Substitute Calls
        </h1>
        {SubstituteCoachCalls?.length === 0 && (
          <div className="bg-white px-4 py-14 mt-[3rem] rounded-md">
            <h2 className="text-primary_blue text-center text-4xl sm:text-5xl font-bold">
              You Have No Scheduled Calls.
            </h2>
          </div>
        )}
        {SubstituteCoachCalls?.length && (
          <div>
            <div className="grid grid-cols-1 gap-11 lg:grid-cols-3 md:grid-cols-2 mt-[6rem]">
              {SubstituteCoachCalls?.map((item: any) => {
                return (
                  <div key={item?.id}>
                    <ScheduledCallsCard
                      dateCreated={item?.date}
                      roomId={item?.roomId}
                      btntext="Start"
                      callType={"call"}
                      title={item?.title}
                      duration={item?.duration}
                      joinUrl={item?.join_url}
                      meetingId={item?.meeting_id}
                      coachName={item?.coach?.full_name}
                      action="start"
                      startUrl={item?.start_url}
                      occurrance_start={item?.occurrance_start}
                      startTime={item?.startTime}
                      inProgressCall={inProgressCall}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CoachScheduledCalls;
