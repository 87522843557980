const TabContent = ({ content }: any) => {
  return (
    <>
      {content?.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className="py-[2.1rem] px-[3rem] border-b-2 border-[#C1C1C1] rounded-[0.4rem] w-full"
          >
            <>
              <h5 className="text-primary_blue capitalize font-semibold text-[2rem]  leading-[2.3rem] mb-2 ">
                {item?.checkInFormQuestions?.question || item?.question}
              </h5>
              <h2 className="text-[#333333] text-[2.4rem] leading-[3rem]">
                {item?.answer}
              </h2>
            </>
          </div>
        );
      })}
    </>
  );
};

export default TabContent;
