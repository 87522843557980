import CallsCard from "components/CallsCard";
import thumnailimage from "../assets/images/thumnailimage.png"
import { useGetVideoUrlQuery } from "apis/general";
export default function _renderCall({ item }: any) {
  const { data } = useGetVideoUrlQuery(
    item?.url,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <CallsCard
      id={item?.id}
      imgUrl={item?.thumbnail ? item?.thumbnail : thumnailimage}
      title={item?.title ? item?.title : null}
      desc={item?.detail ? item?.detail : null}
      watchTime={item?.watchTime ? item?.watchTime : null}
      postedTime={item?.roomId ? item?.date : item?.createdAt }
      url={data?.data?.uri}
      date={item?.date}
      coachName={item?.coach?.full_name}
    />
  );
}