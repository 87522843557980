import React, { useState } from "react";
import AuthLayout from "layouts/AuthLayout";
import PrimaryButton from "components/common/PrimaryButton";
import { useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";
import { verifyOtp } from "apis/auth-apis";

const VerifyOtp = () => {
  const [error, setError] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = (e: React.SyntheticEvent<EventTarget>): void => {
    e.preventDefault();
    if (!value && value.length !== 5) {
      setError(true);
      return;
    } else if (!error && value.length === 5) {
      verifyOtp(value, setLoading, navigate);
      // navigate("/create-password");
    }
  };

  return (
    <AuthLayout>
      <div className="bg-white rounded-2xl px-24 sm:px-36 py-28 auth_box">
        <h1 className="text-5xl font-bold mb-10">
          Enter OTP Verification Code
        </h1>
        <p className="font-normal text-3xl mb-4 cursor-pointer">
          Enter the verification code sent to your email address.
        </p>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="mt-12 mb-12">
            <PinInput
              length={5}
              initialValue=""
              onChange={(value, index) => {
                index !== 5 && setError(true);
              }}
              type="numeric"
              inputMode="number"
              onComplete={(value) => {
                setError(false);
                setValue(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {error && <p className="error">Enter Valid OTP</p>}
          </div>
          <PrimaryButton
            loading={loading}
            text="Submit"
            type="submit"
            color="primary"
          />
          <div className="mt-8">
            <p
              className="font-normal text-3xl mb-4 cursor-pointer"
              onClick={() => navigate("/reset-password")}
            >
              Didn&apos;t Get OTP Code?
            </p>
            <p
              className="font-semibold text-3xl mb-4 cursor-pointer"
              onClick={() => navigate("/reset-password")}
            >
              Resend
            </p>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default VerifyOtp;
