import {
  useEffect,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  useGetMyProfileQuery,
} from "apis/client";
import { toast } from "react-toastify";
import OnBoardingLayout from "layouts/OnBoardingLayout";
import GHLOnboardingComponent from "components/GHLForms/OnBoarding";
import { socket } from "config/socket";
import { getClient } from "apis/client-apis";
const OnboardingForm = () => {
  const navigate = useNavigate();

  const {
    data: userData,
    isSuccess: success,
    isError,
  } = useGetMyProfileQuery(0);
  const userId = useSelector((state: any) => state?.auth?.user?.id);

  const user: any = userData?.data?.user?.isOnboarded;

  useEffect(() => {
    getClient(userId).then((res) => {
      if (res?.data?.user?.isOnboarded == true) {
        navigate("/client");
      }
    });
  }, [userId]);

  useEffect(() => {
    const navigateHandler = (data:any) => {
      if(data?.user?.isOnboarded == true){
      navigate("/client");
      }
    };
    const reloadHandler = (data: any) => {
  
      if (data?.user=='' &&data?.reload == true) {
        toast.error(data?.message);

      } else if(data?.user?.id!==userId){
        toast.error(data?.message);

      }
      setTimeout(() => {
        window.location.reload();
       }, 2000);
    };
    socket.on(`gohighLevel`, reloadHandler);
    socket.on(`onboarding${userId}`, navigateHandler);
  
    return () => {
      socket.off(`onboarding${userId}`, navigateHandler);
      socket.off(`gohighLevel`, reloadHandler);
    };
  }, [userId, user]);

  useEffect(() => {
    if (user) {
      navigate("/client");
    }

    if (isError && !user) {
      navigate("/client");
    }
  }, [user]);

  return (
    <OnBoardingLayout>
      <div
        className="bg-white rounded-2xl mobile_l:px-30 px-20 pt-14 hide-scrollbar auth_box"
        id="scroll"
        style={{ height: "500px",overflowY:"auto", }}
      >
        <h1 className="text-5xl font-bold mb-4 text-center">
          Client Onboarding
        </h1>
        {success && (
          <GHLOnboardingComponent userData={userData}/>
        )}
      </div>
    </OnBoardingLayout>
  );
};

export default OnboardingForm;
