import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import LeaderBoardBulb from "assets/images/LeaderBoardBulb.svg";
import crossicon from "assets/images/crossicon.svg";
import Fb from "assets/images/Fb.svg";
import Insta from "assets/images/Insta.svg";
import Twit from "assets/images/Twit.svg";
import ProfileImg from "assets/images/profile.png";
import numberWithCommas from "utils/commaseperatedfunction";

const LeaderModal = ({ isOpen, setIsOpen, user }: any) => {
  function closeModal() {
    setIsOpen(false);
  }

  const checkLink = (url: string) => {
    // if (url?.indexOf("http://") != 0 || url?.indexOf("https://") != 0) {
    //   return `http://${url}`;
    // }
    return url;
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 max-w-[118rem] "
          onClose={closeModal}
        >
          <div
            className="fixed inset-0 bg-[#000] bg-opacity-60"
            aria-hidden="true"
          />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto py-56">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-[1rem] w-[90rem] bg-white pt-[2.1rem] pb-[5.9rem] pr-[2.2rem] pl-[2.2rem] align-middle shadow-xl transition-all">
                  <div className="flex  justify-between">
                    <div className="w-[12rem] h-[12rem] tablet:w-[20rem] tablet:h-[20rem] rounded-[1rem] border-white border-[0.3rem] border-solid shadow-[0_0.715554rem__1.43111rem_rgba(0,0,0,0.25)]">
                      <img
                        className="w-full h-full overflow-hidden rounded-[1rem] object-cover"
                        src={
                          user?.profile_photo ? user?.profile_photo : ProfileImg
                        }
                        alt="modalpic"
                      />
                    </div>
                    <div className=" flex items-center sm:gap-3">
                      <div className="sm:w-[9.6rem] w-[6rem] h-[6rem] sm:h-[12.2rem] ">
                        <img
                          className="w-full h-full"
                          src={LeaderBoardBulb}
                          alt="bulb"
                        />
                      </div>
                      <div className="flex flex-col mr-[5rem] sm:mr-[20rem]">
                        <h4 className="text-[3.5rem] font-bold sm:text-[6rem] sm:leading-[7.1rem]  text-[#2165A2]">
                          {numberWithCommas(user?.winRating >= 0 ? user?.winRating : 0)}
                        </h4>
                        <h4 className=" font-normal text-[2rem] sm:text-[5rem] sm:leading-[6.3rem]  text-[#C0C0C0]">
                          Win Rating
                        </h4>
                      </div>
                    </div>
                    <div className="  cursor-pointer w-[2.5rem] h-[2.5rem] md:w-[3rem] md:h-[3rem] sm:w-[3rem] sm:h-[3rem]">
                      <img
                        className="w-full h-full"
                        src={crossicon}
                        alt="crossicon"
                        onClick={closeModal}
                      />
                    </div>
                  </div>

                  <div className=" max-w-[67.4rem] mx-auto mt-[5.3rem]">
                    <form className="schedule_call_input">
                      <div className="flex flex-col items-start">
                        <label
                          className="!text-[#B3B3B3] !font-normal !pb-0 !text-start"
                          htmlFor=""
                        >
                          Name
                        </label>
                        <input
                          className="!bg-[#ffff] !text-[2.25rem] !text-[#605F5F] placeholder:text-[#605F5F] sm:placeholder:text-[2.25rem] placeholder:text-[2rem] !h-24 !border-[0.19rem] !border-solid !mt-[0.7rem] !rounded-[1rem] !border-[#B3B3B3] placeholder:opacity-40"
                          placeholder="Jesica Mike"
                          disabled
                          value={user?.full_name}
                          type="text"
                        />
                      </div>
                      <div className="flex flex-col items-start mt-[1.9rem]">
                        <label
                          className="!text-[#B3B3B3] !font-normal !pb-0 !text-start"
                          htmlFor=""
                        >
                          Company Name
                        </label>
                        <input
                          className="!bg-[#ffff] !text-[2.25rem] !text-[#605F5F] placeholder:text-[#605F5F] sm:placeholder:text-[2.25rem] placeholder:text-[2rem] !h-24 !border-[0.19rem] !border-solid !mt-[0.7rem] !rounded-[1rem] !border-[#B3B3B3] placeholder:opacity-40"
                          placeholder="Company Name"
                          disabled
                          value={user?.company}
                          type="text"
                        />
                      </div>
                      <div className="flex flex-col items-start mt-[1.9rem]">
                        <label
                          className="!text-[#B3B3B3] !font-normal !pb-0 !text-start"
                          htmlFor=""
                        >
                          E-mail
                        </label>
                        <input
                          className="!bg-[#ffff] !text-[2.25rem] !text-[#605F5F] placeholder:text-[#605F5F] sm:placeholder:text-[2.25rem] placeholder:text-[2rem] !h-24 !border-[0.19rem] !border-solid !mt-[0.7rem] !rounded-[1rem] !border-[#B3B3B3] placeholder:opacity-40"
                          disabled
                          value={user?.email}
                          type="text"
                        />
                      </div>
                      <div className="flex flex-col items-start mt-[1.9rem]">
                        <label
                          className="!text-[#B3B3B3] !font-normal !pb-0 !text-start"
                          htmlFor=""
                        >
                          Phone
                        </label>
                        <input
                          className="!bg-[#ffff] !text-[2.25rem] !text-[#605F5F] placeholder:text-[#605F5F] sm:placeholder:text-[2.25rem] placeholder:text-[2rem] !h-24 !border-[0.19rem] !border-solid !mt-[0.7rem] !rounded-[1rem] !border-[#B3B3B3] placeholder:opacity-40"
                          placeholder="+009-1122334455"
                          disabled
                          value={user?.phoneNo}
                          type="text"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="mt-[5rem] gap-[1.5rem] sm:gap-0 grid sm:grid sm:grid-cols-3">
                    <div className="">
                      <h3 className="font-bold text-[3.5em] text-[#2165A2] leading-[5.7rem]">
                        {user?.actionItems?.length +
                          user?.championships?.length}
                      </h3>
                      <h3 className="text-[#C0C0C0] text-[2.8rem] leading-[3.88rem]">
                        Tasks{" "}
                      </h3>
                      <h3 className="text-[#C0C0C0] text-[2.8rem] leading-[3.88rem]">
                        Completed
                      </h3>
                    </div>
                    <div className="sm:border-x-2 sm:border-[#C0C0C0]">
                      <h3 className="font-bold text-[3.5rem] text-[#2165A2] leading-[5.7rem]">
                        {user?.challengesWon}
                      </h3>
                      <h3 className="text-[#C0C0C0] text-[2.8rem] leading-[3.88rem]">
                        Won
                      </h3>
                      <h3 className="text-[#C0C0C0] text-[2.8rem] leading-[3.88rem]">
                        Challenges
                      </h3>
                    </div>
                    <div className="">
                      <h3 className="font-bold text-[3.5rem] text-[#2165A2] leading-[5.7rem]">
                        {user?.challengesInProgress}
                      </h3>
                      <h3 className="text-[#C0C0C0] text-[2.8rem] leading-[3.88rem]">
                        In Progress
                      </h3>
                      <h3 className="text-[#C0C0C0] text-[2.8rem] leading-[3.88rem]">
                        Challenges
                      </h3>
                    </div>
                  </div>
                  <div className="mt-[5rem]">
                    {(user?.facebook || user?.instagram || user?.twitter) && (
                      <h1 className="mb-[4rem] tablet:mt-[10rem] leading-[6.6rem]text-center text-[#2165A2] text-[3rem] sm:text-[5rem] font-normal">
                        Connect
                      </h1>
                    )}

                    <div className="gap-8 px-[2.8rem] tablet:px-0 mx-auto flex justify-center">
                      {user?.facebook && (
                        <a href={checkLink(user?.facebook)} target="_blank" rel="noreferrer" >
                          <div className="w-[7rem]">
                            <img
                              className="w-full h-full"
                              src={Fb}
                              alt="Facebook"
                            />
                          </div>
                        </a>
                      )}

                      {user?.instagram && (
                        <a href={user?.instagram} target="_blank" rel="noreferrer">
                          <div className="w-[7rem]">
                            <img
                              className="w-full h-full"
                              src={Insta}
                              alt="Instagram"
                            />
                          </div>
                        </a>
                      )}
                      {user?.twitter && (
                        <a href={user?.twitter} target="_blank" rel="noreferrer">
                          <div className="w-[7rem]">
                            <img
                              className="w-full h-full"
                              src={Twit}
                              alt="Twitter"
                            />
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default LeaderModal;
