import Hero from 'components/Hero';
import Layout from 'layouts/Layout';
import HomeBanner from 'assets/images/HomeBanner.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ArrowDown from 'assets/images/ArrowDown.svg';
import Calendar from 'assets/images/Calendar.svg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import PrimaryButton from 'components/common/PrimaryButton';
import {
  useUpdateScheduleCallMutation,
  useGetByIdScheduleCallQuery,
  useGetCoachesQuery,
  useRemoveCoachMutation
} from 'apis/coach';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { toast } from 'react-toastify';
import CloseIcon from '../../../assets/images/CloseIcon.svg';
import { isFetchBaseQueryError } from 'apis/client';
dayjs.extend(customParseFormat);

interface IFormInputs {
  date?: Date;
  endDate: Date;
  recurrence: string;
  repeatEvery: string;
  occurrences?: string;
  sub_coaches: string[];
}

const schema = yup.object().shape({
  date: yup.date().required('Required').nullable(),
  endDate: yup.date().nullable().notRequired(),
  repeatEvery: yup.string().required('Required'),
  recurrence: yup.string().required('Required'),
  occurrences: yup.string().nullable(),
  sub_coaches: yup.array()
});

const options = ['Daily', 'Weekly', 'Monthly'];
const days = ['1', '2', '3', '4', '5', '6', '7'];

const EditScheduleCall = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selected, setSelected] = useState('');
  const { data, isFetching, isLoading } = useGetByIdScheduleCallQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  });
  const [selectedDay, setSelectedDay] = useState('');
  const [occurrences, setOccurrences] = useState('');
  const [otherCoaches, setOtherCoaches] = useState([]);
  const [checked, setChecked] = useState('by');
  const [currentUsers, setCurrentUsers] = useState<any[]>([]);
  const [removeCoach] = useRemoveCoachMutation();
  const {
    data: allCoaches,
    isFetching: allFetching,
    isSuccess: successCoaches
  } = useGetCoachesQuery(1, { refetchOnMountOrArgChange: true });
  const selectedCoaches = data && data?.data?.calls?.SubstituteCoaches;
  const [updateScheduleCall] = useUpdateScheduleCallMutation();
  const clientId = data?.data?.calls?.clientId;

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (data?.data) {
      setValue(
        'date',
        new Date(
          dayjs(data && data?.data?.calls?.date).format('MM/DD/YYYY HH:mm')
        )
      );
      setValue(
        'endDate',
        new Date(dayjs(data && data?.data?.calls?.endDate).format('MM/DD/YYYY'))
      );
      setValue('recurrence', data && data?.data?.calls?.recurrence);
      setValue('occurrences', data && data?.data?.calls?.occurrences);
      setValue('repeatEvery', data && data?.data?.calls?.repeatEvery);
      setSelectedDay(data?.data?.calls?.repeatEvery);
      setOccurrences(data?.data?.calls?.occurrences);
      setSelected(data?.data?.calls?.recurrence);
    }
  }, [isFetching, allFetching]);

  const currentUsersHandler = () => {
    if (successCoaches) {
      const temp =
        allCoaches &&
        allCoaches?.data?.coaches?.filter((item: any) => {
          return !selectedCoaches?.find(function (e: any) {
            return e?.user?.id === item?.id;
          });
        });
      setCurrentUsers([...temp]);
    }
  };

  useEffect(() => {
    if (successCoaches) {
      currentUsersHandler();
    }
  }, [isFetching]);

  const onSubmit = async (data: IFormInputs) => {
    const end = dayjs(data?.endDate);
    const start = dayjs(data?.date);
    const endDate = data?.endDate;
    const occurrences = data?.occurrences;
    const dateCheck = end.isBefore(start, "date");

    if (dateCheck && checked === "by") {
      toast.error("EndDate should be greater than startDate")
      return
    }

    if (
      (endDate?.toString() === '' && checked === 'by') ||
      (endDate === undefined && checked === 'by') ||
      (endDate === null && checked === 'by')
    ) {
      toast.error('Select endDate ');
      return;
    }
    if (
      (occurrences === '' && checked === 'after') ||
      (occurrences === undefined && checked === 'after') ||
      (occurrences === null && checked === 'after')
    ) {
      toast.error('Select occurrences ');
      return;
    }

    const payload = {
      date: dayjs(data.date).format('MM/DD/YYYY HH:mm'),
      endDate:
        checked === 'by'
          ? dayjs(data.endDate).format('MM/DD/YYYY')
          : dayjs(data.date).format('MM/DD/YYYY'),
      recurrence: data.recurrence,
      repeatEvery: data.repeatEvery,
      occurrences: checked === 'after' ? data.occurrences : null,
      clientId: clientId,
      sub_coaches: data.sub_coaches
    };
    await updateScheduleCall({ id, payload })
      .unwrap()
      .then((res: any) => {
        toast.success(res?.message);
        navigate(`/coach/schedule-call-calendar/${clientId}`);
      })
      .catch((error: any) => {
        toast.error(error?.data?.message);
      });
  };

  function handleEndType(e: any) {
    setChecked(e.target.value);
  }

  function handleRecurrenceChange(data: any) {
    setSelected(data);
    setValue('recurrence', data);
  }

  const handleRemoveUser = async (id: any) => {
    const payload = {
      call_id: data?.data?.calls?.id,
      coach_id: id
    };

    try {
      await removeCoach({ payload: payload })
        .unwrap()
        .then(res => {
          toast.success(res?.message);

          currentUsersHandler();
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  };

  return (
    <Layout>
      <Hero image={HomeBanner} />
      {isLoading ? (
        <h1 className="text-white text-center text-[2.4rem]">Loading...</h1>
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container mx-auto py-28 px-10 desktop:px-10 pb-[20rem]">
          <h1 className="text-white text-center lg:text-left text-[3.6rem] leading-none font-medium mt-0">
            Schedule Video Call
          </h1>

          <div className="flex flex-col gap-6 sm:flex-row sm:gap-[18.6rem] mt-[7.6rem] items-start">
            <h3 className="text-[2.7rem] text-white font-bold">Date</h3>

            <div className="relative">
              <img
                className="absolute w-[2.4rem] h-[2.4rem] !left-[2rem] sm:left-[1.4rem] !top-[1.8rem] sm:top-[2.2rem]"
                src={Calendar}
                alt="Calendar"
              />
              <img
                className="absolute right-[2rem] !top-[2.6rem]"
                src={ArrowDown}
                alt="ArrowDown"
              />
              <Controller
                control={control}
                name="date"
                defaultValue={new Date()}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...rest } }) => {
                  return (
                    <DatePicker
                      className="input-form-calendar !rounded-[1rem] !mt-0 !text-[2.8rem] !max-w-[35rem] !h-[6rem]"
                      selected={value}
                      onChange={onChange}
                      {...rest}
                      showTimeInput
                      timeInputLabel="Time:"
                      dateFormat="dd-MM-yyyy h:mm aa"
                      minDate={new Date()}
                    />
                  );
                }}
              />
              {errors.date && <p className="error">{errors?.date?.message}</p>}
            </div>
          </div>

          <div className="flex flex-col sm:flex-row items-start gap-2 sm:items-start mt-[5.2rem]">
            <div className=" flex flex-col sm:flex-row gap-4 sm:gap-[6.7rem] items-left">
              <h3 className="text-[2.7rem] text-white font-bold">
                Recurring Call
              </h3>
              <input type="checkbox" className="w-[2.6rem] h-[2.6rem]" />
            </div>
            <h3 className="text-[1.8rem] text-white">
              <span>
                {' '}
                {`Every ${selected} days, until ${'Jan 26, 2023'}, ${7} occurrences(s)`}
              </span>
            </h3>
          </div>
          <div className=" flex flex-col sm:flex-row gap-6 sm:gap-[10.2rem] mt-[4.8rem] items-start">
            <h3 className="text-[2.7rem] text-white font-bold">Recurrence</h3>
            <div className="w-[22.7rem] h-[6rem]">
              <Controller
                control={control}
                name="recurrence"
                defaultValue=""
                render={({
                  field: { onChange, value, name, ...restField }
                }) => (
                  <Listbox
                    {...restField}
                    onChange={(selected: any) => {
                      onChange(selected);
                      handleRecurrenceChange(selected);
                    }}
                    value={value}
                    name={name}
                  >
                    <div className="relative">
                      <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        {!selected && (
                          <span className="block  text-[2.6rem]">
                            {'Please Select'}
                          </span>
                        )}
                        <span className="block text-[2.6rem]">{selected}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                          <img className="w-[1.6rem]" src={ArrowDown}></img>
                        </span>
                      </Listbox.Button>
                      <Listbox.Options className="z-50 absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {options.map((option, index) => (
                          <Listbox.Option
                            key={index}
                            className="cursor-pointer text-center h-[4rem]"
                            value={option}
                          >
                            {({ selected }) => (
                              <>
                                <p
                                  className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center   ${selected
                                    ? 'text-primary_blue bg-primary_blue bg-opacity-[20%]'
                                    : 'text-[#424242]'
                                    }`}
                                >
                                  {option}
                                </p>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                )}
              />
              {errors.recurrence && (
                <p className="error">{errors.recurrence?.message}</p>
              )}
            </div>
          </div>

          <div className=" flex flex-col sm:flex-row gap-6 sm:gap-[8.3rem] mt-[4.8rem] items-start">
            <h3 className="text-[2.7rem] text-white font-bold">Repeat Every</h3>
            <div className="flex gap-4 items-center">
              <div className="w-[22.7rem] h-[6rem]">
                <Listbox
                  defaultValue=""
                  value={selectedDay}
                  onChange={(selected: any) => {
                    setSelectedDay(selected);
                    setValue('repeatEvery', selected);
                  }}
                >
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      {!selectedDay && (
                        <span className="block  text-[2.6rem]">
                          {'Please Select'}
                        </span>
                      )}
                      <span className="block text-[2.6rem]">{selectedDay}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                        <img className="w-[1.6rem]" src={ArrowDown}></img>
                      </span>
                    </Listbox.Button>
                    <Listbox.Options className="z-50 absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {days.map((days, index) => (
                        <Listbox.Option
                          key={index}
                          className="cursor-pointer text-center h-[4rem]"
                          value={days}
                          {...register('repeatEvery')}
                        >
                          {({ selected }) => (
                            <>
                              <p
                                className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center   ${selected
                                  ? 'text-primary_blue bg-primary_blue bg-opacity-[20%]'
                                  : 'text-[#424242]'
                                  }`}
                              >
                                {days}
                              </p>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                  {errors.repeatEvery && (
                    <p className="error">{errors.repeatEvery?.message}</p>
                  )}
                </Listbox>
              </div>
              <h3 className="text-[2.7rem] text-white">Days</h3>
            </div>
          </div>

          <div className=" flex flex-col sm:flex-row gap-6 sm:gap-[3.6rem] mt-[7.6rem] items-start">
            <h3 className="text-[2.7rem] text-white font-bold">End Date</h3>
            <div className="flex gap-4 items-center">
              <input
                type="radio"
                name="by"
                value="by"
                defaultChecked={checked === 'by' ? true : false}
                onChange={handleEndType}
                className="w-[2rem] h-[2rem]"
              />
              <h3 className="text-[2.7rem] text-white">By</h3>
            </div>
            <div className="relative">
              <img
                className="absolute w-[2.4rem] h-[2.4rem] !left-[2rem] sm:left-[1.4rem] !top-[1.8rem] sm:top-[2.2rem]"
                src={Calendar}
                alt="Calendar"
              />
              <img
                className="absolute right-[2rem] !top-[2.6rem]"
                src={ArrowDown}
                alt="ArrowDown"
              />
              <Controller
                control={control}
                name="endDate"
                //defaultValue={new Date()}
                rules={{ required: true }}
                render={({ field: { onChange, value, name, ...rest } }) => {
                  return (
                    <DatePicker
                      className="input-form-calendar  !rounded-[1rem] !mt-0 !text-[2.8rem] !max-w-[35rem] !h-[6rem]"
                      selected={value}
                      onChange={onChange}
                      {...rest}
                      name={name}
                      disabled={checked === 'after'}
                      minDate={new Date()}
                    />
                  );
                }}
              />
              {errors.endDate && (
                <p className="error">{errors?.endDate?.message}</p>
              )}
            </div>
          </div>
          <div className=" flex flex-col sm:flex-row gap-6 sm:gap-[14rem] mt-[7.6rem] items-start">
            <h3 className="text-[2.7rem] text-white font-bold"></h3>
            <div className="flex flex-col sm:flex-row gap-4 items-start">
              <div className="flex gap-4 items-center">
                <input
                  type="radio"
                  name="by"
                  value="after"
                  defaultChecked={checked === 'after' ? true : false}
                  onChange={handleEndType}
                  className="w-[2rem] h-[2rem]"
                />
                <h3 className="text-[2.7rem] text-white">After</h3>
              </div>
              <div className="w-[22.7rem] h-[6rem]">
                <Listbox
                  disabled={checked === 'by'}
                  value={occurrences}
                  onChange={(selected: any) => {
                    setOccurrences(selected);
                    setValue('occurrences', selected);
                  }}
                >
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      {!occurrences && (
                        <span className="block  text-[2.6rem]">
                          {'Please Select'}
                        </span>
                      )}
                      <span className="block text-[2.6rem]">{occurrences}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                        <img className="w-[1.6rem]" src={ArrowDown}></img>
                      </span>
                    </Listbox.Button>
                    <Listbox.Options className="z-50 absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {days.map((days, index) => (
                        <Listbox.Option
                          key={index}
                          className="cursor-pointer text-center h-[4rem]"
                          value={days}
                          {...register('occurrences')}
                        >
                          {({ selected }) => (
                            <>
                              <p
                                className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center   ${selected
                                  ? 'text-primary_blue bg-primary_blue bg-opacity-[20%]'
                                  : 'text-[#424242]'
                                  }`}
                              >
                                {days}
                              </p>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                </Listbox>
                {errors.occurrences && (
                  <p className="error">{errors?.occurrences?.message}</p>
                )}
              </div>
              <h3 className="text-[2.7rem] text-white">occurrences</h3>
            </div>
          </div>
          <div className="mt-[8.1rem] mb-32">
            <div className=" flex gap-6 sm:gap-[23.6rem] mt-[4.8rem] items-start">
              <div className="w-full sm:w-[52.7rem] h-[6rem]">
                <Listbox
                  multiple
                  value={otherCoaches}
                  onChange={(selected: any) => {
                    const data: any[] = [];
                    selected.map((item: any) => {
                      data.push(item?.id);
                    });
                    setOtherCoaches(selected);
                    setValue('sub_coaches', data);
                  }}
                >
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      {otherCoaches.length == 0 && (
                        <span className="block text-[2.6rem]">
                          Select Coach
                        </span>
                      )}
                      {otherCoaches?.map((item: any, idx:number) => {
                        return (
                          <span key={idx} className="text-[1.8rem] mr-3">
                            {item?.full_name}
                          </span>
                        );
                      })}
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                        <img className="w-[1.6rem]" src={ArrowDown}></img>
                      </span>
                    </Listbox.Button>
                    <Listbox.Options className="z-50 absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {currentUsers?.map((coach: any, index: any) => (
                        <Listbox.Option
                          key={index}
                          className="cursor-pointer text-center h-[4rem]"
                          value={coach}
                          {...register('sub_coaches')}
                        >
                          {({ selected }) => (
                            <>
                              <p
                                className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center   ${selected
                                  ? 'text-primary_blue bg-primary_blue bg-opacity-[20%]'
                                  : 'text-[#424242]'
                                  }`}
                              >
                                {coach?.full_name}
                              </p>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                    {errors.sub_coaches && (
                      <p className="error">{errors?.sub_coaches?.message}</p>
                    )}
                  </div>
                </Listbox>
                {
                  <div className="flex gap-3 mt-6 flex-wrap">
                    {selectedCoaches?.map((coach: any, idx: number) => (
                      <div key={idx} className="px-8 py-2 bg-white font-bold rounded-lg flex justify-between items-center">
                        <span className="text-3xl capitalize text-primary_blue px-8 py-2 bg-white font-bold rounded-lg whitespace-nowrap">
                          {coach?.user?.full_name}
                        </span>
                        <div className="w-[2rem] h-[2rem] cursor-pointer">
                          <img
                            onClick={() => handleRemoveUser(coach?.user?.id)}
                            className="w-full h-full"
                            src={CloseIcon}
                            alt="Close Icon"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                }

                <div className="mt-6 mb-6">
                  {/* <Link to="/coach/schedule-call-calendar"> */}
                  <div className="text-center">
                    <PrimaryButton
                      color="primary"
                      text="Schedule"
                      type="submit"
                      width="w-full sm:!w-[52.7rem] tablet:w-[22.8rem]"
                    />
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default EditScheduleCall;
