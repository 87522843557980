import EditDelDropDown from "components/common/EditDelDropDown";
import dayjs from "dayjs";

const NotesCard = ({ data, index, length }: any) => {
  const splitData = data?.note?.split("\n");
  return (
    <div className="flex flex-col tablet:flex-row mb-8 rounded-[0.6rem]">
      <div className="rounded-t-[0.6rem] rounded-b-none tablet:rounded-t-none tablet:rounded-l-[0.6rem] bg-primary_blue">
        <h3 className=" flex text-center items-center font-medium text-4xl sm:text-5xl text-white py-[1rem] px-[1.2rem] h-full">
          {index + 1}
        </h3>
      </div>
      <div className="grid grid-cols-12 bg-[#ffffff] py-[2.2rem] px-[2.6rem] w-full rounded-b-[0.6rem] rounded-t-none tablet:rounded-b-none tablet:rounded-r-[0.6rem]">
        <div
          className="grid sm:col-span-9 col-span-12 mr-12 sm-mr-0"
          id="scroll"
        >
          <div id="notes">
            <ul style={{ wordBreak: "break-word" }} className="pl-[3.2rem] font-bold text-4xl sm:text-5xl text-primary_blue break-all">
              {splitData?.map((item: any, idx:number) => {
                if (item) {
                  return <li key={idx}>{item}</li>
                }
              })}
            </ul>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-3 flex justify-end items-center">
          <div className="flex gap-14 justify-end">
            <div className="flex gap-8">
              <h4 className="font-semibold text-2xl sm:text-4xl  text-[#333333]">
                {dayjs(data?.createdAt).format("MM/DD/YYYY")}
              </h4>
              <EditDelDropDown
                id={data?.id}
                data={data}
                index={index}
                length={length}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesCard;
