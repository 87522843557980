import AuthLayout from "layouts/AuthLayout";
import PrimaryButton from "components/common/PrimaryButton";
import { useNavigate } from "react-router-dom";
import TextInput from "components/common/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { forgotPassword } from "apis/auth-apis";
import { useState } from "react";

interface IFormInputs {
  email: string;
}

const schema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Invalid Email format")
    .required(" Email is required"),
});
const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFormInputs) => {
    forgotPassword(data.email, setLoading, navigate);
  };

  return (
    <AuthLayout>
      <div className="bg-white rounded-2xl px-24 sm:px-36 py-28 auth_box">
        <h1 className="text-5xl font-bold mb-16">Reset your password</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="mb-4">
            <TextInput
              name="email"
              type="email"
              label="Email"
              register={register}
              palceholder="Enter Email "
            />
            {errors.email && <p className="error">{errors.email?.message}</p>}
          </div>
          <div className="text-start">
            <p className="font-normal text-3xl mb-4 cursor-pointer">
              Enter the email address associated with your account, and we&apos;ll
              send you an OTP code to reset your password
            </p>
          </div>
          <PrimaryButton
            loading={loading}
            text="Send Reset Link"
            type="submit"
            color="primary"
          />
        </form>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
