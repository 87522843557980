//import useAuth from "../hooks/useAuth";
import {Navigate} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import {logoutAsync} from 'store/reducers/auth.reducer';
import {useDispatch} from 'react-redux';

const PrivateRoute = ({permissions, children}: any) => {
 const auth = useAuth();
 const dispatch = useDispatch();

 if (auth?.user === undefined) {
  dispatch(logoutAsync());
  return <Navigate to='/login' />;
 }

 const userRole = auth?.user?.role;
 const found = permissions?.some((p: string) => userRole?.indexOf(p) >= 0);

 if (auth.token && !found) {
    dispatch(logoutAsync());
//   return <Navigate to='/not-authorized' />;
 }
 return auth.token ? children : <Navigate to='/' />;
};

export default PrivateRoute;
