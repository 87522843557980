import { Tab } from "@headlessui/react";
import dayjs from "dayjs";
import TabContent from "./TabContent";

const FormsTabs = ({ startDate, endDate, answers }: any) => {

  const filteredFormData = answers?.filter((form: any) => {
    const start = dayjs(startDate).startOf('day');
    const end = dayjs(endDate).endOf('day');
    const date = dayjs(form?.date);
    return date.isAfter(start) && date.isBefore(end);
  });

  return (
    <>
      {filteredFormData?.length === 0 ? (
        <div className="bg-[#fff] px-5 py-10 rounded-lg mt-[5rem]">
          <p className="text-4xl text-center text-primary_blue">No Check-In Data Found</p>
        </div>
      ) : (
        <Tab.Group>
          <div className="sm:flex sm:flex-row flex-col mb-12 sm:items-start">
            <Tab.List className=" flex flex-row pb-8 overflow-x-auto sm:overflow-visible sm:flex-col sm:border-b-0  sm:border-l-2 sm:border-[#ffffff] tabs_blk self-start">
              {filteredFormData?.map((item: any, index: number) => {
                return (
                  <Tab key={index} className={({ selected }) => (
                    selected ? "text-[#299aff] text-[1.5rem] tablet:text-[2.4rem] sm:mb-[3rem] leading-[3.5rem] font-bold px-8 pb-[1rem] tablet:pb-0  sm:pl-4 form_dot border-b-2 border-[#ffffff] sm:border-b-0 active focus:outline-none" :
                      "form_dot sm:pl-4 sm:p-0 border-b-2 border-[#ffffff] text-white px-8 pb-[1rem] tablet:pb-0 text-[1.5rem] tablet:text-[2.4rem] leading-[3.5rem] sm:border-b-0  sm:mb-[3rem] focus:outline-none"
                  )
                  }>
                    <p>{dayjs(item?.date).format("MM/DD/YYYY")}</p>
                    <p>{dayjs(item?.date).format("hh:mm A")}</p>
                  </Tab>
                )
              })}
            </Tab.List>
            <Tab.Panels className="inline-block bg-[#ffffff] sm:ml-[2.5rem] mt-[3.5rem] sm:mt-0 w-full">
              {filteredFormData?.map((item: any, index: number) => {
                return (
                  <Tab.Panel key={index}>
                    <TabContent
                      answers={filteredFormData}
                      content={item?.data} />
                  </Tab.Panel>
                )
              })}
            </Tab.Panels>
          </div>
        </Tab.Group>
      )}
    </>
  );
};
export default FormsTabs;
