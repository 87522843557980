import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useLazyGetMeetingDetailsQuery } from "apis/general";

const ScheduledCallsCard = ({ btntext, dateCreated, callType, title, duration, joinUrl, meetingId, coachName, action, startUrl, occurrance_start, startTime, inProgressCall }: any) => {
  const [getMeetingDetails, results] = useLazyGetMeetingDetailsQuery();

  const joinCallHandler = async () => {
    await getMeetingDetails(meetingId).unwrap().then((res) => {
      const meeting = res.data.meeting;

      if (meeting && action === "start") {
        if (startUrl) {
          window.open(startUrl, "_blank", "noopener,noreferrer");
        } else {
          toast.warn("Meeting is not started by Host")
        }
        return;
      }

      if (meeting && action !== "start" && meeting.status === "started") {
        if (joinUrl) {
          window.open(joinUrl, "_blank", "noopener,noreferrer")
        }
      } else {
        toast.info("Meeting is not started by Host")
      }

    });
  }

  const date = occurrance_start ? occurrance_start : startTime;
  const isSame = dayjs(date).isSame(dayjs(), "day");

  return (
    <div className="bg-white px-10 py-12 rounded-[0.36rem] shadow-lg shadow-[#333333]-500 h-[25rem] flex flex-col justify-between">
      {title && (
        <h5 className="text-[#2165A2] text-[2.8rem] font-extrabold truncate leading-none text-start min-h-[3rem]">{title}</h5>
      )}
      <div className="flex mt-7 gap-10">
        <h6 className="text-[#333333] text-4xl leading-none">
          {dayjs(dateCreated).format("MMM DD, YYYY")}
        </h6>
        <div className="w-1 bg-primary_blue"></div>
        <h6 className="text-[#333333] text-4xl leading-none">
          {dayjs(dateCreated).format("hh:mm A")}
        </h6>
      </div>
      <div className="mt-10">
        {(coachName && callType === "call") ? (
          <h6 className="text-primary_blue text-4xl leading-none">
            Coach: <span className="font-semibold">{coachName}</span>
          </h6>
        ) : ""}
      </div>
      <div className="mt-3 flex justify-between items-center">
        <div>
          <h6 className="text-primary_blue text-4xl leading-none">
            Durations: <span className="font-semibold">{duration} min</span>
          </h6>
        </div>
      </div>
      <div className="mt-3 flex justify-between items-center">
      <div>
          <h6 className={`text-primary_blue text-4xl leading-none ${callType==="group" && "text-[#16A000]" }`}>
            {callType === "group" ? "Group Call" : "Call"}
          </h6>
        </div>
      {action !== "start" ? (
          <button
            onClick={joinCallHandler}
            disabled={results.isLoading || inProgressCall}
            className={`${results.isLoading ? "bg-light_gray" : "bg-primary_blue"} font-medium rounded-xl text-white text-[1.4rem] 
          leading-none px-[2.4rem] py-[1.3rem] capitalize`}
          >
            {results.isLoading ? "Loading" : btntext}
          </button>
        ) : (
          <button
            onClick={joinCallHandler}
            disabled={results.isLoading || !isSame || inProgressCall}
            className={`${results.isLoading ? "bg-light_gray" : "bg-primary_blue"} font-medium rounded-xl text-white text-[1.4rem] 
          leading-none px-[2.4rem] py-[1.3rem] capitalize`}
          >
            {results.isLoading ? "Loading" : isSame ? "Start" : "Not Available"}
          </button>
        )}
        </div>
    </div>
  );
};

export default ScheduledCallsCard;
