import React, { useState } from "react";
import { ONBOARDING_FORM } from "config/environment";

export default function OnBoarding({ userData, formData }: any) {
  const email = userData?.email || userData?.data?.user?.email;

  const formId = ONBOARDING_FORM;
  // const formId = "NuoBHFgymUkwgqT7cq7p"; //Local
  // const formId = "ItfFXSA7h5fIddc1jmwO"; //Staging
  // const formId = "fnrxYdhWCTJzT2jXPlKp"; //Production

  const url = `https://api.leadconnectorhq.com/widget/form/${formId}?email=${email}`;
  const [URL, setURL]: any = useState(url);

  React.useEffect(() => {
    if (formData && formData.length) {
      const params: any = [];
      formData.forEach((item: any) => {
        const parsedVal = item?.value?.split(" ")?.join("+");
        const paresedKey = item?.name?.trim()?.toLowerCase()?.split(" ")?.join("_")?.replace("__", "_/_");
        const isGHLfield = item?.queryKey?.toLowerCase()?.includes("ghl");
        const parsedQueryKey = item?.queryKey?.replace("__", "_/_");
        if (isGHLfield) {
          const value = `${parsedQueryKey}=${parsedVal}`
          params.push(value);
        } else {
          const value = `${paresedKey}=${parsedVal}`
          params.push(value);
        }
      });

      if (params?.length) {
        const joinedParams = params?.join("&");
        const newUrl = `${url}&${joinedParams}`
        setURL(newUrl)
      }
    }
  }, [formData]);

  return (
    <div>
      <iframe
        src={URL}
        style={{
          width: "100%",
          height: "max-content",
          border: "none",
          borderRadius: "4px",
        }}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation allow-top-navigation-by-user-activation"
        id={`inline-${formId}`}
        data-layout="{'id':'INLINE'}"
        data-trigger-type="alwaysShow"
        data-trigger-value
        data-activation-type="alwaysActivated"
        data-activation-value
        data-deactivation-type="neverDeactivate"
        data-deactivation-value
        data-form-name="On Boarding"
        data-height={542}
        data-layout-iframe-id={`inline-${formId}`}
        data-form-id={formId}
        title="On Boarding"
        loading="eager"
      ></iframe>
    </div>
  );
}
