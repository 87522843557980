import DatePicker from "react-datepicker";
import CheckIcon from "assets/icons/check-mark-icon.svg";
import CrossIcon from "assets/icons/cross-icon.svg";
import CheckGrayIcon from "assets/icons/gray-check-empty.svg";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

const StreakCalendar = ({ streaksData }: any) => {
  const missedDates = streaksData?.missedDays ? streaksData.missedDays?.map((date: any) => dayjs(date, "DD/MM/YYYY", true).format("DDMMYYYY")) : [];
  const succssDates = streaksData?.successDays ? streaksData.successDays?.map((date: any) => dayjs(date, "DD/MM/YYYY", true).format("DDMMYYYY")) : [];

  const renderDayContent = (dayOfMonth: any, date: any) => {
    const isMissed = missedDates?.includes(dayjs(date).format("DDMMYYYY"));
    const isSuccess = succssDates?.includes(dayjs(date).format("DDMMYYYY"));
    // const icon = !isBetween ? CheckGrayIcon : isExits ? CrossIcon : CheckIcon
    const icon = isMissed ? CrossIcon : isSuccess ? CheckIcon : CheckGrayIcon;
    return (
      <img src={icon} />
    )
  };

  return (
    <div>
      <DatePicker
        // readOnly
        onChange={() => { }}
        // startDate={startDate}
        endDate={new Date()}
        // maxDate={new Date(new Date(maxDate.toDate()))}
        // minDate={minDate}
        inline
        calendarClassName={"streak_calender"}
        renderDayContents={(dayOfMonth, date) => renderDayContent(dayOfMonth, date)}
      />
    </div>
  );
}

export default StreakCalendar;