/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import HomeBanner from "assets/images/HomeBanner.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowDown from "assets/images/ArrowDown.svg";
import Calendar from "assets/images/Calendar.svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef } from "react";
import { Listbox } from "@headlessui/react";
import PrimaryButton from "components/common/PrimaryButton";
import { useCreateScheduleCallMutation, useGetCoachesQuery } from "apis/coach";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toast } from "react-toastify";
import { Fragment } from "react";
import { Combobox } from "@headlessui/react";
import SelectWeeklyDays from "./SelectWeeklyDays";
import SelectMonthlyDays from "./SelectMonthlyDays";
import Select from 'react-select';
import { useGetAllClientsQuery } from "apis/coach";
import ConfirmModal from "./ConfirmModal";

dayjs.extend(customParseFormat);
const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
interface IFormInputs {
  date: Date;
  endDate?: Date;
  recurrence: string;
  repeatEvery: string;
  occurrences?: string;
  sub_coaches?: string;
  duration: number;
  title: string;
  occursOn?: string;
  dayOfMonth?: string;
  weekIndex?: string;
  WeekDay?: string;
  dayOfWeek?: string;
}

const schema = yup.object().shape({
  date: yup.date().required("Required").nullable(),
  endDate: yup.date().nullable().notRequired(),
  repeatEvery: yup.string().required("Required"),
  recurrence: yup.string().required("Required"),
  occurrences: yup.string(),
  sub_coaches: yup.string().nullable().notRequired(),
  title: yup.string().required("Required"),
  duration: yup.number().required("Required"),
});

const CreateScheduleCall = () => {
  const { id } = useParams();
  const [checked, setChecked] = useState("by");
  const [createScheduleCall, { isLoading }] = useCreateScheduleCallMutation();
  const { data } = useGetCoachesQuery(1, { refetchOnMountOrArgChange: true });
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [clients, setClients] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [occurrenceString, setOccurrenceString] = useState("");
  const [options, setOptions] = useState(["Daily", "Weekly", "Monthly"]);
  const [duration, setDuration] = useState([30, 45, 60, 90]);
  const [selectedDuration, setSelectedDuration] = useState(60);
  const [selectedRecurrance, setSelectedRecurrance] = useState(options[0]);
  const [days, setDays] = useState(["1", "2", "3", "4", "5", "6", "7"]);
  const [selectedDay, setSelectedDay] = useState(days[0]);
  const [afterOccurrences, setAfterOccurrences] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
  ]);
  const [selectedAfterOccurance, setSelectedAfterOccurance] = useState(
    afterOccurrences[0]
  );
  const sub_coaches = data?.data?.coaches;
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState([]);
  const [focused, setFocused] = useState(false);
  const comboboxRef = useRef<HTMLDivElement | null>(null);
  const [monthState, setMonthState] = useState({
    monthlyDay: 1,
    weekOption: { title: "First", value: 1 },
    weekDayOption: { title: "Sunday", value: 1 },
    mode: "WEEK_DAY",
  });
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  // New States
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const { data: clientsData, isSuccess: isClientSuccess } = useGetAllClientsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const [key, setKey] = useState(0)
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  maxDate.setDate(maxDate.getDate() - 1);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    setError,
    watch,
    getValues,
    formState: { errors },
    reset
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      date: new Date(dayjs().add(5, "minute").format("YYYY-MM-DDTHH:mm")),
      duration: 60,
      repeatEvery: "1",
      occurrences: "1",
      recurrence: "Daily",
      endDate: dayjs().add(7, "day").toDate(),
      sub_coaches: "",
      title: "",
      occursOn: "",
      dayOfMonth: "1",
      weekIndex: "First",
      dayOfWeek: "Monday",
      WeekDay: "Monday",
    },
  });

  useEffect(() => {
    const { occurrences, date, endDate, repeatEvery, recurrence } = getValues();
    setAutoEndDate();
    if (date && endDate) {
      const startEventDate: any = new Date(date);
      const endEventDate: any = new Date(endDate);
      const numberOfDays: number =
        (endEventDate - startEventDate) / (1000 * 60 * 60 * 24);
      getMeetingOccurrences(
        date,
        endDate,
        recurrence?.toString(),
        repeatEvery,
        numberOfDays,
        checked === "by" ? true : false,
        occurrences
      );
    }
  }, [checked, getValues]);

  const setAutoEndDate = () => {
    const { date, recurrence } = getValues();
    switch (recurrence) {
      case "Daily":
        setValue("endDate", dayjs(date).add(7, "day").toDate());
        break;
      case "Weekly":
        setValue("endDate", dayjs(date).add(7, "week").toDate());
        break;
      case "Monthly":
        setValue("endDate", dayjs(date).add(6, "month").toDate());
        break;
      default:
        return;
    }
  };

  //NEW EFFECTS
  useEffect(() => {
    const day = String(dayjs().get("day") + 1);
    setSelectedDays([...selectedDays, day]);
  }, []);

  useEffect(() => {
    if (isClientSuccess) {
      const clients = clientsData?.data?.users?.map((client: any) => {
        return {
          id: client?.id,
          label: client?.full_name,
          value: client?.id,
          email: client?.email
        }
      });
      setClients(clients)
    }
  }, [isClientSuccess])

  const onSubmit = async (data: IFormInputs) => {
    if(!id && !selectedClient?.id) {
      toast.info("Select Client to Schedule Call");
      return;
    }
    const end = dayjs(data?.endDate);
    const start = dayjs(data?.date);
    const endDate = data?.endDate;
    const occurrences = data?.occurrences;
    const dateCheck = end.isBefore(start, "date");

    let payload: any;

    if (isRecurring) {
      if (dateCheck && checked === "by") {
        toast.error("End date should be greater than start date");
        return;
      }

      if (
        (endDate?.toString() === "" && checked === "by") ||
        (endDate === undefined && checked === "by") ||
        (endDate === null && checked === "by")
      ) {
        toast.error("Please select endDate for Recurring Scheduling Call");
        return;
      }
      if (
        (occurrences === "" && checked === "after") ||
        (occurrences === undefined && checked === "after")
      ) {
        toast.error(" Please select occurrences for Recurring Scheduling Call");
        return;
      }

      // Weekly Validation
      if(data.recurrence === "Weekly"){
        const currentDay = dayjs().day() + 1;
        const isCurrentDay = selectedDays?.includes(String(currentDay));
        if(isCurrentDay){
          const isBefore = dayjs(data?.date).isBefore(new Date());
          if (isBefore) {
            // setStartDateError(
            //   "Select time later from current time"
            // );
            toast.error("Please schedule call in future day & time");
            return;
          }
        }
      }
    }

    if (isRecurring) {
      const weekDays = selectedDays?.join(",");
      payload = {
        title: data.title,
        date: dayjs(data.date).format(),
        // date: SelectedDate?SelectedDate: dayjs(data.date).format(),
        duration: data.duration,
        end_date: checked === "by" ? dayjs(data.endDate) : dayjs(data.date),
        endDate:
          checked === "by"
            ? dayjs(data.endDate).format("YYYY-MM-DD")
            : dayjs(data.date).format("YYYY-MM-DD"),
        recurrence: data?.recurrence?.toLowerCase(),
        repeatEvery: data.repeatEvery,
        occurrences: checked === "after" ? data.occurrences : null,
        clientId: id ? id : selectedClient?.id,
        isEndDate: checked === "after" ? false : true,
        isRecurring: isRecurring,
        sub_coaches: data?.sub_coaches ? [data?.sub_coaches] : null,
        weekly_days: weekDays,
      };
      if (data?.recurrence === "Weekly") {
        payload.weekly_days = weekDays;
      }
      if (data?.recurrence === "Monthly") {
        payload.monthMode = monthState.mode;
        if (monthState.mode === "WEEK_DAY") {
          payload.monthly_day = monthState.monthlyDay;
        }
        if (monthState.mode === "MONTH_DAY") {
          payload.monthly_week = monthState.weekOption.value;
          payload.monthly_week_day = monthState.weekDayOption.value;
        }
      }
    } else {
      payload = {
        title: data.title,
        date: dayjs(data.date).format(),
        duration: data.duration,
        end_date: dayjs(data.date).format(),

        endDate: dayjs(data.date).format("YYYY-MM-DD"),
        recurrence: null,
        repeatEvery: null,
        occurrences: null,
        clientId: id ? id : selectedClient?.id,
        isEndDate: null,
        isRecurring: isRecurring,
        sub_coaches: data?.sub_coaches ? [data?.sub_coaches] : null,
      };
    }

    await createScheduleCall(payload)
      .unwrap()
      .then(() => {
        // navigate(`/coach/schedule-call-calendar/${id}`);
        // toast.success(res?.message);
        setIsConfirmOpen(true);
      })
      .catch((error: any) => {
        toast.error(error?.data?.message);
      });
  };

  const onDone = () => {
    if(id){
      navigate("/coach/schedule-call");
    }
    setIsConfirmOpen(true);
    reset();
    setKey((currentKey) => currentKey + 1);
    setSelectedClient(null);
    setSelectedDuration(60);
    setSelectedRecurrance(options[0]);
    setIsRecurring(false);
    setSelectedDay(days[0]);
    setSelectedAfterOccurance(afterOccurrences[0]);
    setChecked('by');
    setMonthState({
      monthlyDay: 1,
      weekOption: { title: "First", value: 1 },
      weekDayOption: { title: "Sunday", value: 1 },
      mode: "WEEK_DAY",
    });
    
  }

  function handleEndType(e: any) {
    setChecked(e.target.value);
  }

  const getMeetingOccurrences = async (
    startEventDate: any,
    endEventDate: any,
    recurrence: any,
    repeatEvery: any,
    numberOfDays: any,
    isEndDateOption: any,
    afterOccurrences: any
  ) => {
    let occurrenceCount = 0;
    let formattedDate;
    let recurrenceWord;
    let finalMessage;
    const occurrenceDates = [];
    switch (recurrence) {
      case "Daily":
        recurrenceWord = "day";
        occurrenceCount = 0;
        const date1 = dayjs(dayjs(startEventDate).format("YYYY-MM-DD"));
        const date2 = dayjs(dayjs(endEventDate).format("YYYY-MM-DD"));
        const diff = date2.diff(date1, "day") + 1;
        occurrenceCount = Math.ceil(diff / repeatEvery);
        if (!isEndDateOption) {
          occurrenceCount = afterOccurrences;
        }

        for (let i = 0; i < occurrenceCount; i++) {
          const occurrenceDate = new Date(startEventDate);
          occurrenceDate.setDate(occurrenceDate.getDate() + i * repeatEvery);
          occurrenceDates.push(occurrenceDate.toISOString().slice(0, 10));
        }
        break;
      case "Weekly":
        recurrenceWord = "week";
        occurrenceCount = 0;
        const dateStartWeek = dayjs(dayjs(startEventDate).format("YYYY-MM-DD"));
        const dateEndWeek = dayjs(dayjs(endEventDate).format("YYYY-MM-DD"));
        const diffWeek = dateEndWeek.diff(dateStartWeek, "week") + 1;
        occurrenceCount = Math.ceil(diffWeek / repeatEvery);

        if (!isEndDateOption) {
          occurrenceCount = afterOccurrences;
        }
        for (let i = 0; i < occurrenceCount; i++) {
          const occurrenceDate = new Date(startEventDate);
          occurrenceDate.setDate(
            occurrenceDate.getDate() + i * repeatEvery * 7
          );
          occurrenceDates.push(occurrenceDate.toISOString().slice(0, 10));
        }
        break;
        case "Monthly":
          recurrenceWord = "month";
          occurrenceCount = 0;
        const dateStartMonth = dayjs(
          dayjs(startEventDate).format("YYYY-MM-DD")
        );
        const dateEndMonth = dayjs(dayjs(endEventDate).format("YYYY-MM-DD"));
        const diffMonth = dateEndMonth.diff(dateStartMonth, "month") + 1;
        occurrenceCount = Math.ceil(diffMonth / repeatEvery);

        if (!isEndDateOption) {
          occurrenceCount = afterOccurrences;
        }
        for (let i = 0; i < occurrenceCount; i++) {
          const occurrenceDate = new Date(startEventDate);
          occurrenceDate.setMonth(occurrenceDate.getMonth() + i * repeatEvery);
          occurrenceDates.push(occurrenceDate.toISOString().slice(0, 10));
          }
          break;
      default:
        break;
    }

    if (isEndDateOption) {
      if (occurrenceCount > 0) {
        formattedDate = dayjs(endEventDate).format("MMM D, YYYY");
      }
      finalMessage = `Every ${repeatEvery} ${recurrenceWord}, until ${formattedDate}, ${occurrenceCount} occurrences`;
    } else {
      finalMessage = `Every ${repeatEvery} ${recurrenceWord}, ${afterOccurrences} occurrences`;
    }

    setOccurrenceString(finalMessage);
  };

  const recurranceChangeHandler = (selected: any) => {
    setSelectedRecurrance(selected);
    setSelected(selected);
    setValue("recurrence", selected);
    setSelectedDay(days[0]);
    setValue("repeatEvery", "1");
    setError(
      "recurrence",
      { type: "focus" },
      { shouldFocus: true }
    );
    setAutoEndDate();
  }

  const showRecurringView = () => {
    const { recurrence } = getValues();
    return (
      <div>
        <div className=" flex flex-col sm:flex-row gap-6 sm:gap-[10.2rem] mt-[4.8rem] items-start">
          <h3 className="text-[2.7rem] text-white font-bold">Recurrence</h3>
          <div className="w-[22.7rem] h-[6rem]">
            <Listbox
              defaultValue={selected}
              onChange={(selected: any) => {
                recurranceChangeHandler(selected);
              }}>
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block text-[2.6rem]">
                    {selectedRecurrance}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                    <img className="w-[1.6rem]" src={ArrowDown}></img>
                  </span>
                </Listbox.Button>
                <Listbox.Options className="ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm absolute z-50 w-full py-4 mt-4 overflow-auto text-base bg-white rounded-md shadow-lg">
                  {options?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className="cursor-pointer text-center h-[4rem]"
                      value={option}
                      {...register("recurrence")}>
                      {({ selected }) => {
                        return (
                          <>
                            <p
                              className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center   ${
                                selected
                                  ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                  : "text-[#424242]"
                              }`}>
                              {option}
                            </p>
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
            {errors.recurrence && (
              <p className="error">{errors.recurrence?.message}</p>
            )}
          </div>
        </div>
        <div className=" flex flex-col sm:flex-row gap-6 sm:gap-[8.3rem] mt-[4.8rem] items-center">
          <h3 className="text-[2.7rem] text-white font-bold">Repeat Every</h3>
          <div className="flex items-center gap-4">
            <div className="w-[22.7rem] h-[6rem]">
              <Listbox
                value={selectedDay}
                onChange={(selected: any) => {
                  setSelectedDay(selected);
                  setValue("repeatEvery", selected);
                  setError(
                    "repeatEvery",
                    { type: "focus" },
                    { shouldFocus: true }
                  );
                }}>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block text-[2.6rem]">{selectedDay}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                      <img className="w-[1.6rem]" src={ArrowDown}></img>
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm absolute z-50 w-full py-4 mt-4 overflow-auto text-base bg-white rounded-md shadow-lg">
                    {days
                      .slice(0, recurrence === "Monthly" ? 3 : 10)
                      .map((days, index) => (
                        <Listbox.Option
                          key={index}
                          className="cursor-pointer text-center h-[4rem]"
                          value={days}
                          {...register("repeatEvery")}>
                          {({ selected }) => (
                            <>
                              <p
                                className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center   ${
                                  selected
                                    ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                    : "text-[#424242]"
                                }`}>
                                {days}
                              </p>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </div>
                {errors.repeatEvery && (
                  <p className="error">{errors.repeatEvery?.message}</p>
                )}
              </Listbox>
            </div>
            <h3 className="text-[2.7rem] text-white">
              {selectedRecurrance === "Daily"
                ? "days"
                : selectedRecurrance === "Weekly"
                ? "weeks"
                : "months"}
            </h3>
          </div>
        </div>
        {recurrence === "Weekly" && (
          <SelectWeeklyDays
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
          />
        )}
        {recurrence === "Monthly" && (
          // occursOnMonthly()
          <SelectMonthlyDays state={monthState} setState={setMonthState} />
        )}
        <div className=" flex flex-col sm:flex-row gap-6 sm:gap-[3.6rem] mt-[7.6rem] items-center">
          <h3 className="text-[2.7rem] text-white font-bold">End Date</h3>
          <div className="flex items-center gap-4">
            <input
              type="radio"
              name="by"
              value="by"
              checked={checked === "by"}
              onChange={handleEndType}
              className="w-[2.6rem] h-[2.6rem]"
            />
            <h3 className="text-[2.7rem] text-white">By</h3>
          </div>
          <div className="relative">
            <img
              className="absolute w-[2.4rem] h-[2.4rem] !left-[2rem] sm:left-[1.4rem] !top-[1.8rem] sm:top-[2.2rem]"
              src={Calendar}
              alt="Calendar"
            />
            <img
              className="absolute right-[2rem] !top-[2.6rem]"
              src={ArrowDown}
              alt="ArrowDown"
            />
            <Controller
              control={control}
              name="endDate"
              render={({ field: { onChange, value, name } }) => {
                return (
                  <DatePicker
                    className="input-form-calendar placeholder:opacity-100 !rounded-[1rem] !mt-0 !text-[2.8rem] !max-w-[35rem] !h-[6rem]"
                    selected={value}
                    onChange={(date) => {
                      onChange(date);
                      // endDateHandler(date);
                    }}
                    name={name}
                    disabled={checked === "after"}
                    dateFormat="MM/dd/yyyy "
                    placeholderText="Select date"
                    minDate={new Date(getValues("date"))}
                    maxDate={maxDate}
                  />
                );
              }}
            />
            {errors.endDate && (
              <p className="error">{errors?.endDate?.message}</p>
            )}
          </div>
        </div>
        <div className=" flex flex-col sm:flex-row gap-6 sm:gap-[14rem] mt-[3.6rem] items-center">
          <h3 className="text-[2.7rem] text-white font-bold"></h3>
          <div className="sm:flex-row flex flex-col items-center gap-4">
            <div className="flex items-center gap-4">
              <input
                type="radio"
                name="by"
                value="after"
                checked={checked === "after"}
                onChange={handleEndType}
                className="w-[2.6rem] h-[2.6rem]"
              />
              <h3 className="text-[2.7rem] text-white">After</h3>
            </div>
            <div className="w-[22.7rem] h-[6rem]">
              <Listbox
                disabled={checked === "by"}
                value={selectedAfterOccurance}
                onChange={(selected: any) => {
                  setSelectedAfterOccurance(selected);
                  setValue("occurrences", selected);
                }}>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block text-[2.6rem]">
                      {selectedAfterOccurance}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                      <img className="w-[1.6rem]" alt="" src={ArrowDown}></img>
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm absolute z-50 w-full py-4 mt-4 overflow-auto text-base bg-white rounded-md shadow-lg">
                    {afterOccurrences.map((days, index) => (
                      <Listbox.Option
                        key={index}
                        className="cursor-pointer text-center h-[4rem]"
                        value={days}
                        {...register("occurrences")}>
                        {({ selected }) => (
                          <>
                            <p
                              className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center   ${
                                selected
                                  ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                  : "text-[#424242]"
                              }`}>
                              {days}
                            </p>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
              {errors.occurrences && (
                <p className="error">{errors?.occurrences?.message}</p>
              )}
            </div>
            <h3 className="text-[2.7rem] text-white">occurrences</h3>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setFocused(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredPeople =
    query === ""
      ? sub_coaches
      : sub_coaches?.filter((item: any) =>
          item.full_name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  function handleCoaches(value: any) {
    setSelected(value);
    setFocused(false);

    if (value) {
      setValue("sub_coaches", value.id);
    }
  }

  const eventStartDate = watch("date");
  const eventEndDate = watch("endDate");
  const getRecurringText = () => {
    const { recurrence, repeatEvery, occurrences } = getValues();
    const isByDate = checked === "by";
    const start = dayjs(dayjs(eventStartDate).format("YYYY-MM-DD"));
    const end = dayjs(dayjs(eventEndDate).format("YYYY-MM-DD"));
    const formattedEnd = dayjs(eventEndDate).format("MMM D, YYYY");
    let text = "";

    if (recurrence === "Daily") {
      let occurrenceCount = 0;
      if (!isByDate) {
        return `Every ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "days": "day"}, ${occurrences} occurrence(s)`;
      } else {
        const diff = end.diff(start, "day") + 1;
        occurrenceCount = Math.ceil(diff / Number(repeatEvery));
        text = `Every ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "days": "day"}, until ${formattedEnd}, ${occurrenceCount} occurrence(s)`;
      }
    }
    if (recurrence === "Weekly") {
      const result = [];
      for (const day of selectedDays) {
        const index = parseInt(day) - 1; // Adjust index to match array indices (starting from 0)
        result.push(daysOfWeek[index]);
      }
      const daysString = result?.join(", ");
      if (!isByDate) {
        return `Every ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "weeks": "week"} on ${daysString}, ${occurrences} occurrence(s)`;
      } else {
        let count = 0;
        let currentDate = start;
        while (currentDate.isSameOrBefore(end)) {
          const weekdayValue = String(currentDate.day() + 1);
          if (selectedDays.includes(weekdayValue)) {
            count++;
          }
          currentDate = currentDate.add(1, "day");
        }
        const newOccur = (count / Number(repeatEvery)).toFixed(0);
        text = `Every ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "weeks": "week"} on ${daysString} until ${formattedEnd}, ${newOccur} occurrence(s)`;
        // text = `Every week on ${daysString} until ${formattedEnd}`;
      }
    }
    if (recurrence === "Monthly") {
      const monthValue =
        monthState.mode === "WEEK_DAY"
          ? `${monthState.monthlyDay} of the month`
          : `${monthState.weekOption.title} ${monthState.weekDayOption.title}`;
      if (!isByDate) {
        return `Every ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "months": "month"} on the ${monthValue}, ${occurrences} occurrence(s)`;
      } else {
        let count = 0;
        let currentDate = start;
        const endDate = end;
        while (currentDate.isSameOrBefore(end)) {
          if (monthState.mode === "WEEK_DAY") {
            if (
              (currentDate != start && currentDate.month() != endDate.month()) ||
              (currentDate == start && currentDate.date() <= monthState.monthlyDay) ||
              (currentDate.month() == endDate.month() && endDate.date() >= monthState.monthlyDay)
            ) {
              count++;
            }
          } else {
            const newDate = getDateOfMonth(
              currentDate,
              monthState.weekOption.title,
              monthState.weekDayOption.title
            );
            if (
              (currentDate != start &&
                currentDate.month() != endDate.month()) ||
              (currentDate == start && currentDate.date() <= newDate.date()) ||
              (currentDate.month() == endDate.month() &&
                endDate.date() >= newDate.date())
            ) {
              count++;
            }
          }
          currentDate = currentDate.add(1, "month");
        }
        const lastDay = dayjs(end).date() ;
        if((dayjs(start).month() !== dayjs(end).month())){
          if( lastDay >= monthState.monthlyDay){
            count = count ++;
          }
        }
        const newOccur = (count / Number(repeatEvery)).toFixed(0);
        text = `Every  ${repeatEvery ? repeatEvery : ""} ${Number(repeatEvery) > 1 ? "months": "month"} on the ${monthValue} until, ${formattedEnd}, ${newOccur} occurrence(s)`;
      }
    }
    return text;
  };

  function getDateOfMonth(
    currentDate: any,
    selectWeekCount: any,
    selectedDay: any
  ) {
    const __weekDays: any = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    const _weeksCount: any = { First: 0, Second: 1, Third: 2, Fourth: 3 };

    if (selectWeekCount == "Last") {
      const lastDayOfMonth = currentDate.endOf("month");
      const dayCount = lastDayOfMonth.day();
      let targetDate;
      if (__weekDays[selectedDay] <= dayCount) {
        targetDate = lastDayOfMonth.subtract(
          dayCount - __weekDays[selectedDay],
          "day"
        );
      } else {
        targetDate = lastDayOfMonth.subtract(
          7 - (__weekDays[selectedDay] - dayCount),
          "day"
        );
      }
      return targetDate;
    } else {
      const firstDayOfMonth = currentDate.startOf("month");
      const dayOfWeek = firstDayOfMonth.day();

      const daysToTargetDay = (7 + __weekDays[selectedDay] - dayOfWeek) % 7;

      const targetDate = firstDayOfMonth.add(
        daysToTargetDay + _weeksCount[selectWeekCount] * 7,
        "day"
      );

      return targetDate;
    }
  }

  const selectClientHandler = (value: any) => {
    setSelectedClient(value);
  }

  return (
    <Layout>
      <Hero image={HomeBanner} />
      <div className="container mx-auto py-28 px-10 desktop:px-10 pb-[30rem]" key={key}>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
          <h1 className="text-white text-center lg:text-left text-[3.6rem] leading-none font-medium mt-0">
            Schedule Video Call
          </h1>
          <div className="flex flex-col  gap-6 sm:flex-row sm:gap-[18.6rem] mt-[7.6rem] items-center">
            <h3 className="text-[2.7rem] text-white font-bold">Title</h3>
            <div className="w-[40rem] bg  h-[6rem] border-[0.2rem] border-[#FFFFFF] rounded-[1rem]">
              <input
                {...register("title")}
                style={{ background: "transparent" }}
                type="text"
                className="w-full !text-[2.8rem] !bg-transparent h-full text-center font-normal text-[#FFFFFF] placeholder:text-[2.2rem]  focus:outline-none"
                placeholder="Title"
              />
              {errors.title && (
                <p className="error">{errors?.title?.message}</p>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-6 sm:flex-row sm:gap-[18.6rem] mt-[5rem] items-center">
            <h3 className="text-[2.7rem] text-white font-bold">Date</h3>

            <div className="relative">
              <img
                className="absolute w-[2.4rem] h-[2.4rem] !left-[2rem] sm:left-[1.4rem] !top-[1.8rem] sm:top-[2.2rem]"
                src={Calendar}
                alt="Calendar"
              />
              <img
                className="absolute right-[2rem] !top-[2.6rem]"
                src={ArrowDown}
                alt="ArrowDown"
              />
              <Controller
                control={control}
                name="date"
                defaultValue={new Date()}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <DatePicker
                      className="input-form-calendar !rounded-[1rem] !mt-0 !text-[2.8rem] !max-w-[40rem] !px-20 !h-[6rem]"
                      selected={value}
                      onChange={(date) => {
                        onChange(date);
                        setAutoEndDate();
                      }}
                      showTimeInput
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      name={name}
                      minDate={new Date()}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  );
                }}
              />
              {errors.date && <p className="error">{errors?.date?.message}</p>}
            </div>
          </div>

          <div className=" flex flex-col sm:flex-row gap-6 sm:gap-[14rem] mt-[4.8rem] items-start">
            <h3 className="text-[2.7rem] text-white font-bold">Duration</h3>
            <div className="w-[22.7rem] h-[6rem]">
              <Listbox
                defaultValue={selected}
                onChange={(selected: any) => {
                  setSelectedDuration(selected);
                  setValue("duration", selected);
                  setError(
                    "duration",
                    { type: "focus" },
                    { shouldFocus: true }
                  );
                }}>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block text-[2.6rem]">
                      {selectedDuration} min
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                      <img className="w-[1.6rem]" src={ArrowDown}></img>
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm absolute z-50 w-full py-4 mt-4 overflow-auto text-base bg-white rounded-md shadow-lg">
                    {duration?.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        className="cursor-pointer text-center h-[4rem]"
                        value={option}
                        {...register("duration")}>
                        {({ selected }) => {
                          return (
                            <>
                              <p
                                className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center   ${
                                  selected
                                    ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                    : "text-[#424242]"
                                }`}>
                                {option} min
                              </p>
                            </>
                          );
                        }}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
              {errors.recurrence && (
                <p className="error">{errors.recurrence?.message}</p>
              )}
            </div>
          </div>

          {!id && (
            <div className="flex flex-col sm:flex-row gap-6 sm:gap-[9rem] mt-[4.8rem] items-baseline">
              <h3 className="text-[2.7rem] text-white font-bold">Select Client</h3>
              <div>
                <div className="w-[40rem]">
                  <Select
                    closeMenuOnSelect={false}
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedClient}
                    onChange={(val) => selectClientHandler(val)}
                    isClearable={false}
                    isSearchable
                    name="timezone"
                    options={clients}
                    placeholder="Clients"
                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: 'transparent',
                        borderColor: '#fff',
                        height: "50px",
                        boxShadow: "none",
                        fontSize: "22px",
                        color: '#fff',
                        paddingLeft: '10px',
                        borderWidth: '2px',
                        borderRadius: "10px",
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        color: '#fff'
                      }),
                      singleValue: (baseStyles) => ({
                        ...baseStyles,
                        color: '#fff'
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: '#fff'
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isSelected ? "#1e75c21f" : "#fff",
                        color: state.isSelected ? "#2165a2" : "#333333",
                        fontSize: "18px"
                      }),
                      noOptionsMessage: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: '20px'
                      })
                    }}
                  />
                </div>

              </div>
            </div>
          )}

          <div className="flex flex-col sm:flex-row items-start gap-4 sm:items-center mt-[5.2rem]">
            <div className=" flex flex-row gap-12 sm:gap-[6.7rem] items-center">
              <h3 className="text-[2.7rem] text-white font-bold">
                Recurring Call
              </h3>
              <label className="checkbox mb-[0.3rem]">
                <input
                  type="checkbox"
                  className="w-[2.6rem] h-[2.6rem]"
                  onChange={() => {
                    setIsRecurring(!isRecurring);
                  }}
                />
                <div className="checkbox__indicator"></div>
              </label>
            </div>
            {isRecurring ? (
              <h3 className="text-[1.8rem] text-white">
                <span> {getRecurringText()}</span>
              </h3>
            ) : null}
          </div>

          {/* ==Recurring View == */}
          {isRecurring ? showRecurringView() : null}
          <div className="">
            <div className=" flex gap-6 sm:gap-[23.6rem] mt-[4.8rem] items-start">
              <div className="w-full sm:w-[52.7rem] h-[6rem]">
                <div ref={comboboxRef} className="w-full">
                  <Combobox
                    value={selected}
                    onChange={(e: any) => handleCoaches(e)}>
                    {({ open }) => (
                      <>
                        <div className="relative mt-1">
                          <h3 className="text-[2.7rem] text-white font-bold mb-[1.8rem]">
                            Select Substitute Coach
                          </h3>
                          <div className="focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm relative w-full overflow-hidden text-left bg-white rounded-lg shadow-md cursor-default">
                            <Combobox.Input
                              className="w-full border-none py-[2rem] pl-3 pr-10 text-[2rem] leading-5 text-gray-900 focus:ring-0 !bg-transparent h-full text-center font-normal placeholder:text-[2.2rem]  focus:outline-none"
                              style={{ background: "transparent" }}
                              type="text"
                              autoComplete="off"
                              placeholder="Select Coach"
                              displayValue={(item: any) => item.full_name}
                              onChange={(event) => {
                                setQuery(event.target.value);
                                setSelected([]);
                                if (event.target.value === "") {
                                  setValue("sub_coaches", "");
                                }
                              }}
                              onFocus={() => setFocused(true)}
                            />
                            <button
                              type="button"
                              className="right-8 top-1/4 absolute"
                              onClick={() => {
                                setSelected([]);
                                setValue("sub_coaches", "");
                              }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                              </svg>
                            </button>
                          </div>
                          {(open || focused) && (
                            <Combobox.Options
                              static
                              className="max-h-80 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm absolute w-full py-2 mt-1 overflow-auto text-center bg-white rounded-md shadow-lg">
                              <Combobox.Option
                                className={({ active }) =>
                                  `relative cursor-default select-none py-4 hover:bg-light_gray pl-10 pr-4 ${
                                    active
                                      ? "bg-teal-600 text-black text-[2rem] font-normal"
                                      : "text-primary_blue text-[2rem]"
                                  }`
                                }
                                value="">
                                {({ selected, active }) => (
                                  <>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                          active
                                            ? "text-black"
                                            : "text-teal-600"
                                        }`}></span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                              {filteredPeople?.length === 0 && query !== "" ? (
                                <div className="relative cursor-default select-none py-2 px-4 bg-teal-600 text-black text-[2rem] font-normal mb-4 mt-4">
                                  Nothing found.
                                </div>
                              ) : (
                                filteredPeople?.map((item: any) => (
                                  <Combobox.Option
                                    key={item.id}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-4 hover:bg-light_gray pl-10 pr-4 ${
                                        active
                                          ? "bg-teal-600 text-black text-[2rem] font-normal"
                                          : "text-primary_blue text-[2rem]"
                                      }`
                                    }
                                    value={item}>
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={`block leading-[3rem] truncate ${
                                            selected
                                              ? "font-medium text-[2rem]"
                                              : "font-normal text-[2rem]"
                                          }`}>
                                          {item.full_name}
                                        </span>
                                        {selected ? (
                                          <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                              active
                                                ? "text-black"
                                                : "text-teal-600"
                                            }`}></span>
                                        ) : null}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))
                              )}
                            </Combobox.Options>
                          )}
                        </div>
                      </>
                    )}
                  </Combobox>
                </div>

                <div className="flex flex-wrap gap-3 mt-6"></div>
                <div className="mt-6">
                  <div className="text-center">
                    <PrimaryButton
                      loading={isLoading}
                      color="primary"
                      text="Schedule"
                      type="submit"
                      width="w-full sm:!w-[52.7rem] tablet:w-[22.8rem]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <ConfirmModal
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        onDone={onDone}
      />
    </Layout>
  );
};

export default CreateScheduleCall;
