import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface IFormInputs {
  answer: string;
}

const schema = yup.object({
  answer: yup.string().required("Required"),
});
const FirstQuestion = ({
  item,
  handleSave,
  data,
  arrayLength,
  setPage,
  page,
  isCompleted,
  isLastStep,
  nextHandler,
  loading,
  isSavingData
}: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  function handleBack() {
    if (page === 1) {
      return;
    }
    setPage(page - 1);
  }

  useEffect(() => {
    const filterData = data?.filter(
      (data: any) => item?.id === data?.questionId
    );
    setValue("answer", filterData?.[0]?.answer);
  }, [data]);

  const onSubmit = (data: IFormInputs) => {
    if (isCompleted) {
      nextHandler();
      return;
    }
    const answers = {
      questionId: item?.id,
      answer: data.answer,
    };
    handleSave(answers);
  };

  return (
    <div className="">
      <h1 className="text-[#fff] text-7xl font-light">{item?.question}</h1>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-[6rem] max-w-[80.8rem] mx-auto">
            <label className="text-5xl text-white">{item?.label}</label>
            <input
              disabled={isCompleted}
              {...register("answer")}
              style={{ background: "#fff" }}
              className={`${isCompleted && arrayLength === 1 && "mb-[10rem]"
                } text-[#333333] w-full text-[2.25rem] py-[1rem] px-[1rem] sm:py-[1.7rem] sm:px-[2.3rem] rounded-xl mt-7
              placeholder:text-start placeholder:text-[#787878] placeholder:sm:text-[3rem] sm:leading-[5.2rem] outline-none`}
              placeholder="Type here.."
            >
            </input>

            {errors.answer && <p className="error">{errors.answer?.message}</p>}
            {isCompleted && arrayLength === 1 ? (
              <div
                style={{
                  display: "none",
                }}
              ></div>
            ) : (
              <div className="pb-[10rem] flex gap-4">
                <button
                  style={{ display: page === 1 ? "none" : "null" }}
                  onClick={handleBack}
                  type="button"
                  className="sm:py-7 w-full text-[#fff] justify-center sm:h-24 px-20 bg-primary_blue font-bold leading-[6.3rem] text-[2.4rem] sm:text-[2.4rem] rounded-xl flex gap-2 m-auto mt-[3rem] sm:mt-[7.2rem] items-center"
                >
                  Back
                </button>
                {isCompleted && page == isLastStep ? null : (
                  <button
                    type={"submit"}
                    disabled={isSavingData}
                    className="sm:py-7 w-full text-[#fff] justify-center sm:h-24 px-20 bg-primary_blue font-bold leading-[6.3rem] text-[2.4rem] sm:text-[2.4rem] rounded-xl flex gap-2 m-auto mt-[3rem] sm:mt-[7.2rem] items-center"
                  >
                    {loading ? "Loading..." : page == isLastStep ? "Submit" : "Next"}
                  </button>
                )}

              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.memo(FirstQuestion);
