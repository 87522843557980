import { postRequest, getRequest } from "config/axiosConfig";
import { toast } from "react-toastify";

export async function forgotPassword(
  email: string,
  setLoading: any,
  navigate: any
) {
  let result;
  try {
    setLoading(true);
    result = await postRequest("forgot-password", {
      email,
    });
    setLoading(false);
    navigate("/verify-otp");
    toast.success(result?.message);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    setLoading(false);
  }
  return result;
}

export async function verifyOtp(value: string, setLoading: any, navigate: any) {
  let result;
  try {
    setLoading(true);
    result = await postRequest("verify-otp", {
      otp: value,
    });
    setLoading(false);
    navigate(`/create-password/${value}`);
    toast.success("OTP verified successfully");
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    setLoading(false);
  }
  return result;
}

export async function createPassword(
  value: any,
  password: string,
  confirmPassword: string,
  setLoading: any,
  navigate: any
) {
  let result;
  try {
    setLoading(true);
    result = await postRequest("reset-password", {
      otp: value,
      password,
      confirm_password: confirmPassword,
    });
    setLoading(false);
    navigate("/login");
    toast.success(result?.message);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    setLoading(false);
  }
  return result;
}

// verify user through invite link
export async function verify(token: any, navigate: any, setUserEmail: any) {
  let result = null;
  try {
    result = await getRequest(`invite?key=${token}`);
    setUserEmail(result?.data?.email);
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    // navigate("/login")
  }
  return result;
}

// Register user
export async function userRegister(
  payload: any,
  navigate: any,
  setLoading: any
) {
  let result;
  setLoading(true);
  try {
    result = await postRequest("register", payload);
    setLoading(false);
    toast.success(result?.message);
    navigate("/login");
  } catch (error: any) {
    setLoading(false);
    toast.error(error?.response?.data?.message);
    // navigate("/login");
  }
  return result;
}

// get google token
export async function GoogleToken(code: string) {
  return getRequest(`google/redirect?code=${code}`);
}
// get outlook token
export async function OutlookToken(code: string) {
  let result = null;
  try {
    result = await getRequest(`outlook/redirect?code=${code}`);
  } catch (error: any) {
    // toast.error(error?.response?.data?.message);
    // navigate("/login")
  }
  return result;
}
// revoke token
export async function RevokeGoogleToken(id: string) {
  return await postRequest(`revoke-token/${id}`, {});
}
// revoke outlook token
export async function RevokeOutlookToken(id: string) {
  return await postRequest(`revoke-outlook-token/${id}`, {});
}
