// CLIENT PAGE IMPORTS
import Empty from "pages/Client/Empty";
import Home from "pages/Client/Home";
import VideoCalls from "pages/Client/VideoCalls";
import SingleCourse from "pages/Client/Courses/SingleCourseGHL";
import Events from "pages/Client/Events";
import SingleEvent from "pages/Client/Events/SingleEvent";
import RegisterEvent from "pages/Client/Events/GHLRegisterEvent";
import Huddles from "pages/Client/Huddles";
import Podcasts from "pages/Client/Podcasts";
import YoutubeVideos from "pages/Coach/YoutubeVideos";
import Tasks from "pages/Client/Tasks";
import OnBoarding from "pages/Client/OnBoarding";
import SwotAnalysis from "pages/Client/SwotAnalysis";
import CheckinForm from "pages/Client/CheckinForms";
import AccountResetPassword from "pages/Client/UserProfile/AccountResetPassword";
import UserProfile from "pages/Client/UserProfile";
import ClientEditAccount from "pages/Client/UserProfile/ClientEditAccount";
import LeaderBoardClient from "pages/Client/LeaderBoard";
import ClientScheduledCalls from "pages/Client/ClientScheduledCalls";
import Notes from "pages/Client/Notes";
import Roles from "./permissions";
import OnboardingForm from "pages/Client/OnBoarding/OnboardingForm";
import LegacyStatement from "pages/Client/LegacyStatement";
import LegacyStatementQuote from "pages/Client/LegacyStatementQuote";
import CreateLegacyStatement from "pages/Client/LegacyStatement/LegacyCreate";
import EditLegacyStatement from "pages/Client/LegacyStatement/LegacyEdit";
import SwotAnalysisCreate from "pages/Client/SwotAnalysis/SwotAnalysisCreate";
import Courses from "pages/Client/Courses";
import Apparels from "pages/Client/Apparels";
import Notifications from "pages/Client/Notifications";
import TermsAndConditions from "pages/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Support from "pages/Client/Support";
import EditSwotAnalysis from "pages/Client/SwotAnalysis/SwotAnalysisEdit"
import RegisterCourse from "pages/Client/Courses/RegisterCourse";
import EditCheckIn from "pages/Client/CheckinForms/EditCheckIn";
import EditOnboarding from "pages/Client/OnBoarding/EditOnboarding";
import GroupCalls from "pages/Client/GroupCalls";

export const ClientRoutes = [
  {
    path: "/client/empty",
    page: <Empty />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client",
    page: <Home />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/home",
    page: <Home />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/onboard",
    page: <OnboardingForm />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/leaderboard",
    page: <LeaderBoardClient />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/video-calls",
    page: <VideoCalls />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/group-calls",
    page: <GroupCalls />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/course/:id",
    page: <SingleCourse />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/register-course/:id",
    page: <RegisterCourse />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/huddles",
    page: <Huddles />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/podcasts",
    page: <Podcasts />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/youtube-videos",
    page: <YoutubeVideos />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/apparels",
    page: <Apparels />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/tasks",
    page: <Tasks />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/onboarding",
    page: <OnBoarding />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/onboarding/edit",
    page: <EditOnboarding />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/swot-analysis",
    page: <SwotAnalysis />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/swot-analysis/create",
    page: <SwotAnalysisCreate />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/checkin-form",
    page: <CheckinForm />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/checkin-form/edit",
    page: <EditCheckIn />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/account",
    page: <UserProfile />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/edit-account",
    page: <ClientEditAccount />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/account-reset-password",
    page: <AccountResetPassword />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/scheduled-calls",
    page: <ClientScheduledCalls />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/notes",
    page: <Notes />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/legacy-statement/create",
    page: <CreateLegacyStatement />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/legacy-statement/quote",
    page: <LegacyStatementQuote />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/legacy-statement",
    page: <LegacyStatement />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/legacy-statement/edit",
    page: <EditLegacyStatement />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },

  //RESOURCE PAGES
  {
    path: "/client/courses",
    page: <Courses />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },

  //WINRATE RESOURCES APPAREL
  {
    path: "/client/apparels",
    page: <Apparels />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },

  {
    path: "/client/events",
    page: <Events />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  //SINGLE EVENT

  {
    path: "client/event/:id",
    page: <SingleEvent />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },

  //REGISTER EVENT

  {
    path: "client/register-event/:id",
    page: <RegisterEvent />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  {
    path: "/client/notifications",
    page: <Notifications />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  //Terms & Condition
  {
    path: "/client/terms-condition",
    page: <TermsAndConditions />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },

  //Privacy Policy
  {
    path: "/client/privacy-policy",
    page: <PrivacyPolicy />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  //Support
  {
    path: "client/support",
    page: <Support />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
  // Edit Swot
  {
    path: "/client/swot-analysis/edit",
    page: <EditSwotAnalysis />,
    isPrivate: true,
    permissions: [Roles.CLIENT],
  },
];

export default ClientRoutes;
