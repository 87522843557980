import {Navigate} from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const ProtectedRoute = ({children}: any) => {
 const {user, checkedType, token} = useAuth();
 let startPaged = '';
 if (user?.role?.length === 2) {
  const filteredRole = user?.role?.filter((obj: string) => obj === checkedType);
  startPaged = `/${filteredRole}`;
 } else {
  startPaged = user?.role?.[0] === 'coach' ? '/coach' : '/client';
 }
 //const startPage = user?.role?.includes('client') ? '/client' : '/coach';
 return token ? <Navigate to={startPaged} /> : children;
};

export default ProtectedRoute;
