import { useState, useEffect, useRef } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import YoutubeCard from "components/YoutubeCard";
import ClientHeroImage from "assets/images/client-hero.png";
import { useGetYoutubeVideosQuery } from "apis/general";
import Pagination from "components/CustomPagination/Pagination";

const YoutubeVideos = () => {
  const PageSize = 10;
  const newRef = useRef<null | HTMLDivElement>(null);
  const [pageToken, setPageToken] = useState("");
  const { data, refetch, isLoading, isFetching } = useGetYoutubeVideosQuery({
    pageToken: pageToken,
  });


  const youtubeVids = data?.data?.items?.items;
  const nextPageToken = data?.data?.items?.nextPageToken;
  const prevPageToken = data?.data?.items?.prevPageToken;
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (value: number) => {
    setCurrentPage(value);
    if (value === 1) {
      setPageToken('');
    } else if (value > currentPage || data?.data?.items?.nextPageToken) {
      setPageToken(nextPageToken);
    } else if (value < currentPage || value <= parseInt(data?.data?.items?.pageInfo?.totalResults)) {
      setPageToken(prevPageToken);
    }
  };

  useEffect(() => {
    refetch();
  }, [pageToken, refetch]);

  return (
    <Layout>
      <Hero image={ClientHeroImage} heading="YouTube Videos" />
      <div
        ref={newRef}
        className="container mx-auto pt-[1rem] pb-[3rem] sm:pt-[2rem] sm:pb-[8rem] px-10 desktop:px-10"
      >
        <h4 className="text-[2.6rem] font-medium mt-[13.3rem] text-white mb-[4.3rem]">
          YouTube Videos
        </h4>
        {/* {youtubeVids?.length === 0 && <EmptyCard type={"Video"} />} */}

        {isFetching || isLoading ? (
          <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
            <p className="text-4xl sm:text-5xl text-primary_blue">
              Loading YouTube Videos...
            </p>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-[1.42rem] gap-y-[2.13rem] pb-[3rem]">
              {youtubeVids?.map((video: any, index: number) => (
                <YoutubeCard key={index} video={video} />
              ))}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={
                  parseInt(data?.data?.items?.pageInfo?.totalResults) || 0
                }
                siblingCount={1}
                pageSize={PageSize}
                onPageChange={(page: any) => {
                  setCurrentPage(page);
                  handleChange(page);
                }}
              />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default YoutubeVideos;
