import Hero from "components/Hero";
import Layout from "layouts/Layout";
import ClientHeroImage from "assets/images/client-hero.png";
import SupportForm from "components/SupportForm";

const Support = () => {
  return (
    <Layout>
      <Hero image={ClientHeroImage} isCentered heading="Help & Support" />
      <div className="container mx-auto py-28 px-10 desktop:px-10">
        <SupportForm />
      </div>
    </Layout>
  );
};

export default Support;
