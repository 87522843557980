import { useState } from "react";
import NotesCard from "./NotesCard";
import CreateNewNote from "./CreateNewNote";
import { useGetNotesQuery } from "apis/coach";
import { useParams } from "react-router-dom";
import Add from "assets/images/Add.svg";
import ClientNotesCard from "./ClientNotesCard ";

const Notes = () => {
  const [newNote, setNewNoteItem] = useState(false);
  const { id } = useParams();
  const { data, isFetching, isLoading } = useGetNotesQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const coachNotes = data?.data?.notes?.notesReceived || [];
  const clientNotes = data?.data?.notes?.notesSent || [];

  return (
    <>
      {isFetching || isLoading ? (
        <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
          <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
        </div>
      ) : (
        <div className="mt-[5.6rem]">
          {!newNote ? (
            <>
              <div className="flex justify-between mb-[5.6rem]">
                <h3 className="font-bold text-4xl sm:text-5xl leading-[3.5rem] text-white self-center">
                Coach&apos;s Notes
                </h3>
                <button
                  onClick={() => setNewNoteItem(true)}
                  className="w-max px-8 h-20 bg-primary_blue text-white font-bold text-[1.6rem] sm:text-[2rem] rounded-[0.8rem] disabled:opacity-50 flex items-center border"
                >
                  <img src={Add} />
                  Create New Note
                </button>
              </div>
              {clientNotes.map((item: any, index: number) => {
                return (
                  <NotesCard
                    key={item?.id}
                    data={item}
                    index={index}
                    length={clientNotes?.length}
                  />
                );
              })}

              <div className="mt-[5.6rem]">
                <div className="flex justify-between mb-20">
                  <h3 className="font-bold text-4xl sm:text-5xl leading-[3.5rem] text-white">
                    Client&apos;s Notes
                  </h3>
                </div>
                {coachNotes?.map((item: any, index: number) => {
                  return (
                    <>
                      <ClientNotesCard
                        key={item?.id}
                        data={item}
                        index={index}
                        length={coachNotes?.length}
                      />
                    </>
                  );
                })}
              </div>
            </>
          ) : (
            <CreateNewNote setNewNoteItem={setNewNoteItem} />
          )}
        </div>
      )}
    </>
  );
};

export default Notes;
