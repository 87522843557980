import { Fragment } from "react";
import ProfileImg from "../../../../assets/images/profile.png";
import { Dialog, Transition } from "@headlessui/react";
import numberWithCommas from "utils/commaseperatedfunction";

const DetailModal = ({ item, setShowModal, showModal }: any) => {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-[999999]" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000] bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[85rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mb-10 h-[40rem]">
                    <img
                      className="object-cover text-primary_blue text-[3rem] text-center font-bold w-full h-full rounded-[2rem]"
                      src={item?.image ? item?.image : ProfileImg}
                      alt="NoImage"
                    />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className=" text-[#2165A2] font-semibold text-gray-900"
                  >
                    <div className="flex justify-between">
                      <p className="text-5xl capitalize">
                        {item?.title}
                      </p>
                      <p className="text-5xl  min-w-[10rem]">
                        ${numberWithCommas(item?.price)}
                      </p>
                    </div>
                  </Dialog.Title>
                  <div className="mt-2">
                    <p
                      className="text-3xl h-[7rem] overflow-y-auto break-normal text-gray-500 pt-2 pb-3"
                      id="scroll"
                    >
                      {item?.description}
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DetailModal;
