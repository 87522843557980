import { useState } from "react";
import ChevronDown from "assets/icons/chevron-down-filled.svg";
import { useNavigate } from "react-router-dom";
import { Listbox } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCreateChampionshipMutation } from "apis/client";
import { toast } from "react-toastify";

interface options {
  title: string;
  value: string;
}

interface IFormInputs {
  taskName: string;
  options: options;
}

const schema = yup.object({
  taskName: yup.string().required("enter task"),
  options: yup.string().required("select category"),
});

const options = [
  { title: "For Me", value: "myself" },
  { title: "For Someone Else ", value: "someone" },
  { title: "For My Business ", value: "business" },
];

const ChampionshipTask = () => {
  const [createChampionship, { isLoading }] =
    useCreateChampionshipMutation();
  const navigate = useNavigate();

  const [selected, setSelected] = useState<any>({
    title: "Please Select",
    value: "",
  });

  const {
    setError,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFormInputs) => {
    const payload = {
      title: data?.taskName,
      category: data?.options,
    };
    createChampionship(payload).then(()=>{
      toast.success("Championship Created")
      navigate("/client");
    }).catch((error:any)=>{
      toast.success(error?.message)
    })
  };

  return (
    <div className="mt-[8.8rem] mb-[8.8rem]">
      <div className="max-w-[55rem] mx-auto">
        <form className="schedule_call_input" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="mb-[3rem]">
              <label>Championship Day Item</label>
              <input
                type="text"
                {...register("taskName")}
                placeholder="Enter Championship Day Item"
                className="placeholder:!text-[#605F5F]"
              />
              {errors.taskName && (
                <p className="error">{errors.taskName?.message}</p>
              )}
            </div>

            <div>
              <label>Category</label>
              <Listbox
                value={selected.value}
                onChange={(selected: any) => {
                  setSelected(selected);
                  setValue("options", selected.value);
                  setError("options", { type: "focus" }, { shouldFocus: true });
                }}
              >
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-pointer border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block text-[2.6rem]">
                      {selected?.title}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                      <img className="w-[1.6rem]" alt="ChevronDown" src={ChevronDown}></img>
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="absolute mt-0 w-[90%] mx-[3rem] overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {options.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        className=" cursor-pointer text-center h-[4rem]"
                        value={option}
                        {...register("options")}
                      >
                        {({ selected }) => (
                          <>
                            <p
                              className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center   ${selected
                                ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                : "text-[#424242]"
                                }`}
                            >
                              {option.title}
                            </p>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
              {errors.options && (
                <p className="error">{errors.options?.message}</p>
              )}
            </div>
            <div>
              <button
                disabled={isLoading}
                style={{ background: isLoading ? "grey" : "#2165A2" }}
                type="submit"
                className=" w-full bg-primary_blue rounded-[1rem] text-white text-[2.4rem] leading-none font-medium  mt-[7rem] h-24 "
              >
                {isLoading ? "Loading..." : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChampionshipTask;
