import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowDown from "assets/icons/arrow-down-fill.svg";
import Calendar from "assets/images/Calendar.svg";
import { useEffect, useState } from "react";
import LeftArrow from "assets/icons/LeftArrow.svg";
import RightArrow from "assets/icons/RightArrow.svg";
import CallCalendar from "./Calendar";
import CrossIcon from "assets/icons/cross-white.svg";
import moment from "moment";
import {
  useDeleteScheduleCallMutation,
  useGetUpcomingGroupCallsQuery
} from "apis/coach";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";

dayjs.extend(utc);
dayjs.extend(timezone);

const ScheduleCallCalendar = () => {
  const {
    data,
    isLoading: Loading,
    isFetching,
    refetch,
  } = useGetUpcomingGroupCallsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const [deleteScheduleCall] = useDeleteScheduleCallMutation();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [date, setDate] = useState(moment());
  const [selectedMonth, setSelectedMonth] = useState(
    moment().format("MMM YYYY")
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [eventsList, setEventsList] = useState(data?.data?.calls);

  const handlePrev = () => {
    const newDate = moment(selectedDate).subtract(1, "month");
    setSelectedMonth(moment(newDate).format("MMM YYYY"));
    setSelectedDate(newDate);
    setDate(newDate);
  };

  const handleNext = () => {
    const newDate = moment(selectedDate).add(1, "month");
    setSelectedMonth(moment(newDate).format("MMM YYYY"));
    setSelectedDate(newDate);
    setDate(newDate);
  };

  useEffect(() => {
    let scheduledCalls: any = [];
    if (data?.data?.result) {
      scheduledCalls = data?.data?.result?.map((item: any) => {
        return {
          ...item,
          endDate: moment(item?.date).format(),
          start: new Date(item?.date),
          end: new Date(item?.date),
          allDay: true,
        };
      });
    }
    setEventsList(scheduledCalls);
  }, [Loading, refetch, isFetching]);

  // Get Filter Calls Function
  function getFilterCalls() {
    setDate(moment(startDate));
    setSelectedMonth(moment(startDate).format("MMM YYYY"));
    setSelectedDate(moment(startDate));
    const start = dayjs(startDate).startOf("day").format();
    const end = dayjs(endDate).startOf("day").format();
    if (end < start) {
      toast.warn("Select end date greater than start date");
      setEventsList([]);
      return;
    }
    const payload = {
      start_date: dayjs(startDate).startOf("day").format(),
      end_date: dayjs(endDate).startOf("day").format(),
    };
    const newCalls = data?.data?.result
      ?.filter((event: any) => {
        return dayjs(event.date).isBetween(
          payload.start_date,
          payload.end_date,
          "day",
          "[]"
        );
      })
      .map((item: any) => {
        return {
          ...item,
          endDate: moment(item?.date).format(),
          start: new Date(item?.date),
          end: new Date(item?.date),
          allDay: true,
        };
      });
    setEventsList([...newCalls]);
  }

  function handleNavigate(date: any) {
    setDate(date);
  }

  /// Setting the Calls  and clearing date-picker states
  const handleClear = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setSelectedMonth(moment().format("MMM YYYY"));
    setDate(moment());
    refetch();
  };

  const inProgressEvent = false;

  return (
    <div className="container mx-auto py-28 px-10 desktop:px-10">
      <div className="flex items-center gap-10 flex-col sm:flex-row  sm:justify-between">
        <h1 className="text-white text-center lg:text-left text-[3.6rem] leading-none font-medium mt-0">
          Scheduled Group Calls
        </h1>
        <div className="flex gap-[3.1rem]">
          <h1 className="text-white text-[2.4rem] font-medium ">
            {selectedMonth}
          </h1>
          <div className="flex gap-4 justify-center items-center">
            <button
              onClick={handlePrev}
              className="px-[1rem] py-[0.85rem] w-[3rem] h-[3rem] rounded-[50%] bg-[#2165A2]"
            >
              <img className="" src={LeftArrow} alt="Left Arrow" />
            </button>
            <button
              onClick={handleNext}
              className="px-[1rem] py-[0.85rem] w-[3rem] h-[3rem] rounded-[50%] bg-[#828282]"
            >
              <img className="" src={RightArrow} alt="Right Arrow" />
            </button>
          </div>
        </div>
      </div>
      <h3 className="text-center sm:text-left text-white text-[1.8rem] font-bold mt-[3.5rem]">
        Filter by
      </h3>
      <div className="flex flex-col  sm:flex-row mt-[2.3rem] sm:gap-[13.7rem] md:gap-[9rem]">
        <div className="flex flex-wrap gap-6">
          <div className="flex gap-6 items-center">
            <h3 className="text-white text-[1.4rem] font-medium">Date</h3>
            <div className="relative">
              <img
                className="absolute w-[1.87rem] h-[1.87rem] !left-[1.4rem] sm:left-[1.4rem] !top-[1.3rem] sm:top-[2.2rem]"
                src={Calendar}
                alt="Calendar"
              />
              <img
                className="absolute right-[1.4rem] !top-[0.8rem] sm:!top-[1.4rem]"
                src={ArrowDown}
                alt="ArrowDown"
              />
              <DatePicker
                className="input-form-calendar !rounded-[1rem] !mt-0 !text-[1.4rem] font-medium !max-w-[15.9rem] !h-[4.6rem] "
                selected={new Date(startDate)}
                onChange={(date: any) => setStartDate(date)}
                placeholderText="Select  date"
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-6 items-center">
            <h3 className="text-white text-[1.4rem] font-medium sm:mr-0 ml-6">
              To
            </h3>
            <div className="relative">
              <img
                className="absolute w-[1.87rem] h-[1.87rem] !left-[1.4rem] sm:left-[1.4rem] !top-[1.3rem] sm:top-[2.2rem]"
                src={Calendar}
                alt="Calendar"
              />
              <img
                className="absolute right-[1.4rem] !top-[0.8rem] sm:!top-[1.4rem]"
                src={ArrowDown}
                alt="ArrowDown"
              />
              <DatePicker
                className="input-form-calendar !rounded-[1rem] !mt-0 !text-[1.4rem] font-medium !max-w-[15.9rem] !h-[4.6rem]"
                onChange={(date: any) => setEndDate(date)}
                selected={endDate}
              />
            </div>
            <div className="flex flex-wrap items-center gap-10 sm:m-0 mr-auto ">
              <div>
                <button
                  type="button"
                  onClick={getFilterCalls}
                  className="text-white rounded-[1rem] bg-[#2165a2] border-solid border-[white]  !mt-0 border-[0.2rem] text-[1.4rem] font-medium w-[10rem] h-[4.6rem]"
                >
                  Filter
                </button>
              </div>

              <div
                onClick={handleClear}
                className="w-[3.4rem] h-[3.4rem] cursor-pointer"
              >
                <img
                  src={CrossIcon}
                  alt="CrossIcon"
                  className="w-full h-full"
                />
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>

      <div className="mt-20 cursor-pointer">
        <div className="bg-white rounded-[1.23547rem] w-full h-full overflow-hidden">
          <CallCalendar
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            myEventsList={eventsList || []}
            deleteScheduleCall={deleteScheduleCall}
            handleNavigate={handleNavigate}
            date={date}
            refetch={refetch}
            inProgressEvent={inProgressEvent}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleCallCalendar;
