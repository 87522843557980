import { createSlice } from "@reduxjs/toolkit";
import { clientServerApi } from "apis/client";

const initialState = {
  token: "",
  user: "",
  isOnboarded: false,
  checkedType: "client",
  legacyDate: new Date(),
  display: true,
  notiCount: 0,
  checkedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsOnboarded: (state, action) => {
      state.isOnboarded = action.payload;
    },
    setCheckedType: (state, action) => {
      state.checkedType = action.payload;
    },
    setNotiCount: (state, action) => {
      state.notiCount = action.payload;
    },
    setModalDisplay: (state, action) => {
      state.display = action.payload;
    },
    setlegacyDate: (state, action) => {
      state.legacyDate = action.payload;
    },
    setCheckedIn: (state, action) => {
      state.checkedIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      clientServerApi.endpoints.submitAnswer.matchFulfilled,
      (state) => {
        state.isOnboarded = true;
      }
    );
  },
});

export const setToken = authSlice.actions.setToken;
export const setUser = authSlice.actions.setUser;
export const setIsOnboarded = authSlice.actions.setIsOnboarded;
export const setCheckedType = authSlice.actions.setCheckedType;
export const setNotiCount = authSlice.actions.setNotiCount;
export const setModalDisplay = authSlice.actions.setModalDisplay;
export const setlegacyDate = authSlice.actions.setlegacyDate;
export const setCheckedIn = authSlice.actions.setCheckedIn;

const AuthSlice = authSlice.reducer;
export default AuthSlice;
