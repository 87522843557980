import { useEffect } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import ClientHeroImage from "assets/images/client-hero.png";
import { useGetGroupCallRecordingsQuery } from "apis/coach";
import { useState } from "react";
import VideoCallsTabs from "../../../components/VideoCallsTabs";
import ScheduleCallCalendar from "./components";
import PreviousCall from "./components/PreviousCall";
import SearchFilters from "./components/SearchFilters";

const GroupCalls = () => {
  const [tab, setTab] = useState("upcomingcalls");
  const [callsData, setCallsData] = useState([]);

  const { data, isLoading, isFetching, isSuccess } = useGetGroupCallRecordingsQuery(1, { refetchOnMountOrArgChange: true, skip: tab === "upcomingcalls" });
  const calls = data?.data?.data;

  useEffect(() => {
    if (calls && !isFetching) {
      setCallsData(calls);
    }
  }, [isSuccess]);

  function RenderCalls() {
    return (
      <div>
        <ScheduleCallCalendar />
      </div>
    );
  }
  
  return (
    <Layout>
      <Hero image={ClientHeroImage} heading="Scheduled Group Calls" />
      <div className="container mx-auto pt-[4rem] sm:pt-[4rem] px-10 desktop:px-10 pb-[8rem]">
        <div className="flex flex-col gap-8 lg:flex-row justify-between lg:items-end">
          <VideoCallsTabs setTab={setTab} />
          {tab === "previouscalls" && (
            <SearchFilters
              callsData={callsData}
              setCallsData={setCallsData}
              calls={calls}
            />
          )}
        </div>

        {tab === "previouscalls" && (
          <PreviousCall
            callsData={callsData}
            isLoading={isLoading}
          />
        )}

        {tab !== "previouscalls" && (
          <RenderCalls />
        )}

      </div>
    </Layout>
  );
};

export default GroupCalls;
