import { Dialog, Transition } from "@headlessui/react";
import dayjs from "dayjs";
import CloseIcon from "../../../../assets/images/CloseIcon.svg";
import { Fragment } from "react";

function Modal({ isOpen, setIsOpen, note, date, title }: any) {
  const splitData = note?.split("\n");
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[999999]"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000] bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex justify-center p-2 items-center h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[60rem]">
                  <div className="">
                    <div
                      className="max-h-[50rem] overflow-y-auto bg-white w-full md:min-w-[72rem] rounded-[0.4rem] pt-[2.4rem] pr-[2.9rem] pb-[4rem] pl-[3rem] flex gap-32 border border-l-[1.8rem] border-primary_blue"
                      id="scroll"
                    >
                      <div className="bg-primary_blue w-[1.34rem]  rounded-[0.45rem] absolute top-0"></div>
                      <div className="flex-1">
                        <div className="flex justify-between">
                          <Dialog.Title className="text-primary_blue capitalize text-[2.4rem] font-medium leading-[2.8rem]">
                            {title && title}
                          </Dialog.Title>
                          <div className="min-w-[2.9rem] w-[2.9rem] h-[2.9rem] cursor-pointer">
                            <img
                              onClick={() => setIsOpen(false)}
                              className="w-full h-full"
                              src={CloseIcon}
                              alt="Close Icon"
                            />
                          </div>
                        </div>
                        <Dialog.Description className="text-[#333333] break-normal text-[2rem] leading-[2.4rem] mt-[2.8rem]">
                          <div id="notes">
                            <ul className="font-normal text-[2rem] leading-[2.4rem] text-[#333333]" style={{ wordBreak: "break-word" }}>
                              {splitData?.map((item: any, idx:number) => {
                                if (item) {
                                  return <li key={idx}>{item}</li>
                                }
                              })}
                            </ul>
                          </div>
                        </Dialog.Description>

                        <h6 className="text-[#333333] text-[1.87rem] mt-[4.4rem] ">
                          {dayjs(date).format("MM/DD/YYYY")}
                        </h6>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
export default Modal;
