import { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { CreateActionItem } from "apis/coach-apis";
import "react-datepicker/dist/react-datepicker.css";

interface IFormInputs {
  task_name: string;
  // reminder: string;
  action_item: string;
}

const schema = yup.object({
  task_name: yup.string().required("Enter Task"),
  // reminder: yup.string().required("Enter Remidner"),
  action_item: yup.string(),
});

const CreateNewAction = ({ setNewActionItem, refetch }: any) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    const paylaod = {
      clientId: id,
      title: data.task_name,
      description: data.action_item,
    };
    CreateActionItem(paylaod)
      .then((result) => {
        setLoading(false);
        if (result) {
          toast.success(result?.message);
          setNewActionItem(false);
          refetch(); 
        } 
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response.data.message);
      });
  };
  return (
    <div>
      <h3 className="font-bold text-white text-6xl">Action Items </h3>
      <div className="mt-[5.6rem] max-w-[53.3rem] mx-auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-[1rem]">
            <h6 className="text-white text-[2.4rem] leading-none font-normal text-start mb-4">
              Task Name
            </h6>
            <input
              type="text"
              {...register("task_name")}
              style={{ background: "transparent" }}
              className="text-white w-full h-24 rounded-xl text-4xl px-[1.4rem] border-[1.5px] border-white mt-[1rem] text-left placeholder:text-center placeholder:text-[#B4B4B4] focus:outline-none"
              placeholder="Enter Task"
            />
            {errors.task_name && (
              <p className="error">{errors.task_name?.message}</p>
            )}
          </div>
          
          <div className="mt-[4.4rem]">
            <button
              type="submit"
              className="h-24 w-full px-20 bg-primary_blue text-[2.6rem] rounded-xl"
              disabled={loading}
              style={{ background: loading ? "grey" : "#2165A2" }}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewAction;
