import React from "react";
import AuthLayout from "layouts/AuthLayout";
import PrimaryButton from "components/common/PrimaryButton";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <div className="bg-white rounded-2xl px-24 sm:px-36 py-32 auth_box">
        <h1 className="text-5xl font-bold mb-2">Welcome!</h1>
        <p className="text-5xl font-normal mb-16">Log In to Continue</p>
        <PrimaryButton
          color="primary"
          text="Log In"
          onClick={() => navigate("/login")}
        />
      </div>
    </AuthLayout>
  );
};

export default Index;
