import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCreateActionItemMutation } from "apis/client";
import { toast } from "react-toastify";

interface IFormInputs {
  taskName: string;
  // reminder: string;
}

const schema = yup.object({
  taskName: yup.string().required("Enter task"),
  // reminder: yup.string().required("Enter Remidner")
});

const ActionItemTask = () => {
  const [createActionItem, { isLoading }] = useCreateActionItemMutation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFormInputs) => {
    const payload = {
      title: data?.taskName,
      // reminder: data?.reminder,
    };
    createActionItem(payload)
    .then((result: any) => {
      if (result?.data?.status == 201) {
        toast.success(result?.data?.message);
        navigate("/client")
      } 
      else  if(result?.error?.status == 400) {
        toast.error(result?.error?.data?.message);
      }
    })
  };

  return (
    <div className="mt-[8.8rem] mb-[8.8rem]">
      <div className="max-w-[55rem] mx-auto">
        <form className="schedule_call_input" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="">
              <label>Action Item</label>
              <input
                type="text"
                {...register("taskName")}
                placeholder="Enter Action Item"
                className="placeholder:!text-[#605F5F]"
              />
              {errors.taskName && (
                <p className="error">{errors.taskName?.message}</p>
              )}
            </div>

            <div>
              <button
                disabled={isLoading}
                style={{ background: isLoading ? "grey" : "#2165A2" }}
                type="submit"
                className=" w-full bg-primary_blue rounded-[1rem] text-white text-[2.4rem] leading-none font-medium  mt-[7rem] h-24 "
              >
                {isLoading ? "Loading..." : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActionItemTask;
