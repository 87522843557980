import PlayButton from "assets/images/PlayButton.svg";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { selectUser } from "store/selectors";
import { useSelector } from "react-redux";
import CheckGreen from "assets/icons/check-green-fill.svg";
import CheckBlue from "assets/icons/check-blue.svg";

interface PodcastCardProps {
  id: any;
  title: string;
  name: string;
  date: string;
  duration?: string;
  thumbnail: string;
  toPage?: string;
  playVideo?: string;
  showCheck?: boolean
  members?: any
}

const PodcastCard = ({
  title,
  name,
  date,
  duration,
  thumbnail,
  toPage,
  showCheck,
  members,
}: PodcastCardProps) => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const navigatePage = () => {
    if (!toPage) {
      return;
    }
    navigate(toPage);
  };

  const isMember = members?.find((member: any) => member?.email === user?.email)

  return (
    <>
      <div className="w-full bg-white rounded-[0.8rem] overflow-hidden cursor-pointer"
        onClick={navigatePage}
      >
        <div className="relative h-[24rem]">
          {showCheck && (
            <div className="absolute top-[1.6rem] left-0 w-full px-8 z-[1]">
              <div className="flex justify-end">
                <img
                  src={isMember ? CheckGreen : CheckBlue}
                  className="h-[2.8em] mt-3"
                  alt="eventImage"
                />
              </div>
            </div>
          )}
          <div className="bg-darkgrey w-full h-full absolute top-0 left-0 opacity-[40%]"></div>
          <div>
            <img
              className="w-full h-[24rem] object-cover"
              src={thumbnail}
              alt="Card Thumbnail"
            />
          </div>
          <div
            className="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] cursor-pointer"
          // onClick={openModal}
          >
            <img className="w-[5rem]" src={PlayButton} alt="Play Button" />
          </div>
          {duration && (
            <div>
              <h6 className="absolute text-white leading-9 font-thin text-[2.5rem] right-10 bottom-10">
                {duration}
              </h6>
            </div>
          )}
        </div>

        <div className="px-[1.5rem] py-[1.4rem]">
          <h1 className="text-primary_blue  truncate capitalize text-[3rem] font-bold leading-[3.5rem] break-normal max-w-[40rem]">
            {title}
          </h1>
          <div className="flex justify-between items-center mt-[1rem]">
            <h1 className="text-[#333333] capitalize text-[2.7rem] font-normal leading-[3.1rem] whitespace-nowrap max-w-[25rem] text-ellipsis overflow-hidden">
              {name}
            </h1>
            <h1 className="text-[#333333] text-[1.8rem] font-light leading-[2.8rem] mt-3">
              {dayjs(date).format("MM-DD-YYYY")}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default PodcastCard;