import SwotTimeLineTabs from "../components/SwotTimeLineTabs";
import { useGetSwotAnalysisQuery } from "apis/coach";
import { useParams } from "react-router-dom";

const SwotTab = () => {
  const { id } = useParams()
  const { data } = useGetSwotAnalysisQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const swotAnswers = data?.data?.swotAnalysis;

  return (
    <div>
      {swotAnswers?.length > 0 ? (
        <SwotTimeLineTabs answers={swotAnswers} />
      ) : (
        <div className="bg-[#fff] px-5 py-10 rounded-lg mt-[5rem]">
          <p className="text-4xl text-center text-primary_blue">No SWOT Analysis Found</p>
        </div>
      )
      }
    </div>
  );
};

export default SwotTab;
