import { Disclosure } from "@headlessui/react";
import DownArraow from "../../../../assets/images/DownArraow.svg";

export default function OnBoardingAccordian({ question, answer }: any) {
  return (
    <div className="mt-[1.7rem]">
      <div className="mx-auto w-full rounded-2xl bg-white p-2">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="items-center leading-[2.5rem] sm:leading-[4rem] flex w-full h-[8rem] sm:h-[10rem] justify-between rounded-[1.2rem] bg-transparent px-[1.8rem] py-[2rem] text-left text-[2rem] sm:text-[2.7rem] md:text-[3.7rem] font-medium text-[#2165A2] focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 whitespace-normal">
                <span className="pr-10">{question}</span>
                <img
                  alt="img"
                  src={DownArraow}
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-[4.1rem] w-[4.1rem]`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-[2.4rem] pt-[3.2rem] pb-[3.2rem] text-[#fff] text-[2rem] sm:text-[2.7rem] md:text-[3.7rem] bg-[#333333] font-normal">
                {answer}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
