import React, { useState } from "react";
import LeaderModal from "./LeaderModal";
import ProfileImg from "assets/images/profile.png";
import { useCreateChallengeMutation } from "apis/client";
import { toast } from "react-toastify";

const Card = ({ index, user }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [createChallenge] = useCreateChallengeMutation();

  const submitHandler = () => {
    const payload = {
      opponentId: user.id,
    };
    createChallenge(payload)
      .unwrap()
      .then((res: any) => {
        if (res && res?.message) {
          toast.success(res?.message);
        }
      })
      .catch((err) => {
        if (err?.data && err?.data?.message) {
          toast.error(err.data.message);
        }
      });
  };
  
   // Converting numbers with comma separated
   function numberWithCommas(x:any) {
    x = x?.toString();
    const pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
}

  return (
    <>
      <LeaderModal isOpen={isOpen} setIsOpen={setIsOpen} user={user} />

      <div className=" bg-white rounded-[0.4rem] py-[3rem] pl-[2rem] pr-[3rem]">
        <div className="flex flex-col gap-[1rem]  mobile_l:flex-row  sm:items-center">
          <div className="flex w-full mobile_l:w-[40%] gap-8 items-center">
            <h6 className="text-[3rem] text-[#2165A2] font-medium leading-[3.5rem] ">
              {index + 1}
            </h6>
            <div className=" flex gap-8 items-center w-full">
              <div
                className="w-[8rem] h-[8rem] min-w-[8rem] rounded-[50%] overflow-hidden cursor-pointer"
                onClick={() => setIsOpen(true)}
              >
                <img
                  className="min-w-[8rem] h-[8rem] object-cover"
                  src={user?.profile_photo ? user?.profile_photo : ProfileImg}
                  alt="leaderboarduser"
                />
              </div>
              <h6 className="sm:w-[40rem] capitalize truncate sm:overflow-hidden sm:whitespace-nowrap sm:text-ellipsis text-[2.5rem] text-[#2165A2] font-bold leading-[3rem]">
                {user?.full_name}
              </h6>
            </div>
          </div>
          <div className="flex w-full mobile_l:w-[60%]  justify-between items-center sm:flex-row">
            <div className="ml-0 sm:ml-[15rem] flex sm:flex-row gap-[0.78rem] items-center">
              <h6 className="font-bold  text-[#2165A2] text-[2.2rem] leading-[2.6rem] ">
                Win Rating:
              </h6>
              <h6 className="text-[#333333] text-[2rem] leading-[3rem] ">
                {numberWithCommas(user?.winRating >= 0 ? user?.winRating  : 0 )}
              </h6>
            </div>
            <div className="flex  items-center sm:flex-row">
              <button
                onClick={submitHandler}
                className="max-w-[12rem] max-h-[5.1rem] px-[1.8rem] py-[1rem] bg-primary_blue rounded-[1.1rem] text-white font-medium  text-[1.8rem] leading-[2.2rem]"
              >
                Challenge
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
