import { useNavigate } from "react-router-dom";
import LocationIcon from "assets/icons/location-icon.svg";
import dayjs from "dayjs";
import CheckGreen from "assets/icons/check-green-fill.svg";
import CheckBlue from "assets/icons/check-blue.svg";
import { selectUser } from "store/selectors";
import { useSelector } from "react-redux";
import RedCross from "assets/icons/cross-red.svg"

const EventCard = ({
  title,
  desc,
  location,
  startTime,
  date,
  toPage,
  thumbnail,
  members,
}: any) => {
  const navigate = useNavigate();
  const navigatePage = () => {
    if (!toPage) {
      return;
    }
    navigate(toPage);
  };

  const user = useSelector(selectUser);
  const isRSVP = members?.find((member: any) => member?.email == user?.email);

  return (
    <div
      className="bg-white w-full  rounded-lg overflow-hidden cursor-pointer"
      onClick={() => navigatePage()}
    >
      <div className="w-full h-[26.3rem] overflow-hidden relative">
        <div>
          <div className="bg-darkgrey w-full h-full absolute top-0 left-0 opacity-[50%]"></div>
          <img
            className="object-cover w-full h-[26.3rem]"
            src={thumbnail}
            alt="eventImage"
          ></img>
        </div>
        <div className="absolute top-[1.9rem] left-0 w-full px-8">
          <div className="flex w-full gap-4 justify-between">
            <p className="text-6xl text-white font-extrabold w-4/5 capitalize truncate">
              {title}
            </p>
            <img
              src={isRSVP?.isAttending === "YES" ? CheckGreen : isRSVP?.isAttending === "NO" ? RedCross : CheckBlue}
              className="h-[2.8em] mt-3"
              alt="eventImage"
            />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full">
          <div className="px-6 h-[3.59rem] bg-[#000] bg-opacity-[50%] flex flex-row w-full justify-between items-center">
            <div className="flex flex-row items-center gap-3">
              <img className="mt-[-2px]" src={LocationIcon}></img>
              <p className="text-[1.195rem] capitalize text-white font-normal leading-0">
                {location}
              </p>
            </div>
            <div>
              <p className="text-[1.395rem] text-white font-light leading-0">
                {startTime}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="flex flex-row justify-between items-center pb-3">
          <p className="text-primary_blue capitalize text-[2rem] leading-[2.3rem] font-bold w-full truncate">
            {title}
          </p>
        </div>
        <div className="flex flex-col justify-between ">
          <p className="text-[1.6rem] capitalize font-semibold text-grey_medium event_des">
            {desc}
          </p>
          <p className="text-primary_blue text-[1.4rem] font-light text-right">
            {dayjs(date).format("MMM DD, YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EventCard;