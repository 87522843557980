import moment from "moment";
import { toast } from "react-toastify";
import DeleteIcon from "assets/icons/bin-icon.svg";
import { useDeleteActionItemMutation } from "apis/coach";

export default function ActionItem({ item, refetch }: any) {
  const [deleteActionItem] = useDeleteActionItemMutation();
  const textColor = item.isCompleted
    ? "text-primary_blue"
    : "text-primary_blue";

  const deleteHandler = async () => {
    await deleteActionItem(item.id)
      .unwrap()
      .then((res) => {
        refetch();
        toast.success(res?.message);
      })
      .catch((error) => {
        toast.error(error?.data?.message);
      });
  };

  return (
    <div
      key={item?.id}
      className="bg-white px-[3rem] py-[2rem] flex justify-between rounded-2xl mt-[2.4rem] items-center"
    >
      <div className="flex flex-col">
        <h4
          className={`font-semibold text-5xl leading-[3.5rem] text-[#2165A2] mb-[0.6rem] ${textColor}`}
        >
          {item?.title}
        </h4>
        <div className="flex flex-row gap-4">
          <p
            className={`text-[#7C7C7C] text-[2.6rem] leading-[3rem] ${textColor}`}
          >
            {item?.isCompleted
              ? moment(item?.completedAt).format("L")
              : moment(item?.createdAt).format("L")}
          </p>
          {item.createdBy == "coach" && (<>
            <div className="border border-[#E0E0E0] my-[0.5rem]"></div>
            <button onClick={deleteHandler}>
              <img className="w-[1.8rem]" src={DeleteIcon} />
            </button>
          </>)}
        </div>
      </div>
    </div>
  );
}
