import ClientHeroImage from "assets/images/client-hero.png";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import LegacyTabs from "./components/LegacyTabs";

const Index = () => {

  return (
    <Layout>
      <Hero image={ClientHeroImage} subHeading="My Champions Legacy" />
      <div className="container mx-auto py-32 px-10 desktop:px-0">
        <LegacyTabs />
      </div>
    </Layout>
  );
};
export default Index;
