import Hero from "components/Hero";
import Layout from "layouts/Layout";
import PodcastCard from "components/PodcastCard";
import ClientHeroImage from "assets/images/client-hero.png";
import { useGetAllCoursesQuery } from "apis/general";
import { useLocation } from 'react-router-dom';

const Courses = () => {
  const { data, isFetching, isLoading } = useGetAllCoursesQuery(2, {
    refetchOnMountOrArgChange: true,
  });

  const location = useLocation();
  const currentUrl = location.pathname;
  const pageToNavigate = currentUrl.includes("/client/") ? "client" : "coach";
  
  return (
    <Layout>
      <Hero image={ClientHeroImage} />
      <div className="container mx-auto pt-10 pb-32 px-10 desktop:px-10">
        <div>
          <h6 className="text-white font-medium text-[3rem] pb-12">Courses</h6>
        </div>

        {isFetching || isLoading ? (
          <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
            <p className="text-4xl sm:text-5xl text-primary_blue">
              Loading Courses...
            </p>
          </div>
        ) : data?.data?.courses?.length === 0 ? (
          <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
            <p className="text-4xl sm:text-5xl text-primary_blue">
              No Courses Found...
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 mobile_l:grid-cols-2 tablet:grid-cols-3 gap-10">
            {data?.data?.courses?.map((item: any, index: number) => (
              <PodcastCard
                key={index}
                id={item.id}
                title={item.title}
                name={item.detail}
                date={item.createdAt}
                thumbnail={item.thumbnail}
                playVideo={item.file}
                // duration={item.duration}
                showCheck={true}
                members={item.members}
                toPage={`/${pageToNavigate}/course/${item.id}`}
              />
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Courses;
