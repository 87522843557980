import React from "react";
import { Link, useNavigate } from "react-router-dom";
import BgImage from "../assets/images/bg-image.png";
import LogoWhite from "../assets/images/logo-white-h.png";
import { useGetBannerByTypeQuery } from "apis/general";

interface LayoutProps {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: LayoutProps) => {
  const id = "COACH_CLIENT_LOGIN";
  const role = "client";
  const { data } = useGetBannerByTypeQuery(
    { id, role },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const navigate = useNavigate();
  return (
    <div className="h-screen overflow-hidden relative">
      <div className="relative">
        <div className="relative">
          <div className="w-full h-screen bg-darkgrey absolute top-0 left-0 opacity-90"></div>
          <img
            className="auth-bg-image h-screen object-cover"
            src={
              data?.data?.result?.imageUrl
                ? data?.data?.result?.imageUrl
                : BgImage
            }
          ></img>
          <div className="z-50 absolute top-[1rem] right-[3rem] sm:top-[3rem] sm:right-[5.33rem] flex gap-8">
            <Link to="/privacy-policy">
              <h4 className="text-[2.4rem] text-white font-medium">
                Privacy Policy
              </h4>
            </Link>
            <Link to="/terms-condition">
              <h4 className="text-[2.4rem] text-white font-medium">
                Terms & Conditions{" "}
              </h4>
            </Link>
          </div>
        </div>
        <div className="absolute top-16 left-16 cursor-pointer">
          <img
            src={LogoWhite}
            className="winrate-logo w-[32rem] sm:w-[29rem]"
            onClick={() => {
              navigate("/login");
            }}
            alt="WinRate Logo"
          />
        </div>
      </div>
      <div className="absolute top-0 left-0">
        <div className="h-screen w-screen flex justify-center items-center">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;