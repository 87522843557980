import React from "react";
import CloclIcon from "assets/icons/clock-icon.svg";
import BlueBulb from "assets/icons/BlueBulb.svg";
import { useSelector } from "react-redux";
import { selectUser } from "store/selectors";
import { useUpdateChallengeMutation } from "apis/client";
import { toast } from "react-toastify";
import ProfileImg from "assets/images/profile.png";
import { useKickAssMutation } from "apis/client";
import AssKickedCard from "./AssKickedCard";
import GotAssKickedCard from "./GotAssKickedCard";
import numberWithCommas from "utils/commaseperatedfunction";
import moment from "moment";

const ChallengeCard = ({
  challenge: {
    id,
    opponent,
    challenger,
    status,
    endDate,
  },
  socket,
}: any) => {
  const [assKicked, setAssKicked] = React.useState(false);
  const [gotAssKicked, setGotAssKicked] = React.useState(false);
  const [kickAss] = useKickAssMutation();

  const user = useSelector(selectUser);
  const [updateChallenge] = useUpdateChallengeMutation();

  const renderAvatar = (
    name: string,
    image: string,
    position?: "right" | "left"
  ) => {
    return (
      <div className={`${position === "right" ? "text-right" : "text-left"}`}>
        <img
          className={`w-[4.43rem] h-[4.43rem] object-cover rounded-full overflow-hidden mb-[0.6rem] ${position === "right" ? "ml-auto" : ""
            }`}
          alt="profileimg"
          src={image ? image : ProfileImg}
        ></img>
        <p
          className={`full_name text-[2.16rem] w-full text-primary_blue font-medium leading-[2.5rem]  break-all`}
        >
          {name?.split(/\s/)[0]}
        </p>
      </div>
    );
  };

  const acceptHandler = () => {
    const payload = {
      challengeId: id,
      status: "accepted",
    };
    updateChallenge(payload);
    toast.success("Challenge Accepted");
  };

  const rejectHandler = () => {
    const payload = {
      challengeId: id,
      status: "rejected",
    };
    updateChallenge(payload);
    toast.warning("Challenge Rejected");
  };

  socket.on(`ass-kick-to-${user?.id}`, (response: any) => {
    if (response?.challengeId === id) {
      setGotAssKicked(true);
    }
  });

  const kickAssHandler = () => {
    const payload = {
      challengeId: id,
      recieverId: opponent.id === user.id ? challenger.id : opponent.id,
    };
    kickAss(payload)
      .unwrap()
      .then(() => {
        setAssKicked(true);
      });
  };

  function calculateTimeDifference(futureDateTime: Date) {
    const futureTime = moment(futureDateTime);
    if (!moment()?.isSameOrBefore(futureDateTime)){
      return "expired"
    }

    const duration = moment?.duration(futureTime?.diff(moment()));
    duration.subtract(2, "seconds");
    const days = Math?.ceil(duration?.asDays());
    const hours = Math?.ceil(duration?.asHours());
    const minutes = Math?.ceil(duration?.asMinutes());
  
    let result = "";
  
    if (days > 0) {
      result += `${days} day${days > 1 ? 's' : ''} left`;
    } else if (hours > 0) {
      result += `${hours} hour${hours > 1 ? 's' : ''} left`;
    } else {
      result += `${minutes} minute${minutes > 1 ? 's' : ''} left`;
    }
  
    return result;
  }

  return (
    <>
      {!assKicked && !gotAssKicked && (
        <div className="w-full bg-white rounded-[0.4rem] px-[1.2rem] py-[1.2rem]">
          <div className="flex flex-row items-start justify-between mb-[0.5rem]">
            <div className="w-[35%]">
              {renderAvatar(challenger.full_name, challenger.profile_photo)}
            </div>
            <div className="w-[30%]">
              <div className="text-center"></div>
            </div>
            <div className="w-[35%]">
              {renderAvatar(
                opponent.full_name,
                opponent.profile_photo,
                "right"
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mb-[0.5rem] relative">
            <div className="flex flex-row gap-2 items-center">
              <img
                className="h-[2.407rem] w-[1.603rem] mt-[-2px]"
                src={BlueBulb}
                alt="bulb"
              ></img>
              <p className="text-[2.16rem] leading-[2.16rem] text-primary_blue font-normal">
                {numberWithCommas(challenger.winRating > 0 ? challenger.winRating : 0)}
              </p>
            </div>
            <p className="text-[2.6rem] font-extrabold leading-[2.6rem] text-primary_blue absolute top-0 m-0 left-[50%] translate-x-[-50%]">
              VS
            </p>
            <div className="flex flex-row gap-2 items-center">
              <p className="text-[2.16rem] leading-[2.16rem] text-primary_blue font-normal">
                {numberWithCommas(opponent.winRating > 0 ? opponent.winRating : 0)}</p>
              <img
                className="h-[2.407rem] w-[1.603rem] mt-[-2px]"
                src={BlueBulb}
                alt="bulb"
              ></img>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between mb-[0.7rem]">
            <p className="text-[1.41rem] leading-[1.7rem] text-primary_blue font-normal">
              Won Challenges {numberWithCommas(challenger.challengesWon)}
            </p>
            <p className="text-[1.41rem] leading-[1.7rem] text-primary_blue font-normal">
              Won Challenges {numberWithCommas(opponent.challengesWon)}
            </p>
          </div>

          <div className="mt-5">
            {status === "pending" && user.id !== challenger.id && (
              <>
                <div className="flex w-full gap-3">
                  <p className="truncate text-primary_blue text-[1.8rem] font-semibold leading-[2.7rem] break-all">
                    {challenger.full_name?.split(/\s/)[0]}
                  </p>
                  <span className="text-primary_blue min-w-min-[15rem] text-[1.8rem] font-semibold leading-[2.7rem]">
                    Challenged You
                  </span>
                </div>
                <div className="flex justify-end mt-5 gap-3">
                  <button
                    onClick={acceptHandler}
                    className="w-[5.814rem] h-[2.653rem] bg-primary_blue rounded-[0.271rem] text-[1.07592rem] text-white font-medium"
                  >
                    Accept
                  </button>
                  <button
                    onClick={rejectHandler}
                    className="w-[5.814rem] h-[2.653rem] bg-[#D10C0C] rounded-[0.271rem] text-[1.07592rem] text-white font-medium"
                  >
                    Reject
                  </button>
                </div>
              </>
            )}

            {status === "pending" && user.id === challenger.id && (
              <p className="text-primary_blue w-full  truncate text-center text-[1.8rem] font-semibold leading-[2.7rem]">
                You Challenged {opponent.full_name?.split(/\s/)[0]}
              </p>
            )}

            {status === "accepted" && (
              <div className="flex w-full justify-between items-center">
                <div className="flex flex-row items-center gap-[0.5rem] mx-auto pl-[5.814rem]">
                  <img
                    className="w-[1.65rem] mt-[-1px]"
                    alt="icon"
                    src={CloclIcon}
                  ></img>
                  <p className="uppercase text-[#038B21] text-[1.6rem] font-normal leading-[1.6rem] whitespace-nowrap">
                    {/* {moment(new Date()).to(endDate, true).toUpperCase()} Left */}
                    {calculateTimeDifference(endDate)}
                  </p>
                </div>
                <div className="text-end">
                  <button
                    onClick={kickAssHandler}
                    className="w-[5.814rem] h-[2.653rem] bg-primary_blue rounded-[0.271rem] text-[1.07592rem] text-white font-medium"
                  >
                    Ass Kick
                  </button>
                </div>
              </div>
            )}

            {status === "rejected" && (
              <p className="text-primary_blue w-full text-center text-[1.8rem] font-semibold leading-[2.7rem]">
                Challenge Rejected by{" "}
                {user.id !== challenger.id ? "You" : opponent.full_name}
              </p>
            )}
          </div>
        </div>
      )}

      {assKicked && (
        <AssKickedCard
          opponent={opponent}
          challenger={challenger}
          user={user}
          name={
            opponent.id === user.id ? challenger.full_name : opponent.full_name
          }
        />
      )}

      {gotAssKicked && (
        <GotAssKickedCard
          opponent={opponent}
          challenger={challenger}
          user={user}
          name={
            challenger.id === user.id
              ? opponent.full_name
              : challenger.full_name
          }
        />
      )}
    </>
  );
};

export default ChallengeCard;
