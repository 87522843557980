import { Fragment, useEffect } from "react";
import Winratelogo from "../assets/icons/winrate-logo-white.png"
import { NavLink, Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import ChevronDown from "../assets/icons/chevron-down.svg";
import EmptyProfile from "assets/images/EmptyProfile.jpg";
import { useNavigate } from "react-router-dom";
import BellIcon from "../assets/images/bell-fill.svg";
import { useGetNotificationsCountQuery, useGetProfileByIdQuery } from "apis/general";
import { useDispatch, useSelector } from "react-redux";
import { setNotiCount } from "store/slices/auth.slice";

const MainHeader = ({
  navLinks,
  notification,
  accountLink,
  showRoleBtn,
  isClient,
}: any) => {
  const id = useSelector((state: any) => state?.auth?.user?.id);
  const notificationCount = useSelector((state: any) => state?.auth);
  const notiCount: number = notificationCount?.notiCount;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUserRole = isClient ? 'client' : 'coach'

  //get Notifications Count Api
  const { data: notificationsCount } = useGetNotificationsCountQuery({ id, role: currentUserRole }, {
    refetchOnMountOrArgChange: true,
  });
  const { data: userData } = useGetProfileByIdQuery(id, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })

  useEffect(() => {
    dispatch(
      setNotiCount(
        notificationsCount?.data?.count ? notificationsCount?.data?.count : 0
      )
    );
  }, [dispatch, notificationsCount?.data?.count]);

  return (
    <header className="h-[10.4rem] !bg-[#18242c] fixed left-0 top-0 shadow-2xl w-full flex items-center justify-between px-[8rem] z-[999] border-b-2 border-[#fff]">
      <Link to={navLinks[0].url}>
        <div className="nav_brand mt-3 w-64">
          <img src={Winratelogo} className="w-64"></img>
        </div>
      </Link>
      <div className="nav_links">
        <ul className="flex flex-row items-center gap-8">
          {navLinks.map((navItem: any, index: any) =>
            !navItem.isDropdown ? (
              <li key={index}>
                <NavLink
                  to={navItem.url}
                  className="text-white text-3xl font-semibold hover:text-primary_light"
                >
                  {navItem.title}
                </NavLink>
              </li>
            ) : (
              <li key={index}>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center text-white text-3xl bg-transparent font-semibold  hover:tex-primary_light">
                      <div className="flex flex-row gap-3 items-center">
                        {navItem.title}
                        <img src={ChevronDown} className="mt-2"></img>
                      </div>
                    </Menu.Button>
                  </div>
                  <Menu.Items className="absolute right-0 pt-3 mt-2 w-72 origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1">
                      {navItem.dropContent?.map((item: any, index: any) => (
                        <Fragment key={index}>
                          <Menu.Item>
                            <Fragment>
                              <NavLink
                                to={item.url}
                                className="flex w-full items-center text-3xl px-5 py-2 hover:text-primary_light hover:bg-gray-200"
                              >
                                {item.title}
                              </NavLink>
                            </Fragment>
                          </Menu.Item>
                        </Fragment>
                      ))}
                    </div>
                  </Menu.Items>
                </Menu>
              </li>
            )
          )}
        </ul>
      </div>

      <div className="flex items-center gap-6">
        {showRoleBtn && (
          <div>
            <button
              onClick={() => navigate(isClient ? "/coach" : "/client")}
              className="px-[1.7rem] py-[0.4rem] text-[2rem] text-white bg-[#288EBF] rounded-[1.8rem] border-[0.15rem]"
            >
              Switch to {isClient ? "Coach" : "Client"}
            </button>
          </div>
        )}
        <div
          onClick={() =>
            navigate(
              isClient ? "/client/notifications" : "/coach/notifications"
            )
          }
          className="relative w-[8rem] cursor-pointer"
        >
          <img
            src={BellIcon}
            alt="BellIcon"
            className="w-[3.5rem] h-[3.5rem]"
          />
          <div className="absolute top-[-2.1rem] left-[2rem] w-[3.1rem] h-[3.1rem] rounded-[1.2rem] border-white border-2 bg-[#299AFF] flex justify-center items-center">
            <h4 className="text-[1.4rem] text-white">
              {notiCount > 99 ? "99+" : notiCount}
            </h4>
          </div>
        </div>
        <div className="nav_avatar">
          <NavLink to={accountLink}>
            <div className="w-24 h-24 bg-primary_light rounded-full ml-auto relative">
              <img
                className="w-full h-full rounded-full object-cover"
                alt="profile_photo"
                src={userData?.data?.user?.profile_photo ? userData?.data?.user?.profile_photo : EmptyProfile}
              />
              {notification && (
                <div className="bg-[#11D000] w-[1rem] h-[1rem] rounded-full absolute top-[20%] right-[-2px]"></div>
              )}
            </div>
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default MainHeader;