import React from "react";
import YoutubePlayButton from "assets/images/YoutubePlayButton.svg";
import dayjs from "dayjs";
import PlayVideoYouTube from "./PlayVideoYouTube";

const YoutubeCard = ({ video }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { title, description, publishedAt, thumbnails } = video?.snippet;
  const videoUrl = `https://www.youtube.com/watch?v=${video?.id?.videoId}`;

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div>
        <div className=" bg-white rounded-[0.8rem] overflow-hidden">
          <div className="relative">
            <img
              className="w-full h-full"
              src={thumbnails?.medium?.url}
              alt="Youtube Thumbnail"
            />
            <img
              className="absolute  left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] cursor-pointer"
              src={YoutubePlayButton}
              alt="Play Button"
              onClick={openModal}
            />
            {/* <h6 className="absolute text-white bg-[#000] bg-opacity-[80%] leading-1 pt-[0.3rem] px-[0.7rem] pb-[0rem] font-light text-[1rem] right-5 bottom-5 rounded-[0.3rem]">
            10:16
          </h6> */}
          </div>
          <div className="px-[1.5rem] py-[1rem]">
            <div className="flex justify-between pb-[1rem]">
              <h1 className="text-primary_blue capitalize text-[1.5rem] font-bold leading-[1.8rem] whitespace-nowrap w-[100%] text-ellipsis overflow-hidden">
                {title.replace("&#39;", "'")}
              </h1>
            </div>
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-primary_blue text-[1.2rem] font-semibold leading-[1.4rem] whitespace-nowrap w-[17.5rem] text-ellipsis overflow-hidden">
                {description}
              </h1>
              <h1 className="text-[#4D4D4D] text-[1.2rem] tablet:text-[1rem] font-light leading-[1.2rem] text-right">
                {dayjs(publishedAt).format("DD, MMM YYYY")}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <PlayVideoYouTube
        title={title}
        description={description}
        videoUrl={videoUrl}
        setIsOpen={setIsOpen}
        open={isOpen}
      />
    </>
  );
};

export default YoutubeCard;