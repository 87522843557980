import { useEffect, useState } from "react";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import NotificationsCard from "components/NotificationsCard";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetNotificationsQuery,
  useMarkAllNotificationsMutation,
  useMarkNotificationsMutation,
} from "apis/general";
import { setNotiCount } from "store/slices/auth.slice";
import { socket } from "config/socket";
import { useLocation } from "react-router-dom";

const Notifications = () => {
  const [socketArray, setSocketArray] = useState<any>([]);
  const [notifications, setNotifications] = useState<any>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const userId = useSelector((state: any) => state?.auth?.user?.id);
  const { pathname } = useLocation();
  const isClient = pathname.includes("/client");
  const currentUserRole = isClient && 'client'

  const dispatch = useDispatch();
  const user_img = useSelector(
    (state: any) => state?.auth?.user?.profile_photo
  );

  //get Notifications Api
  const {
    data: getNotifications,
    isLoading,
    isFetching,
    refetch,
  } = useGetNotificationsQuery({ userId, role: currentUserRole }, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  //mark Notifications Api
  const [markNotifications, { data: markedNotifications }] =
    useMarkNotificationsMutation();
  //mark All Notifications Api
  const [markAllNotifications] = useMarkAllNotificationsMutation();

  const handleMarkAllNotification = () => {
    const payload = {
      userId: userId,
    };
    markAllNotifications(payload);
    setTrigger(true);
  };

  useEffect(() => {
    const array = getNotifications?.data?.Notifications || [];
    setNotifications(array);
  }, [markedNotifications?.data.Notifications, isLoading, getNotifications?.data?.Notifications]);

  useEffect(() => {
    const handleNotification = (response: any) => {
      dispatch(setNotiCount(response?.count));
      setSocketArray((prevSocketArray: any) => [...prevSocketArray, response]);
    };

    socket.on(`clientCreateChallengeNotification${userId}`, handleNotification);
    socket.on(`clientKickAssOpponentNotification${userId}`, handleNotification);
    socket.on(`clientAcceptChallengeNotification${userId}`, handleNotification);
    socket.on(`clientRejectChallengeNotification${userId}`, handleNotification);
    socket.on(`clientChallengeWinnerNotification${userId}`, handleNotification);
    socket.on(`clientChallengeLoserNotification${userId}`, handleNotification);
    socket.on(`clientChallengeTieNotification${userId}`, handleNotification);
    socket.on(`coachCreateNoteNotification${userId}`, handleNotification);
    socket.on(`coachCreateActionItemNotification${userId}`, handleNotification);
    socket.on(`coachingCallReminderNotification${userId}`, handleNotification);
    socket.on(
      `substituteCoachAssignedNotification${userId}`,
      handleNotification
    );
    socket.on(`eventReminderNotification${userId}`, handleNotification);
    socket.on(`clientNewStreakHitNotification${userId}`, handleNotification);
    socket.on(
      `callInOneDayScheduleCallReminderNotification${userId}`,
      handleNotification
    );
    socket.on(
      `callTodayScheduleCallReminderNotification${userId}`,
      handleNotification
    );
    socket.on(`callScheduledNotification${userId}`, handleNotification);
    socket.on(`videoUploadedNotification${userId}`, handleNotification);
    socket.on(`clientAssignedToClientNotification${userId}`, handleNotification);
    socket.on(`userCalendarDisconnectedNotification${userId}`, handleNotification);

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off(
        `clientCreateChallengeNotification${userId}`,
        handleNotification
      );
      socket.off(
        `clientKickAssOpponentNotification${userId}`,
        handleNotification
      );
      socket.off(
        `clientAcceptChallengeNotification${userId}`,
        handleNotification
      );
      socket.off(
        `clientRejectChallengeNotification${userId}`,
        handleNotification
      );
      socket.off(
        `clientChallengeWinnerNotification${userId}`,
        handleNotification
      );
      socket.off(
        `clientChallengeLoserNotification${userId}`,
        handleNotification
      );
      socket.off(`clientChallengeTieNotification${userId}`, handleNotification);
      socket.off(`coachCreateNoteNotification${userId}`, handleNotification);
      socket.off(
        `coachCreateActionItemNotification${userId}`,
        handleNotification
      );
      socket.off(
        `coachingCallReminderNotification${userId}`,
        handleNotification
      );
      socket.off(
        `substituteCoachAssignedNotification${userId}`,
        handleNotification
      );
      socket.off(`eventReminderNotification${userId}`, handleNotification);
      socket.off(`clientNewStreakHitNotification${userId}`, handleNotification);
      socket.off(
        `callInOneDayScheduleCallReminderNotification${userId}`,
        handleNotification
      );
      socket.off(
        `callTodayScheduleCallReminderNotification${userId}`,
        handleNotification
      );
      socket.off(`callScheduledNotification${userId}`, handleNotification);
      socket.off(`videoUploadedNotification${userId}`, handleNotification);
      socket.off(`clientAssignedToClientNotification${userId}`, handleNotification);
      socket.off(`userCalendarDisconnectedNotification${userId}`, handleNotification);
    };
  }, [userId]);

  return (
    <Layout>
      <Hero image={ClientHeroImage} isCentered heading="Notifications" />
      <div className="container mx-auto py-32 px-10 desktop:px-10">
        <h1 className="text-[5rem] text-white mb-[2.366rem]">Notifications</h1>
        <div className="w-full bg-white px-8 sm:pl-[3.619rem] sm:pr-[4.381rem] pt-[4.285rem] pb-[18.919rem] rounded-[2rem]">
          {notifications?.length !== 0 && <div className="flex justify-end">
            <button
              onClick={handleMarkAllNotification}
              disabled={
                notifications?.length > 0 &&
                (!notifications?.some(
                  (notification: any) => !notification?.mark_as_read
                ))
              }
              className="my-[1rem] w-max px-8 h-[4.2rem] bg-primary_blue text-white font-bold tracking-wide text-[1.6rem] rounded-[0.8rem] disabled:bg-grey_semi disabled:opacity-50 flex items-center border"
            >
              Mark All As Read
            </button>
          </div>}
          {socketArray?.length > 0 && (
            <h1 className="text-[2.4rem] text-[#000] mb-[2rem]">
              New Notifications
            </h1>
          )}
          <div className="mb-[5rem]">
            {socketArray &&
              socketArray?.map((item: any, index: any) => {
                return (
                  <div key={index} className="mb-[1.827rem]">
                    <NotificationsCard
                      item={item}
                      user_img={user_img}
                      socketArray={socketArray}
                      trigger={trigger}
                      setTrigger={setTrigger}
                    />
                  </div>
                );
              })}
          </div>
          {isFetching ||
            (isLoading && (
              <div className="bg-[#2166a251] w-full my-[3rem] text-center py-16 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">
                  Loading Notifications...
                </p>
              </div>
            ))}{" "}
          {!isLoading && notifications?.length === 0 ? (
            <div className="bg-[#2166a251] w-full my-[3rem] text-center py-16 rounded-lg">
              <p className="text-4xl sm:text-5xl text-primary_blue">
                No Notifications
              </p>
            </div>
          ) : (
            notifications?.map((item: any, index: any) => {
              return (
                <div key={index} className="mb-[1.827rem]">
                  <NotificationsCard
                    item={item}
                    user_img={user_img}
                    markNotifications={markNotifications}
                    refetch={refetch}
                    trigger={trigger}
                    setTrigger={setTrigger}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;
