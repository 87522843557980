import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { useCreateCoachNoteMutation } from "apis/client";
import { toast } from "react-toastify";

interface IFormInputs {
  date: Date;
  note: string;
}

const schema = yup.object({
  date: yup.date().required("Date is required"),
  note: yup.string().required("Enter note"),
});

const CoachNote = () => {
  const [createCoachNote, { isLoading }] = useCreateCoachNoteMutation();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());

  const {
    setError,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      date: startDate,
    },
  });

  const onSubmit = (data: IFormInputs) => {
    const payload = {
      date: moment(data.date).format("DD/MM/YYYY"),
      note: data.note,
    };
    createCoachNote(payload).unwrap().then((res) => {
      toast.success(res?.message);
      navigate("/client/notes");
    }).catch((err) => {
      toast.error(err?.data?.message)
    })
  };

  return (
    <div className="mt-[8.8rem] mb-[8.8rem]">
      <div className="max-w-[55rem] mx-auto">
        <form className="schedule_call_input" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="mb-[3rem]">
              <label>Date</label>
              <div className="relative bg-transparent">
                <DatePicker
                  required
                  className="!w-full  placeholder-[#B4B4B4]"
                  selected={startDate}
                  minDate={new Date()}
                  placeholderText={"Enter Date"}
                  onChange={(date: any) => {
                    setStartDate(date);
                    setValue("date", date);
                    setError("date", { type: "focus" }, { shouldFocus: true });
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                <input type="hidden" {...register("date")} />
              </div>
              {errors.date && <p className="error">{errors.date?.message}</p>}
            </div>
            <div className="mb-[3rem]">
              <label>Note</label>
              <textarea
                {...register("note")}
                placeholder="Enter Note..."
                className="placeholder:!text-[#605F5F]"
                rows={5}
              ></textarea>
              {errors.note && <p className="error">{errors.note?.message}</p>}
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className=" w-full bg-primary_blue rounded-[1rem] text-white text-[2.4rem] leading-none font-medium  mt-[4rem] h-24 "
              >
                {isLoading ? "Loading..." : "Send Note"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CoachNote;
