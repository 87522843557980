import Hero from "components/Hero";
import Layout from "layouts/Layout";
import ClientHeroImage from "assets/images/client-hero.png";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "components/common/PrimaryButton";
import { useSelector } from "react-redux";
import { selectUser } from "store/selectors";
import { useGetCourseByIdQuery } from "apis/general";
import ReactPlayer from "react-player";
import ParticipantCard from "../Events/components/ParticipantCard";
import { useLocation } from 'react-router-dom';

const SingleCourse = () => {
  const user = useSelector(selectUser);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const pageToNavigate = currentUrl.includes("/client/") ? "client" : "coach";

  const { data, isLoading } = useGetCourseByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const course = data?.data?.course;
  const members = course?.members;
  const isRegistered = members?.find((member: any) => member?.email === user.email);

  const viewCourseHandler = () => {
    if (course?.ghlLink) {
      window.open(course?.ghlLink, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Layout>
      <Hero image={ClientHeroImage} />
      <div className="container mx-auto py-32 pt-10 px-10 desktop:px-10">
        {!isLoading ? (
          <>
            <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-2 gap-10 gap-y-12 tablet:gap-y-5">
              <div className="tablet:pt-6">
                <p className="text-6xl capitalize font-semibold mb-[1.4rem] text-primary_light">
                  {course?.title}
                </p>
                <p className="text-white text-[2.4rem] font-normal mb-4">
                  {course?.detail}
                </p>
                {isRegistered && (
                  <div className="mt-[3rem]">
                    <PrimaryButton
                      color="primary"
                      text="View Full Course"
                      width="w-full tablet:w-[22.8rem]"
                      onClick={() => viewCourseHandler()}
                    />
                  </div>
                )}

              </div>
              <div className="col-span-1 mt-8">
                <div className="w-full">
                  <ReactPlayer
                    url={course?.file}
                    controls
                    muted
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </div>
            </div>


            <div className="bg-primary_light bg-opacity-10 w-full h-full px-[1.8rem] py-[1.8rem]  rounded-xl mt-20 overflow-hidden">
              {course?.members?.length ? (
                <>
                  <p className="text-5xl font-semibold text-white mb-8">
                    Course Members:
                  </p>
                  <div className="grid grid-cols-1 tablet:grid-cols-3 gap-8">
                    {course?.members.map((item: any, index: number) => {
                      return <ParticipantCard key={index} user={item} />;
                    })}
                  </div>
                </>
              ) : (
                <p className="text-5xl text-center text-white mb-8 mt-8">
                  0 Members Registered
                </p>
              )}
            </div>

            {!isRegistered && (
              <div className="pt-28 text-center">
                <PrimaryButton
                  color="primary"
                  text="Register"
                  width="w-full tablet:w-[22.8rem]"
                  onClick={() => navigate(`/${pageToNavigate}/register-course/${id}?email=${user.email}`)}
                />
              </div>
            )}
          </>
        ) : (
          <div className="text-center text-white font-semibold text-5xl">
            Loading Course...
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SingleCourse;
