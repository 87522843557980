const Percentage = ({ percentage, name, status }: any) => {
  return (
    <>
      <div className="mt-[4rem]">
        <div className="flex justify-between">
          <h6 className="text-white text-[2rem] truncate w-[26rem] leading-[2.3rem]">{name}</h6>
          <h6 className="text-white text-[2rem] leading-[2.3rem]">{status}</h6>
        </div>
        <div className="w-full max-w-[52.1rem] bg-[#BEBEBE] h-[1.1rem] mt-[0.4rem] rounded-[3rem]">
          <div
            className="bg-[#299AFF] h-[1.1rem] rounded-[3rem]"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Percentage;
