import moment from "moment";
import { useMarkChampCompleteMutation } from "apis/client";
import { useDeleteChampMutation } from "apis/client";
import { toast } from "react-toastify";
import DeleteIcon from "assets/icons/bin-icon.svg";

interface ChampionDayCardProps {
  id: string;
  title: string;
  time: string;
  updated: string;
  isCompleted: boolean;
  wonDayHandler: any;
}

const ChampionDayCard = ({
  id,
  title,
  time,
  isCompleted,
  wonDayHandler,
}: ChampionDayCardProps) => {
  const [markChampComplete, { isLoading }] = useMarkChampCompleteMutation();
  const [deleteChamp, { isLoading: Loading }] = useDeleteChampMutation();

  const textColor = !isCompleted ? "text-primary_blue" : "text-primary_blue";

  const handleChange = async () => {
    const payload = {
      taskId: id,
      isCompleted: !isCompleted,
    };
    await markChampComplete(payload)
      .unwrap()
      .then((res) => {
        const id = res?.data?.task?.id;
        if (res?.data?.task?.isCompleted) {
          wonDayHandler(id);
        }
      });
  };

  const deleteHandler = async () => {
    await deleteChamp(id)
      .unwrap()
      .then(() => {
        toast.success("Championship Deleted");
      })
      .catch(() => {
        toast.error("Error Deleting Championship");
      });
  };


  return (
    <div className="w-full bg-white pl-[3.3rem] mobile_l:pl-[4.3rem] pr-[1rem] py-[2.5rem] sm:py-[2rem] rounded-[0.95rem] mb-[4rem] tablet:mb-[4rem] relative">
       <div className="flex flex-row mobile_l:flex-row justify-between sm:items-end tablet:flex-row sm:gap-[2rem]">
        <div className="flex flex-col tablet:flex-row gap-[1.6rem] tablet:gap-[4rem] tablet:items-start text-left sm:text-center tablet:text-start">
          <div>
            <p
              className={`text-[3.3rem] mobile_l:text-[4.3rem] font-medium leading-[3rem] mobile_l:leading-[5rem] mb-[1rem] ${textColor}`}
            >
              {title}
            </p>
            <div className="flex flex-row gap-4">
              <p
                className={`text-[2rem] sm:text-[2rem] font-regular leading-[3rem] mobile_l:leading-[3rem] ${textColor}`}
              >
                {moment(time).format("L")}
              </p>
              <div className="border border-[#E0E0E0] my-[0.5rem]"></div>
              <button disabled={Loading} onClick={deleteHandler}>
                <img className="w-[1.8rem]" src={DeleteIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-row mobile_l:flex-col gap-4 items-center justify-center align-center self-center mx-[22px]">
          <label className="checkbox">
            <input
              type="checkbox"
              defaultChecked={isCompleted}
              onChange={handleChange}
              disabled={isLoading}
            />
            <div className="checkbox__indicator"></div>
          </label>
          
        </div>
      </div>
      
    </div>
  );
};

export default ChampionDayCard;
