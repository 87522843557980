// COACH PAGE IMPORTS
import ScheduleCall from "pages/Coach/ScheduleCall/CreateScheduleCall";
import LeaderBoard from "pages/Coach/LeaderBoard";
import ClientDetail from "pages/Coach/ClientDetail";
import Podcasts from "pages/Client/Podcasts";
import YoutubeVideos from "pages/Coach/YoutubeVideos";
import VideoCalls from "pages/Coach/VideoCalls";
import MyAccount from "pages/Coach/MyAccount";
import EditAccount from "pages/Coach/MyAccount/EditAccount";
import AccountResetPassword from "pages/Coach/MyAccount/AccountResetPassword";
import SingleCourse from "pages/Coach/Courses/SingleCourseGHL";
import Huddles from "pages/Coach/Huddles";
import Events from "pages/Coach/Events";
import SingleEvent from "pages/Coach/Events/SingleEvent";
import RegisterEvent from "pages/Coach/Events/components/GHLRegisterEvent";
import ClientProfile from "pages/Coach/ClientProfile";
import CoachProfile from "pages/Coach/CoachProfile";
import EditScheduleCall from "pages/Coach/ScheduleCall/EditScheduleCall";
import ScheduleCallCalendar from "pages/Coach/ScheduleCallCalendar";
import Courses from "pages/Client/Courses";
import Roles from "./permissions";
import Apparels from "pages/Client/Apparels";
import Notifications from "pages/Coach/Notifications";
import TermsAndConditions from "pages/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Support from "pages/Coach/Support";
import CoachScheduledCalls from "pages/Coach/ScheduledCalls/ScheduledHuddleCall";
import RegisterCourse from "pages/Coach/Courses/RegisterCourse";
import GoogleAuth from "pages/Coach/GoogleAuth";
import OutlookAuth from "pages/Coach/OutlookAuth";
import GroupCall from "pages/Coach/GroupCall";
import GroupCalls from "pages/Coach/GroupCalls";

export const CoachRoutes = [
  {
    path: "/coach/schedule-call/:id",
    page: <ScheduleCall />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/schedule-call",
    page: <ScheduleCall />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/edit-schedule-call/:id",
    page: <EditScheduleCall />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/schedule-call-calendar/:id",
    page: <ScheduleCallCalendar />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/home",
    page: <LeaderBoard />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach",
    page: <LeaderBoard />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/detail/:id",
    page: <ClientDetail />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/video-calls",
    page: <VideoCalls />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/group-calls",
    page: <GroupCalls />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/podcasts",
    page: <Podcasts />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/youtube-videos",
    page: <YoutubeVideos />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/apparels",
    page: <Apparels />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/account",
    page: <MyAccount />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/edit-account",
    page: <EditAccount />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/account-reset-password",
    page: <AccountResetPassword />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/courses",
    page: <Courses />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/course/:id",
    page: <SingleCourse />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/register-course/:id",
    page: <RegisterCourse />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/huddles",
    page: <Huddles />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/events",
    page: <Events />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/event/:id",
    page: <SingleEvent />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/register-event/:id",
    page: <RegisterEvent />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/member-profile/:id",
    page: <ClientProfile />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/profile/:id",
    page: <CoachProfile />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },

  {
    path: "/coach/notifications",
    page: <Notifications />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  //Terms & Conditions
  {
    path: "/coach/terms-condition",
    page: <TermsAndConditions />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },

  //Privacy Policy
  {
    path: "/coach/privacy-policy",
    page: <PrivacyPolicy />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  //Support
  {
    path: "/coach/support",
    page: <Support />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "/coach/scheduled-calls",
    page: <CoachScheduledCalls />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  //Google Auth Redirection
  {
    path: "coach/google/redirect",
    page: <GoogleAuth />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  //outlook Auth Redirection
  {
    path: "coach/outlook/redirect",
    page: <OutlookAuth />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
  {
    path: "coach/group-call",
    page: <GroupCall />,
    isPrivate: true,
    permissions: [Roles.COACH],
  },
];

export default CoachRoutes;
