import ActionItemsCard from "./components/ActionItemsCard";
import CreateButton from "./components/CreateBtn";
import EmptyCard from "./components/EmptyCard";
import { useGetActionItemsQuery } from "apis/client";
import ActionItemTabs from "./components/ActionItemTabs";
import { useState } from "react";

const ActionItems = () => {
  const { data, isLoading, isFetching } = useGetActionItemsQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  const [tab, setTab] = useState("client");
  const items = data?.data?.tasks;
  const filteredItems = items?.filter((item: any) => item.createdBy === tab);

  return (
    <div>    
      <div className="mt-[5.8rem] mb-[3.8rem] flex flex-col gap-12 sm:gap-0 sm:flex-row justify-between">
      <ActionItemTabs setTab={setTab} tab={tab} />
      <CreateButton link="/client/tasks" />
    </div>
     { isLoading || isFetching ? (
        <h1 className="text-white text-center py-20 text-[4rem]">Loading...</h1>
      ) : (
        <>         
          <div>
            {filteredItems?.length == 0 ? (
              <EmptyCard />
            ) : (
              filteredItems?.map((item: any) => (
                <div key={item?.id}>
                  <ActionItemsCard
                    id={item?.id}
                    title={item?.title}
                    date={item?.createdAt}
                    isCompleted={item?.isCompleted}
                    completedAt={item?.completedAt}
                    createdBy={item?.createdBy}
                    updatedAt={item?.updatedAt}
                  />
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ActionItems;
