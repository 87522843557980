import { useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Bulb from "assets/icons/winrate-buld.png";
import StreakModal from "./StreakModal";
import "./index.css";

function RadialSeparator(props: any) {
  const bgColor = props.place < props.percentage ? "#0C7BFF" : "#fff";
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div
        style={{
          ...props.style,
          ...(props.place % 11 === 0
            ? {
                height: "8%",
                marginTop: "-4.3rem",
                width: "2px",
              }
            : {
                height: "4%",
                marginTop: "-2.6rem",
              }),
          backgroundColor: bgColor,
        }}
      />
    </div>
  );
}

function RadialSeparators(props: any) {
  return props.values.map((value: any, index: number) => (
    <RadialSeparator
      turns={value}
      place={index}
      key={index}
      percentage={props.percentage}
      style={{
        ...props.style,
      }}
    />
  ));
}

const range = (start: any, end: any) =>
  Array.from(Array(Math.abs(end - start) + 1), (_, i) => start + i);

const ScoreMeter = ({ user, coachSide }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const winRating = user?.winRating;
  let currentTarget = 0;
  const rotation = 0.75;
  const circleRatio = 0.5;
  const numSeparators = 99;
  let percentage = 0;

  switch (true) {
    case winRating <= 7:
      percentage = (winRating / 7) * 100;
      currentTarget = 7;
      break;
    case winRating > 7 && winRating <= 30:
      percentage = (winRating / 30) * 100;
      currentTarget = 30;
      break;
    case winRating > 30 && winRating <= 75:
      percentage = (winRating / 75) * 100;
      currentTarget = 75;
      break;
    case winRating > 75 && winRating <= 125:
      percentage = (winRating / 125) * 100;
      currentTarget = 125;
      break;
    case winRating > 125 && winRating <= 250:
      percentage = (winRating / 250) * 100;
      currentTarget = 250;
      break;
    case winRating > 250:
      let temp;
      if (winRating > 10000) {
        const res = Math.floor(winRating / 10000);
        temp =  winRating % 10000;
        currentTarget = Math.ceil(temp / 250) * 250;
        percentage = (temp / currentTarget) * 100;
        currentTarget += res*10000;

      }else{
        currentTarget = Math.ceil(winRating / 250) * 250;
        percentage = (winRating / currentTarget) * 100;
      }
      break;
  }
  
  // Converting numbers with comma separated
  function numberWithCommas(x:any) {
    x = x?.toString();
    const pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
}


  return (
    <>
      <div
       onClick={() => setIsOpen(!coachSide)}
        className={`${coachSide ? "h-full xl:w-[550px] mt-48 mx-auto" : "cursor-pointer h-full xl:w-[550px]  mx-auto -translate-y-[30%]"}`}
      >
        <CircularProgressbarWithChildren
          className={`h-full md:!w-[500px] mx-auto ${coachSide ? "w-full" : "sm:!w-[300px]"}`}
          value={percentage}
          strokeWidth={2.5}
          circleRatio={0.51}
          styles={buildStyles({
            strokeLinecap: "round",
            rotation: 0.745,
            pathColor: "#0C7BFF",
          })}
        >
          <div className="xl:w-[230px] lg:w-[200px] md:w-[160px] sm:w-[180px] w-[180px] relative">

            <CircularProgressbarWithChildren
              className="w-full m-auto h-auto"
              value={percentage}
              strokeWidth={3.5}
              circleRatio={circleRatio}
              styles={buildStyles({
                strokeLinecap: "round",
                rotation: rotation,
                pathColor: "#0C7BFF",
              })}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <RadialSeparators
                  values={range(0, numSeparators).map(
                    (index: any) =>
                      (circleRatio / numSeparators) * index + rotation
                  )}
                  style={{
                    background: "#fff",
                    width: "1px",
                  }}
                  percentage={percentage}
                />
                <div className="w-full text-center mx-auto relative top-[-3rem] xl:top-[-3rem]">
                  <img
                    className={`w-[24px] lg:w-[36px] xl:w-[40px] object-contain mx-auto mb-1 ${!coachSide && "cursor-pointer"}`}
                    src={Bulb}
                    alt="Bulb"
                  ></img>
                  <div className="text-center">
                    <p className="text-[4rem] mobile_l:text-[4rem] text-[#fff] font-bold leading-[5rem] mobile_l:leading-[5rem]">
                      {numberWithCommas(user?.winRating >= 0 ? user?.winRating : 0 || 0)}
                    </p>
                    <p className="text-[2rem] mobile_l:text-[2rem] text-[#fff] font-regular leading-[2.0rem] mobile_l:leading-1">
                      Your Win Rating
                    </p>
                  </div>
                </div>
              </div>
              {/* sm:right-[-6.8rem] sm:bottom-[8.5rem] md:bottom-[7.5rem] lg:bottom-[7rem] xl:bottom-[8rem] xl:right-[-7rem] laptop_lg:bottom-[7.5rem] */}
              <div className={` text-[#fff] leading-[1.4rem] ${currentTarget >= 100000 ? "mobile_l:text-[1.4rem]" : "mobile_l:text-[2rem]"} mobile_l:leading-[2.5rem] absolute bottom-[9rem] right-[-6.4rem] sm:bottom-[32%] ${coachSide ? "text-[1.6rem] sm:right-[-11.4rem]" : `text-[1.6rem] sm:right-[-7.4rem]`}`}>{`Goal: ${numberWithCommas(currentTarget)}`}</div>

            </CircularProgressbarWithChildren>
          </div>
        </CircularProgressbarWithChildren>
      </div>
      {!coachSide && <StreakModal open={isOpen} setIsOpen={setIsOpen} user={user} />}
    </>
  );
};

export default ScoreMeter;