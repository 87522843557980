import { Dialog } from "@headlessui/react";
import CloseIcon from "../../../../assets/images/CloseIcon.svg";
import VideoIcon from "../../../../assets/icons/VideoIcon.svg";
import Dropdown from "./DropDown";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectUser } from "store/selectors";
import { useLazyGetMeetingDetailsQuery } from "apis/general";
import { toast } from "react-toastify";

function Modal({ isOpen, setIsOpen, selectedEvent, deleteScheduleCall, inProgressEvent }: any) {
  const [getMeetingDetails, results] = useLazyGetMeetingDetailsQuery();
  const user = useSelector(selectUser);

  const handleStartCall = async () => {
    await getMeetingDetails(selectedEvent?.meeting_id).unwrap().then((res) => {
      const meeting = res.data.meeting;

      if (meeting) {
        if (selectedEvent?.start_url) {
          window.open(selectedEvent?.start_url, "_blank", "noopener,noreferrer");
          setIsOpen(false);
        }
      }
    }).catch((err) => {
      toast.error(err?.data?.message);
    });
  }

  const date = selectedEvent?.occurrance_start ? selectedEvent?.occurrance_start : selectedEvent?.startTime;
  const isSame = dayjs(date).isSame(dayjs(), "day");
  
  return (
    <div className="">
      <Dialog
        as="div"
        className="relative z-[99999]"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div
          className="fixed inset-0 bg-[#000] bg-opacity-60"
          aria-hidden="true"
        />
        <Dialog.Panel as="div">
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white w-[84.2rem] hide-scrollbar max-h-full flex-wrap overflow-y-auto rounded-[1.6rem] pt-[2.4rem] pr-[3.3rem] pb-[3.2rem] pl-[5.1rem] flex gap-32">
              <div className="flex-1">
                <div className="flex justify-between">
                  <div className="w-full flex justify-between items-baseline mt-[3rem]">
                    <Dialog.Title
                      as="h3"
                      className="text-primary_blue text-[4rem] font-medium"
                    >
                      Call Details
                    </Dialog.Title>

                    <Dropdown
                      selectedEvent={selectedEvent}
                      setIsOpen={setIsOpen}
                      deleteScheduleCall={deleteScheduleCall}
                    />
                  </div>
                  <div className="w-[2.4rem] h-[2.4rem] cursor-pointer">
                    <img
                      onClick={() => setIsOpen(false)}
                      className="w-full h-full"
                      src={CloseIcon}
                      alt="Close Icon"
                    />
                  </div>
                </div>
                <Dialog.Description as="div" className="mt-[2.6rem]">
                  <div className="flex justify-between mt-8">
                    <div >
                      <h3 className="text-primary_blue text-[3rem] font-medium md:min-w-[220px] md:pr-6">
                        Title
                      </h3>
                      <h6 className="text-[#333333] text-[3rem] md:min-w-[220px] md:pr-6">
                        {selectedEvent?.title}
                      </h6>
                    </div>
                  </div>

                  <div className="flex justify-between mt-8">
                    <div>
                      <h3 className="text-primary_blue text-[3rem] font-medium md:pr-6">
                        Date
                      </h3>
                      <h6 className="text-[#333333] text-[3rem] md:pr-6">
                        {dayjs(selectedEvent?.date).format("MM-DD-YYYY")}
                      </h6>
                    </div>
                    <div>
                      <h3 className="text-primary_blue text-[3rem] font-medium md:pr-6">
                        Time
                      </h3>
                      <h6 className="text-[#333333] text-[3rem] md:pr-6">
                        {dayjs(selectedEvent?.date).format("hh:mm A")}
                      </h6>
                    </div>
                    <div>
                      <h3 className="text-primary_blue text-[3rem] font-medium md:pr-6">
                        Duration:
                      </h3>
                      <h6 className="text-[#333333] text-[3rem] md:pr-6">
                        {selectedEvent?.duration} min
                      </h6>
                    </div>
                    <div>
                      <h3 className="text-primary_blue text-[3rem] font-medium md:pr-6">
                        Recurring:
                      </h3>
                      <h6 className="text-[#333333] text-[3rem] md:pr-6">
                        {selectedEvent?.isRecurring ? "Yes" : "No"}
                      </h6>
                    </div>
                  </div>

                  <div className="flex gap-10 mt-8">
                    {selectedEvent?.callType === "call" && (
                      <div >
                        <h3 className="text-primary_blue text-[3rem] font-medium  md:pr-6">
                          Coach:
                        </h3>
                        <h6 className="text-[#333333] text-[3rem] font-medium md:pr-6">
                          {selectedEvent?.user?.coach?.full_name || ""}
                        </h6>
                      </div>
                    )}
                    <div >
                      <h3 className="text-primary_blue text-[3rem] font-medium  md:pr-6">
                        Subbed Coach:
                      </h3>
                      <h6 className="text-[#333333] text-[3rem]  md:pr-6 font-medium">
                        {selectedEvent?.SubstituteCoaches?.length ? (
                          selectedEvent?.SubstituteCoaches?.map((item: any, idx:number) => {
                            return (
                              <h3 key={idx} className="text-[#333333] ">
                                {item?.user?.full_name}
                              </h3>
                            );
                          })
                        ) : "N/A"}
                      </h6>
                    </div>
                  </div>

                  {selectedEvent?.callType === "group" && (
                    <div>
                      <h3 className="text-primary_blue text-[3rem] font-medium  md:pr-6 mb-5">
                        Group Call Members:
                      </h3>
                      <div className="flex gap-[10px] flex-wrap">
                        {selectedEvent?.groupCallMembers?.map((member: any, idx:number) => (
                          <div key={idx} className="rounded-xl border border-grey_semi px-[10px] pt-[3px] pb-[3px]">
                            <h6 className="text-[18px]">{member?.user?.full_name}</h6>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {selectedEvent?.callType === "call" && (
                    <div >
                      <h3 className="text-primary_blue text-[3rem] font-medium  md:pr-6">
                        Client:
                      </h3>
                      <h6 className="text-[#333333] text-[3rem] font-medium md:pr-6">
                        {selectedEvent?.user?.full_name || ""}
                      </h6>
                    </div>
                  )}

                  {(selectedEvent?.SubstituteCoaches?.length === 0 || selectedEvent?.SubstituteCoaches[0]?.user.id == user.id) && (
                    <>
                      {!inProgressEvent && (
                        <div className="flex justify-end mt-10">
                          {(selectedEvent?.meeting_type === 2) ? (
                            <button
                              disabled={!isSame || results?.isFetching}
                              onClick={handleStartCall}
                              className={`${!isSame ? "bg-light_gray" : "bg-primary_blue"} px-10 h-[4.5rem] text-[1.4rem] text-white font-medium flex justify-center items-center rounded-[1.12978rem] gap-3`}
                            >
                              <img src={VideoIcon} alt="Video Icon" />
                              Start Call
                            </button>
                          ) : (
                            <button
                              disabled={!isSame || results?.isFetching}
                              onClick={handleStartCall}
                              className={`${!isSame ? "bg-light_gray" : "bg-primary_blue"}  px-10 h-[4.5rem] text-[1.4rem] text-white font-medium flex justify-center items-center rounded-[1.12978rem] gap-3`}
                            >
                              <img src={VideoIcon} alt="Video Icon" />
                              {isSame ? "Start Call" : "Not Available"}
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  )}

                </Dialog.Description>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
}
export default Modal;
