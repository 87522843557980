const EmptyCard = ({ title }: any) => {

    return (
        <div className="w-full bg-white pl-[4.3rem] pr-[1rem] py-[2.5rem] sm:py-[2rem] rounded-[0.95rem] mb-[4rem] tablet:mb-[4rem]">
            <div className="flex flex-col tablet:flex-row gap-[2rem] justify-center">
                <div className="flex flex-col tablet:flex-row gap-[1.6rem] tablet:gap-[4rem] justify-center sm:text-[3rem] text-[2rem] text-primary_blue font-semibold sm:py-[3rem] py-[1rem]">
                    <h1 className="text-center">
                        {title || "No Task Available"}
                    </h1>
                </div>

            </div>
        </div>
    );
};

export default EmptyCard;
