import LegacyTabs from "./LegacyTabs";
import { useParams } from "react-router-dom";
import { useGetChampionshipLegacyStatementQuery } from "apis/coach";

const LegacyStatement = () => {
  const { id } = useParams();
  const {
    data = [],
    isLoading,
  } = useGetChampionshipLegacyStatementQuery(id, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const sortOrder = [
    'my_purpose',
    'i_am',
    'i_will',
    'never_again',
    'core_values',
    'reminders',
    'quote',
  ];

  const destructedLegacyData =
    data &&
    data.data?.legacyStatement?.map(({ heading, description, id, category }: any) => ({
      category,
      heading,
      description,
      id,
    }));

  function customCompare(a: any, b: any) {
    const indexA = sortOrder?.indexOf(a?.category);
    const indexB = sortOrder?.indexOf(b?.category);
    return indexA - indexB;
  }

  const sortedArray = destructedLegacyData?.sort(customCompare);

  return (
    <>
      <div className="mt-[5rem]">
        {isLoading && (
          <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
            <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
          </div>
        )}
        {!isLoading && sortedArray?.length === 0 ? (
          <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
            <p className="text-4xl sm:text-5xl text-primary_blue">
              No Champions Legacy Found{" "}
            </p>
          </div>
        ) : (
          <LegacyTabs data={sortedArray && sortedArray} />
        )}
      </div>
    </>
  );
};

export default LegacyStatement;
