import { OutlookToken } from "apis/auth-apis";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessPage from "components/SuccessPage";
import ErrorPage from "components/ErrorPage";

export default function OutlookAuth() {
  const navigate = useNavigate();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const code = query.get("code");
  useEffect(() => {
    if (code) {
      OutlookToken(code).then(() => {
        setTimeout(() => {
          navigate("/coach");
        }, 3000);
      });
    }
  }, [code]);
  const dashboardClickHandler = () => {
    navigate("/coach");
  };
  return (
    <>
      <div>
        {code ? (
          <SuccessPage onClick={dashboardClickHandler} />
        ) : (
          <ErrorPage onClick={dashboardClickHandler} />
        )}
      </div>
    </>
  );
}
