import moment from "moment";
import { useMarkActionItemCompleteMutation } from "apis/client";
import  { toast } from "react-toastify";
import DeleteIcon from "assets/icons/bin-icon.svg";
import { useDeleteActionItemMutation } from "apis/client";

interface ActionItemsCardProps {
  id: string;
  title: string;
  date: string;
  isCompleted: boolean;
  completedAt: string;
  createdBy: string;
  updatedAt: string;
  coachSide?: boolean;
}

const ActionItemsCard = ({
  id,
  title,
  date,
  completedAt,
  isCompleted,
  createdBy,
  coachSide
}: ActionItemsCardProps) => {
  const [markActionItemComplete, { isLoading }] =
    useMarkActionItemCompleteMutation();
  const [deleteActionItem] = useDeleteActionItemMutation();

  const handleChange = () => {
    const payload = {
      taskId: id,
      isCompleted: !isCompleted,
    };
    if(!coachSide) {
      markActionItemComplete(payload);
    }
  };

  const deleteHandler = async () => {
    if(!coachSide) {
      await deleteActionItem(id)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((error) => {
        toast.error(error?.data?.message);
      });
    }
  };

  return (
    <div className="w-full bg-white pl-[3.3rem] mobile_l:pl-[4.3rem] pr-[1rem] py-[2.5rem] sm:py-[2rem] rounded-[0.95rem] mb-[4rem] tablet:mb-[4rem] cursor-pointer relative">
      <div className="flex flex-row mobile_l:flex-row justify-between sm:items-end tablet:flex-row sm:gap-[2rem]">
        <div className="flex flex-col tablet:flex-row gap-[1.6rem] tablet:gap-[4rem] tablet:items-start text-left sm:text-center tablet:text-start">
          <div>
            <p
              className={`text-[3.3rem]  text-[#2165A2] mobile_l:text-[4.3rem] font-medium leading-[3rem] mobile_l:leading-[5rem] mb-[1rem] `}
            >
              {title}
            </p>
            <div className="flex flex-row gap-4">
              <p
                className={`text-[2rem] text-[#2165A2] sm:text-[2rem] font-regular leading-[3rem] mobile_l:leading-[3rem] `}
              >
                {moment(date).format("L")}
              </p>
              {createdBy === "client" && (
                <>
                  <div className="border border-[#E0E0E0] my-[0.5rem]"></div>
                  <button onClick={deleteHandler}>
                    <img className="w-[1.8rem]" src={DeleteIcon} />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col mobile_l:flex-col items-center justify-center gap-5 align-center self-center mx-[22px]">
          <label className="checkbox">
            <input
              type="checkbox"
              disabled={isLoading}
              checked={isCompleted}
              onChange={handleChange}
            />
            <div className="checkbox__indicator"></div>
          </label>
          <p className={`text-[2rem] sm:text-[2rem] font-regular leading-[3rem] mobile_l:leading-[3rem] ${(coachSide && isCompleted) ? "text-[black]" : "text-white"}`}>{moment(completedAt).format("L")}</p>
        </div>
      </div>
    </div>
  );
};

export default ActionItemsCard;
