import Hero from "components/Hero";
import Layout from "layouts/Layout";
import ApparelShoppingCard from "./components/ApparelShoppingCard";
import ClientHeroImage from "assets/images/client-hero.png";
import { useGetAllApparelsQuery } from "apis/general";

const Apparels = () => {
  const { data, isLoading, isFetching } = useGetAllApparelsQuery(1, {
    refetchOnMountOrArgChange: true,
  });

 
  return (
    <Layout>
      <Hero image={ClientHeroImage} heading="WinRate Apparel" />
      <div className="container mx-auto pt-28 pb-[5rem] px-10 desktop:px-10">
        <h1 className=" text-white text-[3rem] mb-5 font-bold leading-[3.5rem] text-center lg:text-left">
          WinRate Apparel
        </h1>
        <div className="h-[100rem] overflow-y-auto pr-5" id="scroll">
          {data?.data?.result?.length === 0 && (
            <div className="bg-[#fff] rounded-xl mt-[4rem] py-14 px-4">
              <h1 className="text-center text-primary_blue text-[3rem] font-medium">
                No Apparel Found
              </h1>
            </div>
          )}
          {isFetching || isLoading ? (
            <div className="bg-white w-full mt-[3rem] text-center py-16 rounded-lg">
              <p className="text-4xl sm:text-5xl text-primary_blue">
                Loading Apparel...
              </p>
            </div>
          ) : (
            <>
              <div className="mt-[4rem] grid grid-cols-1 md:grid-cols-2 gap-10 lg:grid-cols-3">
                {data?.data?.result?.map((item: any) => {
                  return (
                    <div key={item?.id} className="cursor-pointer">
                      <ApparelShoppingCard item={item} />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Apparels;
