import CallsCard from "components/CallsCard";
import DefaultImage from "assets/images/thumnailimage.png";
import RenderCall from "./_renderCall";
const FilterRecordedCall = ({ filterCalls }: any) => {
  const filterHuddleCalls = filterCalls?.map((call: any) => {
    const temp = new Date(call.createdAt).getTime() / 1000;
    return {
      ...call,
      filterDate: temp
    }
  })?.sort((a: any, b: any) => b?.filterDate - a?.filterDate);
  return (
    <div className="mt-[4rem] grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
      {filterHuddleCalls?.map((item: any) => (
        <div
          className="bg-white w-full max-h-[28.7rem] rounded-lg overflow-hidden cursor-pointer"
          key={item?.id}
        >
          {item?.url ? (
            <RenderCall item={item} />
          ) : (
            <CallsCard
              imgUrl={item?.thumbnail ? item?.thumbnail : DefaultImage}
              title={item?.title ? item?.title : null}
              desc={item?.detail ? item?.detail : null}
              watchTime={item?.watchTime ? item?.watchTime : null}
              postedTime={item?.roomId ? item?.date : item?.createdAt }
              url={item?.file ? item?.file : item?.url}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default FilterRecordedCall;
