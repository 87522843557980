import React, { useState, useEffect } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import HomeBanner from "assets/images/HomeBanner.png";
import PersonGrayIcon from "assets/images/PersonGrayIcon.svg";
import EditIcon from "assets/images/EditIcon.svg";
import UploadImgIcon from "assets/images/UploadImgIcon.svg";
import EmailIcon from "assets/images/EmailIcon.svg";
import PhoneIcon from "assets/images/PhoneIcon.svg";
import TwitterIcon from "assets/images/TwitterIcon.svg";
import FbIcon from "assets/images/FbIcon.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/selectors";
import { postRequest } from "config/axiosConfig";
import { toast } from "react-toastify";
import EmptyProfile from "assets/images/profile.png";
import { setUser } from "store/slices/auth.slice";
import { useUpdateProfileMutation, } from "apis/client";
import insta from "assets/images/Instagram.svg";
import { useGetProfileQuery } from "apis/client";
import { useGetProfileByIdQuery } from "apis/general";
import Select from 'react-select';

interface IFormInputs {
  fullName: string;
  phone: string;
  facebook: string;
  instagram: string;
  twitter: string;
}

const schema = yup.object({
  fullName: yup.string().required("Name required").min(4).max(20),
  phone: yup.string().required("Phone required").min(10).max(15),
  facebook: yup.string(),
  instagram: yup.string(),
  twitter: yup.string(),
});

const TIMEZONES = [
  { label: "EST (UTC -04:00)", value: "EST5EDT" },
  { label: "CST (UTC -06:00)", value: "CST" },
  { label: "PT (UTC -08:00)", value: "PST" },
  { label: "MST (UTC -07:00)", value: "MST7MDT" },
];

const EditAccount = () => {
  const user: any = useSelector(selectUser);
  const { data: userData, isLoading, isFetching } = useGetProfileQuery(user?.id, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const { refetch } = useGetProfileByIdQuery(user?.id);

  const [updateProfile] = useUpdateProfileMutation();

  const [phone, setPhone] = useState(userData?.data?.user?.phoneNo);
  const [imgUrl, setImgUrl] = useState(userData?.data?.user?.profile_photo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State variables to store form field values
  const [fullName, setFullName] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [timezone, setTimeZone] = useState<any>({});

  // Fetch data from the API and update form fields when component mounts
  useEffect(() => {
    if (userData?.data?.user) {
      const { full_name, phoneNo, facebook, instagram, twitter, profile_image, timezone } = userData.data.user;
      setFullName(full_name);
      setValue("fullName", full_name)
      setPhone(phoneNo);
      setValue("phone", phoneNo)
      setFacebookUrl(facebook);
      setValue("facebook", facebook)
      setInstagramUrl(instagram);
      setValue("instagram", instagram)
      setTwitterUrl(twitter);
      setValue("twitter", twitter)
      setImgUrl(profile_image)

      const tempTimeZone = TIMEZONES?.find((tz) => tz?.value === timezone);
      setTimeZone(tempTimeZone);
    }
  }, [isFetching]);

  // const userProfile = userData?.data?.user;

  function isValidHttpUrl(str: any): any {
    const pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/)
    return pattern?.test(str);
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: userData?.data?.user?.full_name,
      facebook: userData?.data?.user?.facebook,
      instagram: userData?.data?.user?.instagram,
      twitter: userData?.data?.user?.twitter,
      phone: userData?.data?.user?.phoneNo
    },
  });

  function handleNumber(e: any) {
    const enteredValue = e.target.value.replace(/\D/g, "");
    e.preventDefault();
    setPhone(enteredValue);
    setValue("phone", enteredValue, { shouldValidate: true });
  }

  const onSubmit = async (data: IFormInputs) => {
    if (facebookUrl !== "") {
      if (!isValidHttpUrl(facebookUrl)) {
        toast.error('Please enter a Facebook valid URL.');
        return;
      }
    }
    if (twitterUrl !== "") {
      if (!isValidHttpUrl(twitterUrl)) {
        toast.error('Please enter a Twitter valid URL.');
        return;
      }
    }

    const userData = {
      fullName: data?.fullName,
      phone: data?.phone,
      address: "",
      profilePhoto: imgUrl,
      facebook: data?.facebook,
      twitter: data?.twitter,
      instagram: data?.instagram,
      timezone: timezone?.value
    };
    await updateProfile(userData)
      .unwrap()
      .then((res: any) => {
        toast.success("Profile Updated");
        const user = res?.data?.user;
        dispatch(setUser(user));
        navigate("/client/account");
      }).catch(() => {
        toast.error("Something went wrong!")
      });
  };

  const imageHandler = async (e: any) => {
    const img = e.target.files[0];
    const formData = new FormData();
    formData.append("image", img);

    const promise = postRequest("upload-profile", formData)
      .then((result) => {
        if (result) {
          updateProfile({
            profilePhoto: result,
          }).unwrap()
            .then((res) => {
              dispatch(setUser(res?.data?.user));
              setImgUrl(result);
              refetch();
            });
        }
      })
      .catch(() => {
      });

    await toast.promise(promise, {
      pending: "Updating Profile Image",
      success: "Profile Image Updated",
      error: "Error Uploading Image",
    });
  };

  return (
    <Layout>
      <Hero image={HomeBanner} />
      {isLoading && <h1>Loading....</h1>}
      <div className="container mx-auto py-32 px-10 desktop:px-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="md:flex md:px-20 lg:px-0 gap-40 md:flex-col lg:flex-row lg:items-start md:items-center flex-col items-start px-0">
            <div className="flex flex-col items-center">
              <div className="w-[21rem] relative">
                <div className="overflow-hidden rounded-[0.63rem]">
                  <img
                    className="w-[23.5rem] h-[19.5rem] object-cover"
                    src={
                      imgUrl
                        ? imgUrl
                        : userData?.data?.user?.profile_photo
                          ? userData?.data?.user?.profile_photo
                          : EmptyProfile
                    }
                    alt="Account Img"
                  />
                </div>
                <div>
                  <div className="absolute w-[5rem] h-[5rem] -right-10 top-[5.2rem] ">
                    <label htmlFor="upload-image">
                      <img
                        className="cursor-pointer "
                        src={UploadImgIcon}
                        alt="UploadImg Icon"
                      />
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="upload-image"
                      onChange={imageHandler}
                      className="hidden opacity-0 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <h1 className="text-center text-white truncate w-[18rem] text-[3rem] font-medium leading-[3.6rem] mt-[1.6rem] capitalize">
                {userData?.data?.user?.full_name}
              </h1>
              <h1 className=" text-[#969696] text-[3.3rem] font-light leading-[3.9rem] mt-[0.4rem] capitalize">
                {user?.role?.join(" | ")}
              </h1>
            </div>
            <div className="mx-auto laptop:mx-0 max-w-[69.7rem] ">
              <div>
                <div className="flex gap-6 items-baseline">
                  <img
                    className="w-[3rem]"
                    src={PersonGrayIcon}
                    alt="Person Icon"
                  />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light">
                    User Name
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    {...register("fullName")}
                    type="text"
                    value={fullName}
                    {...register("fullName", {
                      onChange: (e: any) => setFullName(e.target.value)
                    })}
                    className="mt-2 px-[4.2rem] w-full text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:capitalize placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder="Enter Name"
                  />
                  <div className="absolute right-0 bottom-20">
                    <div>
                      <img
                        className="w-[2.2rem]"
                        src={EditIcon}
                        alt="Edit Icon"
                      />
                    </div>
                  </div>
                </div>
                {errors.fullName && (
                  <p className="error">{errors.fullName?.message}</p>
                )}
              </div>
              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-6 items-baseline">
                  <img className="w-[3rem]" src={EmailIcon} alt="Email Icon" />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light mt-[1.8rem]">
                    E-mail
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    type="email"
                    className="mt-2 w-full pl-[4.2rem] text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder={user?.email}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-6 items-baseline">
                  <img className="w-[3rem]" src={PhoneIcon} alt="Phone Icon" />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light mt-[1.8rem]">
                    Phone Number
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    type="text"
                    value={phone}
                    {...register("phone")}
                    className="mt-2 w-full pl-[4.2rem] text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder="Enter Phone Number"
                    onChange={handleNumber}
                  />

                  <div className="absolute right-0 bottom-20">
                    <img
                      className="w-[2.2rem]"
                      src={EditIcon}
                      alt="Edit Icon"
                    />
                  </div>
                </div>
                {errors.phone && (
                  <p className="error">{errors.phone?.message}</p>
                )}
              </div>

              {/* ====================== */}
              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-6 items-baseline mb-4">
                  <img className="w-[3rem]" src={PersonGrayIcon} alt="Phone Icon" />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light mt-[1.8rem]">
                    User Timezone
                  </h1>
                </div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={timezone}
                  onChange={(val) => setTimeZone(val)}
                  isClearable={false}
                  isSearchable={false}
                  name="timezone"
                  options={TIMEZONES}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: 'transparent',
                      borderColor: '#969696',
                      height: "45px",
                      boxShadow: "none",
                      fontSize: "22px",
                      color: '#fff',
                      paddingLeft: '10px'
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: '#fff'
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: '#fff'
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isSelected ? "#1e75c21f" : "#fff",
                      color: state.isSelected ? "#2165a2" : "#333333",
                      fontSize: "18px"
                    })
                  }}
                />
              </div>

              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-6 items-baseline">
                  <img
                    className="h-[3rem] bg-[#333333]"
                    src={FbIcon}
                    alt="Email Icon"
                  />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light mt-[1.8rem]">
                    Facebook
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    value={facebookUrl}
                    autoComplete="off"
                    {...register("facebook", {
                      onChange: (e: any) => setFacebookUrl(e.target.value)
                    })}
                    type="text"
                    className="mt-2 w-full pl-[4.2rem] text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder={"e.g www.facebook.com/mike"}
                  />
                </div>
              </div>

              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-6 items-baseline">
                  <img
                    className="w-[2.6rem]"
                    src={TwitterIcon}
                    alt="Email Icon"
                  />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light mt-[1.8rem]">
                    Twitter
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    autoComplete="off"
                    {...register("twitter", {
                      onChange: (e: any) => setTwitterUrl(e.target.value)
                    })}
                    type="text"
                    className="mt-2 w-full pl-[4.2rem] text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder={twitterUrl || "e.g www.twitter.com/mike"}
                  />
                </div>
              </div>

              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-6 items-baseline">
                  <img className="h-[3rem]" src={insta} alt="Email Icon" />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light mt-[1.8rem]">
                    Instagram
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    autoComplete="off"
                    {...register("instagram", {
                      onChange: (e: any) => setInstagramUrl(e.target.value)
                    })}
                    type="text"
                    className="mt-2 w-full pl-[4.2rem] text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder={instagramUrl || "e.g www.instagram.com/mike"}
                  />
                </div>
              </div>

              <div className="flex flex-col justify-center text-center">
                <button
                  type="submit"
                  disabled={isLoading}
                  style={{ background: isLoading ? "grey" : "#2165A2" }}
                  className="w-full tablet:w-[55.2rem] mx-auto py-[1.23rem] rounded-[0.94rem] bg-[#2165A2] text-white text-[2.8rem] leading-[3.9rem] mt-[8rem]"
                >
                  {isLoading ? "Loading..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditAccount;
