import { toast } from "react-toastify";
import {
  getRequest,
  patchRequest,
  postRequest,
  // deleteRequest,
} from "config/axiosConfig";
import { setUser } from "store/slices/auth.slice";

// Update Coach
export async function updateCoach(
  userData: any,
  dispatch: any,
  navigate: any,
  setIsloading: any
) {
  let result = null;
  try {
    setIsloading(true);
    result = await patchRequest("coach/update", {
      ...userData,
    });
    if (result?.status == 200) {
      dispatch(setUser(result?.data?.user));
      toast.success(result?.message);
      navigate("/coach/account");
      setIsloading(false);
    } else {
      toast.error("Something Wrong");
      setIsloading(false);
    }
  } catch (error: any) {
    setIsloading(false);
  }
  return result;
}

// Create Note
export async function CreateNote(payload: any) {
  const result = await postRequest("coach/notes/create", {
    ...payload,
  });
  return result;
}

export async function GetCoachNotes() {
  const result = await getRequest("coach/notes");
  return result;
}
// export async function DeleteCoachNote(id: any) {
//   const result = await deleteRequest(`coach/notes/${id}`);
//   return result;
// }
//create action item
export async function CreateActionItem(payload: any) {
  const result = await postRequest("coach/action-item/create", {
    ...payload,
  });
  return result;
}
//start call
export async function CoachStartCall(payload: any) {
  const result = await postRequest("coach/call/start", {
    ...payload,
  });
  return result;
}
//exit call
export async function CoachExitCall(payload: any) {
  const result = await postRequest("coach/call/exit-call", {
    ...payload,
  });
  return result;
}

//  coach join admin huddle call
export async function joinAdminHuddleCall(payload: any) {
  const result = await postRequest("coach/call/huddle/join-call", {
    ...payload,
  });
  return result;
}
