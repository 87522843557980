import { useState } from "react";
import { Menu } from "@headlessui/react";
import MenuIcon from "../../../../assets/icons/MenuIcon.svg";
import DeleteIcon from "../../../../assets/icons/DeleteIcon.svg";

import ConfirmModal from "./ConfirmModal";

const Dropdown = ({ selectedEvent, setIsOpen }: any) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  
  const handleDelete = () => {
    setIsConfirmOpen(true);
  };

  return (
    <>
      <Menu as="div" className="relative text-left">
        <Menu.Button className="w-[2rem] h-[3rem] mr-[-2rem]">
          <img className="w-full h-full" src={MenuIcon} alt="Menu Icon" />
        </Menu.Button>
        <Menu.Items className="py-5 px-5 w-[23rem] h-[6rem] absolute -right-8 mt-2 divide-y-2 divide-[#E9E6E6] origin-top-right divide-gray-100 rounded-md bg-white shadow-[0rem_0rem_0.29rem_rgba(0,0,0,0.3)] focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={handleDelete}
                className={`${active &&
                  "bg-[#2165A2] bg-opacity-30 font-semibold !text-[#2165A2]"
                  } text-2xl text-left py-3 text-[#333] w-full border-0 flex justify-between`}
              >
                Delete
                <img src={DeleteIcon} alt="delete icon" />
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>

      <ConfirmModal
        selectedEvent={selectedEvent}
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default Dropdown;
