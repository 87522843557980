import { Dialog } from '@headlessui/react';
import RecorderIcon from "assets/icons/recorder.svg";
import CalendarIcon from "assets/icons/multi-calendar.svg";
import { useNavigate } from 'react-router-dom';

export default function ConfirmModal({ isConfirmOpen, setIsConfirmOpen, onDone }: any) {
  const navigate = useNavigate();
  function closeModal() {
    onDone();
    setIsConfirmOpen(false);
  }

  const btnStyle = "bg-[#2165a2] disabled:bg-opacity-50 disabled:text-opacity-50 text-white px-8 py-4 text-4xl rounded-xl border border-[#cbd5e1] flex gap-2 items-center";

  return (
    <>
      <Dialog
        as="div"
        className="relative z-[99999]"
        open={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
      >
        <div
          className="fixed inset-0 bg-[#000] bg-opacity-60"
          aria-hidden="true"
        />
        <Dialog.Panel as="div">
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white w-[66rem] rounded-[1.6rem] px-12 py-20 pb-28 flex gap-32">
              <div className="flex-1">
                <div className="flex justify-between">
                  <div className="w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-primary_blue text-5xl font-medium text-center mb-16"
                    >
                      Call Scheduled Successfully
                    </Dialog.Title>

                    <Dialog.Panel as='div' className={"flex justify-center gap-6 flex-col items-center"}>
                      <button
                        onClick={closeModal}
                        className={btnStyle}
                      >
                        <img src={RecorderIcon} className='h-[1.9rem]'></img>
                        Schedule Another Call
                      </button>
                      <button
                        onClick={() => navigate("/coach/video-calls")}
                        className={btnStyle}
                      >
                        <img src={CalendarIcon} className='h-[2.2rem]'></img>
                        Go to Schedule Calendar
                      </button>
                    </Dialog.Panel>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  )
}
