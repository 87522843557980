import Hero from "components/Hero";
import Layout from "layouts/Layout";
import EventImg from "assets/dummy/event-img.png";
import EventCard from "components/EventCard";
import ClientHeroImage from "assets/images/client-hero.png";
import { useGetAllEventsQuery } from "apis/general";

const Events = () => {
  const { data, isLoading, isFetching } = useGetAllEventsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const upcomingEvents = data?.data?.events?.filter(
    (item: any) => item.status === "active"
  );

  return (
    <Layout>
      <Hero image={ClientHeroImage} heading="WinRate Events" />
      <div className="container mx-auto pt-24 pb-1 px-10 desktop:px-10">
        <div>
          <h6 className="text-white font-medium text-[3rem] pb-12">
            Upcoming Events
          </h6>
        </div>
        {isFetching || isLoading ? (
          <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
            <p className="text-4xl sm:text-5xl text-primary_blue">
              Loading Events...
            </p>
          </div>
        ) : upcomingEvents?.length === 0 ? (
          <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
            <p className="text-4xl sm:text-5xl text-primary_blue">
              No Upcoming Events Found
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 mobile_l:grid-cols-2 tablet:grid-cols-3 gap-10 pb-[10rem]">
            {upcomingEvents &&
              upcomingEvents?.map((item: any, index: number) => (
                <EventCard
                  key={index}
                  // heading={item.heading}
                  id={item?.id}
                  title={item?.title}
                  desc={item?.description}
                  location={item?.location}
                  startTime={item?.time}
                  //endTime={item.endTime}
                  // isLiked={item.isLiked}
                  date={item?.date}
                  thumbnail={
                    item?.eventPhotos[0]?.url
                      ? item?.eventPhotos[0]?.url
                      : EventImg
                  }
                  toPage={`/client/event/${item?.id}`}
                  members={item?.members}
                />
              ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Events;
