import React, { useState, useEffect } from "react";
import BoardingPage from "../components/BoardingPage";
import { useGetOnboardingGHLAnswersQuery } from "apis/coach";
import { useParams } from "react-router-dom";
import { getClient } from "apis/client-apis";

const OnboardingTab = () => {
  const { id } = useParams()
  const [isOnBoarding, setIsOnBoarding] = useState(false);
  const { data } = useGetOnboardingGHLAnswersQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    getClient(id).then((res) => {
      setIsOnBoarding(res?.data?.user?.isOnboarded);
    });
  }, [id]);

  const answers = data?.data?.data;

  const regExp = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
  // Regular expression to check if string is a valid UUID
  function onlyLettersAndNumbers(str: string) {
    return regExp.test(str);
  }

  // rendering the onboarding and checking the user is already onboard or not 
  function _renderAccordian() {
    const result = Object.keys(answers).filter((dt) => onlyLettersAndNumbers(dt));

    if (result && result?.length > 0) {
      return (
        result &&
        result?.map((item) => {
          return (
            <React.Fragment key={item}>
              <BoardingPage item={item} answers={answers} />
            </React.Fragment>
          );
        })
      );
    }
  }

  return (
    <div className="mt-16">
      {isOnBoarding &&
        answers !== undefined && Object.keys(answers).length > 0 ? (
        <div>{_renderAccordian()}</div>
      ) : (
        <div className="bg-[#fff] px-5 py-10 rounded-lg mt-[5rem]">
          <p className="text-4xl text-center text-primary_blue">No Onboarding Form Found</p>
        </div>
      )
      }
    </div>
  );
};

export default OnboardingTab;
