import { useEffect, useState } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import HomeBanner from "assets/images/HomeBanner.png";
import CoachTabs from "./components/Tabs";
import { useDispatch } from "react-redux";
import { getUserProfile } from "store/reducers/client.reducer";


const LeaderBoard = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchInput, setSearchInput] = useState("Clients")
  const dispatch = useDispatch();

  useEffect(() => {
    //Get User Profile and store in redux
    dispatch(getUserProfile("coach/profile"))
  }, [])
  return (
    <Layout>
      <Hero image={HomeBanner}
      />
      <div className="container mx-auto pt-[5rem] pb-32 px-10 desktop:px-10">
        <div className="relative">
          <CoachTabs setSearchInput={setSearchInput} />
        </div>
      </div>
    </Layout>
  );
};

export default LeaderBoard;
