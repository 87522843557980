import ChallengeCard from "./components/ChallengeCard";
import { useGetChallengesQuery } from "apis/client";
import { ChallangeInterface } from "types/interfaces";
import { socket } from "config/socket";

const MyChallenges = () => {
  const { data, isLoading } = useGetChallengesQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const activeChallenges = data?.data?.challenges?.filter(
    (item: any) =>
      (item?.status === "accepted" || item?.status === "pending") &&
      item?.isCompleted === false
  );

  return (
    <div className="mb-[2rem] cursor-pointer">
      {activeChallenges?.length === 0 && (
        <div className="bg-[#fff] rounded-xl py-14 px-4">
          <h1 className="text-center text-primary_blue text-[3rem] font-medium">
            No Challenges Found
          </h1>
        </div>
      )}

      <div className="grid grid-cols-1 mobile_l:grid-cols-2 tablet:grid-cols-3 gap-[1.76rem] cursor-pointer">
        {isLoading ? (
          <h1 className="text-center text-primary_blue text-[3rem] font-medium">
            Loading...
          </h1>
        ) : (
          activeChallenges?.map((item: ChallangeInterface, index: number) => (
            <ChallengeCard key={index} challenge={item} socket={socket} />
          ))
        )}
      </div>
    </div>
  );
};

export default MyChallenges;
