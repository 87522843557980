import RecorderIcon from "assets/icons/recorder-icon.svg";
import thumnailimage from "../assets/images/thumnailimage.png"
import moment from "moment";

const ZoomCallCard = ({
  imgUrl,
  title,
  desc,
  postedTime,
  url,
  status,
  call
}: any) => {

  function openVideoHandler() {
    if (url && status === "completed") {
      window.open(url, "_blank", "noopener,noreferrer")
    }
  }

  return (
    <>
      <div
        className="bg-white w-full max-h-[28.7rem] rounded-[20px] overflow-hidden cursor-pointer"
        onClick={openVideoHandler}
      >
        <div className="w-full h-[25rem] md:h-[18rem] overflow-hidden relative">
          <div className="absolute top-[1.9rem] left-[2.6rem]">
            <img className="w-[3.4rem]" alt="" src={RecorderIcon}></img>
          </div>
          <div>
            <img
              alt="DefaultImage"
              className="object-cover w-full h-[25rem] md:h-[18rem]"
              src={imgUrl ? imgUrl : thumnailimage}
            ></img>
          </div>
          <div className="absolute top-[1rem] right-[1rem]">
            <span className="px-3 py-1 bg-darkgrey bg-opacity-50 text-[1.4rem] text-white font-light rounded-md">
              {status === "completed" ? "Available" : "Processing"}
            </span>
          </div>
          <div className={`absolute bottom-[0rem] w-full h-1/2 flex items-center justify-center`} >
          <div className={`absolute bottom-[0rem] bg-[#2165a2] w-full opacity-50 h-full ${call?.callType === "group" && "bg-[#16A000]"}`} />
            <p className="text-white opacity-100 relative capitalize text-[2rem] font-bold pb-1 whitespace-nowrap text-ellipsis overflow-hidden">
                {title}
              </p>
            </div>
        </div>
        {/* <div className="p-5">
          <div className="flex gap-[1.3rem] items-center">
            <p className="text-primary_blue capitalize text-[2rem] font-bold pb-1 whitespace-nowrap w-[100%] text-ellipsis overflow-hidden">
              {title}
            </p>
          </div>
          <div className="items-center flex gap-14">
            <p className="text-[1.6rem] font-semibold text-[#333333] whitespace-nowrap w-[100%] text-ellipsis overflow-hidden">
              {desc}
            </p>
            <p className="text-gray-500 text-[1.4rem] font-light text-[#4D4D4D] whitespace-nowrap">
              {postedTime && moment(postedTime).fromNow()}
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ZoomCallCard;
