interface ButtonProps {
  type?: "button" | "submit" | "reset";
  text: string;
  width?: string;
  color: string;
  onClick?: any;
  loading?: any;
}

const PrimaryButton = ({
  type,
  text,
  width,
  color,
  onClick,
  loading,
}: ButtonProps) => {
  const getBtnColor = (color: string) => {
    switch (color) {
      case "primary":
        return "bg-primary_blue";
    }
  };

  return (
    <button
      disabled={loading}
      style={{ background: loading && "grey" }}
      type={type ? type : "button"}
      className={`h-24 text-4xl rounded-2xl  text-white ${width ? width : "w-full"
        } ${getBtnColor(color)}`}
      onClick={onClick}
    >
      {loading ? "Loading..." : text}
    </button>
  );
};

export default PrimaryButton;
