import { Fragment, useState } from "react";
import WinrateLogo from "../assets/icons/winrate-logo-white.png";
import BurgerIcon from "../assets/icons/burger-icon.svg";
import { NavLink } from "react-router-dom";
import ChevronDown from "../assets/icons/chevron-down.svg";
import { Disclosure, Dialog, Transition } from "@headlessui/react";
import EmptyProfile from "assets/images/EmptyProfile.jpg";
import { useDispatch } from "react-redux";
import { logoutAsync } from "store/reducers/auth.reducer";
import { isAndroid } from 'react-device-detect';

const MobileHeader = ({ navLinks, user, accountLink, isClient }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logoutAsync());
  };

  const handleAppRedirectionClick = () => {
    if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.winrate.app&pli=1';
    } else {
      window.open("https://apps.apple.com/us/app/winrate-consulting/id6446288804", "blank")
    }
  };

  return (
    <header className="h-[10.4rem] !bg-[#18242c] fixed left-0 top-0 shadow-2xl w-full flex items-center justify-between px-12 z-10">
      <div className="nav_burger cursor-pointer">
        <div className="p-2" onClick={() => setIsOpen(true)}>
          <img src={BurgerIcon}></img>
        </div>
      </div>
      <div className="nav_brand mt-3 w-64">
        <img src={WinrateLogo} className="w-64"></img>
      </div>
      <div className="nav_avatar flex items-center gap-5">
        {isClient && (<div>
          <button
            onClick={handleAppRedirectionClick}
            className="px-[1.7rem] py-[0.4rem] text-[1.3rem] mobile_l:text-[1.8rem] text-white bg-[#288EBF] rounded-[1.8rem] border-[0.15rem]"
          >
            Switch to App
          </button>
        </div>)}
        <NavLink to={accountLink}>
          <div className="w-24 h-24 bg-primary_light rounded-full overflow-hidden ml-auto flex items-center">
            <img
              className="w-full h-full"
              src={user?.profile_photo ? user?.profile_photo : EmptyProfile}
            ></img>
          </div>
        </NavLink>
      </div>

      <>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 md:hidden"
            onClose={() => setIsOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-200 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-200 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="flex relative z-10 flex-col w-3/5 h-full bg-darkgrey  md:hidden">
                <div className="overflow-y-auto flex-1">
                  <div className="mb-10 mt-16 px-16">
                    <div className="nav_brand mt-3 w-64 mb-16">
                      <img src={WinrateLogo} className="w-64"></img>
                    </div>
                    <ul className="flex flex-col gap-8">
                      {navLinks.map((navItem: any, index: any) =>
                        !navItem.isDropdown ? (
                          <li key={index}>
                            <NavLink
                              to={navItem.url}
                              className="text-white text-3xl font-semibold hover:text-primary_light focus-visible:ring-0 shadow-none outline-none focus-visible:shadow-none"
                            >
                              {navItem.title}
                            </NavLink>
                          </li>
                        ) : (
                          <Disclosure key={index}>
                            {() => (
                              <>
                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-transparent text-left text-sm font-medium hover:bg-transparent focus:outline-none focus-visible:ring focus-visible:ring-transparent focus-visible:ring-opacity-0">
                                  <span className="text-white text-3xl bg-transparent font-semibold">
                                    {navItem.title}
                                  </span>
                                  <img src={ChevronDown} className="mt-2"></img>
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-4 pb-2 text-sm text-gray-500">
                                  <ul>
                                    {navItem.dropContent?.map(
                                      (item: any, index: any) => (
                                        <li key={index}>
                                          <NavLink
                                            to={item.url}
                                            className="flex text-white w-full items-center text-3xl px-5 py-2 hover:text-primary_light hover:bg-gray-200"
                                          >
                                            {item.title}
                                          </NavLink>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )
                      )}
                      <li
                        onClick={logoutHandler}
                        className="text-white  cursor-pointer text-3xl font-semibold hover:text-primary_light focus-visible:ring-0 shadow-none outline-none focus-visible:shadow-none"
                      >
                        Logout
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Transition.Child>
            <Transition.Child
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-200 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              as={Fragment}
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-50"></Dialog.Overlay>
            </Transition.Child>
          </Dialog>
        </Transition>
      </>
    </header>
  );
};

export default MobileHeader;
