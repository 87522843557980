import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import "./App.scss";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { AllRoutes } from "routes";
import { Provider } from "react-redux";
import PrivateRoute from "./routes/PrivateRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import store from "./store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Register from "pages/Coach/Register";
import { useLayoutEffect } from "react";

const Wrapper = ({children}:any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 400);
  }, [location.pathname]);
  return children
}
// app component
function App() {

  return (
    <Provider store={store}>
      <ToastContainer
        theme="dark"
        style={{ fontSize: "1.8rem", fontWeight: 700, zIndex: 999999999 }}
      />
      <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path="/register" element={<Register />} />
          {AllRoutes?.map((item: any, index) => (
            <Route
              key={index}
              path={item.path}
              element={
                item.isPrivate ? (
                  <PrivateRoute permissions={item?.permissions}>
                    {item?.page}
                  </PrivateRoute>
                ) : (
                  <ProtectedRoute>{item.page}</ProtectedRoute>
                )
              }
            ></Route>
          ))}
        </Routes>
        </Wrapper>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
