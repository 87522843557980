import FilteruplaodedCall from "components/FilteruplaodedCall";
import ZoomCallCard from "components/ZoomCallCard";

const PreviousCall = ({ callsData, isLoading }: any) => {
  const zoomCalls = callsData?.filter((call: any) => call?.isZoom);
  const uploadCalls = callsData?.filter((call: any) => !call?.isZoom);
  
  const sortedZoomCalls = zoomCalls?.sort((a: any, b: any) => {
    const aDate = new Date(a?.date)?.getTime();
    const bDate = new Date(b?.date)?.getTime();
    return bDate - aDate
  })

  return (
    <div>
      {isLoading && (
        <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
          <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
        </div>
      )}

      {!isLoading && <h2 className="text-white text-5xl mt-20">Call Recordings</h2>}
      {(!isLoading && sortedZoomCalls?.length === 0) ? (
        <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
          <p className="text-4xl sm:text-5xl text-primary_blue">No Recordings Found</p>
        </div>
      ) : (
        <div className="mt-[4rem] grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
          {sortedZoomCalls?.map((call: any, index: number) => {
            const video = call?.recording_files?.find((vid: any) => vid.file_type === "MP4");
            const url = `${video?.play_url}?pwd=${call?.recording_play_passcode}`
            return (
              <div
                key={index}>
                <ZoomCallCard
                  title={call?.topic}
                  postedTime={video?.recording_end || new Date}
                  status={video?.status}
                  url={url}
                />
              </div>
            )
          })}
        </div>
      )}

      {!isLoading && <h2 className="text-white text-5xl mt-20">Call Uploads</h2>}
      {(!isLoading && uploadCalls?.length === 0) ? (
        <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
          <p className="text-4xl sm:text-5xl text-primary_blue">No Upload Found</p>
        </div>
      ) : (
        <FilteruplaodedCall filterCalls={uploadCalls} />
      )}
    </div>
  );
};

export default PreviousCall;
