import EmptyProfile from "assets/images/EmptyProfile.jpg";
import Bulb from "assets/images/bulb-icon.svg";
import Twit from "assets/images/Twit.svg";
import Insta from "assets/images/Insta.svg";
import Fb from "assets/images/Fb.svg";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import HomeBanner from "assets/images/HomeBanner.png";
import { useParams } from "react-router-dom";
import moment from "moment";
import numberWithCommas from "utils/commaseperatedfunction";
import { useGetClientByIdQuery } from "apis/coach";

const ClientProfile = () => {
  const { id } = useParams();

  const { data, isSuccess } = useGetClientByIdQuery(id, {
    refetchOnMountOrArgChange: true, refetchOnFocus: true
  });

  const user = data?.data?.user || {};

  const checkLink = (url: string) => {
    return url;
  };

  return (
    <Layout>
      <Hero image={HomeBanner} />
      <div className="container mx-auto py-24 px-10 desktop:px-10">
        {(isSuccess && Object.keys(user)?.length === 0) ? (
          <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
            <p className="text-4xl sm:text-5xl text-primary_blue">
              User Data Not Found
            </p>
          </div>
        ) : (
          <div className="bg-[#202F39]">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  md:px-24 lg:px-0  gap-12 text-center items-end lg:grid-cols-3">
              <div className="mt-10 sm:mt-0 flex flex-col items-center">
                <div className="w-[20rem] h-[20rem] mx-auto  rounded-full border-4 border-white text-white overflow-hidden mb-[1rem]">
                  <img
                    className="w-full h-full block object-cover"
                    src={user?.profile_photo ? user?.profile_photo : EmptyProfile}
                    alt="Client"
                  />
                </div>
                <h1 className="text-white text-[4rem] leading-none font-bold">
                  {user?.full_name}
                </h1>
                <h1 className="text-white text-[3.8rem] leading-none font-normal">
                  {user?.address ? user?.address : ""}
                </h1>
              </div>
              <div className="flex justify-center lg:text-left sm:mt-10">
                <img className="h-[9rem]" src={Bulb} alt="Bulb" />
                <div className="my-auto">
                  <h1 className="text-white text-[4rem] leading-none font-bold">
                    {numberWithCommas(user?.winRating >= 0 ? user?.winRating : 0)}
                  </h1>
                  <h1 className="text-white text-[4rem] leading-none font-normal">
                    Win Rating
                  </h1>
                </div>
              </div>
              <div>
                <h1 className="text-white text-[4rem] leading-none font-bold">
                  Client Since
                </h1>
                <h1 className="text-white text-[4rem] leading-none font-normal">
                  {moment(user?.createdAt).format("L")}
                </h1>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-20 md:flex md:flex-row md:justify-center lg:flex lg:justify-around mt-[5rem] tablet:mt-[6rem] ">
              <div className="text-center col-span-3">
                <h1 className="text-white text-[5rem] leading-[6rem] font-bold">
                  {user?.actionItems?.length + user?.championships?.length}
                </h1>
                <h1 className="text-white text-[3.8rem] leading-[3.8rem] font-normal text-center">
                  Tasks <br /> Completed
                </h1>
              </div>
              <div className="text-center col-span-3">
                <div className="md:border-x-2 border-white md:px-28">
                  <h1 className="text-white text-[5rem] leading-[6rem] font-bold">
                    {numberWithCommas(user?.challengesWon)}
                  </h1>
                  <h1 className="text-white text-[3.8rem] leading-[3.8rem] font-normal text-center">
                    Won <br /> Challenges
                  </h1>
                </div>
              </div>
              <div className="text-center col-span-3">
                <h1 className="text-white text-[5rem] leading-[6rem] font-bold">
                  {numberWithCommas(user?.challengesInProgress)}
                </h1>
                <h1 className="text-white text-[3.8rem] leading-[3.8rem] font-normal text-center">
                  In Progress <br /> Challenges
                </h1>
              </div>
            </div>
            <div className="w-full tablet:px-20 sm:px-0 md:mx-auto tablet:w-[73.1rem] tablet:mx-auto mt-[10rem]">
              <form className="schedule_call_input">
                <div className="w-full mx-auto">
                  <label>Company Name</label>
                  <input
                    disabled
                    value={user?.company}
                    type="text"
                    className="placeholder:text-white"
                    placeholder="Software  Development"
                  />
                </div>
                <div className="mt-[2.6rem] mx-auto">
                  <label>E-mail</label>
                  <input
                    disabled
                    value={user?.email}
                    type="email"
                    className="placeholder:text-white"
                    placeholder="jesica@example.com"
                  />
                </div>
                <div className="mt-[2.6rem] w-full mx-auto">
                  <label>Phone</label>
                  <input
                    disabled
                    value={user?.phoneNo}
                    type="text"
                    className="placeholder:text-white"
                    placeholder="+009-1122334455"
                  />
                </div>
              </form>
            </div>

            {(user?.facebook || user?.instagram || user?.twitter) && (
              <h1 className="mt-[4rem] tablet:mt-[6rem] text-center text-white text-[6rem] font-normal">
                Connect
              </h1>
            )}

            <div className="mt-[3rem] gap-[2rem]  tablet:mt-[4rem] px-[2.8rem] tablet:px-0 mx-auto flex items-center justify-center mb-[3rem]">
              {user?.facebook && (
                <a
                  href={checkLink(user?.facebook)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="w-[7rem]">
                    <img className="w-full h-full" src={Fb} alt="Facebook" />
                  </div>
                </a>
              )}

              {user?.instagram && (
                <a
                  href={checkLink(user?.instagram)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="w-[7rem]">
                    <img className="w-full h-full" src={Insta} alt="Instagram" />
                  </div>
                </a>
              )}

              {user?.twitter && (
                <a
                  href={checkLink(user?.twitter)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="w-[7rem]">
                    <img className="w-full h-full" src={Twit} alt="Twitter" />
                  </div>
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ClientProfile;
