import Index from "pages/Coach";
import Login from "pages/Coach/Login";
import ResetPassword from "pages/Coach/ResetPassword";
import CreatePassword from "pages/Coach/CreatePassword";
import VerifyOtp from "pages/Coach/VerifyOtp";
import PasswordChanged from "pages/Coach/PasswordChanged";
import NotFound from "pages/NotFound";
import ClientRoutes from "./client-routes";
import CoachRoutes from "./coach-routes";
import Roles from "./permissions";
import NotAuthorized from "pages/NotAuthorized";
import TermsAndConditions from "pages/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy";

export const AllRoutes = [
  {
    path: "/",
    page: <Index />,
    isPrivate: false,
    permissions: [...Object.values(Roles)],
  },
  {
    path: "/login",
    page: <Login />,
    isPrivate: false,
    permissions: [...Object.values(Roles)],
  },
  {
    path: "/reset-password",
    page: <ResetPassword />,
    isPrivate: false,
    permissions: [...Object.values(Roles)],
  },
  {
    path: "/verify-otp",
    page: <VerifyOtp />,
    isPrivate: false,
    permissions: [...Object.values(Roles)],
  },
  {
    path: "/create-password/:otp",
    page: <CreatePassword />,
    isPrivate: false,
    permissions: [...Object.values(Roles)],
  },
  {
    path: "/password-changed",
    page: <PasswordChanged />,
    isPrivate: false,
    permissions: [...Object.values(Roles)],
  },
  //Terms & Condition
  {
    path: "/terms-condition",
    page: <TermsAndConditions />,
    isPrivate: false,
  },

  //Privacy Policy
  {
    path: "/privacy-policy",
    page: <PrivacyPolicy />,
    isPrivate: false,
  },

  // CLIENT ROUTES
  ...ClientRoutes,

  // COACH ROUTES
  ...CoachRoutes,

  {
    path: "/not-authorized",
    page: <NotAuthorized />,
    isPrivate: true,
    permissions: [...Object.values(Roles)],
  },

  //404 Error Page
  {
    path: "*",
    page: <NotFound />,
    isPrivate: false,
    permissions: [...Object.values(Roles)],
  },

];
