import { useState } from "react";
import ProfileImg from "../../../../assets/images/profile.png";
import ParticipantModal from "./ParticipantModal";

const ParticipantCard = ({ event, user }: any) => {
  const [showModal, setShowModal] = useState(false);
  const isRSVP = event?.members?.find((member: any) => member?.email == user?.email);
  const border = isRSVP.isAttending === "YES" ? "border-[green]" : "border-[red]";

  return (
    <>
      <ParticipantModal
        event={event}
        item={user}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className={`bg-white px-6 py-5 rounded-xl border-2	${border}`}>
        <div className="flex items-center gap-4">
          <div className="w-[5rem] flex-1 aspect-square rounded-full overflow-hidden display-inline">
            <img
              src={user?.profile_photo ? user?.profile_photo : ProfileImg}
              alt="member profile img"
              className="w-full h-full"
              // onClick={() => setShowModal(true)}
            />  
          </div>
          <div className="w-[calc(100%-5rem)]">
            <h4
              className="w-[22rem] text-black text-4xl font-mediums truncate"
              // onClick={() => setShowModal(true)}
            >
              {user?.full_name}
            </h4>
            <h4 className="text-grey_semi text-3xl overflow-hidden overflow-ellipsis">{user?.email}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParticipantCard;
