import { Dialog } from '@headlessui/react'
import { useDeleteGoogleScheduleCallMutation } from "apis/coach";
import { toast } from 'react-toastify';

export default function ConfirmModal({ selectedEvent, isConfirmOpen, setIsConfirmOpen, setIsOpen }: any) {
  const [deleteGoogleScheduleCall] = useDeleteGoogleScheduleCallMutation();

  function closeModal() {
    setIsConfirmOpen(false)
  }

  const handleDelete = (type: any) => {
    const payload = {
      type: type,
      recurrenceId: selectedEvent?.recurrenceId,
      occurrence_id: selectedEvent?.occurrence_id,
      id: selectedEvent?.id
    };

    deleteGoogleScheduleCall(payload)
      .unwrap()
      .then((res: any) => {
        toast.success(res?.message);
        closeModal();
        setIsOpen(false);
      })
      .catch((error: any) => {
        toast.error(error?.data?.message);
        closeModal();
        setIsOpen(false);
      });
  };

  return (
    <>
      <Dialog
        as="div"
        className="relative z-[99999]"
        open={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
      >
        <div
          className="fixed inset-0 bg-[#000] bg-opacity-60"
          aria-hidden="true"
        />
        <Dialog.Panel as="div">
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white w-[66rem] rounded-[1.6rem] px-12 py-12 flex gap-32">
              <div className="flex-1">
                <div className="flex justify-between">
                  <div className="w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-primary_blue text-6xl font-medium text-center mb-6"
                    >
                      {selectedEvent?.isRecurring ? "Delete Recurring Meeting" : "Delete Meeting"}
                    </Dialog.Title>

                    <Dialog.Description as='p' className="text-slate-800 text-4xl font-medium text-center mb-8">
                      Are you sure you want to delete this meeting?
                    </Dialog.Description>

                    <Dialog.Panel as='div' className={"flex justify-center gap-6"}>
                      {selectedEvent?.isRecurring ? (
                        <>
                          <button
                            onClick={() => handleDelete("single")}
                            className={`bg-[#dc2626] text-white px-7 py-3 text-3xl font-medium rounded-xl border border-[#dc2626] disabled:bg-opacity-50 disabled:text-opacity-50`}
                          >
                            Delete This Occurrence
                          </button>
                          <button
                            onClick={() => handleDelete("multiple")}
                            className={`bg-[#e2e8f0] disabled:bg-opacity-50 disabled:text-opacity-50 text-[#0f172a] px-7 py-3 text-3xl font-medium rounded-xl border border-[#cbd5e1]`}
                          >
                            Delete All Occurrences
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => handleDelete("multiple")}
                          className={`bg-[#dc2626] text-white px-7 py-3 text-3xl font-medium rounded-xl border border-[#dc2626] disabled:bg-opacity-50 disabled:text-opacity-50`}
                        >
                          Delete
                        </button>
                      )}
                      <button
                        onClick={closeModal}
                        className={`bg-[#e2e8f0] disabled:bg-opacity-50 disabled:text-opacity-50 text-[#0f172a] px-7 py-3 text-3xl font-medium rounded-xl border border-[#cbd5e1]`}
                      >
                        Cancel
                      </button>
                    </Dialog.Panel>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  )
}
