import { useGetSwotsHistoryQuery } from "apis/client";
import SwotTimeLineTabs from "pages/Coach/ClientDetail/components/SwotTimeLineTabs";

const History = () => {
  // Fetch SWOT data
  const { data, isLoading } = useGetSwotsHistoryQuery(0, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const answers = data?.data?.data;

  return (
    <>
      {isLoading && (
        <div className="bg-[#fff] px-5 py-10 rounded-lg mt-[5rem]">
          <p className="text-4xl text-center text-primary_blue">Loading...</p>
        </div>
      )}

      {answers?.length > 0 && (
        <SwotTimeLineTabs answers={answers} />
      )}
    </>
  );
};

export default History;
