import { useState, useEffect } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import HomeBanner from "assets/images/HomeBanner.png";
import PersonGrayIcon from "assets/images/PersonGrayIcon.svg";
import EditIcon from "assets/images/EditIcon.svg";
import UploadImgIcon from "assets/images/UploadImgIcon.svg";
import EmailIcon from "assets/images/EmailIcon.svg";
import PhoneIcon from "assets/images/PhoneIcon.svg";
import TwitterIcon from "assets/images/TwitterIcon.svg";
import FbIcon from "assets/images/FbIcon.svg";
import insta from "assets/images/Instagram.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { patchRequest, postRequest } from "config/axiosConfig";
import { toast } from "react-toastify";
import EmptyProfile from "assets/images/EmptyProfile.jpg";
import { updateCoach } from "apis/coach-apis";
import { setUserProfile } from "store/slices/client.slice";
import { selectUser } from "store/selectors";
import { useGetProfileByIdQuery } from "apis/general";

interface IFormInputs {
  userName: string;
  phone: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
}

const schema = yup.object({
  userName: yup.string().required("Name required").min(4).max(20),
  phone: yup.string().required("Phone required").min(10).max(15),
  facebook: yup.string(),
  instagram: yup.string(),
  twitter: yup.string(),
});

const EditAccount = () => {
  const user = useSelector(selectUser);
  const { data, isFetching, refetch } = useGetProfileByIdQuery(user?.id, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })

  const navigate = useNavigate();
  const [phone, setPhone] = useState(data?.data?.user?.phoneNo);
  const [imgUrl, setImgUrl] = useState("");
  const [isLoading, setIsloading] = useState(false);

  // State variables to store form field values
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");

  function isValidHttpUrl(string: any): any {
    const pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/)
    return pattern?.test(string);
  }

  const dispatch = useDispatch();

  // Fetch data from the API and update form fields when component mounts
  useEffect(() => {
    if (data?.data?.user) {
      const { full_name, phoneNo, facebook, instagram, twitter, profile_image } = data.data.user;
      setValue("userName", full_name)
      setPhone(phoneNo);
      setValue("phone", phoneNo)
      setFacebookUrl(facebook);
      setValue("facebook", facebook)
      setInstagramUrl(instagram);
      setValue("instagram", instagram)
      setTwitterUrl(twitter);
      setValue("twitter", twitter)
      setImgUrl(profile_image)
    }
  }, [isFetching]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      userName: data?.data?.user?.full_name,
      facebook: data?.data?.user?.facebook,
      instagram: data?.data?.user?.instagram,
      twitter: data?.data?.user?.twitter,
    },
  });

  function handleNumber(e: any) {
    const enteredValue = e.target.value.replace(/\D/g, "");
    e.preventDefault();
    setPhone(enteredValue);
    setValue("phone", enteredValue, { shouldValidate: true });
  }

  const onSubmit = (data: IFormInputs) => {
    if (facebookUrl !== "") {
      if (!isValidHttpUrl(facebookUrl)) {
        toast.error('Please enter a Facebook valid URL.');
        return;
      }
    }
    if (twitterUrl !== "") {
      if (!isValidHttpUrl(twitterUrl)) {
        toast.error('Please enter a Twitter valid URL.');
        return;
      }
    }
    const userData = {
      fullName: data?.userName,
      phone: data?.phone,
      address: "",
      profilePhoto: imgUrl === "" ? user?.profile_photo : imgUrl,
      facebook: data?.facebook,
      twitter: data?.twitter,
      instagram: data?.instagram,
    };
    updateCoach(userData, dispatch, navigate, setIsloading);
  };

  const imageHandler = async (e: any) => {
    const img = e.target.files[0];
    const formData = new FormData();
    formData.append("image", img);
    try {
      setIsloading(true);
      const result = await postRequest("upload-profile", formData)
      if (result) {
        const updated = await patchRequest("coach/update", {
          profilePhoto: result,
        })
        setImgUrl(result);
        setIsloading(false);
        dispatch(setUserProfile(updated?.data?.user));
        refetch();
      } else {
        toast.error("Something Wrong");
        setIsloading(false);
      }
    } catch (error: any) {
      setIsloading(false);
    }
  };


  return (
    <Layout>
      <Hero image={HomeBanner} />
      <div className="container mx-auto py-32 px-10 desktop:px-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="md:flex md:px-20 lg:px-0 gap-40 md:flex-col lg:flex-row lg:items-start md:items-center flex-col items-start px-0">
            <div className="flex flex-col items-center">
              <div className="w-[21rem] relative">
                <div className="overflow-hidden rounded-[0.63rem]">
                  <img
                    className="w-[23.5rem] h-[19.5rem] object-cover"
                    src={
                      imgUrl
                        ? imgUrl
                        : data?.data?.user?.profile_photo
                          ? data?.data?.user?.profile_photo
                          : EmptyProfile
                    }
                    alt="Account Img"
                  />
                </div>
                <div>
                  <div className="">
                    <img
                      className="absolute w-[5rem] h-[5rem] -right-10 top-[5.2rem] cursor-pointer "
                      src={UploadImgIcon}
                      alt="UploadImg Icon"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={imageHandler}
                      className="absolute w-[5rem] h-[5rem] -right-10 top-12 opacity-0 cursor-pointer"
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
              <h1 className=" text-white text-[3rem] font-medium max-w-[20rem] truncate leading-[3.6rem] mt-[1.6rem] capitalize">
                {data?.data?.user?.full_name}
              </h1>
              <h1 className=" text-[#969696] text-[3.3rem] font-light leading-[3.9rem] mt-[0.4rem] capitalize">
                {user?.role}
              </h1>
            </div>
            <div className="mx-auto laptop:mx-0 max-w-[69.7rem] ">
              <div>
                <div className="flex gap-6 items-baseline">
                  <img
                    className="w-[3rem]"
                    src={PersonGrayIcon}
                    alt="Person Icon"
                  />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light">
                    User Name
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    autoComplete="off"
                    type="text"
                    {...register("userName")}
                    className="mt-2 px-[4.2rem] w-full text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:capitalize placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder="Enter Name"
                  />
                  <div className="absolute right-0 bottom-20">
                    <div>
                      <img
                        className="w-[2.2rem]"
                        src={EditIcon}
                        alt="Edit Icon"
                      />
                    </div>
                  </div>
                </div>
                {errors.userName && (
                  <p className="error">{errors.userName?.message}</p>
                )}
              </div>
              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-6 items-baseline">
                  <img className="w-[3rem]" src={EmailIcon} alt="Email Icon" />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light mt-[1.8rem]">
                    E-mail
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    autoComplete="off"
                    type="email"
                    className="mt-2 w-full pl-[4.2rem] text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder={user?.email}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-6 items-baseline">
                  <img className="w-[3rem]" src={PhoneIcon} alt="Phone Icon" />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light mt-[1.8rem]">
                    Phone Number
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    autoComplete="off"
                    type="text"
                    value={phone}
                    {...register("phone")}
                    className="mt-2 w-full pl-[4.2rem] text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder={"Enter Phone Number"}
                    onChange={handleNumber}
                  />

                  <div className="absolute right-0 bottom-20">
                    <img
                      className="w-[2.2rem]"
                      src={EditIcon}
                      alt="Edit Icon"
                    />
                  </div>
                </div>
                {errors.phone && (
                  <p className="error">{errors.phone?.message}</p>
                )}
              </div>

              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-8 items-baseline">
                  <img className="h-[3rem]" src={FbIcon} alt="Email Icon" />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light mt-[1.8rem]">
                    Facebook
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    autoComplete="off"
                    {...register("facebook", {
                      onChange: (e: any) => setFacebookUrl(e.target.value)
                    })}
                    type="text"
                    className="mt-2 w-full pl-[4.2rem] text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder={facebookUrl || "e.g www.facebook.com/mike"}
                  />
                </div>
              </div>

              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-8 items-baseline">
                  <img
                    className="w-[2.6rem]"
                    src={TwitterIcon}
                    alt="Email Icon"
                  />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light mt-[1.8rem]">
                    Twitter
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    autoComplete="off"
                    {...register("twitter", {
                      onChange: (e: any) => setTwitterUrl(e.target.value)
                    })}
                    type="text"
                    className="mt-2 w-full pl-[4.2rem] text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder={twitterUrl || "e.g www.twitter.com/mike"}
                  />
                </div>
              </div>

              <div className="mt-[4rem] tablet:mt-[6rem]">
                <div className="flex gap-8 items-center">
                  <img className="h-[3rem]" src={insta} alt="Email Icon" />
                  <h1 className=" text-[#969696] text-[2.6rem] leading-[4.5rem] font-light">
                    Instagram
                  </h1>
                </div>
                <div className="w-full border-b-2 border-[#969696] relative">
                  <input
                    autoComplete="off"
                    {...register("instagram", {
                      onChange: (e: any) => setInstagramUrl(e.target.value)
                    })}
                    type="text"
                    className="mt-2 w-full pl-[4.2rem] text-white text-[3.4rem] focus:outline-none placeholder:text-[#B4B4B4] leading-[5.3rem] bg-[#202F39] placeholder:text-[2.2rem] placeholder:sm:text-[3.2rem]"
                    placeholder={
                      instagramUrl || "e.g www.instagram.com/mike"
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col justify-center text-center">
                <button
                  type="submit"
                  disabled={isLoading}
                  style={{ background: isLoading ? "grey" : "#2165A2" }}
                  className="w-full tablet:w-[55.2rem] mx-auto py-[1.23rem] rounded-[0.94rem] bg-[#2165A2] text-white text-[2.8rem] leading-[3.9rem] mt-[8rem]"
                >
                  {isLoading ? "Loading..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditAccount;
