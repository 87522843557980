import { useGetNotificationsCountQuery } from "apis/general";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotiCount } from "store/slices/auth.slice";
import EmptyProfile from "../assets/images/EmptyProfile.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetAllClientsQuery } from "apis/coach";

const NotificationsCard = ({
  item,
  user_img,
  markNotifications,
  socketArray,
  trigger,
  setTrigger,
}: any) => {
  const [readMark, setReadMark] = useState(false);
  const [clients, setClients] = useState<any>([]);
  const id = useSelector((state: any) => state?.auth?.user?.id);
  const userType = useSelector((state: any) => state?.auth?.checkedType);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [type, setType] = useState("")

  const { pathname } = useLocation();
  const isClient = pathname.includes("/client");
  const currentUserRole = isClient ? 'client' : 'coach'

  //get Notifications Count Api
  const { refetch: CountRefetch } =
    useGetNotificationsCountQuery({ id, role: currentUserRole }, {
      refetchOnMountOrArgChange: true,
    });
  const { data, isFetching }: any = useGetAllClientsQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  const handleMark = async (e: any) => {
    setReadMark(e.target.checked);

    await markNotifications({
      id: item?.id,
      mark_as_read: e.target.checked,
    });

    CountRefetch()
      .unwrap()
      .then((res: any) => {
        dispatch(setNotiCount(res?.data?.count));
      });
  };

  useEffect(() => {
    const array = data?.data?.users || [];
    setClients(
      [...array]?.sort((a, b): any => {
        return b?.winRating - a?.winRating;
      })
    );
  }, [isFetching]);

  // Setting the type of the notifications and getting from backend
  function handleType(type: any) {
    setType(type)
  }

  switch (true) {
    // Clients Notifications
    case type === "CALL_SCHEDULED" && userType === "client":
      navigate(`/client/video-calls`)
      break;
    case type === "RECURRING_CALL_SCHEDULED" && userType === "client":
      navigate(`/client/video-calls`)
      break;
    case type === "GROUP_CALL_SCHEDULED" && userType === "client":
      navigate(`/client/video-calls`)
      break;
    case type === "CLIENT_NOTE":
      navigate(`/client/notes?type=coach`)
      break;
    case type === "CLIENT_SUB_COACH_ASSIGNED":
      navigate(`/client/video-calls`)
      break;
    case type === "CLIENT_ACTION_ITEM_ASSIGNED":
      navigate(`/client?type=coach&action=action`)
      break;
    case type === "CLIENT_REJECT_CHALLENGE":
      navigate(`/client`)
      break;
    case type === "CLIENT_ACCEPT_CHALLENGE":
      navigate(`/client?action=challenge`)
      break;
    case type === "CLIENT_EXPIRE_CHALLENGE":
      navigate(`/client?action=challenge`)
      break;
    case type === "CLIENT_KICK_ASS_OPPONENT":
      navigate(`/client?action=challenge`)
      break;
    case type === "CLIENT_CHALLENGES":
      navigate(`/client?action=challenge`)
      break;
    case type === "CLIENT_CHALLENGE_WINNER":
      navigate(`/client?action=challenge`)
      break;
    case type === "CLIENT_CHALLENGE_LOSER":
      navigate(`/client?action=challenge`)
      break;
    case type === "CLIENT_CHALLENGE_TIE":
      navigate(`/client?action=challenge`)
      break;
    case type === "CLIENT_NEW_STREAK_HIT":
      navigate(`/client?action=streak`)
      break;
    case type === "EVENT_REMINDER" && userType === "client":
      navigate(`/client/events`)
      break;
    case type === "VIDEO_UPLOADED" && userType === "client":
      navigate(`/client/video-calls?type=previous`)
      break;
    case type === "HUDDLE_VIDEO_UPLOADED" && userType === "client":
      navigate(`/client/huddles?type=previous`)
      break;
    case type === "GROUP_VIDEO_UPLOADED" && userType === "client":
      navigate(`/client/video-calls?type=previous`)
      break;
    case type === "CLIENT_COACH_ASSIGNED":
      navigate(`/client/account`)
      break;


    // Coach Notifications
    case type === "ALL_ACTION_ITEMS_COMPLETED":
      navigate(`/coach/detail/${item?.client_id}?type=Client Action Items`)
      break;
    case type === "SINGLE_ACTION_ITEMS_COMPLETED":
      navigate(`/coach/detail/${item?.client_id}?type=Client Action Items`)
      break;
    case type === "ONBOARDING_FORM_COMPLETED":
      navigate(`/coach/detail/${item?.client_id}?type=Forms&form=0`)
      break;
    case type === "CLIENT_COMPLETE_LEGACY":
      navigate(`/coach/detail/${item?.client_id}?type=Forms&form=2`)
      break;
    case type === "CHECKINFORM_COMPLETED":
      navigate(`/coach/detail/${item?.client_id}?type=Forms&form=1`)
      break;
    case type === "CHECKIN_FORM_REMINDER":
      navigate(`/client/checkin-form/edit`)
      break;
    case type === "CLIENT_SWOT_REMINDER":
      navigate(`/client/swot-analysis`)
      break;
    case type === "COACH_NEW_STREAK_HIT":
      navigate(`/coach/detail/${item?.client_id}?type=WinRate Points`)
      break;
    case type === "CALL_SCHEDULED" && userType === "coach":
      navigate(`/coach/video-calls`)
      break;
    case type === "GROUP_CALL_SCHEDULED" && userType === "coach":
      navigate(`/coach/video-calls`)
      break;

    case type === "COACH_NEW_CLIENT_ASSIGNED":
      navigate(`/coach/member-profile/${item?.client_id}`)
      break;
    case type === "COACHING_CALL_REMINDER":
      navigate(`/client/video-calls`)
      break;
    case type === "COACH_SUB_COACH_ASSIGNED":
      navigate(`/coach/scheduled-calls`)
      break;
    case type === "COACH_SCHEDULED_CALL_REMINDER":
      navigate(`/coach/video-calls`)
      break;
    case type === "EVENT_REMINDER" && userType === "coach":
      navigate(`/coach/events`)
      break;
    case type === "CALL_IN_ONE_DAY_REMINDER":
      navigate(`/coach/video-calls`)
      break;

    case type === "CALL_TODAY_REMINDER" && userType === "coach":
      navigate(`/coach/video-calls`)
      break;
    case type === "CALL_TODAY_REMINDER" && userType === "client":
      navigate(`/client/video-calls`)
      break;
    case type === "GROUP_CALL_TODAY_REMINDER" && userType === "coach":
      navigate(`/coach/video-calls`)
      break;
    case type === "GROUP_CALL_TODAY_REMINDER" && userType === "client":
      navigate(`/client/video-calls`)
      break;
    case type === "COACH_RANKING_CHANGED":
      navigate(`/coach?type=coach`)
      break;
    case type === "VIDEO_UPLOADED" && userType === "coach":
      navigate(`/coach/video-calls?type=previous`)
      break;
    case type === "GROUP_VIDEO_UPLOADED" && userType === "coach":
      navigate(`/coach/video-calls?type=previous`)
      break;
    case type === "HUDDLE_VIDEO_UPLOADED" && userType === "coach":
      navigate(`/coach/huddles?type=previous`)
      break;
    case type === "COACH_NOTE":
      navigate(`/coach/detail/${item?.client_id}?type=Client Notes`)
      break;
    case type === "OLD_COACH_CLIENT_ASSIGNED":
      navigate(`/coach/detail/${item?.client_id}`)
      break;
    case type === "CALENDAR_DISCONNECTED":
      navigate(`/coach/detail/${clients[0]?.id}`)
      break;
  }

  useEffect(() => {
    setReadMark(item?.mark_as_read);
    if (trigger) {
      setTrigger(false);
      CountRefetch()
        .unwrap()
        .then((res: any) => {
          dispatch(setNotiCount(res?.data?.count));
        });
    }
  }, [CountRefetch, dispatch, item, item?.mark_as_read, setTrigger, trigger]);

  return (
    <div
      style={{
        background: readMark ? "#2165A233" : "#2166a251",
      }}

      key={item?.id}
      className="w-full cursor-pointer bg-[#2165A233] rounded-[0.913342rem] px-4 sm:pl-[2.466rem] sm:pr-[2.878rem] pt-[1.96rem] pb-[1.688rem]"
    >
      <div className="flex flex-col gap-4 sm:gap-0 sm:flex-row sm:justify-between">
        <div onClick={() => handleType(item?.type)} className="flex flex-col sm:flex-row gap-[2.1rem] items-center grow">
          <div className="min-w-[6.485rem] overflow-hidden rounded-full ">
            <img
              src={user_img ? user_img : EmptyProfile}
              alt="userimg"
              className="h-[6.485rem] w-[6.485rem] object-cover"
            />
          </div>
          <div>
            <h3 className="text-[#333333] break-normal text-[2.19202rem] font-semibold max-w-[80rem]">
              {item?.title}
            </h3>
            <h5 className="text-[#333333] break-normal text-[1.64402rem] font-medium max-w-[70rem]">
              {item?.body}
            </h5>
          </div>
        </div>
        <div className="mx-auto sm:mx-0 lg:w-[200px] text-right">
          <h3 className="text-[#333333] text-[1.64402rem] min-w-[26rem] font-medium flex justify-start">
            {dayjs(item?.createdAt).format("ddd, MMM D, YYYY h:mm A")}
          </h3>
          <div style={{ display: socketArray?.length > 0 ? "none" : "block" }}>
            <div className="flex gap-4 mt-[0.5rem] items-center  sm:justify-end">
              <h3 className="text-[#333333] text-[1.64402rem] font-medium w-[14rem]">
                Mark as {readMark ? "Unread" : "read"}
              </h3>
              <label className="checkbox mb-[0.3rem]">
                <input
                  checked={readMark}
                  type="checkbox"
                  onChange={(e) => handleMark(e)}
                />
                <div className="checkbox__indicator"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsCard;
