import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { useNavigate } from "react-router-dom";
import CheckGreen from "assets/icons/check-green.svg";

const PasswordChanged = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <div className="bg-white rounded-2xl px-24 sm:px-36 py-28 text-center auth_box">
        <img className="w-52 h-52 mb-20 mx-auto" src={CheckGreen}></img>
        <p className="font-semibold text-4xl mb-10 cursor-pointer text-center">
          Your password has been Changed Successfully!
        </p>
        <button
          className="text-primary_blue text-2xl font-normal underline"
          onClick={() => navigate("/login")}
        >
          Login to Continue
        </button>
      </div>
    </AuthLayout>
  );
};

export default PasswordChanged;
