import StreakCalendar from "./StreakCalendar";
import EmptyStreakModal from "./EmptyStreakModal";
import { useGetClientStreaksQuery } from "apis/coach";
import { useParams } from "react-router";

const CoachClientStreakModal = ({ user }: any) => {

  const { id } = useParams();
  const { data } = useGetClientStreaksQuery(id, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
  const streaksData = data?.data?.streak;

  return (
    <>
      <div className="flex relative flex-col min-h-[50rem] bg-white min-w-[40rem] sm:min-w-[50rem] rounded-lg">
        <div className="overflow-y-auto flex-1">
          <p className="text-5xl font-medium text-[#202F39] text-center mt-[7.3rem]">
            Current Streak
            <br />
            {streaksData?.currentStreak
              ? streaksData?.currentStreak
              : "0"}
            {""} {streaksData?.currentStreak ? streaksData?.currentStreak > 1 ? "Days" : "Day" : ""}
          </p>
          {streaksData ? (
            <>
              <div className="text-center mt-[3.6rem] mb-[3rem]"></div>
              <div className="px-4">
                <StreakCalendar user={user} streaksData={streaksData} />
              </div>
            </>
          ) : (
            <div className="text-center mt-[3.6rem] mb-[3rem]">
              <div className="px-4">
                <EmptyStreakModal />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CoachClientStreakModal;
