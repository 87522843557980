import { useEffect } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "store/selectors";
import { useGetSingleEventsQuery } from "apis/general";
import { socket } from "config/socket";

const RegisterEvent = () => {
  const user = useSelector(selectUser);
  const { id } = useParams();
  const navigate = useNavigate();
  const userId = user?.id;

  const { data } = useGetSingleEventsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const event = data?.data?.event;
  const isRSVP = event?.members?.find((member: any) => member?.email == user?.email);

  const eventGHLForm = data?.data?.event?.ghlForm;
  const urlObj: URL = new URL(eventGHLForm);
  const formId: string | undefined = urlObj.pathname.split('/').pop();

  useEffect(() => {

    const navigateHandler = () => {
      navigate(`/client/events`);
    };

    socket.on(`gohighLevel`, navigateHandler);
    socket.on(`event-${user.email}`, navigateHandler);

    return () => {
      socket.off(`event-${user.email}`, navigateHandler);
      socket.off(`gohighLevel`, navigateHandler);
    };

  }, [userId]);

  useEffect(() => {
    if(isRSVP){
      navigate(`/client/events`);
    }
  }, [isRSVP])

  return (
    <Layout>
      <Hero />
      <div className="container mx-auto pt-16 pb-1 px-10 desktop:px-10">
        <div className='text-center pb-[6rem] tablet:pb-[3rem]'>
          <h6 className='text-white font-medium text-[5rem] pb-0 tablet:mx-[10rem]'>Register</h6>
        </div>

        <div className="max-w-[67rem] mx-auto pb-[8rem]">
          <iframe
            src={eventGHLForm}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              borderRadius: "4px",
            }}
            id={`inline-${formId}`}
            data-layout="{'id':'INLINE'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name={event.title}
            data-height={481}
            data-layout-iframe-id={`inline-${formId}`}
            data-form-id={formId}
            title={event?.title}
          ></iframe>
          <script src="https://link.msgsndr.com/js/form_embed.js"></script>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterEvent;

