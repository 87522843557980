import React, { useEffect, useState } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import ClientHeroImage from "assets/images/client-hero.png";
import {
  useGetCallsQuery,
  useGetMergedCallsDyDateQuery,
} from "apis/client";
import VideoCallsTabs from "components/VideoCallsTabs";
import FilteruplaodedCall from "../../../components/FilteruplaodedCall";
import { useSelector } from "react-redux";
import { selectUser } from "store/selectors";
import ScheduleCallComp from "components/ScheduleCallComp";
import ZoomCallCard from "components/ZoomCallCard";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

const VideoCalls = () => {
  const [tab, setTab] = useState("upcomingcalls");
  const [startDate, setStartDate] = useState(new Date());
  const [month, setMonth] = useState(dayjs().startOf('month').format("YYYY-MM-DD"));
  const user = useSelector(selectUser);

  const { data: calls, isLoading } = useGetCallsQuery(user?.id, {
    refetchOnMountOrArgChange: true,
  });

  const { data, isLoading: upcomingLoading, isSuccess } = useGetMergedCallsDyDateQuery(
    month,
    { refetchOnMountOrArgChange: true }
  );

  const upComingCalls = data?.data?.data ?? [];

  const zoomCalls = upComingCalls?.zoom ?? [];

  const sortedZoomCalls = [...zoomCalls]?.sort((a: any, b: any) => {
    const aDate = new Date(a?.start_time)?.getTime();
    const bDate = new Date(b?.start_time)?.getTime();
    return bDate - aDate
  })

  const scheduledCalls = calls?.data?.calls.map((item: any) => {
    const date = item?.date ? item?.date : item?.createdAt;
    const temp = new Date(date).getTime() / 1000;
    return {
      ...item,
      filterDate: temp
    }
  })?.sort((a: any, b: any) => a?.filterDate - b?.filterDate) || [];

  const onChange = (date: any) => {
    const from = dayjs(date).format("YYYY-MM-DD");
    setStartDate(date);
    setMonth(from);
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams?.get('type');

  useEffect(() => {
    if (type) {
        setTab(type==="previous"?"previouscalls":"upcomingcalls");
    }
  }, [type,setTab]);

  return (
    <Layout>
      <Hero image={ClientHeroImage} heading="Scheduled Calls" />
      <div className="container mx-auto pt-[1rem] sm:pt-[2rem] px-10 desktop:px-10 pb-[8rem]">
        <div className="flex flex-col gap-8 md:flex-row justify-between lg:items-end mt-10">
          <VideoCallsTabs setTab={setTab} tab={tab} />
          {
            tab === "previouscalls" && (
              <div className="schedule_call_input_date">
                <div className="flex flex-row items-center gap-4">
                  <label className="text-white">Select Month</label>
                  <div className="relative bg-transparent">
                    <ReactDatePicker
                      selected={startDate}
                      onChange={onChange}
                      dateFormat="MMMM-yyyy"
                      showMonthYearPicker
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </div>

        {tab === "previouscalls" && (
          <div>
            {upcomingLoading && (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
              </div>
            )}
            {!upcomingLoading && <h2 className="text-white text-5xl mt-20">Call Recordings</h2>}
            {(!upcomingLoading && sortedZoomCalls?.length === 0) ? (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">No Recordings Found</p>
              </div>
            ) : (
              <div className="mt-[4rem] grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
                {sortedZoomCalls?.map((call: any, index: number) => {
                  const video = call?.recording_files?.find((vid: any) => vid.file_type === "MP4");
                  const url = `${video?.play_url}?pwd=${call?.recording_play_passcode}`
                  return (
                    <div
                      key={index}>
                      <ZoomCallCard
                        title={call?.topic}
                        postedTime={video?.recording_end || new Date}
                        status={video?.status}
                        url={url}
                        call={call}
                      />
                    </div>
                  )
                })}
              </div>
            )}

            {!upcomingLoading && <h2 className="text-white text-5xl mt-20">Call Uploads</h2>}
            {(!upcomingLoading && upComingCalls?.upload?.length === 0) ? (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">No Upload Found</p>
              </div>
            ) : (
              <FilteruplaodedCall filterCalls={upComingCalls?.upload} />
            )}
          </div>
        )}

        {tab !== "previouscalls" && (
          <div>
            {isLoading ? (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
              </div>
            ) : (
              <ScheduleCallComp title="Scheduled Calls" data={scheduledCalls} />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default VideoCalls;
