import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import bgBulb from "../assets/images/privacybulb.png";
import Layout from "layouts/Layout";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {
  const token = useSelector((state: any) => state?.auth?.token);

  return (
    <div className="">
      {token ? (
        <Layout>
          <Hero image={ClientHeroImage} isCentered heading="Privacy Policy" />
          <div className="container mx-auto py-16 px-12 desktop:px-10 bg-[#202F39]">
            <div className="w-full bg-white rounded-[2rem] pl-[2rem] sm:pl-[8.171rem] pr-[2rem] sm:pr-[7.31rem] pt-[11.377rem] pb-[20.232rem] relative z-4">
              <div className="absolute top-[50%] z-6">
                <img src={bgBulb} alt="bgBulb" className="w-full h-full" />
              </div>
              <h1 className="font-medium text-center sm:text-left text-[3rem] sm:text-[7.08508rem] text-[#2165A2] mb-[4.16rem] ">
                Privacy Policy
              </h1>
              <div className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  WinRate Consulting LLC built the WinRate App as a Free app.
                  This SERVICE is provided by WinRate Consulting LLC at no cost
                  and is intended for use as is.
                </p>
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  This page is used to inform visitors regarding our policies
                  with the collection, use, and disclosure of Personal
                  Information if anyone decided to use our Service.
                </p>
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  If you choose to use our Service, then you agree to the
                  collection and use of information in relation to this policy.
                  The Personal Information that we collect is used for providing
                  and improving the Service we offer. We will not use or share
                  your information with anyone except as described in this
                  Privacy Policy.
                </p>
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which are accessible at
                  WinRate App unless otherwise defined in this Privacy Policy.
                </p>
              </div>
              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Information Collection and Use
              </h4>
              <div className="text-[#000000] text-[2rem] sm:text-[3rem]">
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to name, email, phone
                number, address, social media handles. The information that we
                request will be retained by us and used as described in this
                privacy policy.
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  The app does use third-party services that may collect
                  information used to identify you. Link to the privacy policy
                  of third-party service providers used by the app
                </p>
                <div className="ml-[3rem] sm:ml-[25rem]">
                  <ul className="list-disc">
                    <li>
                      <a
                        className="text-[#2165A2]   text-[2rem] sm:text-[3rem] "
                        href="https://policies.google.com/privacy"
                        target="_blank"
                      >
                        Google Play Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2165A2] text-[2rem] sm:text-[3rem] "
                        href="https://www.facebook.com/about/privacy/update/printable"
                        target="_blank"
                      >
                        Facebook
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Log Data
              </h4>
              <div className="text-[#000000] text-[2rem] sm:text-[3rem]">
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  We want to inform you that whenever you use our Service, in a
                  case of an error in the app we collect data and information
                  (through third-party products) on your phone called Log Data.
                  This Log Data may include information such as your device
                  Internet Protocol (“IP”) address, device name, operating
                  system version, the configuration of the app when utilizing
                  our Service, the time and date of your use of the Service, and
                  other statistics.
                </p>
              </div>
              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Cookies
              </h4>
              <div className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                <p className="text-[#000000] text-[2rem] sm:text-[3rem]">
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device&apos;s internal memory.
                </p>
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem] ">
                  This Service does not use these “cookies” explicitly. However,
                  the app may use third-party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </p>
              </div>

              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Service Providers
              </h4>
              <div className="text-[#000000] text-[2rem] sm:text-[3rem]">
                <p className="text-[#000000] text-[2rem] sm:text-[3rem]">
                  We may employ third-party companies and individuals due to the
                  following reasons:
                </p>
                <div className="ml-[3rem] sm:ml-[25rem]">
                  <ul className="list-disc">
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                  </ul>
                </div>
                <p className="mt-[1rem]">
                  We want to inform users of this Service that these third
                  parties have access to their Personal Information. The reason
                  is to perform the tasks assigned to them on our behalf.
                  However, they are obligated not to disclose or use the
                  information for any other purpose.
                </p>
              </div>

              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Security
              </h4>
              <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                We value your trust in providing us your Personal Information,
                thus we are striving to use commercially acceptable means of
                protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and
                reliable, and we cannot guarantee its absolute security.
              </p>

              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Links to Other Sites
              </h4>
              <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                This Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. We have no control over and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
              </p>

              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Children’s Privacy
              </h4>
              <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                These Services do not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                children under 13 years of age. In the case we discover that a
                child under 13 has provided us with personal information, we
                immediately delete this from our servers. If you are a parent or
                guardian and you are aware that your child has provided us with
                personal information, please contact us so that we will be able
                to do the necessary actions.
              </p>

              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Changes to This Privacy Policy
              </h4>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem]">
                We may update our Privacy Policy from time to time. Thus, you
                are advised to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. This policy is effective as of 2023-03-20
              </p>
              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Contact Us
              </h4>
              <div>
                <p className="text-[#000000]  break-words text-[2rem] sm:text-[3rem]">
                  If you have any questions or suggestions about our Privacy
                  Policy, do not hesitate to contact us at{" "}
                  <span className="text-[#2165A2]">
                    {" "}
                    <a href="#">support@winrateconsulting.com </a>
                  </span>
                  .
                </p>
                <p className="text-[#000000]  text-[2rem] break-words sm:text-[3rem]">
                  This privacy policy page was created at{" "}
                  <u className="text-[#2165A2]">
                    <a
                      href="https://www.privacypolicytemplate.net/"
                      target="_blank"
                    >
                      privacypolicytemplate.net
                    </a>
                  </u>{" "}
                  and modified/generated by{" "}
                  <u className="text-[#2165A2]">
                    <a
                      href="https://app-privacy-policy-generator.nisrulz.com/"
                      target="_blank"
                    >
                      App Privacy Policy Generator
                    </a>
                  </u>
                </p>
              </div>
            </div>
          </div>
        </Layout>
      ) : (
        <div className=" bg-[#202F39]">
          <Hero image={ClientHeroImage} isCentered heading="Privacy Policy" />
          <div className="container mx-auto p-16 desktop:px-10 bg-[#202F39]">
            <div className="w-full bg-white rounded-[2rem] pl-[2rem] sm:pl-[8.171rem] pr-[2rem] sm:pr-[7.31rem] pt-[11.377rem] pb-[20.232rem] relative z-4">
              <div className="absolute top-[50%] z-6">
                <img src={bgBulb} alt="bgBulb" className="w-full h-full" />
              </div>
              <h1 className="font-medium text-center sm:text-left text-[3rem] sm:text-[7.08508rem] text-[#2165A2] mb-[4.16rem] ">
                Privacy Policy
              </h1>
              <div className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  WinRate Consulting LLC built the WinRate App as a Free app.
                  This SERVICE is provided by WinRate Consulting LLC at no cost
                  and is intended for use as is.
                </p>
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  This page is used to inform visitors regarding our policies
                  with the collection, use, and disclosure of Personal
                  Information if anyone decided to use our Service.
                </p>
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  If you choose to use our Service, then you agree to the
                  collection and use of information in relation to this policy.
                  The Personal Information that we collect is used for providing
                  and improving the Service we offer. We will not use or share
                  your information with anyone except as described in this
                  Privacy Policy.
                </p>
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which are accessible at
                  WinRate App unless otherwise defined in this Privacy Policy.
                </p>
              </div>
              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Information Collection and Use
              </h4>
              <div className="text-[#000000] text-[2rem] sm:text-[3rem]">
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to name, email, phone
                number, address, social media handles. The information that we
                request will be retained by us and used as described in this
                privacy policy.
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  The app does use third-party services that may collect
                  information used to identify you. Link to the privacy policy
                  of third-party service providers used by the app
                </p>
                <div className="ml-[3rem] sm:ml-[25rem]">
                  <ul className="list-disc">
                    <li>
                      <a
                        className="text-[#2165A2]   text-[2rem] sm:text-[3rem] "
                        href="https://policies.google.com/privacy"
                        target="_blank"
                      >
                        Google Play Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2165A2] text-[2rem] sm:text-[3rem] "
                        href="https://www.facebook.com/about/privacy/update/printable"
                        target="_blank"
                      >
                        Facebook
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Log Data
              </h4>
              <div className="text-[#000000] text-[2rem] sm:text-[3rem]">
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                  We want to inform you that whenever you use our Service, in a
                  case of an error in the app we collect data and information
                  (through third-party products) on your phone called Log Data.
                  This Log Data may include information such as your device
                  Internet Protocol (“IP”) address, device name, operating
                  system version, the configuration of the app when utilizing
                  our Service, the time and date of your use of the Service, and
                  other statistics.
                </p>
              </div>
              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Cookies
              </h4>
              <div className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                <p className="text-[#000000] text-[2rem] sm:text-[3rem]">
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device&apos;s internal memory.
                </p>
                <p className="text-[#000000]  text-[2rem] sm:text-[3rem] ">
                  This Service does not use these “cookies” explicitly. However,
                  the app may use third-party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </p>
              </div>

              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Service Providers
              </h4>
              <div className="text-[#000000] text-[2rem] sm:text-[3rem]">
                <p className="text-[#000000] text-[2rem] sm:text-[3rem]">
                  We may employ third-party companies and individuals due to the
                  following reasons:
                </p>
                <div className="ml-[3rem] sm:ml-[25rem]">
                  <ul className="list-disc">
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                  </ul>
                </div>
                <p className="mt-[1rem]">
                  We want to inform users of this Service that these third
                  parties have access to their Personal Information. The reason
                  is to perform the tasks assigned to them on our behalf.
                  However, they are obligated not to disclose or use the
                  information for any other purpose.
                </p>
              </div>

              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Security
              </h4>
              <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                We value your trust in providing us your Personal Information,
                thus we are striving to use commercially acceptable means of
                protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and
                reliable, and we cannot guarantee its absolute security.
              </p>

              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Links to Other Sites
              </h4>
              <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                This Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. We have no control over and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
              </p>

              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Children’s Privacy
              </h4>
              <p className="text-[#000000]  text-[2rem] sm:text-[3rem]">
                These Services do not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                children under 13 years of age. In the case we discover that a
                child under 13 has provided us with personal information, we
                immediately delete this from our servers. If you are a parent or
                guardian and you are aware that your child has provided us with
                personal information, please contact us so that we will be able
                to do the necessary actions.
              </p>

              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Changes to This Privacy Policy
              </h4>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem]">
                We may update our Privacy Policy from time to time. Thus, you
                are advised to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. This policy is effective as of 2023-03-20
              </p>
              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem] mb-[2rem]">
                Contact Us
              </h4>
              <div>
                <p className="text-[#000000]  break-words text-[2rem] sm:text-[3rem]">
                  If you have any questions or suggestions about our Privacy
                  Policy, do not hesitate to contact us at{" "}
                  <span className="text-[#2165A2]">
                    {" "}
                    <a href="#">support@winrateconsulting.com </a>
                  </span>
                  .
                </p>
                <p className="text-[#000000]  text-[2rem] break-words sm:text-[3rem]">
                  This privacy policy page was created at{" "}
                  <u className="text-[#2165A2]">
                    <a
                      href="https://www.privacypolicytemplate.net/"
                      target="_blank"
                    >
                      privacypolicytemplate.net
                    </a>
                  </u>{" "}
                  and modified/generated by{" "}
                  <u className="text-[#2165A2]">
                    <a
                      href="https://app-privacy-policy-generator.nisrulz.com/"
                      target="_blank"
                    >
                      App Privacy Policy Generator
                    </a>
                  </u>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicy;
