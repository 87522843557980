import React, { useEffect, useState } from "react";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import OnBoardingAccordian from "./components/Accordian";
import { useGetOnboardingGHLSubmissionQuery } from "apis/client";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getClient } from "apis/client-apis";
import GHLOnboardingComponent from "components/GHLForms/OnBoarding";
import { socket } from "config/socket";
import { toast } from "react-toastify";
import EditIcon from "../../../assets/icons/swot_edit.svg";

const OnBoarding = () => {
  const navigate = useNavigate();
  const [isOnBoarded, setIsOnBoarded] = useState(false);

  const { data, isLoading } = useGetOnboardingGHLSubmissionQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const userId = useSelector((state: any) => state?.auth?.user?.id);
  const userData = useSelector((state: any) => state?.auth?.user);

  useEffect(() => {
    getClient(userId).then((res) => {
      if (res?.data?.user?.isOnboarded == false) {
        navigate("/client/onboard");
      }
      setIsOnBoarded(res?.data?.user?.isOnboarded);
    });
  }, [userId]);

  useEffect(() => {
    const navigateHandler = (data: any) => {
      setIsOnBoarded(data?.user?.isOnboarded);
      if (data?.user?.isOnboarded == true) {
        window.location.href = "/client/onboard";
      }
    };
    const reloadHandler = (data: any) => {

      if (data?.user == '' && data?.reload == true) {
        toast.error(data?.message);

      } else if (data?.user?.id !== userId) {
        toast.error(data?.message);

      }
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    };
    socket.on(`gohighLevel`, reloadHandler);
    socket.on(`onboarding${userId}`, navigateHandler);

    return () => {
      socket.off(`onboarding${userId}`, navigateHandler);
      socket.off(`gohighLevel`, reloadHandler);
    };
  }, [userId]);

  const answers = data?.data?.data;

  function _renderAccordian() {
    if (answers && answers?.length > 0) {
      return answers?.map((item:any, index: number) => {
          return (
            <React.Fragment key={index}>
              <OnBoardingAccordian 
                question={item?.name}
                answer={item?.value}
              />
            </React.Fragment>
          );
        });
    }
  }

  return (
    <Layout>
      <Hero image={ClientHeroImage} subHeading="Onboarding Questionnaire" />
      <div className="container mx-auto pt-10 pb-20 sm:pt-14 sm:pb-40 px-10 desktop:px-10">
        <div className="flex sm:justify-end justify-start">
          <button
            onClick={() => navigate(`/client/onboarding/edit?email=${userData?.email}`)}
            className="flex items-center w-[22rem] sm:w-[24.946rem] bg-[#2165A2] pr-[9.346rem] pl-[4.4rem] gap-[1.446rem] rounded-[1.15675rem]">
            <div className="min-w-[3.046rem] h-[3.042rem]">
              <img src={EditIcon} alt="" />
            </div>
            <h1 className="text-white text-[3.4rem] sm:text-[4.337rem] font-medium sm:font-[500]">
              Edit
            </h1>
          </button>
        </div>
        <div className="mt-[2.8rem] text-[#fffff]">
          {isOnBoarded ? (
            answers !== undefined && Object.keys(answers).length > 0 ? (
              <div>{_renderAccordian()}</div>
            ) : (
              <p className="break-normal text-center bg-white px-[1rem] pt-[1rem] rounded-[1rem] text-[3rem] text-[black] pb-[2rem]">
                {isLoading ? "Loading..." : "No Data found!"}
              </p>
            )
          ) : (
            <GHLOnboardingComponent userData={data}/>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default OnBoarding;
