import { Tab } from "@headlessui/react";
import TabContent from "../ClientDetail/TabContent";

const LegacyTabs = ({ data }: any) => {
  const filteredData = data?.filter((item:any) => item?.description);
  const newData = filteredData?.map((item:any) => {
    const splitDesc =  item?.description?.split("\n");
    return {
      ...item,
      splitDesc: splitDesc
    }
  });

  return (
    <>
      <Tab.Group>
        <div className="mt-[4.2rem] mb-[5rem] ">
          <Tab.List
            className="flex justify-between  flex-row items-start sm:gap-[2rem] gap-[0.56rem] pb-[2rem] overflow-x-auto"
          >
            {filteredData?.map((item: any, index: number) => {
              return (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    selected
                      ? "text-white border-[0.310762rem] sm:border-[0.310762rem] border-[#fff] bg-[#299AFF] outline-none sm:h-[24.8rem] min-h-[15rem]  flex-1 font-bold sm:rounded-[1.24305rem] rounded-[0.64524rem] min-w-[20rem] sm:min-w-[27rem] lg:min-w-[25rem]"
                      : "text-white border-[0.16131rem] border-primary_blue bg-[#2165A2] flex-1 outline-none sm:rounded-[1.24305rem] rounded-[1.24305rem]  min-h-[15rem] sm:h-[24.8rem] min-w-[20rem] sm:min-w-[27rem] lg:min-w-[25rem]"
                  }
                >
                  <div className="flex flex-col">
                    <h4 className="sm:text-[3.226rem] text-[3rem] m-auto w-[90%]  text-center font-medium">
                      {item?.heading}
                    </h4>
                  </div>
                </Tab>
              );
            })}
          </Tab.List>
        </div>
        <Tab.Panels>
          {newData?.map((item: any) => (
            <Tab.Panel key={item?.id} className="text-white">
              <TabContent heading={item?.splitDesc} selected={item?.heading} id={item?.id} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};

export default LegacyTabs;
