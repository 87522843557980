import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useCreateLegacyStatementMutation } from "apis/client";
import { toast } from "react-toastify";
import LegacyForm from "./LegacyForm";

export const values = [
  {
    label: "My Purpose",
    category: "my_purpose",
  },
  {
    label: "I Am",
    category: "i_am",
  },
  {
    label: "I Will",
    category: "i_will",
  },
  {
    label: "Never Again",
    category: "never_again",
  },
  {
    label: "Core Values",
    category: "core_values",
  },
  {
    label: "Reminders",
    category: "reminders",
  },
  {
    label: "Quote",
    category: "quote",
  },
];

const Index = () => {
  const [page, setPage] = useState(0);
  const [
    CreateLegacyStatement,
    { isLoading },
  ] = useCreateLegacyStatementMutation();

  const [formData, setFormData] = useState<any>([]);
  const navigate = useNavigate();
  async function nextHandler(fData: any) {
    if (page !== 6) {
      setPage(page + 1);
    } else {
      const payload = {
        legacy_statement: fData,
      };
      await CreateLegacyStatement(payload)
        .unwrap()
        .then((res) => {
          toast.success(res?.message)
          navigate("/client/legacy-statement");
        })
        .catch((error) => {
          toast.error(error?.data?.message);
        });
    }
  }

  return (
    <>
      {/* <Hero image={ClientHeroImage} subHeading="Create Champions Legacy" /> */}
      <div className="container mx-auto py-32 px-10 desktop:px-0">
        <div className="w-100% bg-white rounded-[2rem] py-16 px-16 pb-32">
          <div className="w-full sm:w-[68rem] mx-auto">
            <h1 className="font-medium text-[#333333] text-[3rem] sm:text-[5rem]">
              Create Champions Legacy
            </h1>
            {values?.map((item: any, index: number) => {
              if (page === index) {
                return (
                  <LegacyForm
                    key={index}
                    index={index}
                    category={item.category}
                    label={item.label}
                    isLoading={isLoading}
                    nextHandler={nextHandler}
                    setFormData={setFormData}
                    page={page}
                    formData={formData}
                    setPage={setPage}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
