import { Link } from "react-router-dom";
import Avatar from "assets/images/EmptyProfile.jpg";
import vector from "assets/images/Vector.svg";
import { useGetAllClientsQuery } from "apis/coach";
import { useEffect, useState } from "react";
 import numberWithCommas from "utils/commaseperatedfunction";
const ClientsDetail = () => {
  const [clients, setClients] = useState<any>([]);
  const { data, isLoading, isFetching } = useGetAllClientsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    const array = data?.data?.users || [];
    setClients(
      [...array]?.sort((a, b): any => {
        return b?.winRating - a?.winRating;
      })
    );
  }, [isFetching]);


  return (
    <div className="mt-[5.5rem] relative">
      <div className=" hidden bg-[#2165A2] px-16 py-10 sm:flex justify-between rounded-2xl sm:mb-[5.3rem]">
        <h3 className="font-medium text-[3rem] leading-[3.5rem]">
          Client Name
        </h3>
        <h3 className="font-medium text-[3rem] leading-[3.5rem]">
          Contract End Date
        </h3>
        <h3 className="font-medium text-[3rem] leading-[3.5rem]">Win Rating</h3>
      </div>
      {isFetching || isLoading ? (
        <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
          <p className="text-4xl sm:text-5xl text-primary_blue">
            Loading Clients...
          </p>
        </div>
      ) : (
        <>
          {clients.length === 0 ? (
            <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
              <p className="text-4xl sm:text-5xl text-primary_blue">
                No Clients Found
              </p>
            </div>
          ) : (
            clients?.map((item: any) => {
              return (
                <div
                  key={item?.id}
                  className="relative sm:flex sm:px-10 bg-white px-9 py-8 rounded-2xl mt-[2rem] sm:text-center"
                >
                  <div className="grid grid-cols-12 flex-1 flex-start sm:gap-0 gap-10">
                    <div className="flex gap-[1.6rem] flex-row items-center sm:col-span-6 col-span-12 ">
                      <Link to={`/coach/member-profile/${item?.id}`}>
                        {" "}
                        <div className="h-[7rem] w-[7rem] tablet:h-[8rem] tablet:w-[8rem] overflow-hidden">
                          {" "}
                          <img
                            alt="IMag"
                            src={
                              item?.profile_photo === null
                                ? Avatar
                                : item?.profile_photo
                            }
                            className="cursor-pointer border border-light_gray h-[7rem] w-[7rem] tablet:h-[8rem] tablet:w-[8rem] object-cover rounded-full"
                          />
                        </div>
                      </Link>
                      <Link to={`/coach/member-profile/${item?.id}`}>
                        <h4 className="font-bold capitalize truncate w-[30rem] text-left text-[2.2rem] tablet:text-[3rem] leading-[3.5rem] text-[#2165A2] sm:col-span-3">
                          {item?.full_name}
                        </h4>
                      </Link>
                    </div>
                    <div className="col-span-6 self-end sm:self-center mb-[0.6rem] sm:mb-0">
                      <h3 className="text-[2rem] tablet:text-[1.6rem] font-medium leading-[1.8rem] text-[#2165A2] sm:hidden mb-[0.6rem]">
                        Contract End Date
                      </h3>
                      <h3 className="text-[2rem] ml-0 sm:ml-[18rem]  tablet:text-[2.6rem] leading-[3.0rem] text-[#333333] sm:text-[#2165A2]">
                        {item?.contractEndDate ? item?.contractEndDate : "---"}
                      </h3>
                    </div>
                  </div>
                  <div className=" grid grid-cols-12 flex-1">
                    <div className="col-span-6 self-end sm:self-center mt-9 sm:mt-0">
                    </div>

                    <div className="col-span-6  self-end sm:self-center">
                      <div className="flex justify-between">
                        <div className="sm:mx-auto">
                          <h3 className="text-[2rem] tablet:text-[1.6rem] font-medium leading-[1.8rem] text-[#2165A2] sm:hidden mb-[0.6rem]">
                            Win Rating
                          </h3>
                          <h3 className="text-[2rem] tablet:text-[2.6rem] leading-[3.0rem] text-[#333333] sm:text-[#2165A2] sm:text-[2.6rem]">
                            {numberWithCommas(item?.winRating >= 0 ? item?.winRating : 0)}
                          </h3>
                        </div>
                        <div>
                          <Link to={`/coach/detail/${item?.id}`}>
                            <div className="col-span-6 sm:col-span-3">
                              {" "}
                              <img
                                alt=""
                                src={vector}
                                className="right-5 cursor-pointer"
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </>
      )}
    </div>
  );
};

export default ClientsDetail;
