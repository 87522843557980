import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndpoint from "config/environment";

export const clientServerApi = createApi({
  reducerPath: "clientServerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/client/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as any).auth.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: [
    "Championships",
    "ActionItems",
    "Notes",
    "Challenges",
    "LegacyStatements",
    "CheckinForm",
    "Swot",
    "Profile",
    "OnBoarding",
    "Support",
  ],

  endpoints: (builder) => ({
    getChampionships: builder.query({
      query: () => "championship",
      providesTags: ["Championships"],
    }),

    markChampComplete: builder.mutation({
      query: (payload) => ({
        url: "championship/mark-complete",
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Championships", "Profile"],
    }),

    deleteChamp: builder.mutation({
      query: (id) => ({
        url: `championship/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Championships", "Profile"],
    }),

    createChampionship: builder.mutation({
      query: (payload) => ({
        url: "championship/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Championships"],
    }),

    getActionItems: builder.query({
      query: () => "action-item",
      providesTags: ["ActionItems"],
    }),

    markActionItemComplete: builder.mutation({
      query: (payload) => ({
        url: "action-item/mark-complete",
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["ActionItems", "Profile"],
    }),

    deleteActionItem: builder.mutation({
      query: (id) => ({
        url: `action-item/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ActionItems", "Profile"],
    }),

    createActionItem: builder.mutation({
      query: (payload) => ({
        url: "action-item/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["ActionItems"],
    }),

    getCoachNotes: builder.query({
      query: () => "notes",
      providesTags: ["Notes"],
    }),
    // From caoch to client notes
    getCoachNotesForClient: builder.query({
      query: () => "notes/coach-notes",
      providesTags: ["Notes"],
    }),
    createCoachNote: builder.mutation({
      query: (payload) => ({
        url: "notes/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Notes"],
    }),

    //Get profile
    getProfile: builder.query({
      query: (id) => `profile/${id}`,
      providesTags: ["Profile", "OnBoarding"],
    }),

    //UPDATE PROFILE
    updateProfile: builder.mutation({
      query: (payload) => ({
        url: "update",
        method: "PATCH",
        body: payload,
      }),
    }),

    resetPassword: builder.mutation({
      query: (payload) => ({
        url: "change-password",
        method: "PATCH",
        body: payload,
      }),
    }),

    //Leaderboard
    getClientLeaderboard: builder.query({
      query: () => "leaderboard",
      providesTags: ["Challenges", "Championships", "ActionItems"],
    }),

    createChallenge: builder.mutation({
      query: (payload) => ({
        url: "challenge/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Challenges"],
    }),

    getChallenges: builder.query({
      query: () => "challenge",
      providesTags: ["Challenges", "Championships", "ActionItems"],
    }),

    updateChallenge: builder.mutation({
      query: (payload) => ({
        url: "challenge",
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Challenges"],
    }),

    submitAnswer: builder.mutation({
      query: (payload) => ({
        url: "onboarding/add-answers",
        method: "POST",
        body: payload,
      }),
    }),
    getOnboardingGHLSubmission: builder.query({
      query: () => "onboarding/ghl/submission",
      providesTags: ["OnBoarding"],
    }),
    //CREATE Champions Legacy
    createLegacyStatement: builder.mutation({
      query: (payload) => ({
        url: "legacy/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["LegacyStatements"],
    }),
    getLegacyStatements: builder.query({
      query: () => "legacy",
      providesTags: ["LegacyStatements"],
    }),
    //CHECKIN FORM
    createCheckinForm: builder.mutation({
      query: (payload) => ({
        url: "checkinform/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["CheckinForm"],
    }),

    getCheckinForm: builder.query({
      query: () => "checkinform",
      providesTags: ["CheckinForm"],
    }),

    getCheckinFormHistory: builder.query({
      query: () => "checkinform/history/all",
      providesTags: ["CheckinForm"],
    }),

    getActiveCheckinForm: builder.query({
      query: () => "checkinform/active",
      keepUnusedDataFor: 0.0001,
      providesTags: ["CheckinForm"],
    }),

    //Swot Apis
    createSwot: builder.mutation({
      query: (payload) => ({
        url: "swot-analysis/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Swot"],
    }),

    getSwots: builder.query({
      query: () => "swot-analysis",
      providesTags: ["Swot"],
    }),

    getSwotsHistory: builder.query({
      query: () => "swot-analysis/history/all",
      providesTags: ["Swot"],
    }),

    getCalls: builder.query({
      query: () => `call`,
    }),

    getGroupCalls: builder.query({
      query: () => `call/group/all`,
    }),

    getHuddleCalls: builder.query({
      query: () => `call/huddle/fetch`,
    }),
    //KICK ASS
    kickAss: builder.mutation({
      query: (payload) => ({
        url: "/challenge/kick-ass",
        method: "POST",
        body: payload,
      }),
      // invalidatesTags: ["Challenges"],
    }),

    //GET STREAKS
    getStreaks: builder.query({
      query: () => `streak`,
    }),
    getMyProfile: builder.query({
      query: () => `profile`,
      providesTags: ["Profile"],
    }),
    //Support APi
    createSupport: builder.mutation({
      query: (payload) => ({
        url: "contact/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Support"],
    }),

    //Get merged recorded and uploaded calls
    getMergedCalls: builder.query({
      query: () => `call/merged/calls`,
    }),
    getMergedCallsDyDate: builder.query({
      query: (from) => `call/merged/calls?from=${from}`,
    }),
    getGroupCallRecordings: builder.query({
      query: () => `call/merged/group-calls`,
    }),
    //Get merged recorded and uploaded  huddle calls
    getMergedHuddleCalls: builder.query({
      query: () => `/call-library/huddle-merged`,
    }),
    // Update Champions Legacy
    updateLegacyStatements: builder.mutation({
      query: ({ id, payload }) => ({
        url: `legacy/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["LegacyStatements"],
    }),

    registerCourse: builder.mutation({
      query: (payload) => ({
        url: `course/register`,
        method: "POST",
        body: payload,
      }),
    }),
  }),

});

export const {
  useGetChampionshipsQuery,
  useMarkChampCompleteMutation,
  useCreateChampionshipMutation,
  useGetActionItemsQuery,
  useCreateActionItemMutation,
  useMarkActionItemCompleteMutation,
  useCreateCoachNoteMutation,
  useGetCoachNotesQuery,
  useUpdateProfileMutation,
  useGetProfileQuery,
  useResetPasswordMutation,
  useGetClientLeaderboardQuery,
  useCreateChallengeMutation,
  useGetChallengesQuery,
  useUpdateChallengeMutation,
  useSubmitAnswerMutation,
  useGetLegacyStatementsQuery,
  useCreateLegacyStatementMutation,
  useCreateCheckinFormMutation,
  useGetActiveCheckinFormQuery,
  useGetCheckinFormQuery,
  useCreateSwotMutation,
  useGetSwotsQuery,
  useKickAssMutation,
  useDeleteChampMutation,
  useDeleteActionItemMutation,
  useGetCallsQuery,
  useGetHuddleCallsQuery,
  useGetStreaksQuery,
  useGetCoachNotesForClientQuery,
  useGetMyProfileQuery,
  useCreateSupportMutation,
  useGetMergedCallsQuery,
  useGetMergedCallsDyDateQuery,
  useGetMergedHuddleCallsQuery,
  useUpdateLegacyStatementsMutation,
  useGetOnboardingGHLSubmissionQuery,
  useGetSwotsHistoryQuery,
  useGetCheckinFormHistoryQuery,
  useGetGroupCallsQuery,
  useGetGroupCallRecordingsQuery,
  useRegisterCourseMutation
} = clientServerApi;

export function isFetchBaseQueryError(error: unknown): error is {
  status: number;
  data: {
    message: string;
    status: number;
    success: boolean;
    data: any;
  };
} {
  return typeof error === "object" && error != null && "status" in error;
}
