import { useState, useEffect } from "react";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/HeroImage.png";
import UserProfileIcon from "../../../assets/images/UserProfileIcon.svg";
import UserProfileCheckIcon from "../../../assets/images/UserProfileCheckIcon.svg";
import UserProfileQuestions from "../../../assets/images/UserProfileQuestions.svg";
import UserProfileSWOT from "../../../assets/images/UserProfileSWOT.svg";
import UserProfileNotes from "../../../assets/images/UserProfileNotes.svg";
import UserProfileCall from "../../../assets/images/UserProfileCall.svg";
import LockIcon from "../../../assets/images/LockIcon.svg";
import LogoutIcon from "../../../assets/images/LogoutIcon.svg";
import AccountBulb from "../../../assets/images/AccountBulb.png";
import AccountLogo from "../../../assets/images/AccountLogo.png";
import EmptyProfile from "../../../assets/images/EmptyProfile.jpg";
import LegacyIcon from "../../../assets/icons/LegacyIcon.svg";
import tandc from "../../../assets/icons/tandc.svg";
import pp from "../../../assets/icons/pp.svg";
import support from "../../../assets/icons/support.svg";
import coachIcon from "../../../assets/icons/coach-icon.svg";
import { Link } from "react-router-dom";
import { selectUser } from "store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { logoutAsync } from "store/reducers/auth.reducer";
import { useGetProfileByIdQuery } from "apis/general";

const UserProfile = () => {
  const user = useSelector(selectUser);
  const { data, isFetching } = useGetProfileByIdQuery(user?.id, {
    refetchOnMountOrArgChange: true,
  });
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const coachFullName = data?.data?.user?.coach?.full_name;
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.data?.user) {
      const { full_name, profile_photo, phoneNo } = data.data.user;
      setFullName(full_name);
      setPhone(phoneNo);
      setImage(profile_photo);
    }
  }, [isFetching]);

  const logoutHandler = () => {
    dispatch(logoutAsync());
  };

  return (
    <Layout>
      <Hero image={ClientHeroImage} />
      <div className="container mx-auto py-32 px-10 desktop:px-10">
        <div className="grid grid-cols-12 gap-9">
          <div className="col-span-12 sm:col-span-4 py-[2rem] sm:pt-[9rem] sm:b-[31rem] bg-[#1E2C35] rounded-[1rem]">
            <div className="flex flex-col items-center px-6">
              <h1 className=" text-white text-[2.5rem] lg:text-[4rem] font-bold leading-[4.7rem]">
                Your Account
              </h1>
              <div className="w-[25rem] h-[22rem] rounded-[0.63rem] overflow-hidden mt-[2.8rem]">
                <img
                  className="w-full h-full object-cover"
                  src={image ? image : EmptyProfile}
                  alt="Account Img"
                />
              </div>
              <h1 className="text-center text-white w-[27rem] break-all truncate text-[2.5rem] lg:text-[2.8rem] font-medium leading-[4rem] mt-[1.8rem] capitalize">
                {fullName}
              </h1>
              <h1 className=" text-[#969696] text-[2.5rem] lg:text-[3rem] font-light leading-[4.4rem] mt-[0.4rem] capitalize">
                {user?.role.includes("client") && user?.role.includes("coach")
                  ? "Client/Coach"
                  : "Client"}
              </h1>
              <h1 className=" text-[#969696] text-[2rem] lg:text-[2.5rem] font-light leading-[3.5rem] mt-[0.4rem] break-all">
                {user?.email}
              </h1>
              <h1 className=" text-[#969696] text-[2rem] lg:text-[2.5rem] font-light leading-[3.5rem] mt-[0.4rem]">
                {phone}
              </h1>
              <div className="flex justify-center items-center mt-[8.7rem] gap-8">
                <div className="flex justify-center items-center gap-2">
                  <img src={coachIcon} alt="coach Icon" className="w-[2.2rem] h-[2.2rem]"/>
                  <h1 className="text-white text-[2rem] lg:text-[2.5rem] font-light leading-none">
                    Coach:
                  </h1>
                </div>
                <h1 className="text-[#969696] text-[2rem] lg:text-[2.5rem] font-light leading-none">
                  {coachFullName}
                </h1>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-8 pt-[8.6rem] pl-[6rem] sm:pl-[16.8rem] pb-[9.7rem] bg-[#1E2C35] rounded-[1rem]">
            <div className="flex flex-col gap-12 items-start">
              <Link to="/client/edit-account">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img
                      className="w-full"
                      src={UserProfileIcon}
                      alt="Person Icon"
                    />
                  </div>
                  Edit Profile
                </button>
              </Link>
              <Link to="/client/checkin-form">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img
                      className="w-full"
                      src={UserProfileCheckIcon}
                      alt="User Profile Check-In Icon"
                    />
                  </div>
                  Check-In Form
                </button>
              </Link>
              <Link to="/client/onboarding">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img
                      className="w-full"
                      src={UserProfileQuestions}
                      alt="Onboarding Questionnaire Icon"
                    />
                  </div>
                  Onboarding Questionnaire
                </button>
              </Link>
              <Link to="/client/swot-analysis">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img
                      className="w-full"
                      src={UserProfileSWOT}
                      alt="SWOT Analysis Icon"
                    />
                  </div>
                  SWOT Analysis
                </button>
              </Link>
              <Link to="/client/notes">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img
                      className="w-full"
                      src={UserProfileNotes}
                      alt="View Notes Icon"
                    />
                  </div>
                  View Notes
                </button>
              </Link>
              <Link to="/client/scheduled-calls">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img
                      className="w-full"
                      src={UserProfileCall}
                      alt="Scheduled Calls Icon"
                    />
                  </div>
                  Scheduled Calls
                </button>
              </Link>
              <Link to="/client/legacy-statement">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img
                      className="w-full"
                      src={LegacyIcon}
                      alt="Legacy Icon"
                    />
                  </div>
                  My Champions Legacy
                </button>
              </Link>
              <Link to="/client/account-reset-password">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img
                      className="w-full"
                      src={LockIcon}
                      alt="Change Password Icon"
                    />
                  </div>
                  Change Password
                </button>
              </Link>
              <Link to="/client/privacy-policy">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img
                      className="w-full"
                      src={pp}
                      alt="Privacy Policy Icon"
                    />
                  </div>
                  Privacy Policy
                </button>
              </Link>
              <Link to="/client/terms-condition">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img className="w-full" src={tandc} alt="T &C Icon" />
                  </div>
                  Terms & Conditions
                </button>
              </Link>
              <Link to="/client/support">
                <button className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline">
                  <div className="w-[2.5rem] sm:w-[2.5rem]">
                    <img className="w-full" src={support} alt="Support Icon" />
                  </div>
                  Support
                </button>
              </Link>
              <button
                className="text-[2rem] lg:text-[3.7rem] text-white flex gap-6 items-baseline"
                onClick={logoutHandler}
              >
                <div className="w-[20%] sm:w-[2.5rem]">
                  <img className="w-full" src={LogoutIcon} alt="Log Out Icon" />
                </div>
                Log Out
              </button>
            </div>
          </div>
        </div>
        <div className="w-[15.7rem] mt-[1rem] mx-auto hidden sm:block">
          <img className="w-full" src={AccountBulb} alt="Bulb Icon" />
        </div>
        <div className="w-full sm:w-[57.1rem] mt-[1rem] mx-auto hidden sm:block">
          <img className="w-full" src={AccountLogo} alt="Bulb Icon" />
        </div>
      </div>
    </Layout>
  );
};

export default UserProfile;
