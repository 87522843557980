import { useEffect, useState } from "react";
import Add from "../../../../assets/images/Add.svg";
import { useNavigate, useParams } from "react-router-dom";
import LibraryTabContent from "pages/Coach/CallLibrary/components/LibraryTabContent";
import { useGetCallsLibraryQuery } from "apis/coach";
import ZoomCallCard from "components/ZoomCallCard";
import FilteruplaodedCall from "components/FilteruplaodedCall";

const CallLibrary = () => {
  const [recordingBtn, setRecordingBtn] = useState(true);
  const { id } = useParams();
  const { data, isLoading } = useGetCallsLibraryQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => { }, [recordingBtn]);
  function handleNavigate() {
    setRecordingBtn(false);
    navigate(`/coach/schedule-call-calendar/${id}`);
  }

  const navigate = useNavigate();

  const zoomCalls = data?.data?.data?.zoom ?? [];
  const sortedZoomCalls = [...zoomCalls]?.sort((a: any, b: any) => {
    const aDate = new Date(a?.start_time)?.getTime();
    const bDate = new Date(b?.start_time)?.getTime();
    return bDate - aDate
  });

  const uploadCalls = data?.data?.data?.upload ?? [];
  const sortedUploadCalls = [...uploadCalls]?.sort((a: any, b: any) => {
    const aDate = new Date(a?.createdAt)?.getTime();
    const bDate = new Date(b?.createdAt)?.getTime();
    return bDate - aDate
  });

  const groupCalls = data?.data?.data?.groupZoom ?? [];
  const sortedGroupZoomCalls = [...groupCalls]?.sort((a: any, b: any) => {
    const aDate = new Date(a?.start_time)?.getTime();
    const bDate = new Date(b?.start_time)?.getTime();
    return bDate - aDate
  });

  return (
    <>
      {recordingBtn ? (
        <div className="mt-20">
            <>
              <div className="flex-col gap-[2rem] flex sm:flex-row justify-between mb-12 items-start tablet:items-center">
                <h3 className="font-bold text-5xl leading-[3.5rem] text-white">
                  Recent Calls
                </h3>
                <div className="flex gap-5">
                  <button
                    onClick={handleNavigate}
                    className="h-[5.6rem] w-max px-[2rem] justify-center bg-primary_blue text-white font-semibold leading-9 text-3xl border border-[#ffffff] rounded-[1rem] items-center flex gap-2"
                  >
                    {/* <img src={Add} alt="add icon" /> */}
                    View Schedule Calendar
                  </button>
                  <button
                    onClick={() => setRecordingBtn(false)}
                    className="h-[5.6rem] w-max px-[2rem] justify-center bg-primary_blue text-white font-semibold leading-9 text-3xl border border-[#ffffff] rounded-[1rem] items-center flex gap-2"
                  >
                    <img src={Add} alt="add icon" />
                    Add Call Recording
                  </button>
                </div>
              </div>

              {!isLoading && <h2 className="text-white text-5xl mt-20">Call Recordings</h2>}
              {isLoading && (
                <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                  <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
                </div>
              )}

              {(!isLoading && sortedZoomCalls?.length === 0) ? (
                <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                  <p className="text-4xl sm:text-5xl text-primary_blue">No Recordings Found</p>
                </div>
              ) : (
                <div className="mt-[4rem] grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
                  {sortedZoomCalls?.map((call: any, index: number) => {
                    const video = call?.recording_files?.find((vid: any) => vid.file_type === "MP4");
                    const url = `${video?.play_url}?pwd=${call?.recording_play_passcode}`
                    return (
                      <div
                        className="bg-white w-full max-h-[28.7rem] rounded-lg overflow-hidden cursor-pointer"
                        key={index}>
                        <ZoomCallCard
                          title={call?.topic}
                          postedTime={video?.recording_end || new Date}
                          status={video?.status}
                          url={url}
                        />
                      </div>
                    )
                  })}
                </div>
              )}

              {!isLoading && <h2 className="text-white text-5xl mt-20">Group Call Recordings</h2>}
              {isLoading && (
                <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                  <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
                </div>
              )}

              {(!isLoading && sortedGroupZoomCalls?.length === 0) ? (
                <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                  <p className="text-4xl sm:text-5xl text-primary_blue">No Recordings Found</p>
                </div>
              ) : (
                <div className="mt-[4rem] grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
                  {sortedGroupZoomCalls?.map((call: any, index: number) => {
                    const video = call?.recording_files?.find((vid: any) => vid.file_type === "MP4");
                    const url = `${video?.play_url}?pwd=${call?.recording_play_passcode}`
                    return (
                      <div
                        className="bg-white w-full max-h-[28.7rem] rounded-lg overflow-hidden cursor-pointer"
                        key={index}>
                        <ZoomCallCard
                          title={call?.topic}
                          postedTime={video?.recording_end || new Date}
                          status={video?.status}
                          url={url}
                        />
                      </div>
                    )
                  })}
                </div>
              )}

              {!isLoading && <h2 className="text-white text-5xl mt-20">Call Uploads</h2>}
              {(!isLoading && sortedUploadCalls?.length === 0) ? (
                <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                  <p className="text-4xl sm:text-5xl text-primary_blue">No Upload Found</p>
                </div>
              ) : (
                <FilteruplaodedCall filterCalls={sortedUploadCalls} />
              )}
            </>

        </div>
      ) : (
        <div className="mt-[5.6rem]">
          <LibraryTabContent setRecordingBtn={setRecordingBtn} />
        </div>
      )}
    </>
  );
};

export default CallLibrary;
