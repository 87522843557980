import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ReactPlayer from "react-player";

interface PlayVideoProps {
    title: string;
    description: string;
    videoUrl?: string;
    setIsOpen?: any;
    open?: boolean;
}

export default function PlayVideoYouTube({
    title,
    description,
    videoUrl,
    setIsOpen,
    open,
}: PlayVideoProps) {
    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-[999999]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#000] bg-opacity-30" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-max items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[80rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="mb-10 h-[40rem]">
                                        <ReactPlayer
                                            url={videoUrl}
                                            controls
                                            muted
                                            width={"100%"}
                                            height={"100%"}
                                        />
                                    </div>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-4xl capitalize text-[#2165A2] font-semibold text-gray-900"
                                    >
                                        {title.replace("&#39;", "'")}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-[2rem] text-gray-500 pt-2">
                                            {description}
                                        </p>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}