import { Menu } from "@headlessui/react";
import VerticalDots from "../../assets/images/VerticalDots.svg";
import { toast } from "react-toastify";
import EditModal from "pages/Coach/ClientDetail/components/EditModal";
import { useState } from "react";
import { useDeleteNoteMutation } from "apis/coach";

const EditDelDropDown = ({ id, data, index, length }: any) => {
  const [show, setShow] = useState(false);
  const [deleteNote] = useDeleteNoteMutation();
  const zCount = length - index;
  const handleDelete = async () => {
    await deleteNote(id)
      .unwrap()
      .then((res: any) => {
        toast.success(res?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handleEdit = () => {
    setShow(true);
  };

  return (
    <>
      <Menu
        style={{ zIndex: `${zCount}` }}
        as="div"
        className="relative inline-block text-left drop-shadow-lg"
      >
        <Menu.Button>
          <img
            src={VerticalDots}
            alt={"img"}
            className="m-auto mt-4 sm:max-w-full max-w-[80%]"
          />
        </Menu.Button>
        <Menu.Items className="absolute right-0 mt-2 w-[18rem] z-10 flex flex-col px-5 origin-top-right divide-y-4 divide-[#F5F5F5] drop-shadow-lg border-2 rounded-md bg-white focus:outline-none cursor-pointer">
          <Menu.Item
            as="div"
            className="py-2 text-[1.96115rem] text-primary_blue font-medium"
            onClick={handleEdit}
          >
            Edit
          </Menu.Item>

          <Menu.Item
            as="a"
            className="py-5 text-[1.96115rem] text-primary_blue font-medium"
            onClick={handleDelete}
          >
            Delete
          </Menu.Item>
        </Menu.Items>
      </Menu>
      <EditModal
        show={show}
        close={setShow}
        data={data}
      />
    </>
  );
};

export default EditDelDropDown;