import React, { useState } from "react";
import CreateNewAction from "./CreateNewAction";
import ActionItemList from "./actionItem/index";
import { useGetActionItemsByUserQuery } from "apis/coach";
import { useParams } from "react-router-dom";
import Add from "assets/images/Add.svg";

const ClientActionItem = () => {
  const [actionItem, setNewActionItem] = useState(false);
  const [actionType, setActionType] = React.useState(true);
  const { id } = useParams();

  const {
    data: { data } = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetActionItemsByUserQuery(
    { clientId: id },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  );

  const complete = data?.tasks?.filter(
    (item: any) => item.isCompleted === actionType
  );

  const handleChange = (type: boolean) => {
    setActionType(type);
  };

  return (
    <>
      <div className="mt-[5rem]">
        {actionItem ? (
          <CreateNewAction
            setNewActionItem={setNewActionItem}
            refetch={refetch}
          />
        ) : (
          <>
            <div className="flex justify-between mb-6 ">
              <h3 className="font-bold text-3xl sm:text-5xl leading-[3.5rem] text-white self-center">
                Action Items
              </h3>
              <button
                onClick={() => setNewActionItem(true)}
                className="w-max px-8 h-24 sm:h-20 bg-primary_blue text-white font-bold text-[1.6rem] sm:text-[2rem] rounded-[0.8rem] disabled:opacity-50 flex items-center border"
              >
                <img src={Add} />
                Create New Action Item
              </button>
              {/* <Dropdown setNewActionItem={setNewActionItem} /> */}
            </div>
            <div className="flex flex-col mb-[4rem]">
              <div className="flex items-center gap-4 mb-8 cursor-pointer">
                <label className="checkbox mb-[0.3rem]">
                  <input
                    type="checkbox"
                    checked={actionType === true}
                    onChange={() => handleChange(true)}
                  />
                  <div className="checkbox__indicator"></div>
                </label>
                <span className="font-medium text-4xl text-white items-center">
                  Complete
                </span>
              </div>
              <div className="flex items-center gap-4">
                <label className="checkbox mb-[0.3rem]">
                  <input
                    type="checkbox"
                    checked={actionType === false}
                    onChange={() => handleChange(false)}
                  />
                  <div className="checkbox__indicator"></div>
                </label>
                <span className="font-medium text-4xl text-white items-center">
                  Incomplete
                </span>
              </div>
            </div>

            {isFetching || isLoading ? (
              <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">
                  Loading...
                </p>
              </div>
            ) : (
              <div>
                <ActionItemList data={complete} refetch={refetch}/>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ClientActionItem;
