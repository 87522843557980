import React, { Fragment } from "react";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import { Tab } from "@headlessui/react";
import ChampionshipTask from "./ChampionshipTask";
import CoachNote from "./CoachNote";
import ActionItemTask from "./ActionItemTask";
import { useSelector } from "react-redux";
import { selectTabIndex } from "store/selectors";

const Tasks = () => {
  const tabValue = useSelector(selectTabIndex);
  const tabsContent = {
    buttons: ["Championship Day", "Action Items", "Note to Coach"],
    panels: [<ChampionshipTask />, <ActionItemTask />, <CoachNote />],
  };

  return (
    <Layout>
      <Hero image={ClientHeroImage} isScoreMeter />
      <div className="container mx-auto sm:py-[4.1rem] pb-1 px-10 desktop:px-10">
        <div>
          <Tab.Group defaultIndex={tabValue}>
            <Tab.List className="w-full flex justify-between">
              {tabsContent.buttons.map((item: any, index: any) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={`text-[1.6rem] tablet:text-[3.27rem] font-medium focus:outline-none ${
                        selected
                          ? "text-primary_light underline underline-offset-8"
                          : "text-white"
                      }`}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              {tabsContent.panels.map((content: any, index: number) => (
                <Tab.Panel key={index}>{content}</Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </Layout>
  );
};

export default Tasks;
