import { Tab } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function NotesTabs({ setTab,tab }: any) {
  const categories = [
    {
      title: "To Coach ",
      value: "tocoach",
    },
    {
      title: "By Coach",
      value: "fromcoach",
    }
  ];

  return (
    <div className="w-full max-w-2xl sm:px-0">
      <Tab.Group
        onChange={(index) => {
          (index === 0 && setTab("tocoach")) ||
            (index === 1 && setTab("fromcoach"));
        }}
        selectedIndex={tab=== "fromcoach" ? 1 : 0}
      >
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-2 border border-white">
          {categories.map((category, index) => (
            <Tab
              key={index}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-[2.4rem] leading-[3rem] font-medium text-blue-700",
                  "ring-none focus:outline-none",
                  selected ? "bg-primary_blue text-white" : "text-white"
                )
              }
            >
              {category.title}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
}
