import React, { useEffect, useState } from "react";
import HeroImage from "assets/images/HeroImage.png";
// import ScoreMeter from "assets/images/score-meter.svg";
import {
  useGetMyProfileQuery,
} from "apis/client";
import ScoreMeter from "./ScoreMeter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetBannerByTypeQuery } from "apis/general";
import { socket } from "config/socket";
import { logoutAsync } from "store/reducers/auth.reducer";
import { Navigate } from "react-router-dom";
import { getClient } from "apis/client-apis";
import { useLocation } from "react-router-dom";
import { setCheckedIn } from "store/slices/auth.slice";
import DownloadAppModal from "./common/DownloadAppModal";

interface HeroProps {
  image?: string;
  heading?: string;
  subHeading?: string;
  isCentered?: boolean;
  isScoreMeter?: boolean;
}

const Hero = ({
  image,
  heading,
  subHeading,
  isCentered,
  isScoreMeter,
}: HeroProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // Checking the client and coach path and changing the header image based on the path
  const isClient = pathname.includes("/client");
  const role = useSelector((state: any) => state?.auth?.checkedType);
  const id = isClient ? "CLIENT_HEADER" : "COACH_HEADER";
  const { data: BannerData } = useGetBannerByTypeQuery({ id, role }, { refetchOnMountOrArgChange: true });
  const userId = useSelector((state: any) => state?.auth?.user?.id);
  const [isOnboarded, setisOnboarded] = useState(false);

  useEffect(() => {
    getClient(userId).then((res) => {
      setisOnboarded(res?.data?.user?.isOnboarded);
      dispatch(setCheckedIn(res?.data?.user?.isCheckIn))
    });
  }, [userId]);

  const { data, isSuccess } = useGetMyProfileQuery(isOnboarded, {
    skip: role === "coach",
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const user = data?.data?.user;
  const isOnboardedUser = user?.isOnboarded;
  const dispatch = useDispatch();

  useEffect(() => {
    const logoutHandler = () => {
      dispatch(logoutAsync());
      return <Navigate to="/login" />;
    };

    socket.on(`logoutUser${userId}`, logoutHandler);

    return () => {
      socket.off(`logoutUser${userId}`, logoutHandler);
    };
  }, []);

  useEffect(() => {
    if (isOnboardedUser !== undefined && !isOnboardedUser && isSuccess) {
      if (pathname === "/client") {
        navigate(`/client/onboard?email=${user?.email}`);
      }
    }
  }, [isOnboardedUser, isSuccess, user]);

  return (
    <div>
      <DownloadAppModal/>
      <div className="w-full relative overflow-hidden">
        {/* <div className="relative"> */}
        <img
          className="min-h-[38rem] w-full tablet:h-[30rem] tablet:object-top object-cover"
          src={
            BannerData?.data?.result?.imageUrl
              ? BannerData?.data?.result?.imageUrl
              : image
                ? image
                : HeroImage
          }
          alt="img"
        />
        {!isScoreMeter ? (
          <div> </div>
        ) : (
          <div className="h-full flex items-center justify-center absolute top-1/2  left-[50%] translate-x-[-50%] w-full 2xl:w-[70%]">
            <ScoreMeter user={user} />
          </div>
        )}
      </div>
      <div
        className={`flex flex-col ${isCentered ? "items-center text-center" : ""
          } px-10 mx-auto w-full ${!isCentered ? "container " : ""
          } justify-center h-full `}
      >
        <h1 className="text-white break-all text-6xl font-semibold mt-16">
          {heading}
        </h1>
        <h1 className="text-white break-all text-6xl font-semibold mt-12">
          {subHeading}
        </h1>
      </div>
    </div>
  );
};

export default Hero;
