import { useEffect, useState } from "react";
import PrimaryButton from "components/common/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BgImage from "../../assets/images/bg-image.png";
import LogoWhite from "../../assets/images/logo-white.png";
import { userRegister } from "apis/auth-apis";
import EyeIcon from "assets/icons/eye-icon.svg";
import EyeShutIcon from "assets/icons/eye-off-icon.svg";
import { getRequest } from "config/axiosConfig";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { logoutAsync } from "store/reducers/auth.reducer";
import { useGetBannerByTypeQuery } from "apis/general";

interface IFormInputs {
  fullName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  company: string;
  facebook: string;
  instagram: string;
  twitter: string;
}

const schema = yup.object({
  fullName: yup.string().required("Full name required").min(4).max(20),
  email: yup.string().trim().email("Invalid Email format").required("Email  required"),
  phone: yup
    .string()
    .required("Phone required")
    .min(10, "Must be at least 10 digits")
    .max(15, "Not More than 15 digits"),
  password: yup
    .string()
    .required("Password required")
    .min(6, "Must be at least 6 characters"),
  confirmPassword: yup
    .string()
    .required("Confirm password required")
    .min(6, "Must be at least 6 characters")
    .oneOf([yup.ref("password")], "Passwords do not match"),
  company: yup.string(),
  facebook: yup.string(),
  instagram: yup.string(),
  twitter: yup.string(),
});

const id = "COACH_CLIENT_LOGIN";
const role = "client";

const Register = () => {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [newPassword, setNewPassword] = useState(true);
  const [confirmNewPassword, setConfirmNewPassword] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteKey = searchParams.get("key");
  const dispatch = useDispatch();
  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  
  /// Check for valid http URL
  function isValidHttpUrl(str: any): any {
    const pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/)
    return pattern?.test(str);
  }
 

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });
  
  // Submit Form
  const onSubmit = async (formvalues: IFormInputs) => {
    if (facebookUrl !== "") {
      if (!isValidHttpUrl(facebookUrl)) {
        toast.error('Please enter a Facebook valid URL.');
        return;
      }
    }
    if (twitterUrl !== "") {
      if (!isValidHttpUrl(twitterUrl)) {
        toast.error('Please enter a Twitter valid URL.');
        return;
      }
    }
    const data: any = {
      ...formvalues,
      instagram: formvalues.instagram ? `https://www.instagram.com/${formvalues.instagram}` : null,
    };
    const payload = {
      inviteKey: inviteKey,
      ...data,
    };
    await userRegister(payload, navigate, setLoading);
  };

  // Number Handler
  function handleNumber(e: any) {
    e.preventDefault();
    const enteredValue = e.target.value.replace(/\D/g, "");
    setValue("phone", enteredValue, { shouldValidate: true });
    setPhone(enteredValue);
  }

  // Verify user through OTP
  const verify = async () => {
    try {
      const result = await getRequest(`invite?key=${inviteKey}`);
      if (result?.data?.isRegistered) {
        toast.success(result?.message);
        navigate("/login");
      }
      setUserEmail(result?.data?.email);
      setValue("email", result?.data?.email);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setError(true);
      // navigate("/");
    }
  };

  useEffect(() => {
    dispatch(logoutAsync());
    verify();
  }, []);
  
  // Api Call for background image change 
  const{data} = useGetBannerByTypeQuery({id,role},{refetchOnMountOrArgChange:true,refetchOnFocus:true} );

  return (
    <div
      className="h-screen overflow-auto relative bg-cover bg-no-repeat "
       style={{ backgroundImage: "url(" + data?.data?.result?.imageUrl + ")" ? "url(" + data?.data?.result?.imageUrl + ")" : "url(" + BgImage + ")" }}
    >
      <div className="absolute top-0 left-0 w-full h-full  bg-darkgrey opacity-90"></div>
      <div className="absolute top-16 left-16 z-[99999]">
        <img
          className="z-[20] cursor-pointer"
          src={LogoWhite}
          onClick={() => {
            navigate("/login");
          }}
          alt="WinRate Logo"
        />
      </div>
      <div className="absolute top-0 left-0 h-full w-full flex justify-center items-center pt-[70rem] px-4 pb-24 sm:overflow-auto ">
        <div className="flex justify-center items-center">
          <div className="bg-white rounded-2xl px-24 sm:px-36 py-16 md:min-w-[65rem]">
            <h1 className="text-5xl font-bold mb-2">Welcome!</h1>
            <p className="text-5xl font-normal mb-12">Create your account</p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-4"
            >
              <div className="mb-4">
                <label className="font-normal text-3xl">Full Name</label>
                <input
                  type="text"
                  {...register("fullName")}
                  placeholder="Enter Name"
                  className="h-24 w-full !bg-transparent flex-1 mt-4 text-4xl px-8  focus:shadow-white focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-0 flex items-center bg-white  border border-light_gray rounded-xl overflow-hidden"
                />
                {errors.fullName && (
                  <p className="error">{errors.fullName?.message}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="font-normal text-3xl">Email</label>
                <input
                  type="text"
                  defaultValue={userEmail}
                  placeholder="Enter Email"
                  {...register("email")}
                  disabled={error ? false : true}
                  className="h-24 w-full !bg-transparent flex-1 mt-4 text-4xl px-8  focus:shadow-white focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-0 flex items-center bg-white  border border-light_gray rounded-xl overflow-hidden"
                />
                {errors.email && (
                  <p className="error">{errors.email?.message}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="font-normal text-3xl">Phone</label>

                <input
                  type="text"
                  value={phone}
                  {...register("phone")}
                  placeholder="Enter Phone Number"
                  className="h-24 w-full !bg-transparent flex-1 mt-4 text-4xl px-8  focus:shadow-white focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-0 flex items-center bg-white  border border-light_gray rounded-xl overflow-hidden"
                  onChange={handleNumber}
                />
                {errors.phone && (
                  <p className="error">{errors.phone?.message}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="font-normal text-3xl">Password</label>
                <div className="flex relative">
                  <input
                    type={newPassword ? "password" : "text"}
                    {...register("password")}
                    placeholder="Enter Password"
                    className="h-24 w-full !bg-transparent flex-1 mt-4 text-4xl px-8  focus:shadow-white focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-0 flex items-center bg-white  border border-light_gray rounded-xl overflow-hidden"
                  />
                  <div
                    className="inline absolute right-8 top-10"
                    onClick={() => setNewPassword(!newPassword)}
                  >
                    <img
                      alt=""
                      className="w-[2.4rem] h-[2.4rem]"
                      src={newPassword ? EyeIcon : EyeShutIcon}
                    />
                  </div>
                </div>
                {errors.password && (
                  <p className="error">{errors.password?.message}</p>
                )}
              </div>

              <div className="mb-4">
                <label className="font-normal text-3xl">Confirm Password</label>
                <div className="flex relative">
                  <input
                    type={confirmNewPassword ? "password" : "text"}
                    {...register("confirmPassword")}
                    placeholder="Enter Confirm Password"
                    className="h-24 w-full !bg-transparent flex-1 mt-4 text-4xl px-8  focus:shadow-white focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-0 flex items-center bg-white  border border-light_gray rounded-xl overflow-hidden"
                  />
                  <div
                    className="inline absolute right-8 top-10"
                    onClick={() => setConfirmNewPassword(!confirmNewPassword)}
                  >
                    <img
                      alt=""
                      className="w-[2.4rem] h-[2.4rem]"
                      src={confirmNewPassword ? EyeIcon : EyeShutIcon}
                    />
                  </div>
                </div>

                {errors.confirmPassword && (
                  <p className="error">{errors.confirmPassword?.message}</p>
                )}
              </div>

              <div className="mb-4">
                <label className="font-normal text-3xl">Company</label>
                <input
                  type="text"
                  {...register("company")}
                  placeholder="Enter Company"
                  className="h-24 w-full !bg-transparent flex-1 mt-4 text-4xl px-8  focus:shadow-white focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-0 flex items-center bg-white  border border-light_gray rounded-xl overflow-hidden"
                />
              </div>
              <div className="mb-4">
                <label className="font-normal text-3xl">Facebook</label>
                <input
                  type="text"
                  {...register("facebook", {
                    onChange: (e: any) => setFacebookUrl(e.target.value),
                  })}
                  placeholder="Enter Facebook"
                  className="h-24 w-full !bg-transparent flex-1 mt-4 text-4xl px-8  focus:shadow-white focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-0 flex items-center bg-white  border border-light_gray rounded-xl overflow-hidden"
                />
              </div>
              <div className="mb-4">
                <label className="font-normal text-3xl">Instagram</label>
                <input
                  type="text"
                  {...register("instagram")}
                  placeholder="Enter Instagram Username"
                  className="h-24 w-full !bg-transparent flex-1 mt-4 text-4xl px-8  focus:shadow-white focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-0 flex items-center bg-white  border border-light_gray rounded-xl overflow-hidden"
                />
              </div>
              <div className="mb-4">
                <label className="font-normal text-3xl">Twitter</label>
                <input
                  type="text"
                  {...register("twitter", {
                    onChange: (e: any) => setTwitterUrl(e.target.value),
                  })}
                  placeholder="Enter Twitter"
                  className="h-24 w-full !bg-transparent flex-1 mt-4 text-4xl px-8  focus:shadow-white focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-0 flex items-center bg-white  border border-light_gray rounded-xl overflow-hidden"
                />
              </div>

              <PrimaryButton
                text="Register"
                type="submit"
                color="primary"
                loading={loading}
              />
              <div className="text-center">
                <p className="font-normal text-3xl mb-2 mt-20">
                  Already have an account?{" "}
                  <span
                    className="text-primary_blue font-bold cursor-pointer"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
