import React from "react";
import PlusIcon from "assets/icons/plus-icon.svg";
import { useNavigate } from "react-router-dom";

interface BtnProps {
  link: string
}

const CreateButton = ({ link }: BtnProps) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(link)}
      className="w-[15rem] h-[5rem] gap-2 flex items-center justify-center text-white text-[2.4rem] border bg-primary_blue font-semibold  hover:tex-primary_light rounded-[0.61rem]"
    >
      <img className="w-[1.8rem] mt-[2px]" src={PlusIcon}></img>
      Create
    </button>
  );
};

export default CreateButton;
