import Footer from "./Footer";
import Header from "./Header";


const Layout = ({ children }: any) => {
	return (
		<div>
			<Header />

			<main className="pt-[10.4rem] bg-[#202F39]">
				<div className="">{children}</div>
			</main>
			<Footer />
		</div>
	);
};

export default Layout;
