import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import {
  useGetLegacyStatementsQuery,
} from "apis/client";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/icons/swot_edit.svg";
import LegacyTabs from "pages/Coach/LegacyStatement/LegacyTabs";
import CreateLegacyForm from "./LegacyCreate/index";

const Index = () => {
  const navigate = useNavigate();

  const {
    data = [],
    isLoading,
  } = useGetLegacyStatementsQuery(0, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const sortOrder = [
    'my_purpose',
    'i_am',
    'i_will',
    'never_again',
    'core_values',
    'reminders',
    'quote',
  ];

  // destructedLegacyData   into object of heading and description
  const destructedLegacyData =
    data &&
    data?.data?.Legacy?.map(({ heading, description, id, category }: any) => ({
      heading,
      description,
      id,
      category,
    }));

  function customCompare(a: any, b: any) {
    const indexA = sortOrder?.indexOf(a?.category);
    const indexB = sortOrder?.indexOf(b?.category);
    return indexA - indexB;
  }

  const sortedArray = destructedLegacyData?.sort(customCompare);

  const renderLegacy = (): any => {
    if (isLoading) {
      return (
        <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
          <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
        </div>
      )
    }
    if (data?.data?.Legacy.length > 0) {
      return <LegacyTabs data={sortedArray} />;
    } else {
      return <CreateLegacyForm />;
    }
  };

  function handleNavigate() {
    navigate("/client/legacy-statement/edit");
  }
  
  return (
    <Layout>
      <Hero image={ClientHeroImage} subHeading="My Champions Legacy" />
      <div className="container mx-auto pb-[8rem] px-10 desktop:px-10">
        {data?.data?.Legacy.length > 0 && (
          <div className="flex sm:justify-end justify-start mt-12">
            <button
              onClick={handleNavigate}
              className="flex items-center bg-[#2165A2] px-12 py-4 gap-3 rounded-2xl"
            >
              <div className="w-[4rem]">
                <img src={EditIcon} alt="" />
              </div>
              <span className="text-white text-6xl font-medium">
                Edit
              </span>
            </button>
          </div>
        )}
        {renderLegacy()}
      </div>
    </Layout>
  );
};

export default Index;
