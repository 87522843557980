import { useState, useEffect } from "react";
import { getGHLQuestions } from "apis/client-apis";

const BoardingPage = ({item,answers}:any) => {

  const [CustomField, setCustomField]: any = useState({});
  useEffect(() => {
    getGHLQuestions(item).then((res) => {
      setCustomField(res?.data?.data);
    });
  }, [item]);
 
  return (
    <>
        <div className="border-2 border-[#fff] rounded-xl mb-[3.3rem]">
          <div className="bg-[#ffffff] py-[2.2rem] px-[2rem]">
            <h3 className="text-[#2165A2] font-medium text-[2.8rem] sm:text-[3.4rem] leading-[4.3rem]">
             {CustomField && CustomField?.name}
            </h3>
          </div>
          <div className="bg-[#333333] py-[2.4rem] px-[2.3rem] ">
            <h3 className="text-[#fff] text-[2.6rem] sm:text-[3.2rem] leading-[3.8rem]">
            {answers && answers[CustomField?.id]}
            </h3>
          </div>
        </div>
   
    </>
  );
};

export default BoardingPage;
