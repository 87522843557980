import React from "react";
import { Tab } from "@headlessui/react";
import TabContent from "./TabContent";

const LegacyTabs = () => {
  const TabContents = [
    { detail: "My Purpose" },
    { detail: "Core Values" },
    { detail: "Never Again" },
    { detail: "Quote" },
  ];

  return (
    <Tab.Group>
      <div>
        <Tab.List className="flex justify-between flex-row items-start sm:gap-6 gap-[0.56rem]">
          {TabContents?.map((item, index) => {
            return (
              <Tab
                key={index}
                className={({ selected }) =>
                  selected
                    ? "text-white border-[0.1rem] sm:border-[0.3rem] border-[#fff] bg-[#299AFF] outline-none w-[9.46rem] sm:w-auto flex-1 font-bold sm:rounded-[1.2rem] rounded-[0.4rem] h-[9.4rem] sm:h-[27.7rem]"
                    : "h-[9.4rem] sm:h-[27.7rem] text-white border-[0.3rem] border-primary_blue bg-[#2165A2] flex-1 outline-none sm:rounded-[1.2rem] rounded-[0.4rem] w-[9.46rem] sm:w-auto"
                }
              >
                <div className="flex flex-col">
                  <h5 className="sm:text-[3.7rem] text-[1.2rem] font-medium sm:px-2">
                    {item.detail}
                  </h5>
                </div>
              </Tab>
            );
          })}
        </Tab.List>
      </div>
      <Tab.Panels>
        {TabContents.map((item, index) => (
          <Tab.Panel key={index} className="text-white">
            <TabContent heading={item.detail} />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default LegacyTabs;
