import { useState } from "react";
import AuthLayout from "layouts/AuthLayout";
import PrimaryButton from "components/common/PrimaryButton";
import { useNavigate } from "react-router-dom";
import TextInput from "components/common/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { signInAsync } from "store/reducers/auth.reducer";
import { setCheckedType } from "store/slices/auth.slice";
import { toast } from "react-toastify";

interface IFormInputs {
  email: string;
  password: string;
}

const schema = yup.object({
  email: yup.string().trim().email("Invalid Email format").required("Email  required"),
  password: yup.string().required("Password  required").min(6),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLaoding] = useState(false);
  const [checked, setChecked] = useState<string>("client");

  const handleChange = (type: string) => {
    dispatch(setCheckedType(type));
    setChecked(type);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFormInputs) => {
    const showError = (errorMessage: string) => {
      toast.error(errorMessage);
      return "";
    };
    setLaoding(true);
    dispatch(signInAsync(data.email.trim(), data.password, checked, showError))
      .then(() => {
        setLaoding(false);
      })
      .catch((error: any) => {
        setLaoding(false);
        toast.error(error.message);
      });
  };

  return (
    <AuthLayout>
      <div className="bg-white mt-[10rem] rounded-2xl px-12 py-16 sm:px-36 auth_box">
        <h1 className="text-5xl font-bold mb-2">Welcome!</h1>
        <p className="text-5xl font-normal mb-12">Log In to Continue</p>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="mb-4">
            <TextInput
              name="email"
              type="text"
              label="Email"
              register={register}
              palceholder="Enter Email"
            />
            {errors.email && <p className="error">{errors.email?.message}</p>}
          </div>
          <div className="mb-4">
            <TextInput
              name="password"
              type="password"
              label="Password"
              register={register}
              palceholder="Enter Password"
            />
            {errors.password && (
              <p className="error">{errors.password?.message}</p>
            )}
          </div>
          <div className="mt-[1rem] mb-[1rem] flex gap-[2rem] items-center">
            <h3 className="text-[1.8rem]">Log In as</h3>
            <div className="flex gap-[2rem] items-center">
              <div className="flex gap-[0.675rem]  items-center">
                <label className="checkbox mb-[0.3rem]">
                  <input
                    value="client"
                    checked={checked === "client" ? true : false}
                    onChange={() => {
                      handleChange("client");
                    }}
                    type="checkbox"
                  />
                  <div className="checkbox__indicator"></div>
                </label>
                <h3 className="text-[1.8rem]">Client</h3>
              </div>
              <div className="flex gap-[0.675rem] items-center">
                <label className="checkbox mb-[0.3rem]">
                  <input
                    value="coach"
                    checked={checked === "coach" ? true : false}
                    onChange={() => {
                      handleChange("coach");
                    }}
                    type="checkbox"
                  />
                  <div className="checkbox__indicator"></div>
                </label>
                <h3 className="text-[1.8rem]">Coach</h3>
              </div>
            </div>
          </div>
          <PrimaryButton
            loading={loading}
            text="Continue"
            type="submit"
            color="primary"
          />
          <div className="text-start">
            <p
              onClick={() => navigate("/reset-password")}
              className="font-normal text-3xl mb-4 cursor-pointer"
            >
              Forgot Password?
            </p>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
