import { useParams } from "react-router-dom";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import { useCreateNoteMutation } from "apis/coach";

interface IFormInputs {
  date: string;
  note: string;
}

const schema = yup.object().shape({
  date: yup.string().required("Required"),
  note: yup.string().required(" Required"),
});

const CreateNewNote = ({ setNewNoteItem }: any) => {
  const [date, setDate] = useState(new Date());
  const [createNote, { isLoading }] = useCreateNoteMutation();
  const { id } = useParams();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      date: new Date().toString(),
    }
  });

  const handleDate = (date: moment.MomentInput) => {
    const value = moment(date).format("MM/DD/YYYY");
    if (date) {
      setDate(new Date(value));
      setValue("date", moment(new Date(value)).format("MM/DD/YYYY"));
    }

  };

  const onSubmit = async (data: IFormInputs) => {
    const payload = {
      ...data,
      clientId: id,
    };

    await createNote(payload)
      .unwrap()
      .then((res: any) => {
        toast.success(res?.message);
        setNewNoteItem(false);
      })
      .catch(() => {
        toast.error("Note title already exists")
      });
  };

  return (
    <>
      <div>
        <h3 className="font-bold text-white text-[4rem]">Create Note</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="lg:mt-[3rem] mt-8 max-w-[53.3rem] mx-auto">
            <div className="mb-[3.4rem]">
              <label className="text-white text-[2.4rem] leading-none font-normal text-start mb-4">
                Date
              </label>

              <DatePicker
                className="input-form-calendar  !placeholder-[#B4B4B4]  !h-24 !max-w-full !min-w-full  !border-[1.5px] focus:outline-none !text-4xl"
                //  value={date}
                {...register("date")}
                selected={new Date(date)}
                onChange={(date) => handleDate(date)}
                //  dateFormat="mm/dd/yyyy"
                minDate={new Date()}
                placeholderText={"Enter Date"}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              {errors?.date && <p className="error">{errors.date?.message}</p>}
            </div>
            <label className="text-white text-[2.4rem] leading-none font-normal text-start mb-4">
              Note Text
            </label>
            <textarea
              style={{ background: "transparent" }}
              rows={5}
              {...register("note")}
              // cols="50"
              className="text-white p-[2rem] w-full rounded-xl text-4xl px-[1.4rem] border-[1.5px] border-white mt-[1rem] text-left placeholder:text-[#B4B4B4] focus:outline-none"
              placeholder="Enter Note..."
            />
            {errors.note && <p className="error">{errors.note?.message}</p>}

            <div className="mt-[4.4rem]">
              <button
                disabled={isLoading}
                type="submit"
                className="h-24 w-full px-20 bg-primary_blue text-[2.6rem] rounded-xl"
                style={{ background: isLoading ? "grey" : "#2165A2" }}
              >
                {isLoading ? "Loading..." : "Add Note"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNewNote;
