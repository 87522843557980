import { useState } from "react";
import { Tab } from "@headlessui/react";
import TabContent from "./TabContent";

const SwotTabs = ({ data ,swot}: any) => {
  const [selected, setSelected] = useState("Strength");

  function handleSelect(e: any, selected: any) {
    setSelected(selected);
  }
  
  return (
    <Tab.Group>
      <div className="mt-[4.2rem] mb-[5rem] ">
        <Tab.List className="flex flex-row items-start sm:gap-[1.554rem] gap-[0.56rem] overflow-x-auto">
          {Object.keys(data)?.map((item) => {
            return (
              <Tab
                key={item}
                onClick={(e: any) => handleSelect(e, item)}
                className={({ selected }) =>
                  selected
                    ? "text-white border-[0.310762rem] sm:border-[0.310762rem] border-[#fff] bg-[#299AFF] outline-none sm:h-[24.8rem] h-[18rem]  flex-1 font-bold sm:rounded-[1.24305rem] rounded-[0.64524rem] min-w-[20rem] sm:min-w-[27rem] lg:min-w-[25rem]"
                    : "text-white border-[0.16131rem] border-primary_blue bg-[#2165A2] flex-1 outline-none sm:rounded-[1.24305rem] rounded-[1.24305rem]  min-h-[18rem] sm:h-[24.8rem] min-w-[20rem] sm:min-w-[27rem] lg:min-w-[25rem]"
                }
              >
                {swot ? <div className="flex flex-col">
                  <h4 className=" text-[5rem] leading-[5rem] sm:text-[15.5rem] sm:leading-[15.5rem] capitalize font-medium">
                    {item.charAt(0)}
                  </h4>
                  <h4 className="text-[3.226rem] m-auto w-[80%]  text-center leading-[3.781rem] truncate font-medium">
                    {item.toUpperCase()}
                  </h4>
                </div>:
                 <h4 className="text-[3.226rem] m-auto w-[80%]  text-center leading-[3.781rem] truncate font-medium">
                    {item.toUpperCase()}
                  </h4> 
                  }
              </Tab>
            );
          })}
        </Tab.List>
      </div>
      <Tab.Panels>
        {Object.values(data).map((item, index) => (
          <Tab.Panel key={index} className="text-white">
            <TabContent heading={item} selected={selected} />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default SwotTabs;
