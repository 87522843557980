import { useState } from "react";
import UploadVideoIcon from "../../../../assets/icons/UploadVideoIcon.svg";
import UploadImgIcon from "../../../../assets/icons/UploadImgIcon.svg";
import Percentage from "./Percentage";
import UploadBox from "./UploadBox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import apiEndpoint from "config/environment";
import { toast } from "react-toastify";
import { useForm, SubmitHandler } from "react-hook-form";
import { isFetchBaseQueryError } from "apis/client";
import { useCreateCallMutation } from "apis/coach";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";

interface IFormInputs {
  image: string;
  video: string;
  title: string;
  detail: string;
  receiverId: string;

}

const schema = yup.object().shape({
  image: yup.string().required("Required"),
  video: yup.string().required("Required"),
  title: yup.string().required("Required"),
  detail: yup.string().required("Required"),

});

const LibraryTabContent = ({ setRecordingBtn }: any) => {
  const [createCall, { isLoading }] = useCreateCallMutation();
  const { id } = useParams();
  const [file, setFile] = useState("")
  const [videoName, setVideoName] = useState("")
  const [progress, setProgress] = useState(0)
  const [progressVideo, setProgressVideo] = useState(0)

  const [image, setImage] = useState({
    name: "",
    file: {},
    isUploading: false,
    url: ""
  });

  const [video, setVideo] = useState({
    name: "",
    file: {},
    isUploading: false,
    url: ""
  });


  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {

    const payload = {
      callType: "call",
      title: data.title,
      thumbnail: data.image,
      file: data.video,
      detail: data.detail,
      receiverId: id
    };

    try {
      await createCall(payload).unwrap().then((res) => {
        toast.success(res?.message)
        setRecordingBtn(true)

      });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        setRecordingBtn(true)
        toast.error(error?.data?.message);
      }
    }
  };

  const handleUploadImage = async (e: any) => {
    setProgress(0)
    setFile("")
    if (!e.target.files) {
      return;
    }
    const file = e.target?.files[0];
    setImage({
      ...image,
      name: file.name,
      file: file,
      isUploading: true
    });
    setFile(file.name)
    const formData = new FormData();
    formData.append("image", file);

    const promise = axios.request({
      method: "POST",
      url: `${apiEndpoint}/upload-one`,
      data: formData
    }).then((res) => {
      setProgress(100)
      setImage({
        ...image,
        isUploading: false,
        url: res.data
      });
      setValue("image", res.data);
      trigger("image");
    }).catch(() => {
      setProgress(0)
      setImage({
        ...image,
        isUploading: false
      });
    });

    await toast.promise(promise, {
      pending: "Uploading Thumbnail",
      success: "Thumbnail Uploaded",
      error: "Error Uploading Thumbnail",
    });
  };

  const handleUploadVideo = async (e: any) => {
    setProgressVideo(0)
    setVideoName("")
    if (!e.target.files) {
      return;
    }
    const file = e.target?.files[0];
    setVideo({
      ...video,
      name: file.name,
      file: file,
      isUploading: true
    });
    setVideoName(file?.name)

    const formData = new FormData();
    formData.append("video", file);

    const promise = axios.request({
      method: "POST",
      url: `${apiEndpoint}/upload-video`,
      data: formData
    }).then((res) => {
      setProgressVideo(100)
      setVideo({
        ...video,
        isUploading: false,
        url: res.data
      });
      setValue("video", res.data);
      trigger("video");
    }).catch(() => {
      setProgressVideo(0)
      setVideo({
        ...video,
        isUploading: false
      });
    });

    await toast.promise(promise, {
      pending: "Uploading Video",
      success: "Video Uploaded",
      error: "Error Uploading Video",
    });

  };

  return (
    <div className="max-w-[98rem]">
      <h1 className=" text-white text-[3rem] mt-[5rem] font-bold leading-[3.5rem] text-left">
        Upload Call Recording
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-[6rem]">
          <h1 className=" text-white text-[3.2rem]  font-semibold leading-[4.7rem] text-left">
            Title
          </h1>
          <input
            className="w-full mobile_l:w-[53rem] h-24 mt-[0.6rem] rounded-xl px-[2.6rem] py-[2.8rem] text-[2.4rem] text-[#333333] leading-[3.2rem] ] placeholder:text-[#BFBFBF] border-none focus:outline-none"
            placeholder="Enter Title"
            {...register("title")}
          />
        </div>
        {errors?.title?.message ? (
          <p className=" text-[red] text-[1.8rem] mt-3"> {errors.title.message} </p>
        ) : null}
        <div className="flex flex-col sm:flex-row sm:gap-10 sm:justify-between">
          <div>
            <UploadBox
              title="Browse Video"
              name="video"
              accept="video/*"
              type="file"
              onChange={handleUploadVideo}
              src={UploadVideoIcon}
              register={register}

            />
            {errors?.video?.message ? (
              <p className=" text-[red] text-[1.8rem] mt-3"> {errors.video.message} </p>
            ) : null}
            {progressVideo === 100 &&
              <Percentage
                percentage={progressVideo}
                name={videoName}
                status="Completed"
              />}
            {video?.url && (
              <div className="bg-[#323232] w-full h-[26rem] mt-14 rounded-[6px] overflow-hidden">
                <ReactPlayer
                  url={video?.url}
                  width="44rem"
                  height="26rem"
                  playing={false}
                  controls={true}
                />
              </div>
            )}
          </div>
          <div>
            <UploadBox
              title="Browse Thumbnail"
              name="image"
              accept="image/*"
              type="file"
              onChange={handleUploadImage}
              src={UploadImgIcon}
              register={register}
            />
            {errors?.image?.message ? (
              <p className=" text-[red] text-[1.8rem] mt-3"> {errors.image.message} </p>
            ) : null}
            {progress === 100 && <Percentage
              percentage={progress}
              name={file}
              status="Completed"
            />}
            {image?.url && (
              <div className="bg-[#fff] w-full h-[26rem] mt-14 rounded-[6px] overflow-hidden">
                <img src={image?.url} className="w-[44rem] h-full object-cover"></img>
              </div>
            )}
          </div>
        </div>
        <div className="mt-[8.2rem]">
          <h1 className=" text-white text-[3.2rem] leading-[3.9rem]">Details</h1>
          <textarea
            rows={5}
            placeholder="Enter Description"
            {...register("detail")}
            className="!bg-darkgrey p-[1.8rem] w-full mobile_l:w-[67.4rem] rounded-xl mt-[2rem] border-2 border-[white] text-[2.8rem] leading-[3.2rem] text-white placeholder:text-[#B4B4B4] focus:outline-double"
          />
        </div>
        {errors?.detail?.message ? (
          <p className=" text-[red] text-[1.8rem] mt-3"> {errors.detail.message} </p>
        ) : null}
        <div className='mt-[4.4rem]'>
          <button type="submit"
            style={{ background: isLoading ? "grey" : "#2165A2" }}
            disabled={isLoading || video.isUploading || image.isUploading} className='h-24 w-full mobile_l:w-[50rem]  px-20 bg-primary_blue text-[2.6rem] rounded-xl'>
            {isLoading ? "Loading..." : "Upload Call "}</button>
        </div>
      </form>
    </div>
  );
};

export default LibraryTabContent;
