import { setToken, setUser, setIsOnboarded, setCheckedIn } from "../slices/auth.slice";
import { postRequest } from "../../config/axiosConfig";
import { setUserProfile } from "store/slices/client.slice";

export function signInAsync(
  email: string,
  password: string,
  type: string,
  showError: (errorMessage: string) => {}
): any {
  return async (dispatch: any) => {
    try {
      const result = await postRequest("login", {
        email,
        password,
      });
      if (result?.data?.user?.role?.includes("admin")) {
        throw Error("User is Not Authorized");
      } else {
        const pageToNavigate = result.data?.user?.role?.includes(type);
        if (pageToNavigate) {
          dispatch(setToken(result.data.token.accessToken));
          dispatch(setUser(result.data.user));
          dispatch(setIsOnboarded(result.data.user.isOnboarded));
          dispatch(setCheckedIn(result.data.user.isCheckIn))
        } else {
          showError(
            `The current user is not associated with any ${type} account`
          );
        }
      }
    } catch (error: any) {
      const errMsg = error?.response?.data
        ? error?.response?.data?.message
        : error;
      throw Error(errMsg);
    }
  };
}

export function logoutAsync(): any {
  return async (dispatch: any) => {
    dispatch(setToken(""));
    dispatch(setUser({}));
    dispatch(setUserProfile({}));
    dispatch({ type: "signout/logout" });
    return true;
  };
}
