import {useSelector} from 'react-redux';
import {selectToken, selectUser} from 'store/selectors';

export default function useAuth() {
 const token = useSelector(selectToken);
 const user = useSelector(selectUser);
 const {checkedType} = useSelector((state: any) => state.auth);

 return {
  token: token,
  path: '/login',
  user: user,
  checkedType,
 };
}
