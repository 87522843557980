import {
  getRequest,
  postRequest,
  patchRequest,
  // deleteRequest,
} from "config/axiosConfig";
import { setUserProfile } from "store/slices/client.slice";
import { toast } from "react-toastify";

// Update Client
export async function updateClient(
  data: any,
  dispatch: any,
  navigate: any,
  setIsloading: any
) {
  let result = null;
  try {
    setIsloading(true);
    result = await patchRequest("client/update", {
      ...data,
    });
    if (result?.status == 200) {
      dispatch(setUserProfile(result?.data?.user));
      toast.success(result?.message);
      navigate("/client/account");
      setIsloading(false);
    } else {
      toast.error("Something Wrong");
      setIsloading(false);
    }
  } catch (error: any) {}
  return result;
}

// Create Tasks
export async function createTask(
  updatedData: any,
  setLoading: any,
  navigate: any
) {
  let result = null;
  try {
    setLoading(true);
    result = await postRequest("tasks/create", {
      ...updatedData,
    });
    if (result?.status == 201) {
      toast.success(result?.message);
      navigate("/client");
      setLoading(false);
    } else {
      toast.error("Something Wrong");
      setLoading(false);
    }
  } catch (error: any) {}
  return result;
}

//get all Champions Legacy
export async function GetAllLegacyStatement(setLoading: any) {
  let result = null;
  try {
    setLoading(true);
    result = await getRequest("client/legacy");
    if (result?.status === 200) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  } catch (error: any) {}
  return result;
}

//create Champions Legacy
export async function CreateLegacyStatement(
  payload: any,
  setLoading: any,
  navigate: any
) {
  let result = null;
  try {
    result = await postRequest("client/legacy/create", {
      ...payload,
    });
    setLoading(false);

    toast.success(result?.message);
    navigate("/client/legacy-statement");
  } catch (error: any) {
    setLoading(false);
    if (error?.response && error?.response?.data) {
      toast.error(error?.response?.data?.message);
    }
  }
  return result;
}

//delete Champions Legacy
// export async function DeleteLegacyStatement(id: any) {
//   alert("hello");
//   let result = null;
//   try {
//     result = await deleteRequest(`client/legacy/${id}`);
//     toast.success(result?.message);
//   } catch (error: any) {
//     console.log(error);
//   }
//   return result;
// }

//update Champions Legacy
export async function UpdateLegacyStatement(
  id: any,
  payload: any,
  setLoading: any
) {
  let result = null;
  try {
    setLoading(true);
    result = await patchRequest(`client/legacy/${id}`, {
      ...payload,
    });
    if (result?.status === 200) {
      toast.success("Champions Legacy updated!");
      setLoading(false);
    } else {
      toast.error("Something went Wrong!");
      setLoading(false);
    }
  } catch (error: any) {
    setLoading(false);
  }
  return result;
}

//get client
export async function getClient(id: any) {
  const result = await getRequest(`user/${id}`);
  return result;
}
//start call
export async function ClientJoinCall(payload: any) {
  const result = await postRequest("client/call/join-call", {
    ...payload,
  });
  return result;
}
//start huddle call
export async function joinClientHuddleCall(payload: any) {
  const result = await postRequest("client/call/huddle/join-call", {
    ...payload,
  });
  return result;
}
//exit call
export async function ClientExitCall(payload: any) {
  const result = await postRequest("client/call/call-logout", {
    ...payload,
  });
  return result;
}

//get ghl questions
export async function getGHLQuestions(id: any) {
  const result = await getRequest(`resources/ghl/${id}`);
  return result;
}
