import { Tab } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function FormTabs({ setTab,tab }: any) {
  const categories = [
    {
      title: "Onboarding",
      value: 0,
    },
    {
      title: "Weekly Check-In",
      value: 1,
    },
    {
      title: "Champions Legacy",
      value: 2,
    },
    {
      title: "SWOT Analysis",
      value: 3,
    },
  ];

  return (
    <div className="w-full sm:px-0">
      <Tab.Group selectedIndex={tab}
        onChange={(index) => setTab(index)}
      >
        <Tab.List className="flex space-x-1 rounded-[0.9rem] p-2 bg-blue-900/20 border border-white">
          {categories.map((category, index) => (
            <Tab
              key={index}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg  text-[1.6rem] leading-[1.2] font-medium text-blue-700",
                  "ring-none focus:outline-none",
                  selected ? "bg-primary_blue text-white py-2.5" : "text-white"
                )
              }
            >
              {category.title}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
}
