import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import { useSelector } from "react-redux";
import { selectUser } from "store/selectors";
import { useGetCallsQuery, useGetHuddleCallsQuery } from "apis/client";
import ScheduledCallsCard from "components/ScheduledCallsCard";

const ClientScheduledCalls = () => {
  const user = useSelector(selectUser);
  const { data } = useGetCallsQuery(user?.id, {
    refetchOnMountOrArgChange: true,
  });
  const { data: huddleCall } = useGetHuddleCallsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const calls = data?.data?.calls.map((item: any) => {
    const date = item?.date ? item?.date : item?.createdAt;
    const temp = new Date(date).getTime() / 1000;
    return {
      ...item,
      filterDate: temp
    }
  })?.sort((a: any, b: any) => a?.filterDate - b?.filterDate) || [];
  const scheduleCallsAdmin = huddleCall?.data?.calls;

  return (
    <Layout>
      <Hero image={ClientHeroImage} />
      <div className="container mx-auto py-24 px-10 desktop:px-10">
        <h1 className="text-white text-[3rem] sm:text-[4rem] text-center lg:text-left leading-none font-medium mt-0">
          Scheduled Calls
        </h1>
        {!calls?.length && (
          <div className="bg-white px-4 py-14 mt-[1rem] rounded-md">
            <h2 className="text-primary_blue text-center text-4xl sm:text-5xl font-bold">
              You Have No Scheduled Calls.
            </h2>
          </div>
        )}

        {calls?.length && (
          <>
            <div className="grid grid-cols-1 gap-11 lg:grid-cols-3 md:grid-cols-2 mt-[6rem]">
              {calls?.map((item: any) => {
                return (
                  <div key={item?.id}>
                    <ScheduledCallsCard
                      dateCreated={item?.date}
                      roomId={item?.roomId}
                      btntext="join"
                      callType={item?.callType ? item?.callType : "call"}
                      title={item?.title}
                      duration={item?.duration}
                      joinUrl={item?.join_url}
                      meetingId={item?.meeting_id}
                      coachName={item?.coach?.full_name}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}

        <h1 className="text-white text-[3rem] sm:text-[4rem] text-center lg:text-left leading-none font-medium mt-[4rem]">
          Scheduled Huddle Calls
        </h1>
        {scheduleCallsAdmin?.length === 0 && (
          <div className="bg-white px-4 py-14 mt-[3rem] rounded-md">
            <h2 className="text-primary_blue text-center text-4xl sm:text-5xl font-bold">
              You Have No Scheduled Calls.
            </h2>
          </div>
        )}
        {scheduleCallsAdmin?.length && (
          <div>
            <div className="grid grid-cols-1 gap-11 lg:grid-cols-3 md:grid-cols-2 mt-[6rem]">
              {scheduleCallsAdmin?.map((item: any, index: number) => {
                return (
                  <div key={index}>
                    <ScheduledCallsCard
                      dateCreated={item?.date}
                      roomId={item?.roomId}
                      btntext="join"
                      callType={"huddle"}
                      title={item?.title}
                      duration={item?.duration}
                      joinUrl={item?.join_url}
                      meetingId={item?.meeting_id}
                      coachName={item?.coach?.full_name}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ClientScheduledCalls;
