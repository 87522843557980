import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import FirstQuestion from "./components/Question";
import { useState } from "react";
import {
  useCreateCheckinFormMutation,
  useGetActiveCheckinFormQuery,
  useGetCheckinFormQuery
} from "apis/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setCheckedIn } from "store/slices/auth.slice";
import { useDispatch } from "react-redux";

const EditCheckIn = () => {
  const [newArray, setNewArray] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: checkInQues, isError } = useGetActiveCheckinFormQuery(1, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  });

  const { data: checkInAns } = useGetCheckinFormQuery(1, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  });

  const form = checkInQues?.data;
  const questions = form?.checkInFormQuestions;
  const answers = checkInAns?.data;

  // const answers = data?.data;
  const isLastStep = questions?.length;
  const [createCheckinForm, {isLoading}] = useCreateCheckinFormMutation();

  const nextHandler = () => {
    if (page !== questions.length) {
      setPage(page + 1);
    }
  };

  const handleSave = (answers: any) => {
    const count = questions.length;
    setNewArray([
      ...newArray.filter(
        (item: any) => item?.questionId !== answers?.questionId
      ),
      answers,
    ]);

    if (count === page) {
      const tempArr = [...newArray];
      tempArr.push(answers);

      const uniquePayload = Array.from(new Set(tempArr?.map((obj:any) => obj.questionId))).map((questionId) => {
        return tempArr?.find((obj:any) => obj.questionId === questionId);
      });

      const payload = {
        checkInFormId: form?.id,
        answers: uniquePayload,
        action: "edit"
      };

      createCheckinForm(payload)
        .unwrap()
        .then((res: any) => {
          setLoader(false);
          dispatch(setCheckedIn(true));
          navigate("/client/checkin-form");
          toast.success(res?.message);
        })
        .catch((err) => {
          setLoader(false);
          if (err && err?.data) {
            toast.error(err?.data?.message);
          }
        });

      return;
    }
    nextHandler();
  };

  return (
    <Layout>
      <Hero image={ClientHeroImage} subHeading="Edit Client's Check-In Form" />
      <div className="container mx-auto px-10 desktop:px-10 pb-[5rem]">
        <div className="flex justify-between items-center sm:mb-[3.1rem] mt-7">
          <h2 className="text-[#fff] text-4xl sm:text-[4rem]  font-medium mb-[2rem] underline underline-offset-8">
            {!isError && form?.title}
          </h2>
        </div>
        <div>
          {isError ? questions && (
            <p className="break-normal bg-white px-[3rem] py-[2rem] rounded-[1rem] text-[2rem] text-[red]">
              {
                "Oops! It looks like there are no active forms for you to fill out at this time. Please check back later or contact customer support if you have any questions"
              }
            </p>
          ) :
            questions?.map((item: any, index: any) => {
              if (page === index + 1) {
                return (
                  <div key={index}>
                    <FirstQuestion
                      item={item}
                      arrayLength={questions.length}
                      handleSave={handleSave}
                      data={answers}
                      setPage={setPage}
                      page={page}
                      // isCompleted={isCompleted}
                      isLastStep={isLastStep}
                      nextHandler={nextHandler}
                      loading={loader}
                      isSavingData={isLoading}
                    />
                  </div>
                );
              }
            })
          }
        </div>
      </div>
    </Layout >
  );
};

export default EditCheckIn;
