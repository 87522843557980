import { useGetClientByIdQuery } from "apis/coach";
import ActionItemsCard from "pages/Client/Home/components/ActionItemsCard"
import EmptyCard from "pages/Client/Home/components/EmptyCard";
import { useParams } from "react-router";

function ClientChampionshipDay() {

    const { id } = useParams();

    const { data } = useGetClientByIdQuery(id, {
        refetchOnMountOrArgChange: true, refetchOnFocus: true
    });

    const user = data?.data?.user || {}
    const championships = user?.championships;
    const forMe = championships?.filter((championship) => championship?.category === "myself");
    const forMyBusiness = championships?.filter((championship) => championship?.category === "business");
    const forSomeoneElse = championships?.filter((championship) => championship?.category === "someone");

    return (
        <div>
            <h3 className="font-medium text-4xl leading-[3.5rem] py-11 text-white">
                For Me
            </h3>
            {forMe?.length === 0 ? (
                <EmptyCard title={"No championship day available for you"}/>
            ) : (
                forMe?.map((item) => (
                    <div key={item?.id}>
                        <ActionItemsCard
                            id={item?.id}
                            title={item?.title}
                            date={item?.createdAt}
                            isCompleted={item?.isCompleted}
                            completedAt={item?.completedAt}
                            createdBy={item?.createdBy}
                            updatedAt={item?.updatedAt}
                            coachSide
                        />
                    </div>
                ))
            )}
            <h3 className="font-medium text-4xl leading-[3.5rem] py-11 text-white">
                For My Business
            </h3>
            {forMyBusiness?.length === 0 ? (
                <EmptyCard title={"No championship day available for your business"}/>
            ) : (
                forMyBusiness?.map((item) => (
                    <div key={item?.id}>
                        <ActionItemsCard
                            id={item?.id}
                            title={item?.title}
                            date={item?.createdAt}
                            isCompleted={item?.isCompleted}
                            completedAt={item?.completedAt}
                            createdBy={item?.createdBy}
                            updatedAt={item?.updatedAt}
                            coachSide
                        />
                    </div>
                ))
            )}
            <h3 className="font-medium text-4xl leading-[3.5rem] py-11 text-white">
                For Someone Else
            </h3>
            {forSomeoneElse?.length === 0 ? (
                <EmptyCard title={"No championship day available for someone esle"}/>
            ) : (
                forSomeoneElse?.map((item) => (
                    <div key={item?.id}>
                        <ActionItemsCard
                            id={item?.id}
                            title={item?.title}
                            date={item?.createdAt}
                            isCompleted={item?.isCompleted}
                            completedAt={item?.completedAt}
                            createdBy={item?.createdBy}
                            updatedAt={item?.updatedAt}
                            coachSide
                        />
                    </div>
                ))
            )}
        </div>
    )
}

export default ClientChampionshipDay