import Hero from "components/Hero";
import Layout from "layouts/Layout";
import LockIcon from "assets/images/LockIcon.svg";
import CheckGreen from "assets/icons/check-green.svg";
import { useState } from "react";
import ClientHeroImage from "assets/images/client-hero.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { patchRequest } from "config/axiosConfig";
import EyeIcon from "assets/images/eye_cut.svg";
import EyeShutIcon from "assets/images/black-eye.svg";

interface IFormInputs {
  currentpassword: string;
  newpassword: string;
  confirmPassword: string;
}

const schema = yup.object({
  currentpassword: yup
    .string()
    .required("Enter current password")
    .min(6, "Current password must be at least 6 characters"),
  newpassword: yup
    .string()
    .required("Enter new password")
    .min(6, "New password must be at least 6 characters"),
  confirmPassword: yup
    .string()
    .required("Enter confirm password")
    .min(6, "Confirm password must be at least 6 characters")
    .oneOf([yup.ref("newpassword")], "Passwords do not match"),
});

const AccountResetPassword = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(true);
  const [newPassword, setNewPassword] = useState(true);
  const [confirmNewPassword, setConfirmNewPassword] = useState(true);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormInputs) => {
    // Update Password
    setLoading(true);
    try {
      const result = await patchRequest("coach/change-password", {
        currentPassword: data?.currentpassword,
        newPassword: data?.newpassword,
      });
      if (result?.status == 200) {
        setLoading(false);
        setShow(true);
      } else {
        setLoading(false);
        toast.error("Something Wrong");
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Layout>
      <Hero image={ClientHeroImage} />
      <div className="container mx-auto py-32 px-10 desktop:px-10">
        {!show ? (
          <form className="account_input" onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto max-w-[77.8rem] px-[3rem] sm:px-[6rem] tablet:px-[8rem] pt-[4.4rem] sm:pt-[6.4rem] tablet:pt-[7.4rem] pb-[8rem] bg-white rounded-[0.8rem]">
              <h1 className=" text-[#333333] text-[3.5rem] sm:text-[4.4rem] font-medium leading-[5.4rem] mb-[3rem] sm:mb-[4.4rem]">
                Reset Password
              </h1>
              <div className="w-full input_wrapper">
                <label className="!text-[2.2rem] sm:!text-[2.8rem]">
                  Current Password
                </label>
                <div className="flex relative">
                  <input
                    className="!h-24 !text-[2.25rem] placeholder:!text-[2.25rem] !rounded-[1rem]"
                    {...register("currentpassword")}
                    placeholder="Current Password"
                    type={currentPassword ? "password" : "text"}
                  />
                  <div
                    className="inline absolute right-8 top-10"
                    onClick={() => setCurrentPassword(!currentPassword)}
                  >
                    <img
                      className="w-[2.4rem] h-[2.4rem]"
                      src={currentPassword ? EyeShutIcon : EyeIcon}
                    />
                  </div>
                </div>
                {errors.currentpassword && (
                  <p className="error">{errors.currentpassword?.message}</p>
                )}
              </div>
              <div className="w-full input_wrapper !mt-[3rem]">
                <label className="!text-[2.2rem] sm:!text-[2.8rem]">
                  New Password
                </label>
                <div className="flex relative">
                  <input
                    className="!h-24 !text-[2.25rem] placeholder:!text-[2.25rem] !rounded-[1rem]"
                    {...register("newpassword")}
                    placeholder="New Password"
                    type={newPassword ? "password" : "text"}
                  />
                  <div
                    className="inline absolute right-8 top-10"
                    onClick={() => setNewPassword(!newPassword)}
                  >
                    <img
                      className="w-[2.4rem] h-[2.4rem]"
                      src={newPassword ? EyeShutIcon : EyeIcon}
                    />
                  </div>
                </div>
                {errors.newpassword && (
                  <p className="error">{errors.newpassword?.message}</p>
                )}
              </div>
              <div className="w-full input_wrapper !mt-[3rem]">
                <label className="!text-[2.2rem] sm:!text-[2.8rem]">
                  Confirm New Password
                </label>
                <div className="flex relative">
                  <input
                    className="!h-24 !text-[2.25rem] placeholder:!text-[2.25rem] !rounded-[1rem]"
                    {...register("confirmPassword")}
                    placeholder="Confirm New Password"
                    type={confirmNewPassword ? "password" : "text"}
                  />

                  <div
                    className="inline absolute right-8 top-10"
                    onClick={() => setConfirmNewPassword(!confirmNewPassword)}
                  >
                    <img
                      className="w-[2.4rem] h-[2.4rem]"
                      src={confirmNewPassword ? EyeShutIcon : EyeIcon}
                    />
                  </div>
                </div>
                {errors.confirmPassword && (
                  <p className="error">{errors.confirmPassword?.message}</p>
                )}
              </div>
              <button
                disabled={loading}
                style={{ background: loading ? "gray" : "#2165A2" }}
                className="w-full mt-[2.4rem] sm:mt-[9.4rem] h-24 rounded-[0.94rem] bg-[#2165A2] text-white text-[2.4rem] leading-[4.1rem] flex justify-center items-center gap-5"
              >
                {loading ? (
                  "Loading ..."
                ) : (
                  <>
                    <img className="w-[2rem]" src={LockIcon} alt="Lock Icon" />{" "}
                    Update Password
                  </>
                )}
              </button>
            </div>
          </form>
        ) : (
          <div className="mx-auto max-w-[77.8rem] h-[90rem]  bg-white rounded-[0.8rem] px-[6rem] tablet:px-[8rem] py-[6.4rem] tablet:py-[7.4rem]">
            <div className="flex flex-col items-center text-center mt-[5rem]">
              <img
                className="w-[16rem]"
                src={CheckGreen}
                alt="Check Green Icon"
              />
              <h1 className=" text-[#333333] text-[4.7rem] leading-[5.6rem] font-black mt-[4rem]">
                Password Changed!
              </h1>
              <h1 className=" text-[#333333] text-[3.7rem] leading-[4.4rem] mt-[7.6rem]">
                Your password has been changed successfully.
              </h1>

              <button
                className="text-primary_blue text-[2rem] mt-[4rem] underline "
                onClick={() => navigate("/coach/account")}
              >
                Back to Account
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AccountResetPassword;
