import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  useGetOnboardingGHLSubmissionQuery
} from "apis/client";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { socket } from "config/socket";
import GHLOnboardingComponent from "components/GHLForms/OnBoarding";

const EditOnboarding = () => {
  const { data: swotData } = useGetOnboardingGHLSubmissionQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const userId = useSelector((state: any) => state?.auth?.user?.id);
  const userData = useSelector((state: any) => state?.auth?.user);

  useEffect(() => {
    const navigateHandler = (data: any) => {
      if (data?.user?.isOnboarded == true) {
        window.location.href = "/client/onboarding";
      }
    };
    const reloadHandler = (data: any) => {

      if (data?.user == '' && data?.reload == true) {
        toast.error(data?.message);

      } else if (data?.user?.id !== userId) {
        toast.error(data?.message);

      }
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    };
    socket.on(`gohighLevel`, reloadHandler);
    socket.on(`onboarding${userId}`, navigateHandler);

    return () => {
      socket.off(`onboarding${userId}`, navigateHandler);
      socket.off(`gohighLevel`, reloadHandler);
    };
  }, [userId]);

  const answers = swotData?.data?.data;
  const data = { ...userData };
  data["answers"] = answers;

  return (
    <>
      <Layout>
        <Hero image={ClientHeroImage} subHeading="Onboarding Questionnaire" />
        <div className="container mx-auto py-32 px-10 desktop:px-0">
          <div className="bg-white pt-[3.5rem] pb-[4rem] rounded-[2.09733rem]">
            <div className="text-center">
              <h1 className="font-medium text-black text-[3rem] sm:text-[5rem]">
                Edit Onboarding Questionnaire
              </h1>
              <GHLOnboardingComponent userData={data} formData={answers} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EditOnboarding;

