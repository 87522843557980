import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen bg-[#CECECE] relative">
      <div className="w-screen h-screen flex flex-col items-center justify-center gap-[3rem]">
        <h1 className="text-[12rem] leading-[9rem] text-[#2165A2] font-extrabold">404</h1>
        <h1 className="text-[6rem] leading-[5rem] text-[#2165A2] font-medium">Page Not Found</h1>
        <button onClick={() => navigate("/login")} className="text-[1.8rem] text-[#2165A2] font-regular underline">Back to Home</button>
      </div>
    </div>
  )
}

export default NotFound