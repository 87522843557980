import { Menu } from "@headlessui/react";
import VerticalDots from "../../assets/images/VerticalDots.svg";
import { toast } from "react-toastify";
import { useDeleteNoteMutation } from "apis/coach";

const DeleteDropDown = ({ id, index, length }: any) => {
  const [deleteNote] = useDeleteNoteMutation();
  const zCount = length - index;
  const handleDelete = async () => {
    await deleteNote(id)
      .unwrap()
      .then((res: any) => {
        toast.success(res?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Menu
        style={{ zIndex: `${zCount}` }}
        as="div"
        className="relative inline-block text-left drop-shadow-lg"
      >
        <Menu.Button>
          <img
            src={VerticalDots}
            alt={"img"}
            className="m-auto mt-4 sm:max-w-full max-w-[80%]"
          />
        </Menu.Button>
        <Menu.Items className="absolute right-0 mt-2 w-[18rem] z-10 flex flex-col px-5 origin-top-right divide-y-4 divide-[#F5F5F5] drop-shadow-lg border-2 rounded-md bg-white focus:outline-none cursor-pointer">
          <Menu.Item
            as="a"
            className="py-5 text-[1.96115rem] text-primary_blue font-medium"
            onClick={handleDelete}
          >
            Delete
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </>
  );
};

export default DeleteDropDown;