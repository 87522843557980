import Hero from "components/Hero";
import Layout from "layouts/Layout";
import LockIcon from "assets/images/LockIcon.svg";
import { useState } from "react";
import ClientHeroImage from "assets/images/client-hero.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import EyeIcon from "assets/images/eye_cut.svg";
import EyeShutIcon from "assets/images/black-eye.svg";
import {
  useResetPasswordMutation,
  isFetchBaseQueryError,
} from "apis/client";

interface IFormInputs {
  currentpassword: string;
  newpassword: string;
  confirmPassword: string;
}

const schema = yup.object({
  currentpassword: yup
    .string()
    .required("Enter current password")
    .min(6, "Current password must be at least 6 characters"),
  newpassword: yup
    .string()
    .required("Enter new password")
    .min(6, "New password must be at least 6 characters"),
  confirmPassword: yup
    .string()
    .required("Enter confirm password")
    .min(6, "Confirm password must be at least 6 characters")
    .oneOf([yup.ref("newpassword")], "Passwords do not match"),
});

const AccountResetPassword = () => {
  const [resetPassword, { isLoading, isSuccess, data }] =
    useResetPasswordMutation();
  const [currentPassword, setCurrentPassword] = useState(true);
  const [newPassword, setNewPassword] = useState(true);
  const [confirmNewPassword, setConfirmNewPassword] = useState(true);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  if (isSuccess) {
    toast.success(data?.message);
    navigate("/client/account");
  }

  const onSubmit = async (data: IFormInputs) => {
    // Update Password
    const payload = {
      currentPassword: data?.currentpassword,
      newPassword: data?.newpassword,
    };
    try {
      await resetPassword(payload).unwrap();
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
      
    }
  };

  return (
    <Layout>
      <Hero image={ClientHeroImage} />
      <div className="container mx-auto py-32 px-10 desktop:px-10">
          <form className="account_input" onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto max-w-[77.8rem] px-[3rem] sm:px-[6rem] tablet:px-[8rem] pt-[4.4rem] sm:pt-[6.4rem] tablet:pt-[7.4rem] pb-[8rem] bg-white rounded-[0.8rem]">
              <h1 className=" text-[#333333] text-[3.5rem] sm:text-[4.4rem] font-medium leading-[5.4rem] mb-[3rem] sm:mb-[4.4rem]">
                Reset Password
              </h1>
              <div className="w-full input_wrapper">
                <label className="!text-[2.2rem] sm:!text-[2.8rem]">
                  Current Password
                </label>
                <div className="flex relative">
                  <input
                    className="!h-24 !text-[2.25rem] placeholder:!text-[2.25rem] !rounded-[1rem]"
                    {...register("currentpassword")}
                    placeholder="Current Password"
                    type={currentPassword ? "password" : "text"}
                  />
                  <div
                    className="inline absolute right-8 top-10"
                    onClick={() => setCurrentPassword(!currentPassword)}
                  >
                    <img
                      className="w-[2.4rem] h-[2.4rem]"
                      src={currentPassword ? EyeShutIcon : EyeIcon}
                    />
                  </div>
                </div>
                {errors.currentpassword && (
                  <p className="error">{errors.currentpassword?.message}</p>
                )}
              </div>
              <div className="w-full input_wrapper !mt-[3rem] ">
                <label className="!text-[2.2rem] sm:!text-[2.8rem] ">
                  New Password
                </label>
                <div className="flex relative">
                  <input
                    className="!h-24 !text-[2.25rem] placeholder:!text-[2.25rem] !rounded-[1rem] "
                    {...register("newpassword")}
                    placeholder="New Password"
                    type={newPassword ? "password" : "text"}
                  />
                  <div
                    className="inline absolute right-8 top-10"
                    onClick={() => setNewPassword(!newPassword)}
                  >
                    <img
                      className="w-[2.4rem] h-[2.4rem]"
                      src={newPassword ? EyeShutIcon : EyeIcon}
                    />
                  </div>
                </div>
                {errors.newpassword && (
                  <span className="error mt-0">{errors.newpassword?.message}</span>
                )}

              </div>
              <div className="w-full input_wrapper !mt-[3rem]">
                <label className="!text-[2.2rem] sm:!text-[2.8rem]">
                  Confirm New Password
                </label>
                <div className="flex relative">
                  <input
                    className="!h-24 !text-[2.25rem] placeholder:!text-[2.25rem] !rounded-[1rem]"
                    {...register("confirmPassword")}
                    placeholder="Confirm New Password"
                    type={confirmNewPassword ? "password" : "text"}
                  />

                  <div
                    className="inline absolute right-8 top-10"
                    onClick={() => setConfirmNewPassword(!confirmNewPassword)}
                  >
                    <img
                      className="w-[2.4rem] h-[2.4rem]"
                      src={confirmNewPassword ? EyeShutIcon : EyeIcon}
                    />
                  </div>
                </div>
                {errors.confirmPassword && (
                  <p className="error">{errors.confirmPassword?.message}</p>
                )}
              </div>
              <button
                disabled={isLoading}
                style={{ background: isLoading ? "gray" : "#2165A2" }}
                className="w-full mt-[2.4rem] sm:mt-[9.4rem] h-24 rounded-[0.94rem] bg-[#2165A2] text-white text-[2.4rem] leading-[4.1rem] flex justify-center items-center gap-5"
              >
                {isLoading ? (
                  "Loading ..."
                ) : (
                  <>
                    <img className="w-[2rem]" src={LockIcon} alt="Lock Icon" />{" "}
                    Update Password
                  </>
                )}
              </button>
            </div>
          </form>
      </div>
    </Layout>
  );
};

export default AccountResetPassword;
