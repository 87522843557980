import { Listbox } from "@headlessui/react";
import ArrowDown from "assets/images/ArrowDown.svg";

const weekOptions = [
  {
    title: 'First',
    value: 1
  },
  {
    title: 'Second',
    value: 2
  },
  {
    title: 'Third',
    value: 3
  },
  {
    title: 'Fourth',
    value: 4
  },
  {
    title: 'Last',
    value: -1
  }
];
const weekDayOptions = [
  {title: 'Sunday', value: 1},
  {title: 'Monday', value: 2},
  {title: 'Tuesday', value: 3},
  {title: 'Wednesday', value: 4},
  {title: 'Thursday', value: 5},
  {title: 'Friday', value: 6},
  {title: 'Saturday', value: 7},
];


const SelectMonthlyDays = ({ state, setState }: any) => {
  const monthlyDays = Array.from({ length: 31 }, (_, index) => index + 1);

  return (
    <div className=" flex flex-col sm:flex-row gap-6 sm:gap-[11.4rem] mt-[4.8rem] items-start">
      <h3 className="text-[2.7rem] text-white font-bold">Occurs on</h3>
      <div>
        <div className="flex items-center gap-[12px] mb-12">
          <div className="flex gap-4 items-center">
            <input
              type="radio"
              checked={state.mode === "WEEK_DAY"}
              onChange={() => setState((prev:any) => ({
                ...prev,
                mode: "WEEK_DAY"
              }))}
              className="w-[2.6rem] h-[2.6rem]"
            />
            <h3 className="text-[2.7rem] text-white">Day</h3>
          </div>
          <div className="w-[18rem] h-[6rem]">
            <Listbox
              defaultValue={state?.monthlyDay}
              disabled={state.mode === "MONTH_DAY"}
              onChange={(selected: any) => {
                setState((prev:any) => ({
                  ...prev,
                  monthlyDay: selected
                }))
              }}
            >
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block text-[2.6rem]">
                    {state.monthlyDay}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                    <img className="w-[1.6rem]" src={ArrowDown}></img>
                  </span>
                </Listbox.Button>
                <Listbox.Options className="z-50 max-h-[30rem] absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {monthlyDays?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className="cursor-pointer text-center h-[4rem]"
                      value={option}
                    >
                      {({ selected }) => {
                        return (
                          <>
                            <p
                              className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center ${selected
                                ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                : "text-[#424242]"
                                }`}
                            >
                              {option}
                            </p>
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
          <div>
            <h3 className="text-[2.7rem] text-white">of the month</h3>
          </div>
        </div>

        <div className="flex items-center gap-[12px]">
          <div className="flex gap-4 items-center">
            <input
              type="radio"
              checked={state.mode === "MONTH_DAY"}
              onChange={() => setState((prev:any) => ({
                ...prev,
                mode: "MONTH_DAY"
              }))}
              className="w-[2.6rem] h-[2.6rem]"
            />
          </div>
          <div className="w-[18rem] h-[6rem]">
            <Listbox
              defaultValue={state?.weekOption}
              onChange={(selected: any) => {
                setState((prev:any) => ({
                  ...prev,
                  weekOption: selected
                }))
              }}
              disabled={state.mode === "WEEK_DAY"}
            >
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block text-[2.6rem]">
                    {state.weekOption.title}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                    <img className="w-[1.6rem]" src={ArrowDown}></img>
                  </span>
                </Listbox.Button>
                <Listbox.Options className="z-50 max-h-[30rem] absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {weekOptions?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className="cursor-pointer text-center h-[4rem]"
                      value={option}
                    >
                      {({ selected }) => {
                        return (
                          <>
                            <p
                              className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center ${selected
                                ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                : "text-[#424242]"
                                }`}
                            >
                              {option.title}
                            </p>
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
          <div className="w-[22rem] h-[6rem]">
            <Listbox
              defaultValue={state?.weekDayOption}
              onChange={(selected: any) => {
                setState((prev:any) => ({
                  ...prev,
                  weekDayOption: selected
                }))
              }}
              disabled={state.mode === "WEEK_DAY"}
            >
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-default border-2 border-white h-[6rem] text-white rounded-[1rem] bg-transparent py-2 pl-3 pr-10 text-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block text-[2.6rem]">
                    {state.weekDayOption.title}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[2rem]">
                    <img className="w-[1.6rem]" src={ArrowDown}></img>
                  </span>
                </Listbox.Button>
                <Listbox.Options className="z-50 max-h-[30rem] absolute mt-4 w-full overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {weekDayOptions?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className="cursor-pointer text-center h-[4rem]"
                      value={option}
                    >
                      {({ selected }) => {
                        return (
                          <>
                            <p
                              className={`font-regular text-[2.6rem] flex h-full w-full items-center justify-center ${selected
                                ? "text-primary_blue bg-primary_blue bg-opacity-[20%]"
                                : "text-[#424242]"
                                }`}
                            >
                              {option.title}
                            </p>
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
          <div>
            <h3 className="text-[2.7rem] text-white">of the month</h3>
          </div>
        </div>
      </div>

    </div>
  )
};

export default SelectMonthlyDays;
