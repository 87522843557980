import { useState } from "react";
import CallCalendar from "components/CallCalendar";
import CrossIcon from "assets/icons/cross-white.svg";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const SearchFilters = ({ setCallsData, calls }: any) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());

  function searchUploadedCallClient(e: any) {
    setSearchQuery(e.target.value);
    if (e.target.value) {
      const newCalls = calls?.filter((event: any) => {
        return event?.title
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) || event?.topic
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase())
      });
      setCallsData(newCalls);
    } else {
      setCallsData(calls);
    }
  }

  const handleClear = () => {
    setSearchQuery("");
    setStartDate(new Date());
    setEndDate(new Date());
    setCallsData(calls);
  };

  const handleFilter = () => {
    const start_date = dayjs(startDate).startOf("day").format();
    const end_date = dayjs(endDate).startOf("day").format();
    if (end_date < start_date) {
      toast.warn("Select end date greater than start date");
      return;
    }
    const newCalls = calls?.filter((event: any) => {
      const date = event?.date;
      if (searchQuery) {
        return (
          dayjs(date).isBetween(start_date, end_date, "day", "[]") &&
          event?.title?.toLowerCase().includes(searchQuery?.toLocaleLowerCase())
        );
      } else {
        return dayjs(date).isBetween(start_date, end_date, "day", "[]");
      }
    });
    setCallsData(newCalls);
  };

  return (
    <div className="flex md:items-end flex-col md:flex-row gap-[2rem] md:gap-[4.6rem]">
      <div className="flex flex-col max-w-[200px]">
        <h3 className="text-[#ffffff] text-4xl mb-5">
          Filter By Title
        </h3>
        <input
          value={searchQuery}
          onChange={(e: any) => searchUploadedCallClient(e)}
          type="text"
          className="w-full h-[3.5rem] sm:max-w-[26.8rem] text-[#333333] text-[2.2rem] px-[1rem] py-[0.3rem] bg-white rounded-[0.2rem] placeholder:text-left placeholder:text-[#B8B8B8] placeholder:text-[2.2rem]  focus:outline-none"
          placeholder="Title"
        />
      </div>
      <div className="relative sm:flex gap-8 items-end">
        <div className="">
          <h3 className="text-[#ffffff] text-4xl">
            Filter By Date
          </h3>
          <CallCalendar
            startDate={startDate && startDate}
            setStartDate={setStartDate}
            endDate={endDate && endDate}
            setEndDate={setEndDate}
            handleFilter={handleFilter}
          />
        </div>
        <div
          onClick={handleClear}
          className="w-[3.4rem] h-[3.4rem] cursor-pointer min-w-[3.4rem] absolute bottom-[0.2rem] left-[10rem] md:static"
        >
          <img
            src={CrossIcon}
            alt="CrossIcon"
            className="w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchFilters;
