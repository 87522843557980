import { useEffect, useState } from "react";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import NotesCard from "./components/NotesCard";
import {
  useGetCoachNotesForClientQuery,
  useGetCoachNotesQuery,
} from "apis/client";
import NotesTabs from "./components/NoteTabs";
import { useLocation, useNavigate } from "react-router-dom";
import { setTabIndex } from "store/slices/client.slice";
import { useDispatch } from "react-redux";

interface Note {
  date: string;
  note: string;
  createdAt: string;
}

const Notes = () => {
  const [tab, setTab] = useState("tocoach");
  const { data } = useGetCoachNotesQuery(1, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const { data: coachNotesforClient } = useGetCoachNotesForClientQuery(1, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const coachNotesForClient = coachNotesforClient?.data?.notes || [];

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const gotoPage = () => {
    dispatch(setTabIndex(2));
    navigate("/client/tasks")
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams?.get('type');

  useEffect(() => {
    if (type) {
        setTab(type==="coach"?"fromcoach":"");
    }
  }, [type,setTab]);

  return (
    <Layout>
      <Hero image={ClientHeroImage} subHeading="Note to Coach" />
      <div className="container mx-auto py-32 px-10 desktop:px-10">
        <div className="mb-[2.5rem] flex flex-col sm:flex-row gap-8 justify-between">
          <NotesTabs setTab={setTab} tab={tab}/>
          <div>
            <button
              onClick={gotoPage}
              className=" text-5xl text-white font-[500] bg-[#2165A2] px-[3rem] py-[1.4rem] rounded-xl">
              Create Note
            </button>
          </div>
        </div>
        {tab === "tocoach" && data?.data?.notes?.length === 0 && (
          <div className="bg-[#fff] rounded-xl py-14 px-4">
            <h1 className="text-center text-primary_blue text-[3rem] font-medium">
              No Notes Found
            </h1>
          </div>
        )}
        {tab === "fromcoach" && coachNotesForClient?.length === 0 && (
          <div className="bg-[#fff] rounded-xl py-14 px-4">
            <h1 className="text-center text-primary_blue text-[3rem] font-medium">
              No Notes Found
            </h1>
          </div>
        )}
        {tab === "tocoach" &&
          data?.data?.notes?.map((note: Note, index: number) => {
            return (
              <div key={index}>
                <NotesCard
                  index={index}
                  date={note?.createdAt}
                  note={note?.note}
                />
              </div>
            );
          })}
        {tab === "fromcoach" &&
          coachNotesForClient?.map((note: Note, index: number) => {
            return (
              <div key={index}>
                <NotesCard
                  index={index}
                  date={note?.createdAt}
                  note={note?.note}
                />
              </div>
            );
          })}
      </div>
    </Layout>
  );
};

export default Notes;
