import React, { useEffect } from "react";
import FormTabs from "./FormTabs";
import LegacyStatement from "pages/Coach/LegacyStatement";
import OnboardingTab from "./OnboardingTab";
import WeeklyCheckInTab from "./WeeklyCheckInTab";
import SwotTab from "./SwotTab";

const ClientForms = ({defaultValue}:any) => {
  const [tab, setTab] = React.useState(0);

  useEffect(() => {
    if(defaultValue){
      setTab(defaultValue)
    }
  }, [defaultValue])
  
  return (
    <div className="mt-16">
      <FormTabs setTab={setTab} tab={tab} />
      <div>
        {(tab === 0) && (
          <OnboardingTab />
        )}

        {(tab === 1) && (
          <WeeklyCheckInTab />
        )}

        {(tab === 2) && (
          <LegacyStatement />
        )}

        {(tab === 3) && (
          <SwotTab />
        )}

      </div>
    </div>
  );
};

export default ClientForms;
