import { useState, useEffect } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "store/selectors";
import { useRegisterCourseMutation } from "apis/client";

interface IFormInputs {
  name: string;
  email: string;
  phone: number | null;
}

const schema = yup.object({
  name: yup.string().required("Name required"),
  email: yup
    .string()
    .trim()
    .email("Invalid Email format")
    .required("Email required"),
  phone: yup.string().required("Phone required").min(9, "Phone number must be at least 9 characters").max(15, "Phone number should have a maximum of 15 characters").nullable(),
});

const RegisterCourse = () => {
  const user = useSelector(selectUser);

  const [phone, setPhone] = useState("");
  const { id } = useParams();
  const [resgisterCourse, { isLoading }] = useRegisterCourseMutation();
  const navigate = useNavigate();
  
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: user?.full_name,
      email: user?.email,
      phone: user?.phoneNo
    }
  });

  useEffect(() => {
    if (user?.phoneNo) {
      setPhone(user?.phoneNo)
    }
  }, [])

  function handleNumber(e: any) {
    const enteredValue = e.target.value.replace(/\D/g, "");
    e.preventDefault();
    setPhone(enteredValue);
    setValue("phone", enteredValue);
  }

  const onSubmit = async (data: IFormInputs) => {
    const payload = {
      full_name: data.name,
      email: data.email,
      phone: data.phone,
      courseId: id
    };

    await resgisterCourse(payload).unwrap()
      .then((res) => {
        toast.success(res?.message);
        navigate(`/client/course/${id}`)
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      })
  };

  return (
    <Layout>
      <Hero />
      <div className="container mx-auto pt-16 pb-1 px-10 desktop:px-10">
        <div className='text-center pb-[6rem] tablet:pb-[3rem]'>
          <h6 className='text-white font-medium text-[5rem] pb-0 tablet:mx-[10rem]'>Register</h6>
        </div>

        <div className="max-w-[67rem] mx-auto pb-[8rem]">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="register_event_input">
              <label>Full Name</label>
              <div>
                <input type="text" {...register("name")} className="placeholder:text-[#605f5f]" placeholder="John Doe" />
              </div>
              {errors.name && <p className="error"> {errors.name?.message}</p>}
            </div>

            <div className="register_event_input">
              <label>Email</label>
              <input
                type="email"
                readOnly
                className="placeholder:text-[#605f5f]"
                {...register("email")}
                placeholder="mail@gmail.com"
              />
            </div>
            {errors.email && <p className="error"> {errors.email?.message}</p>}

            <div className="register_event_input">
              <label>Phone</label>
              <input
                type="text"
                value={phone}
                {...register("phone")}
                className="mt-0 w-full text-white text-[4.5rem] focus:outline-none placeholder:text-[#605f5f] leading-[5.3rem] bg-[#202F39] px-28"
                placeholder="+123-0456-56"
                onChange={handleNumber}
              />
              {errors.phone && <p className="error">{errors.phone?.message}</p>}
            </div>

            <div className="pt-[2rem] text-center">
              <button disabled={isLoading} className="primary_button w-full tablet:w-[50rem] mt-8">
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterCourse;
