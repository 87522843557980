import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userProfile: {},
    tabIndex: 0
};

const clientSlice = createSlice({
    name: "client",
    initialState,
    reducers: {
        setUserProfile: (state, action) => {
            state.userProfile = action.payload;
        },
        setTabIndex: (state, action) => {
            state.tabIndex = action.payload;
        },

    },
});

export const setUserProfile = clientSlice.actions.setUserProfile;
export const setTabIndex = clientSlice.actions.setTabIndex;

const ClientSlice = clientSlice.reducer;
export default ClientSlice;