import React from "react";
import RecorderIcon from "assets/icons/recorder-icon.svg";
import thumnailimage from "../assets/images/thumnailimage.png"
import PlayVideo from "./PlayVideo";
import moment from "moment";

interface CallsCard {
  id?: any;
  imgUrl: string;
  title: string;
  desc: string;
  watchTime: string;
  postedTime: string;
  toPage?: string;
  url?: string;
  date?:string;
  coachName?:string;
}

const CallsCard = ({
  imgUrl,
  title,
  desc,
  postedTime,
  url,
  coachName,
}: CallsCard) => {
  const [isOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  
  return (
    <>
      <div
        className="bg-white w-full max-h-[28.7rem] rounded-lg overflow-hidden cursor-pointer"
        onClick={openModal}
      >
        <div className="w-full h-[18rem] overflow-hidden relative">
          <div className="absolute top-[1.9rem] left-[2.6rem]">
            <img className="w-[3.4rem]" alt="" src={RecorderIcon}></img>
          </div>
          <div>
            <img
              alt="DefaultImage"
              className="object-cover w-full h-[18rem]"
              src={imgUrl ? imgUrl : thumnailimage}
            ></img>
          </div>
        </div>
        <div className="p-5">
          <div className="flex gap-[1.3rem] items-center">
            <p className="text-primary_blue capitalize text-[2rem] font-bold pb-1 whitespace-nowrap w-[100%] text-ellipsis overflow-hidden">
              {title}
              {coachName}
            </p>
          </div>
          <div className="items-center flex gap-14">
            <p className="text-[1.6rem] font-semibold text-[#333333] whitespace-nowrap w-[100%] text-ellipsis overflow-hidden">
              {desc}
            </p>
            <p className="text-gray-500 text-[1.4rem] font-light text-[#4D4D4D] whitespace-nowrap">
              {postedTime && moment(postedTime).fromNow()}
            </p>
          </div>
        </div>
      </div>

      <PlayVideo
        title={title}
        description={desc}
        videoUrl={url}
        setIsOpen={setIsOpen}
        open={isOpen}
      />
    </>
  );
};

export default CallsCard;
