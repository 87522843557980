import React from "react";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";

const Home = () => {
  return (
    <Layout>
      <Hero image={ClientHeroImage} isScoreMeter />
      <div className="container mx-auto py-32 px-10 desktop:px-10">
        <h1 className="mt-[10rem]">HELLO</h1>
      </div>
    </Layout>
  );
};

export default Home;
