import Hero from "components/Hero";
import Layout from "layouts/Layout";
import PrimaryButton from "components/common/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useGetSingleEventsQuery } from "apis/general";
import ParticipantCard from "./components/ParticipantCard";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectUser } from "store/selectors";

const SingleEvent = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { id } = useParams();

  const { data } = useGetSingleEventsQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const event = data?.data?.event;
  const isRSVP = event?.members?.find((member: any) => member?.email == user?.email);

  return (
    <Layout>
      <Hero heading={event?.title} />
      <div className="container mx-auto py-32 px-10 desktop:px-10">
        <div className="grid grid-cols-1 tablet:grid-cols-2 gap-4 gap-y-12 tablet:gap-y-5">
          <div className="tablet:pt-6">
            <p className="text-6xl font-semibold mb-[1.4rem] capitalize text-primary_light">
              {event?.title}
            </p>
            <p className="text-white text-[2.6rem] font-normal mb-4">
              {event?.description}
            </p>
            <p className="text-white text-[2.2rem] font-light mb-4">
              {dayjs(data?.data?.event?.date).format("MMM DD, YYYY")} |{" "}
              {data?.data?.event?.time}
            </p>
            <p className="text-white text-[2.2rem] font-light">
              {event?.location}
            </p>
          </div>
          <div>
            <div className="w-full">
              <img
                className="w-full max-h-[36rem] object-cover"
                src={event?.eventPhotos[0]?.url}
              ></img>
            </div>
          </div>
        </div>

        <div>
          <p className="text-5xl font-semibold mb-6 text-white">Event Photos</p>
          <div className="grid grid-cols-2 tablet:grid-cols-6 gap-8">
            {event?.eventPhotos?.map((photo: any, index: number) => (
              <img
                key={index}
                className="w-full aspect-square object-cover rounded-lg"
                src={photo?.url}
              ></img>
            ))}
          </div>
        </div>

        <div className="bg-primary_light bg-opacity-10 w-full h-full px-[1.8rem] py-[1.8rem]  rounded-xl mt-20 overflow-hidden">
        {event?.members?.length ? (
            <>
              <p className="text-5xl font-semibold text-white mb-8">
                Event Participants:
              </p>
              <div className="grid grid-cols-1 tablet:grid-cols-3 gap-8">
                {event?.members.map((item: any, index: number) => {
                  return <ParticipantCard key={index} user={item} />;
                })}
              </div>
            </>
          ) : (
            <p className="text-5xl text-center text-white mb-8 mt-8">
              0 Participants Registered
            </p>
          )}
        </div>

        {!isRSVP ? (
          <div className="pt-28 text-center">
          <PrimaryButton
            color="primary"
            text="Register Event"
            width="w-full tablet:w-[22.8rem]"
            onClick={() => navigate(`/coach/register-event/${id}?email=${user.email}&current_user=${user.email}`)}
          />
        </div>
        ) : ""}
      </div>
    </Layout>
  );
};

export default SingleEvent;
