import { useState } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import ClientHeroImage from "assets/images/client-hero.png";
import {
  useGetGroupCallsQuery,
  useGetGroupCallRecordingsQuery,
} from "apis/client";
import VideoCallsTabs from "components/VideoCallsTabs";
import { useSelector } from "react-redux";
import { selectUser } from "store/selectors";
import ScheduleCallComp from "components/ScheduleCallComp";
import ZoomCallCard from "components/ZoomCallCard";

const GroupCalls = () => {
  const [tab, setTab] = useState("upcomingcalls");
  const user = useSelector(selectUser);

  const { data: calls, isLoading } = useGetGroupCallsQuery(user?.id, {
    refetchOnMountOrArgChange: true,
  });

  const { data, isLoading: upcomingLoading } = useGetGroupCallRecordingsQuery(user?.id, {
    refetchOnMountOrArgChange: true,
  });

  const upComingCalls = data?.data?.data;

  const scheduledCalls = calls?.data?.calls.map((item: any) => {
    const date = item?.date ? item?.date : item?.createdAt;
    const temp = new Date(date).getTime() / 1000;
    return {
      ...item,
      filterDate: temp
    }
  })?.sort((a: any, b: any) => a?.filterDate - b?.filterDate) || [];

  return (
    <Layout>
      <Hero image={ClientHeroImage} heading="Scheduled Group Calls" />
      <div className="container mx-auto pt-[1rem] sm:pt-[2rem] px-10 desktop:px-10 pb-[8rem]">
        <div className="flex flex-col gap-8 lg:flex-row justify-between lg:items-end mt-10">
          <VideoCallsTabs setTab={setTab} />
        </div>

        {tab === "previouscalls" && (
          <div>
            {upcomingLoading && (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
              </div>
            )}

            {!upcomingLoading && <h2 className="text-white text-5xl mt-20">Call Recordings</h2>}
            {(!upcomingLoading && upComingCalls?.length === 0) ? (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">No Recordings Found</p>
              </div>
            ) : (
              <div className="mt-[4rem] grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
                {upComingCalls?.map((call: any, index: number) => {
                  const video = call?.recording_files?.find((vid: any) => vid.file_type === "MP4");
                  const url = `${video?.play_url}?pwd=${call?.recording_play_passcode}`
                  return (
                    <div
                      className="bg-white w-full max-h-[28.7rem] rounded-lg overflow-hidden cursor-pointer"
                      key={index}>
                      <ZoomCallCard
                        title={call?.topic}
                        postedTime={video?.recording_end || new Date}
                        status={video?.status}
                        url={url}
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )}

        {tab !== "previouscalls" && (
          <div>
            {isLoading ? (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
              </div>
            ) : (
              <ScheduleCallComp title="Scheduled Group Calls" data={scheduledCalls} />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default GroupCalls;
