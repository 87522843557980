import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import { useUpdateNoteMutation } from "apis/coach";

interface IFormInputs {
  note: string;
}

const schema = yup.object().shape({
  note: yup.string().required(" Required"),
});

export default function EditModal({ show, close, data }: any) {
  const [date, setDate] = useState("");
  const [updateNote, { isLoading }] = useUpdateNoteMutation();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const handleDate = (date: moment.MomentInput) => {
    const value = moment(date).format("DD/MM/YYYY");
    setDate(value);
  };

  const handleClose = () => {
    close(false);
  };

  const onSubmit = async (values: any) => {
    const payload = {
      note: values.note
    };

    await updateNote({ id: data.id, payload }).unwrap()
      .then((res: any) => {
        toast.success(res?.message);
        handleClose();
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })
  };

  useEffect(() => {
    setValue("note", data?.note);
    setDate(data?.date)
  }, []);

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 mt-[10rem]"
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000] bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  max-w-[65rem] transform mt-[4rem] overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h2"
                    className="font-bold !text-center text-black text-[3.2rem]"
                  >
                    Edit Note
                  </Dialog.Title>
                  <div>
                    <div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="lg:mt-[2rem] mt-4 max-w-[53.3rem] mx-auto">
                          <div className="mb-[2.4rem]">
                            <label className="text-black text-[2.4rem] leading-none font-normal text-start mb-4">
                              Date
                            </label>

                            <DatePicker
                              className="input-form-calendar !text-[black] !border-[black] !placeholder-[#B4B4B4]  !h-24 !max-w-full !min-w-full  !border-[1.5px] focus:outline-none !text-4xl"
                              value={moment(date).format("MM/DD/YYYY")}
                              readOnly
                              onChange={(date) => handleDate(date)}
                              minDate={moment().toDate()}
                              placeholderText={"Enter Date"}
                            />
                          </div>
                          <label className="text-black text-[2.4rem] leading-none font-normal text-start mb-4">
                            Note Text
                          </label>
                          <textarea
                            style={{ background: "transparent" }}
                            rows={4}
                            {...register("note")}
                            // cols="50"
                            className=" p-[2rem] w-full !text-[black] !border-[black] rounded-xl text-4xl px-[1.4rem] border-[1.5px] border-black mt-[1rem] text-left placeholder:text-[#B4B4B4] focus:outline-none"
                            placeholder="Enter Note..."
                          />
                          {errors.note && (
                            <p className="error">{errors.note?.message}</p>
                          )}

                          <div className="mt-[2rem] mb-[2rem]">
                            <button
                              disabled={isLoading}
                              type="submit"
                              className="h-24 w-full px-20 bg-primary_blue text-[2.6rem] text-[white] rounded-xl"
                            >
                              Update Note
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
