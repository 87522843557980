import BulbIcon from "assets/icons/BulbIcon.svg";
import ProfileImg from "assets/images/profile.png";
import numberWithCommas from "utils/commaseperatedfunction";
import { selectUser } from "store/selectors";
import { useSelector } from "react-redux";


const CompletedChallengeCard = ({ challenge: { id, opponent, challenger, challengerRating, opponentRating, winnerId,
  score, isCompleted, challengerEndRating, opponentEndRating, isExpired } }: any) => {

  const user = useSelector(selectUser);
  const renderAvatar = (
    name: string,
    image: string,
    position?: "right" | "left"
  ) => {
    return (
      <div className={`${position === "right" ? "text-right" : "text-left"}`}>
        <img
          className={`w-[4.43rem] h-[4.43rem] object-cover rounded-full overflow-hidden mb-[0.6rem] ${position === "right" ? "ml-auto" : ""
            }`}
          src={image ? image : ProfileImg}
          alt=""
        ></img>
        <p
          className={`full_name break-all text-[2.16rem] text-[#A7A7A7] font-medium leading-[2.5rem]`}
        >
          {name?.split(/\s/)[0]}
        </p>
      </div>
    );
  };

  return (
    <div key={id} className="w-full bg-white rounded-[0.4rem] px-[1.2rem] py-[1.2rem]">
      <div className="flex flex-row items-start justify-between mb-[1.5rem]">
        <div className="w-[35%]">{renderAvatar(challenger.full_name, challenger.profile_photo)}</div>
        <div className="flex flex-col items-center gap-16 pt-2">
          <div className="w-[20rem] flex  justify-center gap-1">
            {!isExpired ? <>
              <p className="capitalize text-[#159600] text-center  text-[1.6rem] font-bold leading-[1.6rem]">
                {(user?.id === winnerId && winnerId != null)
                  ? `You Won`
                  : (challenger?.id !== winnerId && winnerId !== null)
                    ? `${opponent?.full_name?.split(/\s/)[0]} Won`
                    : (opponent?.id !== winnerId && winnerId !== null)
                      ? `${challenger?.full_name?.split(/\s/)[0]} Won`
                      : "Match Tied"
                }
              </p>
            </> : (
              <>
                <p className="capitalize text-[#e11d48] text-center  text-[1.6rem] font-bold leading-[1.6rem]">
                  EXPIRED
                </p>
              </>
            )}

          </div>
          {!isExpired && (
            <p className="text-center capitalize text-[#A7A7A7] text-[1.6rem] font-normal leading-[1.6rem]">
              Completed
            </p>
          )}
        </div>
        <div className="w-[35%]">
          {renderAvatar(opponent.full_name, opponent.profile_photo, "right")}
        </div>
      </div>
      <div className="flex flex-row items-center justify-between mb-[2.5rem] relative">
        <div className="flex flex-row gap-2 items-center">
          <div className=" h-[2.8rem] w-[1.8rem] mt-[-2px]">
            <img className="h-full w-full" alt="" src={BulbIcon}></img>
          </div>
          <p className="text-[2.16rem] leading-[2.16rem] text-[#A7A7A7] font-normal">
            {
              (isCompleted === false)
                ? numberWithCommas(Math.abs(challenger.winRating))
                : numberWithCommas(Math.abs(challengerEndRating - challengerRating))
            }
          </p>
        </div>
        <p className="text-[2.6rem] leading-[2.2rem] text-[#A7A7A7] font-extrabold  absolute top-0 m-0 left-[50%] translate-x-[-50%]">
          VS
        </p>
        <div className="flex flex-row gap-2 items-center">
          <p className="text-[2.16rem] leading-[2.16rem] text-[#A7A7A7] font-normal">
            {
              (isCompleted === false)
                ? numberWithCommas(Math.abs(opponent.winRating))
                : numberWithCommas(Math.abs(opponentEndRating - opponentRating))
            }
          </p>
          <div className=" h-[2.8rem] w-[1.8rem] mt-[-2px]">
            <img className="h-full w-full" alt="" src={BulbIcon}></img>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-start justify-between">
        <p className="text-[1.41rem] leading-[1.7rem] text-[#A7A7A7] font-normal">
          Won Challenges {numberWithCommas(challenger.challengesWon)}
        </p>
        <p className="text-[1.41rem] leading-[1.7rem] text-[#A7A7A7] font-normal">
          Won Challenges {numberWithCommas(opponent.challengesWon)}
        </p>
      </div>
    </div>
  );
};

export default CompletedChallengeCard;
