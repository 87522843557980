import { useEffect, useState } from "react";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import NotificationsCard from "components/NotificationsCard";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetNotificationsQuery,
  useMarkAllNotificationsMutation,
  useMarkNotificationsMutation,
} from "apis/general";
import { setNotiCount } from "store/slices/auth.slice";
import { socket } from "config/socket";
import { useLocation } from "react-router-dom";

const Notifications = () => {
  const [socketArray, setSocketArray] = useState<any>([]);
  const [notifications, setNotifications] = useState<any>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const userId = useSelector((state: any) => state?.auth?.user?.id);
  const { pathname } = useLocation();
  const isClient = pathname.includes("/client");
  const currentUserRole = !isClient && 'coach'

  const user_img = useSelector(
    (state: any) => state?.auth?.user?.profile_photo
  );
  const dispatch = useDispatch();
  //get Notifications Api
  const {
    data: getNotifications,
    isLoading,
  } = useGetNotificationsQuery({ userId, role: currentUserRole }, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  //mark Notifications Api
  const [markNotifications, { data: markedNotifications }] =
    useMarkNotificationsMutation();
  //mark All Notifications Api
  const [markAllNotifications] = useMarkAllNotificationsMutation();

  const handleMarkAllNotification = () => {
    const payload = {
      userId: userId,
    };
    markAllNotifications(payload);
    setTrigger(true);
  };

  useEffect(() => {
    const array = getNotifications?.data?.Notifications || [];
    setNotifications(array);
  }, [
    markedNotifications?.data?.Notifications,
    isLoading,
    getNotifications?.data?.Notifications,
  ]);

  useEffect(() => {
    const handleNotification = (response: any) => {
      setSocketArray((prevSocketArray: any) => [...prevSocketArray, response]);
      dispatch(setNotiCount(response?.count));
    };
    socket.on(`coachRankChangedNotification${userId}`, handleNotification);
    socket.on(`clientCreateNoteNotification${userId}`, handleNotification);
    socket.on(
      `clientOnboardingAnswerNotification${userId}`,
      handleNotification
    );
    socket.on(`clientCompleteLegacyNotification${userId}`, handleNotification);
    socket.on(
      `clientCheckinformCompletedNotification${userId}`,
      handleNotification
    );
    socket.on(
      `newClientAssignedToCoachNotification${userId}`,
      handleNotification
    );
    socket.on(`coachingCallReminderNotification${userId}`, handleNotification);
    socket.on(
      `coachSubstituteCoachAssignedNotification${userId}`,
      handleNotification
    );
    socket.on(`scheduleCallReminderNotification${userId}`, handleNotification);
    socket.on(`eventReminderNotification${userId}`, handleNotification);
    socket.on(`newUserRegisteredNotification${userId}`, handleNotification);
    socket.on(
      `coachClientHitNewStreakNotification${userId}`,
      handleNotification
    );
    socket.on(
      `callInOneDayScheduleCallReminderNotification${userId}`,
      handleNotification
    );
    socket.on(
      `callTodayScheduleCallReminderNotification${userId}`,
      handleNotification
    );
    socket.on(
      `clientActionItemCompletedNotification${userId}`,
      handleNotification
    );
    socket.on(
      `clientSingleActionItemCompletedNotification${userId}`,
      handleNotification
    );
    socket.on(`videoUploadedNotification${userId}`, handleNotification);
    socket.on(
      `clientAssignedToOldCoachNotification${userId}`,
      handleNotification
    );
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off(`coachRankChangedNotification${userId}`, handleNotification);
      socket.off(`clientCreateNoteNotification${userId}`, handleNotification);
      socket.off(
        `clientOnboardingAnswerNotification${userId}`,
        handleNotification
      );
      socket.off(
        `clientCompleteLegacyNotification${userId}`,
        handleNotification
      );
      socket.off(
        `clientCheckinformCompletedNotification${userId}`,
        handleNotification
      );
      socket.off(
        `newClientAssignedToCoachNotification${userId}`,
        handleNotification
      );
      socket.off(
        `coachingCallReminderNotification${userId}`,
        handleNotification
      );
      socket.off(
        `coachSubstituteCoachAssignedNotification${userId}`,
        handleNotification
      );
      socket.off(
        `scheduleCallReminderNotification${userId}`,
        handleNotification
      );
      socket.off(`eventReminderNotification${userId}`, handleNotification);
      socket.off(`newUserRegisteredNotification${userId}`, handleNotification);
      socket.off(
        `coachClientHitNewStreakNotification${userId}`,
        handleNotification
      );
      socket.off(
        `callInOneDayScheduleCallReminderNotification${userId}`,
        handleNotification
      );
      socket.off(
        `callTodayScheduleCallReminderNotification${userId}`,
        handleNotification
      );
      socket.off(
        `clientActionItemCompletedNotification${userId}`,
        handleNotification
      );
      socket.off(
        `clientSingleActionItemCompletedNotification${userId}`,
        handleNotification
      );
      socket.off(`videoUploadedNotification${userId}`, handleNotification);
      socket.off(
        `clientAssignedToOldCoachNotification${userId}`,
        handleNotification
      );
    };
  }, [userId]);

  return (
    <Layout>
      <Hero image={ClientHeroImage} isCentered heading="Notifications" />
      <div className="container mx-auto py-32 px-10 desktop:px-10">
        <h1 className="text-[5rem] text-white mb-[2.366rem] tracking-wide">
          Notifications
        </h1>
        <div className="w-full bg-white px-8 sm:pl-[3.619rem] sm:pr-[4.381rem] pt-[4.285rem] pb-[18.919rem] rounded-[2rem]">
          {notifications?.length !== 0 && <div className="flex justify-end">
            <button
              onClick={handleMarkAllNotification}
              disabled={notifications?.length > 0 &&
                (!notifications?.some(
                  (notification: any) => !notification?.mark_as_read
                ))
              }
              className="mt-[1rem] w-max px-8 h-[4.2rem] bg-primary_blue text-white font-bold tracking-wide text-[1.6rem] rounded-[0.8rem] disabled:bg-grey_semi disabled:opacity-50 flex items-center border"
            >
              Mark All As Read
            </button>
          </div>}
          {socketArray?.length > 0 && (
            <h1 className="text-[2.4rem] text-[#000] mb-[2rem]">
              New Notifications
            </h1>
          )}
          <div className="mb-[5rem]">
            {socketArray?.map((item: any, index: any) => {
              return (
                <div key={index} className="mb-[1.827rem]">
                  <NotificationsCard
                    item={item}
                    user_img={user_img}
                    socketArray={socketArray}
                    trigger={trigger}
                    setTrigger={setTrigger}
                  />
                </div>
              );
            })}
          </div>
          {isLoading && (
            <div className="bg-[#2166a251] w-full my-[3rem] text-center py-16 rounded-lg">
              <p className="text-4xl sm:text-5xl text-primary_blue">
                Loading Notifications...
              </p>
            </div>
          )}
          {!isLoading && notifications?.length === 0 ? (
            <div className="bg-[#2166a251] w-full my-[3rem] text-center py-16 rounded-lg">
              <p className="text-4xl sm:text-5xl text-primary_blue">
                No Notifications
              </p>
            </div>
          ) : (
            notifications?.map((item: any, index: any) => {
              return (
                <div key={index} className="mb-[1.827rem]">
                  <NotificationsCard
                    item={item}
                    user_img={user_img}
                    markNotifications={markNotifications}
                    trigger={trigger}
                    setTrigger={setTrigger}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;
