import { useRef } from "react";

interface UploadBoxProps {
    title: string;
    name: string;
    accept: string;
    type: string;
    onChange: (e: any) => Promise<void>;
    src: string;
    register: any;
}
const UploadBox = ({ type, onChange, accept, name, src, title }: UploadBoxProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleFileButtonClick = () => {

        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (
        <>
            <div className="w-full sm:w-[44rem]">
                <div className="mt-[6.7rem] rounded-xl bg-white py-[3rem] px-[3rem]">
                    <div className="w-full border-2 border-dashed border-[#939393] rounded-xl">
                        <div className="pt-[3.8rem] pb-[3.4rem] px-[2rem] tablet:px-[4rem] flex flex-col gap-5 items-center text-center">
                            <img className="w-[8rem]" src={src} alt="Upload Video Icon" />
                            <h1 className=" text-primary_blue text-[2.6rem] mt-[1rem] font-medium leading-[3.3rem]">
                                <div>{title}</div>
                                <div>{"To Upload"}</div>
                            </h1>
                            <input
                                name={name}
                                type={type}
                                hidden
                                accept={accept}
                                onChange={onChange}
                                ref={fileInputRef}
                            //{...register(name)}
                            />
                            <button type="button" onClick={handleFileButtonClick} className="bg-primary_blue mt-[1rem] text-center text-white rounded-[0.44rem] px-[3.26rem] py-[1.1rem] leading-[1.9rem] text-[1.66rem] font-bold">
                                Browse File
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadBox
