import ScheduledCallsCard from './ScheduledCallsCard';

const ScheduleCallComp = ({ data, title, type }: any) => {
    return (
        <>
            <div className='flex justify-end'>

            </div>
            <h6 className=" text-white text-[3rem] font-bold  leading-3.5rem] text-center lg:text-left mt-[4rem]">
                {title}
            </h6>
            {data?.length === 0 && (
                <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                    <p className="text-4xl sm:text-5xl text-primary_blue">No Calls Found</p>
                </div>
            )}
            <div className="grid grid-cols-1 gap-11 lg:grid-cols-3 md:grid-cols-2 mt-10">
                {data?.length && (
                    <>
                        {data?.map((item: any) => {
                            return (
                                <div key={item?.id}>
                                    <ScheduledCallsCard
                                        dateCreated={item?.date}
                                        roomId={item?.roomId}
                                        btntext="join"
                                        callType={item?.callType ? item?.callType : "call"}
                                        title={item?.title}
                                        duration={item?.duration}
                                        joinUrl={item?.join_url}
                                        meetingId={item?.meeting_id}
                                        coachName={item?.coach?.full_name}
                                    />
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </>
    )
}

export default ScheduleCallComp
