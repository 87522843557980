import React from 'react'
import FormCalender from './FormCalendar'
import FormsTabs from './FormsTabs'
import dayjs from 'dayjs';

const oneWeekBefore = dayjs().subtract(7, 'day').format();

const TimeLineTabs = ({ answers }: any) => {
    const [startDate, setStartDate] = React.useState(new Date(oneWeekBefore));
    const [endDate, setEndDate] = React.useState(new Date());

    const handleClear = () => {
        setStartDate(new Date(oneWeekBefore));
        setEndDate(new Date());
    };

    const sortedAnswers = [...answers]?.sort((a:any, b:any) => {
        const start = new Date(a?.date).getTime();
        const end = new Date(b?.date).getTime();
        return end - start
    });

    return (
        <div className='bg-[#19252D] py-[3rem] pb-0 tablet:pb-[3rem] tablet:py-[4.6rem] px-[0rem] tablet:px-[6rem] rounded-xl'>
            <div className="flex flex-col mb-[4.1rem] items-center sm:flex-row sm:justify-between">
                <h3 className="font-bold text-5xl leading-[3.5rem] text-white whitespace-nowrap mt-[1rem] mb-[1.8rem] tablet:mb-0">
                    Weekly Check-In Data
                </h3>
                <FormCalender
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    handleClear={handleClear}
                />
            </div>
            <FormsTabs
                answers={sortedAnswers}
                startDate={startDate}
                endDate={endDate}
                handleClear={handleClear}
            />
        </div>
    )
}

export default TimeLineTabs