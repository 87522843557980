import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import CloseIcon from "../assets/icons/close.svg";
import leftArrow from "../assets/icons/left-arrow.svg";
import rightArrow from "../assets/icons/right-arrow.svg";
import LegacyShowComp from "./LegacyShowComp";
import { useDispatch } from "react-redux";
import { setModalDisplay, setlegacyDate } from "store/slices/auth.slice";
import moment from "moment";

export default function LegacyStatementModal({
  setIsOpen,
  isOpen,
  date,
  data,
}: any) {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [CustomField, setCustomField]: any = useState({});
  const [page, setPage] = useState(1);

  const isLastStep = (page - 1) === Object.keys(CustomField).length - 1;

  useEffect(() => {
    const dateDiff = moment().diff(date, "days");
    if (dateDiff > 0) {
      dispatch(setModalDisplay(true));
      dispatch(setlegacyDate(new Date()));
    }
  }, []);

  function closeModal() {
    setIsOpen(false);
    dispatch(setModalDisplay(false));
    dispatch(setlegacyDate(new Date()));
  }

  function nextHandler() {
    if (page) {
      setPage(page + 1);
    }
  }
  function prevHandler() {
    if (page === 1) {
      return;
    } else {
      setPage(page - 1);
    }
  }

  const sortOrder = [
    'my_purpose',
    'i_am',
    'i_will',
    'never_again',
    'core_values',
    'reminders',
    'quote',
  ];

  function customCompare(a: any, b: any) {
    const indexA = sortOrder?.indexOf(a?.category);
    const indexB = sortOrder?.indexOf(b?.category);
    return indexA - indexB;
  }

  const sortedArray = data?.sort(customCompare);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <div className="fixed inset-0 overflow-y-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-[#000] bg-opacity-60" />
            </Transition.Child>
            <div className="flex min-h-full items-center justify-center">
              <Dialog.Panel className="w-full max-w-[99.834rem] transform overflow-hidden rounded-[2.00672rem] bg-white pt-[4.334rem] pb-[3rem] px-[7rem] transition-all relative mt-[12rem]">
                <div className="flex justify-between">
                  <h4 className="text-primary_blue text-[3.10172rem] font-bold">
                    My Champions Legacy
                  </h4>
                  <button className="w-[3rem] h-[3rem]" onClick={closeModal}>
                    <img src={CloseIcon} alt="CloseIcon" />
                  </button>
                </div>
                {
                  sortedArray?.map((item: any, index: any) => {
                    if (page === index + 1) {
                      return (
                        <div key={index}>
                          <LegacyShowComp
                            key={index}
                            data={item}
                            description={item?.description}
                            heading={item?.heading}
                          />
                        </div>
                      );
                    }
                  })
                }
                {(page !== 1) && (
                  <button onClick={prevHandler}>
                    <img
                      className="w-[8rem] h-[8rem] absolute left-0 top-[45%]"
                      src={leftArrow}
                      alt="leftArrow"
                    />
                  </button>
                )}
                {(page !== sortedArray?.length) && (
                  <button onClick={nextHandler} disabled={isLastStep}>
                    <img
                      className="w-[8rem] h-[8rem] absolute right-0 top-[45%]"
                      src={rightArrow}
                      alt="rightArrow"
                    />
                  </button>
                )}
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
