import { useEffect, useState } from "react";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import SwotTabs from "./components/SwotTabs";
import { useGetSwotsQuery } from "apis/client";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/icons/swot_edit.svg";
import SwotAnalysisCreate from "pages/Client/SwotAnalysis/SwotAnalysisCreate";
import HistoryIcon from "../../../assets/icons/history-icon.svg";
import History from "./History";

const SwotAnalysis = () => {
  const [isHistory, setIsHistory] = useState(false);

  const navigate = useNavigate();

  function handleNavigate() {
    navigate("/client/swot-analysis/edit");
  }

  // State to track the loading state
  const [isLoading, setIsLoading] = useState(true);

  // Fetch SWOT data
  const { data, isSuccess } = useGetSwotsQuery(0, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  useEffect(() => {
    // Check if the data has been successfully fetched
    if (isSuccess) {
      setIsLoading(false);
    }
  }, [isSuccess]);

  const swotData = data?.data?.swotAnalysis;

  // Extract the object values to show the swot obj
  const extractedData = {
    strengths: swotData?.strengths,
    weaknesses: swotData?.weakNesses,
    opportunities: swotData?.opportunities,
    threats: swotData?.threats,
  };

  const renderSwotTab = () => {
    if (swotData !== null) {
      return (
        <>
          <div className="flex flex-row justify-between gap-10 mt-12">
            <button
              onClick={() => setIsHistory(!isHistory)}
              className={`flex items-center px-10 rounded-2xl gap-2 border border-1 ${isHistory ? "bg-[#2165A2]" : "bg-transparent"} ${!isHistory ? "border-[#fff]" : "border-[#2165A2]"}`}
            >
              <div >
                <img className="w-[3.4rem]" src={HistoryIcon} alt="edit-icon" />
              </div>
              <h1 className="text-white text-[3.4rem] sm:text-[4.337rem] font-medium sm:font-[500]">
                History
              </h1>
            </button>
            <button
              onClick={handleNavigate}
              className="flex items-center bg-[#2165A2] px-10 rounded-2xl gap-2"
            >
              <div className="w-[4rem]">
                <img src={EditIcon} alt="edit-icon" />
              </div>
              <h1 className="text-white text-[3.4rem] sm:text-[4.337rem] font-medium sm:font-[500]">
                Edit
              </h1>
            </button>
          </div>
          <div className="mt-[2.5rem] mb-[3.1rem] sm:mb-[18.7rem]">
            {isHistory ? (
              <History />
            ) : (
              <SwotTabs data={extractedData} swot={true} />
            )}
          </div>
        </>
      );
    } else {
      return (
        <SwotAnalysisCreate />
      );
    }
  };

  return (
    <Layout>
      <Hero image={ClientHeroImage} subHeading="SWOT Analysis" />
      <div className="container mx-auto px-10 pb-16 sm:pb-1 desktop:px-10">
        {isLoading ? (
          <h3 className="text-white text-[3rem] text-center font-semibold m-[3rem] ">
            Loading...
          </h3>
        ) : (
          renderSwotTab()
        )}
      </div>
    </Layout>
  );
};

export default SwotAnalysis;
