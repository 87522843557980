import { useGetClientByIdQuery } from 'apis/coach';
import CoachClientStreakModal from 'components/CoachClientStreakModal';
import ScoreMeter from 'components/ScoreMeter'
import React from 'react'
import { useParams } from 'react-router';

function ClientWinRatePoints() {

    const { id } = useParams();

    const { data } = useGetClientByIdQuery(id, {
        refetchOnMountOrArgChange: true, refetchOnFocus: true
    });

    const user = data?.data?.user || {}
    return (
        <div className="flex flex-col items-center justify-center mb-16">
            <ScoreMeter user={user} coachSide />
            <CoachClientStreakModal user={user} />
        </div>
    )
}

export default ClientWinRatePoints