import Avatar from "assets/images/EmptyProfile.jpg";
import { Link } from "react-router-dom";
import { useGetCoachLeaderboardQuery } from "apis/coach";

const CoachDetail = () => {
  const { data, isFetching, isLoading } = useGetCoachLeaderboardQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  const users = data?.data?.users || []; 
    // Converting numbers with comma separated
    function numberWithCommas(x:any) {
      x = x.toString();
      const pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x))
          x = x.replace(pattern, "$1,$2");
      return x;
  }
  return (
    <div className="mt-[5.5rem]">
      <div className="hidden bg-[#2165A2] px-14 py-10 sm:flex justify-between rounded-2xl sm:mb-[5.3rem]">
        <h3 className="font-medium text-5xl leading-[3.5rem]">Coach Name</h3>
        <h3 className="font-medium text-5xl leading-[3.5rem]"></h3>
        <h3 className="font-medium text-5xl leading-[3.5rem]">Rank</h3>
      </div>
      {isFetching || isLoading ? (
        <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
          <p className="text-4xl sm:text-5xl text-primary_blue">
            Loading Leaderboard...
          </p>
        </div>
      ) : (
        users?.map((user: any) => {
          return (
            <div
              key={user?.id}
              className="bg-white px-14 py-8 flex flex-col gap-[3rem] tablet:flex-row items-center justify-between  rounded-2xl mt-[2rem]"
            >
              <div className="flex flex-row items-center gap-[0.6rem] tablet:gap-[1.6rem] w-full tablet:w-[50%]">
                <Link to={`/coach/profile/${user?.id}`}>
                  <div className="h-[6.6rem] w-[6.6rem] tablet:h-[7.6rem] tablet:w-[7.6rem] overflow-hidden border border-[#2165A2] rounded-full">
                    <img
                      alt="coachImage"
                      src={user?.profile_photo || Avatar}
                      className="cursor-pointer h-[6.6rem] w-[6.6rem] tablet:h-[7.6rem] tablet:w-[7.6rem] object-cover rounded-full"
                    />
                  </div>
                </Link>
                <Link to={`/coach/profile/${user?.id}`}>
                  <h4 className="full_name capitalize truncate font-bold text-[2.2rem] tablet:text-[3rem] leading-1 text-[#2165A2] sm:col-span-3 !min-h-max">
                    {user?.full_name}
                  </h4>
                </Link>
              </div>
              <div className="flex flex-row  tablet:items-start md:justify-end w-full tablet:w-[50%]">
                <div>
                  <h3 className="text-[2.2rem] tablet:text-[2.6rem] font-medium leading-[1.8rem] text-[#2165A2] sm:hidden inline-block mr-[1rem]">
                    Coach Rank:
                  </h3>
                  <h3 className="text-[2.2rem] tablet:text-[2.6rem] leading-[3.0rem] text-[#333333] sm:text-[#2165A2] inline-block">
                    { numberWithCommas(user?.rank)}
                  </h3>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default CoachDetail;
