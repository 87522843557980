import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useCreateSupportMutation } from "apis/client";

interface IFormInputs {
  subject: string;
  description: string;
}

const schema = yup.object({
  subject: yup.string().required("Subject  required"),
  description: yup.string().required("Description  required"),
});

const SupportForm = () => {
  const navigate = useNavigate();
  const [createSupport, { isLoading }] = useCreateSupportMutation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormInputs) => {
    const payload = {
      subject: data.subject,
      description: data.description
    }
    await createSupport(payload).unwrap().then((res) => {
      toast.success(res?.message)
      navigate("/client")
    }).catch((error) => {
      toast.error((error?.message))
    })

  };

  return (
    <div className="w-max mx-auto bg-white p-[3rem] sm:p-[8rem] rounded-[2.31234rem]">
      <div className="w-full sm:w-[55.269rem] mx-auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-[5rem]">
            <h4 className="text-[#333333] text-[2.8424rem] font-medium">
              Subject
            </h4>
            <input
              {...register("subject")}
              name="subject"
              className="w-full rounded-[1.26329rem] border-2 border-[#333333] h-[6rem] mt-[1.5rem] text-[3rem] px-[2rem]"
              type="text"
            />
            {errors.subject && (
              <p className="error">{errors.subject?.message}</p>
            )}
          </div>
          <div className="mb-[5rem]">
            <h4 className="text-[#333333] text-[2.8424rem] font-medium">
              Description
            </h4>
            <textarea
              {...register("description")}
              name="description"
              rows={6}
              className="w-full rounded-[1.26329rem] border-2 border-[#333333] py-[1rem] mt-[1.5rem] text-[3rem] px-[2rem]"
            />
            {errors.description && (
              <p className="error">{errors.description?.message}</p>
            )}
          </div>
          <div className="mt-[4rem]">
            <button
              disabled={isLoading}
              type="submit"
              className="w-full h-[6rem] bg-[#2165A2] rounded-[1.26329rem] text-[2.8424rem] font-medium text-white"
            >
              {isLoading ? "Loading..." : "Send Request"}

            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SupportForm;
