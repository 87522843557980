import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useCreateSwotMutation } from "apis/client";
import SwotForm from "./SwotForm";
import { toast } from "react-toastify";

const data = [
  {
    label: "STRENGTHS",
    name: "strengths",
  },
  {
    label: "WEAKNESSES",
    name: "weakNesses",
  },
  {
    label: "OPPORTUNITIES",
    name: "opportunities",
  },
  {
    label: "THREATS",
    name: "threats",
  },
];

const Index = () => {
  const [page, setPage] = useState(0);
  const [createSwot, { isLoading }] = useCreateSwotMutation();
  const [formData, setFormData] = useState({
    strengths: "",
    weakNesses: "",
    opportunities: "",
    threats: "",
  });
  const navigate = useNavigate();

  async function nextHandler() {
    if (page !== 3) {
      setPage(page + 1);
    } else {
      await createSwot(formData)
        .unwrap()
        .then(() => {
          navigate("/client/swot-analysis");
          toast.success("Created Successfully");
        })
        .catch((error) => {
          toast.error(error?.data?.message);
        });
    }
  }

  return (
    <div className="container mx-auto py-32 desktop:px-0">
      <div className="w-100% bg-white rounded-[2rem] py-16 px-16 pb-32">
        <div className="w-full sm:w-[68rem] mx-auto">
          <h1 className="font-medium text-[#333333] text-[3rem] sm:text-[5rem]">
            Create SWOT Analysis
          </h1>

          {data?.map((item: any, index: number) => {
            if (page === index) {
              return (
                <SwotForm
                  key={index}
                  index={index}
                  name={item.name}
                  label={item.label}
                  isLoading={isLoading}
                  nextHandler={nextHandler}
                  setFormData={setFormData}
                  page={page}
                  formData={formData}
                  setPage={setPage}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Index;
