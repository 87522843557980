import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  useUpdateLegacyStatementsMutation,
} from "apis/client";
import { toast } from "react-toastify";
import LegacyForm from "./LegacyForm";
import { values } from "../LegacyCreate";
import { getRequest } from "config/axiosConfig";

const Index = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [updateLegacyStatements, { isLoading }] =
    useUpdateLegacyStatementsMutation();
  const [formData, setFormData] = useState<any>([]);
  const [userId, setUserId] = useState<any>([]);

  const sortOrder = [
    'my_purpose',
    'i_am',
    'i_will',
    'never_again',
    'core_values',
    'reminders',
    'quote',
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const result = await getRequest("client/legacy");
      const id = result?.data?.Legacy[0].userId;
      setUserId(id);

      const destructedLegacyData =
        result &&
        result?.data?.Legacy.map(({ heading, description, category }: any) => ({
          heading,
          description,
          category,
        }));

      const sortedArray = destructedLegacyData?.sort(customCompare);
      setFormData(sortedArray);
    } catch(err) {
      // eslint-disable-next-line no-console
      console.log("Error Fetching Data --->", err);
    }
  }

  function customCompare(a: any, b: any) {
    const indexA = sortOrder?.indexOf(a?.category);
    const indexB = sortOrder?.indexOf(b?.category);
    return indexA - indexB;
  }

  async function nextHandler(fData: any) {
    if (page !== 6) {
      setPage(page + 1);
    } else {
      const payload = {
        legacy_statement: fData,
      };
      await updateLegacyStatements({ id: userId, payload })
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          navigate("/client/legacy-statement/");
        })
        .catch((error) => {
          toast.error(error?.data?.message);
        });
    }
  }

  return (
    <>
      <Layout>
        <Hero image={ClientHeroImage} subHeading="Edit Champions Legacy" />
        <div className="container mx-auto py-32 px-10 desktop:px-0">
          <div className="w-100% bg-white rounded-[2rem] py-16 px-16 pb-32">
            <div className="w-full sm:w-[68rem] mx-auto">
              <h1 className="font-medium text-[#333333] text-[3rem] sm:text-[5rem]">
                Edit Champions Legacy
              </h1>
              {values?.map((item: any, index: number) => {
                if (page === index) {
                  return (
                    <LegacyForm
                      key={index}
                      index={index}
                      category={item.category}
                      label={item.label}
                      isLoading={isLoading}
                      nextHandler={nextHandler}
                      setFormData={setFormData}
                      page={page}
                      formData={formData}
                      setPage={setPage}
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Index;
