import { Tab } from "@headlessui/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function ActionItemTabs({ setTab,tab }: any) {
  const categories = [
    {
      title: "By Me",
      value: "client",
    },
    {
      title: "By My Coach",
      value: "coach",
    },
  ];


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams?.get('type');

  useEffect(() => {
    if (type) {
        setTab(type);
    }
  }, [type, setTab]);
  return (
    <div className="w-full max-w-xl sm:px-0">
      <Tab.Group
        onChange={(index) => {
          index === 0 ? setTab("client") : setTab("coach");
        }}
        selectedIndex={tab=== "client" ? 0 : 1}
      >
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-2 border border-white">
          {categories.map((category, index) => (
            <Tab
              key={index}
              value={tab}
              className={({ selected }) =>
                classNames(
                  "w-full  rounded-lg py-2.5 text-[2.4rem] leading-[3rem] font-medium text-blue-700",
                  "ring-none focus:outline-none",
                  selected ? "bg-primary_blue text-white" : "text-white"
                )
              }
            >
              {category.title}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
}
