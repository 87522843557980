import React from "react";
import FilterIcon from "assets/icons/filter-icon.svg";
import CreateButton from "./components/CreateBtn";
import MyChallenges from "./MyChallenges";
import MyCompleteChallenges from "./MyCompleteChallenges";

const Challenges = () => {
  const [isActive, setIsActive] = React.useState(true);

  return (
    <div>
      <div className="mt-[5.8rem] mb-[5rem] flex flex-row justify-between">
        <button
          onClick={() => setIsActive(!isActive)}
          className="inline-flex px-[2rem] justify-center text-white text-[2.45rem] bg-primary_blue font-semibold  hover:tex-primary_light rounded-[0.61rem]"
        >
          <div className="flex flex-row gap-3 items-center  h-[5.97rem] justify-center">
            <img className="w-[2.24rem]" src={FilterIcon}></img>
            {isActive ? "Active" : "Inactive"}
          </div>
        </button>
        <CreateButton link="/client/tasks"/>
      </div>

      <div>{isActive ? <MyChallenges /> : <MyCompleteChallenges />}</div>
    </div>
  );
};

export default Challenges;
