const LegacyShowComp = ({ heading, description }: any) => {
  const splitData = description?.split("\n");

  return (
    <div className="max-h-[32rem] overflow-y-auto pr-5" id="scroll">
      <h4 className="text-grey_medium underline underline-offset-[7px] text-[3.04634rem] mt-[2.7rem] break-normal mb-8">
        {heading}
      </h4>
      <ul className="mb-6">
        {splitData?.map((item: any) => {
          if (item) {
            return <li key={item} className="text-grey_medium text-5xl mb-6 list-disc flex gap-8 items-center">
              <span className="inline-block w-[7px] h-[7px] rounded bg-primary_blue"></span>
              <span>{item}</span>
            </li>
          }
        })}
      </ul>
    </div>
  );
};

export default LegacyShowComp;
