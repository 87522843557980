import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndpoint from "config/environment";

export const coachServerApi = createApi({
  reducerPath: "coachServerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/coach/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as any).auth.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: [
    "Championships",
    "ActionItems",
    "Notes",
    "Challenges",
    "Call",
    "UpcomingCall",
    "MergedCalls",
  ],

  endpoints: (builder) => ({
    getActionItemsByUser: builder.query({
      query: ({ clientId }) => `action-item/client/${clientId}`,
      providesTags: ["ActionItems"],
    }),

    deleteActionItem: builder.mutation({
      query: (id) => ({
        url: `action-item/${id}`,
        method: "DELETE",
      }),
    }),

    getNotes: builder.query({
      query: (id) => `notes/${id}`,
      providesTags: ["Notes"],
    }),

    createNote: builder.mutation({
      query: (payload) => ({
        url: "notes/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Notes"],
    }),

    deleteNote: builder.mutation({
      query: (id) => ({
        url: `notes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Notes"],
    }),

    updateNote: builder.mutation({
      query: ({ id, payload }) => ({
        url: `notes/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Notes"],
    }),

    //Leaderboard
    getCoachLeaderboard: builder.query({
      query: () => "leaderboard",
      providesTags: ["Challenges", "Championships", "ActionItems"],
    }),

    //GET ALL Coach's CLIENTS
    getAllClients: builder.query({
      query: () => "clients",
    }),

    getClientsList: builder.query({
      query: () => "clients-all",
    }),

    // Schedule Call Apis
    createScheduleCall: builder.mutation({
      query: (payload) => ({
        url: "call/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Call", "MergedCalls"],
    }),

    createGroupCall: builder.mutation({
      query: (payload) => ({
        url: "call/group-call",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Call", "MergedCalls"],
    }),

    getClientCheckIns: builder.query({
      query: (id) => `checkin/${id}`,
    }),

    // Create Call
    createCall: builder.mutation({
      query: (payload) => ({
        url: "call-library/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Call"],
    }),

    deleteScheduleCall: builder.mutation({
      query: (id) => ({
        url: `call/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MergedCalls", "Call", "UpcomingCall"],
    }),
    
    deleteGoogleScheduleCall: builder.mutation({
      query: (payload) => ({
        url: `call/${payload.id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["MergedCalls", "Call", "UpcomingCall"],
    }),
    getByIdScheduleCall: builder.query({
      query: (id) => `call/${id}`,
      providesTags: ["Call"],
    }),
    updateScheduleCall: builder.mutation({
      query: ({ id, payload }) => ({
        url: `call/update/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Call"],
    }),

    getCallsLibrary: builder.query({
      query: (id) => `call-library/${id}`,
      providesTags: ["Call"],
    }),

    // Get all coaches
    getCoaches: builder.query({
      query: () => `all-coaches`,
      providesTags: ["Call"],
    }),
    removeCoach: builder.mutation({
      query: ({ payload }) => ({
        url: `call/remove-coach`,
        method: "DELETE",
        body: payload,
      }),
      invalidatesTags: ["Call"],
    }),

    getCoachById: builder.query({
      query: (id) => `/coach-details/${id}`,
      providesTags: ["Call"],
    }),

    // Get client Champions Legacy
    getChampionshipLegacyStatement: builder.query({
      query: (id) => `legacy/${id}`,
      // providesTags: ["Action"],
    }),
    //Get Huddle admin calls
    getHuddleCalls: builder.query({
      query: () => `call/huddle/fetch`,
    }),
    //Get Substitute coach calls of caoch
    getSubstituteCoachCalls: builder.query({
      query: () => `call/substiute-calls`,
    }),
    //Get merged recorded and uploaded calls
    getMergedCalls: builder.query({
      query: () => `call/merged/calls?data=merged`,
    }),

    getGroupCallRecordings: builder.query({
      query: () => `call/merged/group-calls`,
    }),

    //Get merged recorded and uploaded  huddle calls
    getMergedHuddleCalls: builder.query({
      query: () => `/call-library/huddle-merged`,
    }),
    //Get merged recorded and uploaded  huddle calls
    getMergedScheduledUpcomingCalls: builder.query({
      query: (params) => {
        let url = "/call/merged/upcoming";
        if(params.callType){
          url = `${url}?callType=${params.callType}`
        }
        return url
      },
      providesTags: ["MergedCalls"],
    }),
    getUpcomingGroupCalls: builder.query({
      query: () => `/call/merged/upcoming-group`,
      providesTags: ["MergedCalls", "MergedCalls"],
    }),

    //get swot analysis
    getSwotAnalysis: builder.query({
      query: (id) => `swot-analysis/${id}`,
      // providesTags: ["OnBoarding"],
    }),

    // GHL  answers onBoarding
    getOnboardingGHLAnswers: builder.query({
      query: (id) => `ghl/onboard-answers/${id}`,
      // providesTags: ["OnBoarding"],
    }),

    getClientById: builder.query({
      query: (id) => `get-client/${id}`,
    }),

    registerCourse: builder.mutation({
      query: (payload) => ({
        url: `course/register`,
        method: "POST",
        body: payload,
      }),
    }),
    //GET STREAKS
    getClientStreaks: builder.query({
      query: (id) => `streak/${id}`,
    }),
  }),
});

export const {
  useDeleteActionItemMutation,
  useCreateNoteMutation,
  useGetNotesQuery,
  useGetCoachLeaderboardQuery,
  useGetActionItemsByUserQuery,
  useGetAllClientsQuery,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
  useCreateScheduleCallMutation,
  useCreateGroupCallMutation,
  useDeleteScheduleCallMutation,
  useDeleteGoogleScheduleCallMutation,

  useGetByIdScheduleCallQuery,
  useUpdateScheduleCallMutation,
  useGetClientCheckInsQuery,
  useCreateCallMutation,
  useGetCallsLibraryQuery,
  useGetCoachesQuery,
  useRemoveCoachMutation,
  useGetCoachByIdQuery,
  useGetChampionshipLegacyStatementQuery,
  useGetHuddleCallsQuery,
  useGetSubstituteCoachCallsQuery,
  useGetMergedCallsQuery,
  useGetMergedHuddleCallsQuery,
  useGetMergedScheduledUpcomingCallsQuery,
  useGetUpcomingGroupCallsQuery,
  useGetOnboardingGHLAnswersQuery,
  useGetClientByIdQuery,
  useGetSwotAnalysisQuery,
  useGetClientsListQuery,
  useGetGroupCallRecordingsQuery,
  useRegisterCourseMutation,
  useGetClientStreaksQuery
} = coachServerApi;
