import React from "react";
import BgImage from "../assets/images/bg-image.png";
import { useGetBannerByTypeQuery } from "apis/general";

interface LayoutProps {
    children: React.ReactNode;
}

const OnBoardingLayout = ({ children }: LayoutProps) => {
    const id = "COACH_CLIENT_LOGIN";
    const role = "client";
    const { data } = useGetBannerByTypeQuery(
      { id, role },
      {
        refetchOnMountOrArgChange: true,
        refetchOnFocus:true,
      }
    );
    return (
        <div className="h-screen overflow-hidden relative">
            <div className="relative">
                <div className="relative">
                    <div className="w-full h-screen bg-darkgrey absolute top-0 left-0 opacity-90"></div>
                    <img className="auth-bg-image h-screen object-cover" alt="backgroundImage" 
                    src={data?.data?.result?.imageUrl
                      ? data?.data?.result?.imageUrl
                      : BgImage}>                       
                      </img>
                    <div className="z-50 absolute top-[25rem] right-[3rem] sm:top-[7.21rem] sm:right-[5.33rem] flex gap-8">
                    </div>
                </div>
            </div>
            <div className="absolute top-0 left-0">
                <div className="h-screen w-screen flex justify-center items-center">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default OnBoardingLayout;
