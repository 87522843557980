import FooterBackground from "../assets/images/FooterBackground.svg";
import GooglePlay from "../assets/images/GooglePlay.svg";
import AppStore from "../assets/images/AppStore.svg";
import Facebook from "../assets/images/Facebook.svg";
import Youtube from "../assets/images/Youtube.svg";
import Instagram from "../assets/images/InstagramF.svg";
import Tiktok from "../assets/images/tiktok.svg";
import LogoFooter from "../assets/icons/winratefooter.png"
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();
  const isClient = pathname.includes("/client");
  return (
    <>
      <footer
        className="footer-1 py-12 tablet:py-12 px-8 tablet:px-0 w-full bg-no-repeat bg-cover bg-[#152128]"
        style={{ backgroundImage: "url(" + FooterBackground + ")" }}
      >
        <div className="container px-4 lg:px-0 mx-auto">
          <img src={LogoFooter} className="!w-[28.7rem] !h-[11rem]" />
          <div className="grid col-span-1 sm:grid-cols-3 tablet:py-4">
            <div className="sm:px-8 w-full flex flex-col items-start justify-start md:!border-r md:border-[#FFFFFF] md:!border-opacity-30">
              <h5 className="text-4xl font-semibold mb-6 !text-[#E6E6E6]">
                Stay Connected
              </h5>
              <ul className="list-none footer-links">
                <li>
                  <p className="!text-4xl !text-[#E6E6E6] !leading-[4.8rem]">
                    Follow us on social media to stay up-to-date with our latest
                    news and updates. We love hearing from our community, so
                    feel free to like, comment, and share your thoughts with us.
                  </p>
                </li>
              </ul>
              <h5 className="text-4xl font-semibold mb-6 !text-[#E6E6E6] !mt-[3.1rem]">
                Follow Us
              </h5>
              <ul className="list-none footer-links !flex">
                <li>
                  <a
                    href="https://www.instagram.com/winrateconsulting/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt=""
                      src={Instagram}
                      className="!w-[4.8rem] !h-[4.8rem] !mr-8"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/winrateconsulting/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt=""
                      src={Facebook}
                      className="!w-[4.8rem] !h-[4.8rem] !mr-8"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC86r4_wMGU5M1-dVovjxf1w"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt=""
                      src={Youtube}
                      className="!w-[4.8rem] !h-[4.8rem] !mr-8"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@winrate_mikec"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="TiktokIcon"
                      src={Tiktok}
                      className="!w-[4.8rem] !h-[4.8rem] !mr-8"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="sm:px-4 w-full flex flex-col items-start sm:items-center sm:justify-start mt-8 sm:mt-0 sm:border-r sm:border-[#FFFFFF] sm:border-opacity-30">
              <h5 className="text-4xl font-semibold mb-[4.6rem] !text-[#E6E6E6]">
                Useful Links
              </h5>
              <ul className="list-none footer-links">
                <li className="mb-5">
                  <Link
                    to={`${
                      isClient ? "/client/video-calls" : "/coach/video-calls"
                    }`}
                    className=" border-solid border-transparent !text-3xl !text-[#E6E6E6] !leading-9 border-b-0"
                  >
                    Video Calls
                  </Link>
                </li>
                <li className="mb-5">
                  <Link
                    to={`${isClient ? "/client/courses" : "/coach/courses"}`}
                    className=" border-solid border-transparent !text-3xl !text-[#E6E6E6] !leading-9 border-b-0"
                  >
                    New Courses
                  </Link>
                </li>
                <li className="mb-5">
                  <Link
                    to={`${isClient ? "/client/podcasts" : "/coach/podcasts"}`}
                    className=" border-solid border-transparent !text-3xl !text-[#E6E6E6] !leading-9 border-b-0"
                  >
                    Podcasts
                  </Link>
                </li>
                <li className="mb-5">
                  <Link
                    to={`${isClient ? "/client/huddles" : "/coach/huddles"}`}
                    className=" border-solid border-transparent !text-3xl !text-[#E6E6E6] !leading-9 border-b-0"
                  >
                    Huddles
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sm:px-4 lg:pl-[10rem] flex flex-col items-start justify-start w-full mt-8 md:mt-0">
              <h5 className="text-4xl font-semibold mb-6 !text-[#E6E6E6]">
                Contact us
              </h5>
              <ul className="list-none footer-links">
                <li className="mb-[5.6rem]">
                  <a
                    href={`mailto:support@winrateconsulting.com`}
                    className="whitespace-nowrap border-b-0 border-solid break-all border-transparent !text-4xl !text-[#E6E6E6] !leading-10"
                  >
                    support@winrateconsulting.com
                  </a>
                </li>
                <li className="mb-8 cursor-pointer">
                  <a href="https://play.google.com/store/apps/details?id=com.winrate.app" target="_blank">
                    <img src={GooglePlay} className="!w-[18.9rem] !h-[5.5rem] cursor-pointer" />
                  </a>
                </li>
                <li className="mb-8">
                  <a href="https://apps.apple.com/us/app/winrate-consulting/id6446288804" target="_blank">
                    <img src={AppStore} className="!w-[18.9rem] !h-[5.5rem] cursor-pointer" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="sm:flex sm:flex-wrap py-10 !bg-[#0F181D] !w-full !mx-auto">
        <div className="w-full text-center">
          <strong className="text-[#E6E6E6] text-xl">
            All Rights Reserved © 2023 WinRate Consulting
          </strong>
        </div>
      </div>
    </>
  );
};

export default Footer;
