import React, { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import ClientsDetail from './ClientsDetail'
import CoachDetail from './CoachDetail'
import Calendar from "assets/icons/calendar-group.svg"
import { useLocation, useNavigate } from 'react-router-dom'

const CoachTabs = ({ setSearchInput }: any) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const TabContents = ["Clients", "Coach Leaderboard"];
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams?.get('type');
  
    useEffect(() => {
      if (type) {
        if(type === "coach"){
            setSelectedIndex(1);
        }
        if(type === "client"){
            setSelectedIndex(0);
        }
      }
    }, [type, setSelectedIndex]);

    return (
        <div>
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <div className='flex justify-between flex-col md:flex-row gap-[16px]'>
                    <div className='flex flex-col gap-[2rem] tablet:flex-row tablet:items-center  justify-between'>
                        <div>
                            <Tab.List className="flex whitespace-nowrap w-full justify-between tablet:gap-[8rem] h-[5rem]">
                                {TabContents?.map((item, index) => {
                                    return (
                                        <Tab key={index} onClick={() => setSearchInput(item)} className={({ selected }) => (
                                            `text-[3rem] tablet:text-[3.5rem] leading-[4.1rem] focus:outline-none ${selected ? "text-[#4BAAFF] font-bold" : "text-white font-medium"}`
                                        )
                                        }>{item}</Tab>
                                    )
                                })}
                            </Tab.List>
                        </div>
                    </div>

                    <div className='flex items-center gap-6'>
                        <button
                            onClick={() => navigate("/coach/schedule-call")}
                            className='bg-[#2165A2] text-white text-4xl px-8 py-6 rounded-xl flex items-center gap-2 w-max'>
                            <img className='w-10' src={Calendar}></img>
                            Schedule Call
                        </button>
                        <button
                            onClick={() => navigate("/coach/group-call")}
                            className='bg-[#2165A2] text-white text-4xl px-8 py-6 rounded-xl flex items-center gap-2 w-max'>
                            <img className='w-10' src={Calendar}></img>
                            Schedule Group Call
                        </button>
                    </div>
                </div>
                <Tab.Panels>
                    <Tab.Panel className="text-white"><ClientsDetail /></Tab.Panel>
                    <Tab.Panel className="text-white"><CoachDetail /></Tab.Panel>
                </Tab.Panels>
            </Tab.Group >
        </div>
    )
}

export default CoachTabs