import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import GooglePlay from "assets/images/GooglePlay.svg";
import AppStore from "assets/images/AppStore.svg";
import { isAndroid, isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

export default function DownloadAppModal() {
  const [isOpen, setIsOpen] = useState(false)
  const { pathname } = useLocation();

  const isClient = pathname.includes("/client");

  function closeModal() {
    setIsOpen(false);
    const date: any = new Date();
    localStorage.setItem("DOWNLOAD_APP", date);
  }

  function openModal() {
    const date: any = new Date();
    localStorage.setItem("DOWNLOAD_APP", date);
    setIsOpen(true);
  }

  useEffect(() => {
    if (isClient) {
      if (isMobile) {
        const dateState = localStorage.getItem("DOWNLOAD_APP");
        if (!dateState) {
          openModal();
        }

        if (dateState) {
          const dayAfter = dayjs().isAfter(dayjs(dateState), "day");
          if (dayAfter) {
            openModal();
          }
        }
      }
    }

  }, []);

  const openAppHandler = () => {
    if (isMobile) {
      if (isAndroid) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.winrate.app&pli=1';
      } else {
        window.open("https://apps.apple.com/us/app/winrate-consulting/id6446288804", "blank")
      }
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000] bg-opacity-90" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bottom-0">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl m-[14px] p-16 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-6xl font-medium  text-primary_blue text-center mb-6"
                  >
                    Download App
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-4xl text-gray-500 text-center mb-8">
                      For Better WinRate Experience Download WinRate Consulting App
                    </p>
                  </div>
                  <div className='mb-16' onClick={openAppHandler}>
                    <img className='w-[26rem] mx-auto' src={isAndroid ? GooglePlay : AppStore}></img>
                  </div>

                  <div className="mt-4 text-center">
                    <button
                      type="button"
                      className="text-4xl text-primary_blue underline underline-offset-2"
                      onClick={closeModal}
                    >
                      Continue on Web
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
