import { Tab } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function HuddleCallsTabs({ setTab }: any) {
  const categories = [
    {
      title: "Previous Calls",
      value: "previouscalls",
    },
    {
      title: "Upcoming Calls",
      value: "upcomingcalls",
    },
  ];

  return (
    <div className="flex w-full justify-between">
      <div className="w-full max-w-[31.4rem] sm:px-0">
        <Tab.Group
          onChange={(index) => {
            index === 0 ? setTab("previouscalls") : setTab("upcomingcalls");
          }}
        >
          <Tab.List className="flex space-x-1 rounded-[0.9rem] p-2 bg-blue-900/20 border border-white">
            {categories.map((category, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg  text-[1.6rem] leading-[3rem] font-medium text-blue-700",
                    "ring-none focus:outline-none",
                    selected
                      ? "bg-primary_blue text-white py-2.5"
                      : "text-white"
                  )
                }
              >
                {category.title}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>
      <div className="flex justify-end"></div>
    </div>
  );
}
