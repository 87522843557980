import moment from "moment";
import CloseIcon from "../../../../assets/images/CloseIcon.svg";
import ProfileImg from "../../../../assets/images/profile.png";
const ParticipantModal = ({ event, item, showModal, setShowModal }: any) => {
  return (
    <>
      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full bg-white p-20 my-6 mx-auto max-w-[100rem] rounded-[2rem] shadow-2xl">
              <div className="cursor-pointer flex justify-end">
                <img
                  src={CloseIcon}
                  alt="close icon"
                  onClick={() => setShowModal(false)}
                />
              </div>
              <div className="mt-10">
                <div className="flex items-start gap-10">
                  <div className=" w-40 h-40 rounded-full overflow-hidden">
                    <img
                      src={
                        item?.profile_photo ? item?.profile_photo : ProfileImg
                      }
                      alt="member profile img"
                      className="w-full h-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-black text-[2.8rem] font-medium">
                      {item?.full_name}
                    </h4>
                    <h4 className="text-grey_semi text-[2rem] mt-2">
                      {item?.role[0]}
                    </h4>
                    <h4 className="text-black text-[2.8rem] font-medium mt-10">
                      Email
                    </h4>
                    <h4 className="text-grey_semi text-[2rem] mt-2">
                      {item?.email}
                    </h4>
                    <h4 className="text-black text-[2.8rem] font-medium mt-10">
                      Phone
                    </h4>
                    <h4 className="text-grey_semi text-[2rem] mt-2">
                      {item?.phoneNo}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="px-48 mt-20">
                <h4 className="text-[3.4rem] text-primary_blue font-bold">
                  {event?.title}
                </h4>
                <h4 className="mt-5 text-[2.2rem] text-[#333333] break-words">
                  {event?.description}
                </h4>
              </div>
              <div className="flex justify-evenly items-center mt-20">
                <div>
                  <h4 className="text-[3rem] text-[#333333] font-medium">
                    Date
                  </h4>
                  <h4 className="text-[2rem] text-[#ADADAD]">
                    {moment(event?.date).format("l")}
                  </h4>
                </div>
                <div>
                  <h4 className="text-[3rem] text-[#333333] font-medium">
                    Time
                  </h4>
                  <h4 className="text-[2rem] text-[#ADADAD]">{event?.time}</h4>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ParticipantModal;
