import { GoogleToken } from "apis/auth-apis";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessPage from "components/SuccessPage";
import ErrorPage from "components/ErrorPage";
import { toast } from "react-toastify";

export default function GoogleAuth() {
  const navigate = useNavigate();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const code = query.get("code");
  useEffect(() => {
    if (code) {
      GoogleToken(code).then(() => {
        setTimeout(() => {
          navigate("/coach");
        }, 3000);
      }).catch((err)=>{
        navigate("/coach");
      if(err?.response?.data){
        toast.error(err?.response?.data?.message)
      }
      });
    }
  }, [code]);
  const dashboardClickHandler = () => {
    navigate("/coach");
  };
  return (
    <>
      <div>
        {code ? (
          <SuccessPage onClick={dashboardClickHandler} />
        ) : (
          <ErrorPage onClick={dashboardClickHandler} />
        )}
      </div>
    </>
  );
}
