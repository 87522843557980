import React from "react";
import PlayButton from "assets/images/PlayButton.svg";
import dayjs from "dayjs";
import PlayPodcastModal from "./PlayPodcastModal";

interface PodcastCardProps {
  id: any;
  title: string;
  desc: string;
  date: string;
  duration?: string;
  thumbnail: string;
  toPage?: string;
  playVideo?: string;
  season?: string;
  audio: string;
}

const RssFeedCard = ({
  title,
  date,
  duration,
  thumbnail,
  desc,
  season,
  audio,
}: PodcastCardProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="w-full bg-white rounded-[0.8rem] overflow-hidden">
        <div className="relative h-[26rem]">
          <div className="bg-darkgrey w-full h-full absolute top-0 left-0 opacity-[30%]"></div>
          <div>
            <img
              className="w-full h-[26rem] "
              src={thumbnail}
              alt="Card Thumbnail"
            />
          </div>
          <div
            className="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] cursor-pointer"
            onClick={openModal}
          >
            <img className="w-[5rem]" src={PlayButton} alt="Play Button" />
          </div>
          {duration && (
            <div>
              <h6 className="absolute text-white font-bold leading-9  text-[2rem] right-8 bottom-8">
                {duration}
              </h6>
            </div>
          )}
        </div>

        <div className=" flex px-[1rem] items-end gap-4 py-[1.4rem]">
          <h1 className="text-primary_blue rss_card_title capitalize min-w-[9.8rem] text-4xl font-bold text-ellipsis overflow-hidden">
            {title}
          </h1>
          <h1 className="text-[#33333399]  min-w-[10rem] text-2xl font-bold text-right">
            {dayjs(date).format("MM-DD-YYYY")}
          </h1>
        </div>
      </div>
      <PlayPodcastModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={title}
        image={thumbnail}
        desc={desc}
        date={date}
        duration={duration}
        season={season}
        audio={audio}
      />
    </>
  );
};

export default RssFeedCard;