import ReactDatePicker from 'react-datepicker';
import CheckGrayIcon from "assets/icons/gray-check-mark-icon.svg";

const EmptyStreakModal = () => {
    const minDate = new Date();
    const startDate = new Date();
    const renderDayContent = (dayOfMonth: any, date: any) => {
        const icon = date ? CheckGrayIcon : CheckGrayIcon
        return (
            <img src={icon} />
        )
    };

    return (
        <div>
            <ReactDatePicker
                onChange={() => { }}
                startDate={startDate}
                minDate={minDate}
                inline
                calendarClassName={"streak_calender"}
                renderDayContents={(dayOfMonth, date) => renderDayContent(dayOfMonth, date)}
            />
        </div>
    );
}

export default EmptyStreakModal