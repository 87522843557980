
const TabContent = ({ heading, selected }: any) => (
    <div className="bg-[#fff] py-[1.7rem] pl-[2.8rem] pr-[1.8rem] sm:py-[5.3rem] sm:px-[6rem] mt-[4.25rem] sm:mt-[4.5rem] sm:rounded-[2rem] rounded-[0.67rem]">
      <div className="mb-[1.8rem] sm:mb-[3rem] flex items-center justify-between">
        <h5 className="sm:text-[5.8rem] text-[3.2rem] font-medium text-[#299AFF] capitalize">
          {selected}
        </h5>
      </div>
      <div id="swot_tabs">
        {heading?.length > 0 ? (
          <ul className="text-5xl pl-[3.2rem] sm:text-[4.1rem] sm:leading-[4.9rem] font-normal text-[#333333] mb-[2.2rem] sm:mb-[2rem]">
            {heading?.map((item: any, idx: number) => {
              if(item){
                return (
                  <li key={idx}>{item}</li>
                )
              }
            })}
          </ul>
        ) : (
          <p className="text-3xl md:text-[4.1rem] md:leading-[4.9rem] font-normal text-[#333333] mb-[2.2rem] sm:mb-[7rem]">
            {heading?.length > 0 ? heading : "No description found"}
          </p>
        )}
      </div>
    </div>
  );

export default TabContent;
