import Twit from "assets/images/Twit.svg";
import Insta from "assets/images/Insta.svg";
import Fb from "assets/images/Fb.svg";
import Layout from "layouts/Layout";
import Hero from "components/Hero";
import HomeBanner from "assets/images/HomeBanner.png";
import { useGetCoachByIdQuery } from "apis/coach";
import { useParams } from "react-router-dom";
import EmptyProfile from "assets/images/EmptyProfile.jpg";
import dayjs from "dayjs";
import numberWithCommas from "utils/commaseperatedfunction";

const CoachProfile = () => {
  const { id } = useParams();
  const { data } = useGetCoachByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const coach = data?.data || [];

  const checkLink = (url: string) => {
    // if (url?.includes("http://") || url?.includes("https://")) {
    //   return url;
    // } else {
    //   return `https://${url}`;
    // }
    return url
  };

  return (
    <Layout>
      <Hero image={HomeBanner} />
      <div className="container mx-auto py-28 px-10 desktop:px-10">
        <div className="bg-[#202F39]">
          <div>
            <div className="grid grid-cols-1 tablet:grid-cols-2 gap-5 items-center">
              <div className="flex flex-col tablet:flex-row items-center gap-10">
                <div className="w-[20rem] min-w-[20rem] rounded-full border-2 border-white text-white overflow-hidden">
                  <img
                    className="w-[20rem] h-[20rem] object-cover"
                    src={
                      coach?.user?.profile_photo
                        ? coach?.user?.profile_photo
                        : EmptyProfile
                    }
                    alt="Coach"
                  />
                </div>
                <div className="text-center max-w-[50rem] tablet:text-left">
                  <h1 className="text-white text-[4rem] leading-none font-bold">
                    {coach?.user?.full_name}
                  </h1>
                  <h1 className="text-white text-[4rem] leading-none font-normal">
                    {coach?.user?.address}
                  </h1>
                </div>
              </div>
              <div className="mt-[4rem] tablet:mt-0">
              </div>
            </div>
          </div>
          <div className="mt-[6rem] grid grid-cols-3 gap-10 sm:gap-6 lg:flex lg:justify-between">
            <div className="text-center col-span-3 md:col-span-1">
              <h1 className="text-white text-[3.8rem] leading-[3.8rem] font-bold text-center">
                Total Clients
              </h1>
              <h1 className="text-white text-[3.8rem] leading-[6rem] text-center font-normal">
                {numberWithCommas(coach?.clientCount)}
              </h1>
            </div>
            <div className="text-center col-span-3 md:col-span-1 lg:ml-40">
              <h1 className="text-white text-[4rem] leading-[6rem] font-bold text-center">
                Rank
              </h1>
              <h1 className="text-white text-[3.8rem] leading-[3.8rem] font-normal text-center">
                { numberWithCommas(coach?.user?.rank)}
              </h1>
            </div>
            <div className="text-center col-span-3 md:col-span-1">
              <h1 className="text-white text-[4rem] leading-[6rem] font-bold text-center">
                Coaching Since
              </h1>
              <h1 className="text-white text-[3.8rem] leading-[3.8rem] font-normal text-center">
                {dayjs(coach?.user?.createdAt).format("MM/DD/YYYY")}
              </h1>
            </div>
          </div>
          <div className="w-full tablet:px-20 sm:px-0 md:mx-auto tablet:w-[73.1rem] tablet:mx-auto mt-[10rem]">
            <form className="schedule_call_input">
              <div className="w-full mx-auto">
                <label>Company Name</label>
                <input
                  disabled
                  value={coach?.user?.company}
                  type="text"
                  className="placeholder:text-white"
                  placeholder=""
                />
              </div>
              <div className="mt-[2.6rem] mx-auto">
                <label>E-mail</label>
                <input
                  disabled
                  value={coach?.user?.email}
                  type="email"
                  className="placeholder:text-white"
                  placeholder="jesica@example.com"
                />
              </div>
              <div className="mt-[2.6rem] w-full mx-auto">
                <label>Phone</label>
                <input
                  disabled
                  value={coach?.user?.phoneNo}
                  type="text"
                  className="placeholder:text-white"
                  placeholder="+009-1122334455"
                />
              </div>
            </form>
          </div>

          {(coach?.user?.facebook ||
            coach?.user?.instagram ||
            coach?.user?.twitter) && (
              <h1 className="mt-[4rem] tablet:mt-[6rem] text-center text-white text-[6rem] font-normal">
                Connect
              </h1>
            )}
          <div className="mt-[3rem] gap-[2rem]  tablet:mt-[4rem] px-[2.8rem] tablet:px-0 mx-auto flex items-center justify-center mb-[3rem]">
            {coach?.user?.facebook && (
              <a href={checkLink(coach?.user?.facebook)} target="_blank" rel="noreferrer">
                <div className="w-[7rem]">
                  <img className="w-full h-full" src={Fb} alt="Facebook" />
                </div>
              </a>
            )}

            {coach?.user?.instagram && (
              <a href={checkLink(coach?.user?.instagram)} target="_blank" rel="noreferrer">
                <div className="w-[7rem]">
                  <img className="w-full h-full" src={Insta} alt="Instagram" />
                </div>
              </a>
            )}

            {coach?.user?.twitter && (
              <a href={checkLink(coach?.user?.twitter)} target="_blank" rel="noreferrer">
                <div className="w-[7rem]">
                  <img className="w-full h-full" src={Twit} alt="Twitter" />
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CoachProfile;
