import { useState } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import ClientHeroImage from "assets/images/client-hero.png";
import {
  useGetHuddleCallsQuery,
  useGetMergedHuddleCallsQuery,
} from "apis/client";
import FilteruplaodedCall from "../../../components/FilteruplaodedCall";
import HuddleCallsTabs from "./HuddleCallTabs";
import ScheduleCallComp from "components/ScheduleCallComp";
import ZoomCallCard from "components/ZoomCallCard";

const Huddles = () => {
  const [tab, setTab] = useState("previouscalls");

  const { data, isLoading } = useGetMergedHuddleCallsQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  const { data: huddleCall, isLoading: huddleLoading } = useGetHuddleCallsQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  const prevHuddles = data?.data?.data;

  const scheduleCallsAdmin = huddleCall?.data?.calls;
  const filterHuddleCalls = scheduleCallsAdmin?.map((call: any) => {
    const temp = new Date(call.date).getTime() / 1000;
    return {
      ...call,
      filterDate: temp
    }
  })?.sort((a: any, b: any) => a?.filterDate - b?.filterDate);

  return (
    <Layout>
      <Hero image={ClientHeroImage} heading="Huddle Calls" />
      <div className="container mx-auto pt-[1rem] sm:pt-[2rem] px-10 desktop:px-10 pb-[8rem]">
        <div className="flex flex-col gap-8 lg:flex-row justify-between lg:items-end mt-10">
          <HuddleCallsTabs setTab={setTab} />
        </div>

        {tab === "previouscalls" && (
          <div>
            {isLoading && (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
              </div>
            )}

            {!isLoading && <h2 className="text-white text-5xl mt-20">Huddle Recordings</h2>}
            {(!isLoading && prevHuddles?.zoom?.length === 0) ? (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">No Recordings Found</p>
              </div>
            ) : (
              <div className="mt-[4rem] grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
                {prevHuddles?.zoom?.map((call: any, index: number) => {
                  const video = call?.recording_files?.find((vid: any) => vid.file_type === "MP4");
                  const url = `${video?.play_url}?pwd=${call?.recording_play_passcode}`
                  return (
                    <div
                      className="bg-white w-full max-h-[28.7rem] rounded-lg overflow-hidden cursor-pointer"
                      key={index}>
                      <ZoomCallCard
                        title={call?.topic}
                        postedTime={video?.recording_end || new Date}
                        status={video?.status}
                        url={url}
                      />
                    </div>
                  )
                })}
              </div>
            )}

            {!isLoading && <h2 className="text-white text-5xl mt-20">Huddle Uploads</h2>}
            {(!isLoading && prevHuddles?.upload?.length === 0) ? (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">No Upload Found</p>
              </div>
            ) : (
              <FilteruplaodedCall filterCalls={prevHuddles?.upload} />
            )}
          </div>
        )}



        {tab !== "previouscalls" && (
          <div>
            {huddleLoading ? (
              <div className="bg-white w-full my-[3rem] text-center py-12 rounded-lg">
                <p className="text-4xl sm:text-5xl text-primary_blue">Loading...</p>
              </div>
            ) : (
              <ScheduleCallComp title="Scheduled Huddles" type="huddle" data={filterHuddleCalls} />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Huddles;
