import Hero from "components/Hero";
import Layout from "layouts/Layout";
import ClientHeroImage from "assets/images/client-hero.png";
import { useGetPodcastsQuery } from "apis/general";
import RssFeedCard from "components/RssFeedCard";
import dayjs from "dayjs";

const Podcasts = () => {
  const { data, isLoading, isFetching } = useGetPodcastsQuery(1, { refetchOnMountOrArgChange: true });
  const podcasts = data?.data?.podcasts;
  const currentDate = dayjs();
  const oneWeekAgo = currentDate.subtract(1, "week");
  const lastWeeksPodcasts = podcasts?.filter((item: any) => {
    const itemDate = dayjs(item?.pubDate);
    return itemDate.isSameOrAfter(oneWeekAgo);
  });
  const pastWeeksPodcasts = podcasts?.filter((item: any) => {
    const itemDate = dayjs(item?.pubDate);
    return itemDate.isBefore(oneWeekAgo);
  });

  return (
    <Layout>
      <Hero image={ClientHeroImage} heading="WinRate Podcasts" />
      <div className="container mx-auto py-24 px-10 desktop:px-10">
        {isFetching || isLoading ? (
          <div className="bg-white w-full text-center py-12 rounded-lg">
            <p className="text-5xl text-primary_blue">Loading Podcasts...</p>
          </div>
        ) : (
          <>
            <h1 className=" text-white text-[4rem] font-bold  leading-[4.7rem] text-center lg:text-left">
              Recent Podcasts
            </h1>
            <div className="h-[50rem] overflow-y-auto pr-5">
              <div className="mt-[4rem] grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
                {lastWeeksPodcasts?.map((item: any, index: number) => (
                  <RssFeedCard
                    key={index}
                    id={index}
                    title={item.title}
                    desc={item.description}
                    date={item.pubDate}
                    duration={item.duration}
                    thumbnail={item.imageURL}
                    season={item.season}
                    audio={item.audioURL}
                  />
                ))}
              </div>
            </div>
            <h1 className=" mt-[4.6rem] mb-[3rem] text-white text-[4rem] font-bold leading-[4.7rem] text-center lg:text-left">
              Weekly Podcasts
            </h1>
            <div className="h-[50rem] overflow-y-auto pr-5">
              <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
                {pastWeeksPodcasts?.map((item: any, index: number) => (
                  <RssFeedCard
                    key={index}
                    id={index}
                    title={item.title}
                    desc={item.description}
                    date={item.pubDate}
                    duration={item.duration}
                    thumbnail={item.imageURL}
                    season={item.season}
                    audio={item.audioURL}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Podcasts;
