import CompletedChallengeCard from "./components/CompletedChalengesCard";
import { useGetChallengesQuery } from "apis/client";

const MyCompleteChallenges = () => {
  const { data } = useGetChallengesQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const inActiveChallenges = data?.data?.challenges?.filter(
    (item: any) => item?.status === "completed" && item?.isCompleted === true || item?.status === "expired"
  );

  
  return (
    <div className="mb-[2rem]">
      {inActiveChallenges?.length === 0 && (
        <div className="bg-[#fff] rounded-xl py-14 px-4">
          <h1 className="text-center text-primary_blue text-[3rem] font-medium">
            No Completed Challenges Found
          </h1>
        </div>
      )}

      <div className="grid grid-cols-1 mobile_l:grid-cols-2 tablet:grid-cols-3 gap-[1.76rem]">
        {inActiveChallenges?.map((item: any, index: number) => (
          <CompletedChallengeCard key={index} challenge={item} />
        ))}
      </div>
    </div>
  );
};

export default MyCompleteChallenges;
