import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import {
  useGetSwotsQuery,
  useCreateSwotMutation,
} from "apis/client";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SwotForm from "../SwotAnalysisCreate/SwotForm";

const Index = () => {
  const [page, setPage] = useState(0);
  const {
    data: SwotData,
  } = useGetSwotsQuery(0, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  }); // Fetch SWOT data

  const values: SwotObj = SwotData?.data?.swotAnalysis;
  const [createSwot, { isLoading: loading }] = useCreateSwotMutation();

  const [formData, setFormData] = useState({
    strengths: values?.strengths,
    weakNesses: values?.weakNesses,
    opportunities: values?.opportunities,
    threats: values?.threats,
  });
  const navigate = useNavigate();

  const data = [
    {
      label: "STRENGTHS",
      name: "strengths",
    },
    {
      label: "WEAKNESSES",
      name: "weakNesses",
    },
    {
      label: "OPPORTUNITIES",
      name: "opportunities",
    },
    {
      label: "THREATS",
      name: "threats",
    },
  ];

  interface SwotObj {
    createdAt: string; // Date and time when the object was created (format: "yyyy-MM-ddTHH:mm:ss.sssZ")
    id: string; // Unique identifier for the object
    isVisible: boolean; // Indicates whether the object is visible or not
    opportunities?: string; // Information about opportunities related to the object (optional field)
    status: string; // Current status of the object
    strengths?: string; // Information about the strengths of the object (optional field)
    threats?: string; // Information about threats related to the object (optional field)
    updatedAt: string; // Date and time when the object was last updated (format: "yyyy-MM-ddTHH:mm:ss.sssZ")
    userId: string; // Unique identifier of the user associated with the object
    weakNesses?: string; // Information about the weaknesses of the object (optional field)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function nextHandler(data: any) {
    if (page !== 3) {
      setPage(page + 1);
    } else {
      await createSwot(formData)
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          navigate("/client/swot-analysis");
        })
        .catch((error) => {
          toast.error(error?.data?.message);
        });
    }
  }

  return (
    <Layout>
      <Hero image={ClientHeroImage} subHeading="Edit SWOT Analysis" />
      <div className="container mx-auto py-32 px-10 desktop:px-0">
        <div className="w-100% bg-white rounded-[2rem] py-16 px-16 pb-32">
          <div className="w-full sm:w-[68rem] mx-auto">
            <h1 className="font-medium text-[#333333] text-[3rem] sm:text-[5rem]">
              Edit SWOT Analysis
            </h1>
            {data?.map((item: any, index: number) => {
              if (page === index) {
                return (
                  <SwotForm
                    key={index}
                    index={index}
                    name={item.name}
                    label={item.label}
                    isLoading={loading}
                    nextHandler={nextHandler}
                    setFormData={setFormData}
                    page={page}
                    formData={formData}
                    setPage={setPage}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
