import { useState } from "react";
import EyeIcon from "assets/icons/eye-icon.svg";
import EyeShutIcon from "assets/icons/eye-off-icon.svg";

interface TextInputProps {
  name: string;
  type: string;
  label?: string;
  palceholder?: string;
  icon?: any;
  register: any;
  value?: any;
  disabled?: any
}

const TextInput = ({
  type,
  label,
  palceholder,
  register,
  name,
  disabled,
  value,
}: TextInputProps) => {
  const [isPassword, setIsPassword] = useState(true);

  return (
    <div className="flex flex-col">
      {label ? (
        <label className="font-normal text-3xl mb-4">{label}</label>
      ) : (
        ""
      )}
      <div className="flex items-center bg-white h-24 border border-light_gray rounded-xl overflow-hidden focus-visible:border-0">
        <input
          {...register(name)}
          type={type === "password" ? (isPassword ? "password" : "text") : type}
          placeholder={palceholder}
          className={`h-24 !bg-transparent flex-1 border-0 border-light_gray text-4xl px-8  focus:shadow-white focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-0`}
          autoComplete="off"
          value={value}
          disabled={disabled}
        />
        {type === "password" ? (
          <div
            className="inline p-4 cursor-pointer"
            onClick={() => setIsPassword(!isPassword)}
          >
            <img src={isPassword ? EyeIcon : EyeShutIcon} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TextInput;
