import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import CallLibrary from "./CallLibrary";
import ClientActionItem from "./ClientActionItem";
import Notes from "./Notes";
import ClientForms from "../ClientForms";
import ClientChampionshipDay from "./ClientChampionshipDay";
import ClientWinRatePoints from "./ClientWinRatePoints";
import LeftArrow from "../../../../assets/icons/LeftArrow.svg";
import RightArrow from "../../../../assets/icons/RightArrow.svg";
import { useLocation } from "react-router-dom";

const ClientDetailsTab = ({ setScheduleBtn, scheduleBtn }: any) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const TabContents = [
    "Client Action Items",
    "Championship Day",
    "WinRate Points",
    "Call Library",
    "Forms",
    "Client Notes",
  ];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams?.get('type');
  const formValue = searchParams?.get('form');

  useEffect(() => {
    if (type) {
      const index = TabContents.findIndex((tab) => tab === type);
      if (index !== -1) {
        setSelectedIndex(index);
        setScheduleBtn(type);
      }
    }
  }, [type, setScheduleBtn]);

  useEffect(() => {
    const handleScroll = () => {
      const tabsWrapper = document.querySelector(".tabs-wrapper");
      if (tabsWrapper) {
        setScrollPosition(tabsWrapper.scrollLeft);
      }
    };
    document.querySelector(".tabs-wrapper")?.addEventListener("scroll", handleScroll);
    return () => {
      document.querySelector(".tabs-wrapper")?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollLeft = () => {
    const tabsWrapper = document.querySelector(".tabs-wrapper");
    if (tabsWrapper) {
      tabsWrapper.scrollBy({
        left: -150,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    const tabsWrapper = document.querySelector(".tabs-wrapper");
    if (tabsWrapper) {
      tabsWrapper.scrollBy({
        left: 150,
        behavior: "smooth",
      });
    }
  };

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <div className="relative rounded-xl overflow-hidden">
        <div className="bg-primary_blue w-full 
        outline-none px-8 py-4 tablet:px-12 
        tablet:py-[2rem] rounded-xl 
        overflow-auto gradient-bg">
          <div className="flex justify-center items-center gap-10">
            <button className="scroll-left text-white cursor-pointer min-w-[13px] min-h-[24px]" onClick={scrollLeft} disabled={scrollPosition === 0}>
              <img src={LeftArrow} alt="left arrow" />
            </button>
            <Tab.List className="tabs-wrapper hide-scrollbar flex justify-between flex-row items-start sm:flex-row gap-24 overflow-auto">
              {TabContents?.map((item, index) => {
                return (
                  <Tab
                    onClick={() => {
                      setScheduleBtn(item);
                    }}
                    key={index}
                    value={scheduleBtn}
                    className={({ selected }) =>
                      `text-white text-[1.8rem] sm:text-[3rem] 
                    leading-[1.6rem] sm:leading-[4.1rem] py-3 whitespace-nowrap pr-8 sm:pr-0
                    sm:py-0 outline-none ${selected ? "font-bold" : ""}`
                    }
                  >
                    {item}
                  </Tab>
                );
              })}
            </Tab.List>
            <button
              className="scroll-right text-white cursor-pointer min-w-[13px] min-h-[24px]"
              onClick={scrollRight}
            >
              <img src={RightArrow} alt="Right arrow" />
            </button>
          </div>
        </div>
      </div>
      <Tab.Panels>
        <Tab.Panel className="text-white">
          <ClientActionItem />
        </Tab.Panel>
        <Tab.Panel className="text-white">
          <ClientChampionshipDay />
        </Tab.Panel>
        <Tab.Panel className="text-white">
          <ClientWinRatePoints />
        </Tab.Panel>
        <Tab.Panel className="text-white">
          <CallLibrary />
        </Tab.Panel>
        <Tab.Panel className="text-white">
          <ClientForms defaultValue={Number(formValue)} />
        </Tab.Panel>
        <Tab.Panel className="text-white">
          <Notes />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default ClientDetailsTab;
