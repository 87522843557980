import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import FirstQuestion from "./components/Question";
import React, { useState } from "react";
import {
  useCreateCheckinFormMutation,
  useGetActiveCheckinFormQuery,
  useGetCheckinFormQuery
} from "apis/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import EditIcon from "../../../assets/icons/swot_edit.svg";
import CheckInAccord from "../OnBoarding/components/CheckInAccord";
import { selectCheckedIn } from "store/selectors";
import { setCheckedIn } from "store/slices/auth.slice";
import { useDispatch } from "react-redux";
import HistoryIcon from "../../../assets/icons/history-icon.svg";
import CheckInHistory from "./CheckInHistory";

const CheckinForm = () => {
  const [newArray, setNewArray] = useState<any>([]);
  const [isHistory, setIsHistory] = useState(false);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const isCheckedIn = useSelector(selectCheckedIn);
  const dispatch = useDispatch();

  const { data: checkInQues, isLoading: activeLoading, isError } = useGetActiveCheckinFormQuery(1, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  });

  const { data: checkInAns, isLoading: formLoading } = useGetCheckinFormQuery(1, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  });

  const form = checkInQues?.data;
  const questions = form?.checkInFormQuestions;
  const answers = checkInAns?.data?.filter((i:any) => i.checkInFormQuestions !== null);
  const isLastStep = questions?.length;
  const [createCheckinForm, { isLoading }] = useCreateCheckinFormMutation();

  const nextHandler = () => {
    if (page !== questions.length) {
      setPage(page + 1);
    }
  };

  const handleSave = (answers: any) => {
    const count = questions.length;
    setNewArray([
      ...newArray?.filter(
        (item: any) => item?.questionId !== answers?.questionId
      ),
      answers,
    ]);

    if (count === page) {
      const tempArr = [...newArray];
      tempArr.push(answers);
      const payload = {
        checkInFormId: form?.id,
        answers: tempArr,
        action: "create"
      };

      createCheckinForm(payload)
        .unwrap()
        .then((res: any) => {
          setLoader(false);
          dispatch(setCheckedIn(true));
          navigate("/client/account");
          toast.success(res?.message);
        })
        .catch((err) => {
          setLoader(false);
          if (err && err?.data) {
            toast.error(err?.data?.message);
          }
        });

      return;
    }
    nextHandler();
  };

  const formatedQuestions = answers?.map((answer: any) => {
    return {
      answer: answer?.answer,
      question: answer?.checkInFormQuestions?.question
    }
  });

  function _renderAccordian() {
    if (formatedQuestions && formatedQuestions?.length > 0) {
      return formatedQuestions?.map((item: any, index: number) => {
        return (
          <React.Fragment key={index}>
            <CheckInAccord
              question={item?.question}
              answer={item?.answer}
            />
          </React.Fragment>
        );
      });
    } else {
      return <div>No Data found</div>;
    }
  }

  return (
    <Layout>
      <Hero image={ClientHeroImage} subHeading="Client's Check-In Form" />
      <div className="container mx-auto px-10 desktop:px-10 pb-[5rem]">
        <div className="flex justify-between flex-col sm:flex-row items-start sm:items-center sm:mb-[3.1rem] mt-7">
          <h2 className="text-[#fff] text-[4rem]  font-medium mb-[2rem] underline underline-offset-8">
            {!isError && form?.title}
          </h2>

          {(!activeLoading && !formLoading) ? (
            <>
              {isError && (
                <p className="break-normal bg-white px-[3rem] py-[2rem] rounded-[1rem] text-[2rem] text-[red]">
                  Oops! It looks like there are no active forms for you to fill out at this time. Please check back later or contact customer support if you have any questions
                </p>
              )}


              {(isCheckedIn && !isError) ? (
                <>
                  {answers?.length ? (
                    <div className="flex flex-row justify-end sm:justify-between gap-10">
                      <button
                        onClick={() => setIsHistory(!isHistory)}
                        className={`flex items-center px-12 py-4 gap-3 rounded-2xl border border-1 ${isHistory ? "bg-[#2165A2]" : "bg-transparent"} ${!isHistory ? "border-[#fff]" : "border-[#2165A2]"}`}
                      >
                        <div>
                          <img className="w-[3.4rem]" src={HistoryIcon} alt="" />
                        </div>
                        <span className="text-white text-6xl font-medium">
                          History
                        </span>
                      </button>
                      <button
                        onClick={() => navigate("/client/checkin-form/edit")}
                        className="flex items-center bg-[#2165A2] px-20 py-4 gap-3 rounded-2xl"
                      >
                        <div className="w-[4rem]">
                          <img src={EditIcon} alt="" />
                        </div>
                        <span className="text-white text-6xl font-medium">
                          Edit
                        </span>
                      </button>

                    </div>
                  ) : ""}
                </>
              ) : <></>}
            </>
          ) : (
            <>
            </>
          )}
        </div>

        {(!activeLoading && !formLoading) ? (
          <>
            {(!isCheckedIn && !isError) ? (
              <div className="mt-20 mb-32">
                <div>
                  {questions?.map((item: any, index: any) => {
                    if (page === index + 1) {
                      return (
                        <div key={index}>
                          <FirstQuestion
                            item={item}
                            arrayLength={questions.length}
                            handleSave={handleSave}
                            data={newArray}
                            setPage={setPage}
                            page={page}
                            // isCompleted={isCompleted}
                            isLastStep={isLastStep}
                            nextHandler={nextHandler}
                            loading={loader}
                            isSavingData={isLoading}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}

            {(isCheckedIn && !isError) ? (
              <div className="mt-20 mb-32">
                <div>
                  {isHistory ? <CheckInHistory /> : (
                    <>
                      {answers?.length ? (
                        _renderAccordian()
                      ) : (
                        questions?.map((item: any, index: any) => {
                          if (page === index + 1) {
                            return (
                              <div key={index}>
                                <FirstQuestion
                                  item={item}
                                  arrayLength={questions.length}
                                  handleSave={handleSave}
                                  data={newArray}
                                  setPage={setPage}
                                  page={page}
                                  // isCompleted={isCompleted}
                                  isLastStep={isLastStep}
                                  nextHandler={nextHandler}
                                  loading={loader}
                                  isSavingData={isLoading}
                                />
                              </div>
                            );
                          }
                        })
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : <></>}


      </div>
    </Layout >
  );
};

export default CheckinForm;
