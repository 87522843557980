import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const SwotForm = ({
  name,
  label,
  nextHandler,
  isLoading,
  setFormData,
  page,
  setPage,
  formData,
  index,
}: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
  });
  const isLastStep = index === 3;
  const handleChange = (e: any) => {
    const { name: valName, value } = e.target;
    setValue(valName, value);
    setFormData((data: any) => ({
      ...data,
      [valName]: value,
    }));
  };

  const onSubmit = () => {
    nextHandler();
  };
  function handleBack() {
    setPage(page - 1)
  }
  useEffect(() => {
    let values: [string, unknown] | undefined = undefined;
    values = Object?.entries(formData)?.find((data: any) => name === data[0]);
    if (values !== undefined) {
      // Use values here
      setValue(values[0], values[1]);
    }
  }, [])


  return (
    <form className="flex flex-col gap-4">
      <p className="text-[3rem] sm:text-[5rem] leading-[5.9rem] font-medium mb-4 mt-12 text-[#333333]">
        {label}
      </p>
      <div className="mb-4">
        <textarea
          rows={7}
          placeholder="Enter Description"
          className="w-full rounded-xl border-2 px-6 py-6 resize-none border-[#333333] text-[2.8rem] leading-[2.8rem] outline-none focus:outline-double"
          {...register(name, {
            onChange: (e: any) => handleChange(e),
            required: true
          })}
        ></textarea>
        {errors?.strengths && <p className="mt-1 text-[2rem] text-[#D10C0C]">{"Required"}</p>}
        {errors?.weakNesses && <p className="mt-1 text-[2rem] text-[#D10C0C]">{"Required"}</p>}
        {errors?.opportunities && <p className="mt-1 text-[2rem] text-[#D10C0C]">{"Required"}</p>}
        {errors?.threats && <p className="mt-1 text-[2rem] text-[#D10C0C]">{"Required"}</p>}
      </div>
      <div className="text-end text-[2.2rem] font-semibold">{`${page + 1}/${4}`}</div>
      <div className="flex gap-[1.6rem]">
        <button
          type="button"
          // disabled={page === 0}
          style={{ display: page !== 0 ? "block" : "none" }}
          onClick={handleBack}
          className="w-full text-[#fff] py-[1.45rem] bg-primary_blue font-normal leading-[4.4rem] text-[3.78562rem] sm:text-[3.78562rem] rounded-[1.07463rem] "
        >
          {"Back"}
        </button>
        <button
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
          className="w-full text-[#fff] py-[1.45rem] bg-primary_blue font-normal leading-[4.4rem] text-[3.78562rem] sm:text-[3.78562rem] rounded-[1.07463rem]"
        >
          {isLoading ? "Loading..." : isLastStep ? "Submit" : "Next"}
        </button>

      </div>
    </form>
  );
};

export default React.memo(SwotForm);
