import {  getRequest } from "../../config/axiosConfig";
import { setUserProfile } from "store/slices/client.slice";

export function getUserProfile(route: any): any {
    return async (dispatch: any) => {
        try {
            const result = await getRequest(route)
            dispatch(setUserProfile(result?.data?.user));
        } catch (error: any) {
        }
    };
}