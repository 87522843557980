import React from "react";
import KickedBG from "assets/icons/kicked-bg.png";
import KickedIcon from "assets/icons/kicked-icon.svg";
import ProfileImg from "assets/images/profile.png";

interface AssKickedCardProps {
  opponent: any;
  challenger: any;
  user: any;
  name: string;
}

const AssKickedCard = ({
  opponent,
  challenger,
  name,
}: AssKickedCardProps) => {
  const renderAvatar = (
    name: string,
    image: string,
    position?: "right" | "left"
  ) => {
    return (
      <div className={`${position === "right" ? "text-right" : "text-left"}`}>
        <img
          className={`w-[4.43rem] h-[4.43rem] object-cover rounded-full overflow-hidden mb-[0.6rem] ${
            position === "right" ? "ml-auto" : ""
          }`}
          alt="userimage"
          src={image ? image : ProfileImg}
        ></img>
        <p
          className={`full_name break-normal text-[2rem] text-white font-medium leading-[2.5rem]`}
        >
          {name?.split(/\s/)[0]}
        </p>
      </div>
    );
  };

  const trimmedName = name ? name?.split(" ")[0] : '';

  return (
    <div className="relative">
      <div className="w-full min-h-[20rem] h-full bg-primary_blue rounded-[0.4rem] px-[1.2rem] py-[1.2rem] relative overflow-hidden shadow-[0_0_0.8rem_rgba(255,255,255,0.68)]">
        <img className="absolute top-0 left-0" alt="" src={KickedBG}></img>

        <div className="absolute px-[1.2rem] top-0 left-0 w-full translate-y-[14%]">
          <div className="flex flex-row items-start justify-between mb-[1.5rem]">
            <div className="w-[30%]">
              {renderAvatar(challenger.full_name, challenger.profile_photo)}
            </div>
            <div className="w-[30%]">
              {renderAvatar(
                opponent.full_name,
                opponent.profile_photo,
                "right"
              )}
            </div>
          </div>
        </div>
        <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-62%]">
          <img className="w-[13rem]" src={KickedIcon}></img>
        </div>
        <div className="text-center w-full absolute bottom-0 left-1/2 translate-x-[-50%] translate-y-[-0.8rem]">
          <p className="text-[2.4rem] leading-[2.6rem] text-white font-medium truncate flex items-center justify-center gap-2">
            You kicked{" "}
            <span className="truncate max-w-[34rem] block">{trimmedName}</span> in the
            ass
          </p>
        </div>
      </div>
    </div>
  );
};

export default AssKickedCard;
