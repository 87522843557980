import MainHeader from "./MainHeader";
import MobileHeader from "./MobileHeader";
import { useLocation } from "react-router-dom";
import useDocumentTitle from "hooks/useDynamicTitle";
import { selectUser } from "store/selectors";
import { useSelector } from "react-redux";
import Roles from "routes/permissions";

const coachNavLinks = [
  {
    title: "Coach Leaderboard",
    isDropdown: false,
    url: "/coach",
  },
  {
    title: "Client Resources",
    isDropdown: true,
    url: "/",
    dropContent: [
      {
        title: "Calls",
        url: "/coach/video-calls",
      },
      // {
      //   title: "Group Calls",
      //   url: "/coach/group-calls",
      // },
      {
        title: "Courses",
        url: "/coach/courses",
      },
      {
        title: "Events",
        url: "/coach/events",
      },
      {
        title: "The Huddle",
        url: "/coach/huddles",
      },
    ],
  },
  {
    title: "WinRate Resources",
    isDropdown: true,
    url: "/",
    dropContent: [
      {
        title: "Podcasts",
        url: "/coach/podcasts",
      },
      {
        title: "YouTube Videos",
        url: "/coach/youtube-videos",
      },
      {
        title: "Apparel",
        url: "/coach/apparels",
      },
    ],
  },
];

const clientNavLinks = [
  {
    title: "Home",
    isDropdown: false,
    url: "/client",
  },
  {
    title: "Leaderboard",
    isDropdown: false,
    url: "/client/leaderboard",
  },
  {
    title: "Client Resources",
    isDropdown: true,
    url: "/",
    dropContent: [
      {
        title: "Calls",
        url: "/client/video-calls",
      },
      // {
      //   title: "Group Calls",
      //   url: "/client/group-calls",
      // },
      {
        title: "Courses",
        url: "/client/courses",
      },
      {
        title: "Events",
        url: "/client/events",
      },
      {
        title: "The Huddle",
        url: "/client/huddles",
      },
    ],
  },
  {
    title: "WinRate Resources",
    isDropdown: true,
    url: "/",
    dropContent: [
      {
        title: "Podcasts",
        url: "/client/podcasts",
      },
      {
        title: "YouTube Videos",
        url: "/client/youtube-videos",
      },
      {
        title: "Apparel",
        url: "/client/apparels",
      },
    ],
  },
];

const Header = () => {
  const { pathname } = useLocation();
  const isClient = pathname.includes("/client");
  const user = useSelector(selectUser);

  //SETTING DYNAMIC TITLE
  const title = isClient ? "WinRate | Client" : "WinRate | Coach";
  useDocumentTitle(title);

  const navLinks = isClient ? clientNavLinks : coachNavLinks;
  const notification = isClient;
  const accountLink = isClient ? "/client/account" : "/coach/account";

  const containsAll = (arr1: string[], arr2: string[]) =>
    arr2?.every((arr2Item) => arr1?.includes(arr2Item));
  const haveBothRoles = containsAll(user.role, Object.values(Roles));

  return (
    <>
      <div className="hidden tablet:block">
        <MainHeader
          navLinks={navLinks}
          notification={notification}
          accountLink={accountLink}
          user={user}
          showRoleBtn={haveBothRoles}
          isClient={isClient}
        />
      </div>
      <div className="block tablet:hidden">
        <MobileHeader navLinks={navLinks} accountLink={accountLink} user={user} isClient={isClient}/>
      </div>
    </>
  );
};

export default Header;
