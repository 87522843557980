import React, { useEffect, useState } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import HomeBanner from "assets/images/HomeBanner.png";
import Avatar from "assets/images/EmptyProfile.jpg";
import CallVector from "assets/images/CallVector.svg";
import ClientDetailsTab from "./components/Tabs";
import { useNavigate, useParams } from "react-router-dom";
import { getClient } from "apis/client-apis";
import { useGetClientByIdQuery } from "apis/coach";
import { useSelector } from "react-redux";
import { selectUser } from "store/selectors";
import apiEndpoint from "config/environment";

const ClientDetail = () => {
  const [scheduleBtn, setScheduleBtn] = useState("Client Action Items");
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isSuccess } = useGetClientByIdQuery(id, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const user = data?.data?.user || {};
  const userData = useSelector(selectUser);

  const [CODE, setCode] = useState(false);
  const [OutlookCODE, setOutlookCODE] = useState(false);

  const userId = userData?.id;
  useEffect(() => {
    getClient(userId).then((res) => {
      setCode(res?.data?.user?.google_auth);
      setOutlookCODE(res?.data?.user?.outlook_auth);
    });
  }, [userId]);
  const handleGoogleAuth = () => {
    const authUrl = `${apiEndpoint}/google`;
    window.location.href = authUrl; // Redirect current tab
  };
  const handleOutlookAuth = () => {
    const authUrl = `${apiEndpoint}/outlook`;
    window.location.href = authUrl; // Redirect current tab
  };
  return (
    <Layout>
      <Hero image={HomeBanner} />
      <div className="container mx-auto pt-[4.2rem] pb-32 px-10 desktop:px-10">
        {isSuccess && Object.keys(user)?.length === 0 ? (
          <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
            <p className="text-4xl sm:text-5xl text-primary_blue">
              User Data Not Found
            </p>
          </div>
        ) : (
          <>
            <div className="flex-col sm:flex sm:flex-row sm:justify-between mb-16 items-center">
              <div className="flex items-center mb-14 sm:mb-0">
                <div className="w-[8rem] min-w-[8rem] h-[8rem] border-[0.5rem] overflow-hidden border-[#2165A2] rounded-full">
                  <img
                    className="w-[8rem] h-[8rem] object-cover"
                    src={user?.profile_photo ? user?.profile_photo : Avatar}
                    alt=""
                  />
                </div>
                <h3 className="font-bold text-5xl leading-[3.5rem] text-white ml-3">
                  {user?.full_name}
                </h3>
              </div>
              {scheduleBtn === "Call Library" && (
                <div className="flex-col gap-[2rem] flex sm:flex-row justify-between  items-start tablet:items-center">
                  {!CODE && (
                    <button
                      type="button"
                      onClick={handleGoogleAuth}
                      className="h-[5.6rem] w-full tablet:w-[28rem]  justify-center bg-primary_blue text-white font-semibold leading-9 text-3xl border border-[#ffffff] rounded-[1rem] items-center flex gap-2"
                    >
                      Connect with Google Calendar
                    </button>
                  )}
                  {!OutlookCODE && (
                    <button
                      type="button"
                      onClick={handleOutlookAuth}
                      className="h-[5.6rem] w-full tablet:w-[28rem]  justify-center bg-primary_blue text-white font-semibold leading-9 text-3xl border border-[#ffffff] rounded-[1rem] items-center flex gap-2"
                    >
                      Connect with Outlook Calendar
                    </button>
                  )}
                  <button
                    onClick={() => navigate(`/coach/schedule-call/${id}`)}
                    className="py-7 h-[5.6rem] px-[1.8rem] sm:px-[1.8rem] border border-[#ffffff] bg-primary_blue w-full sm:max-w-fit text-white font-semibold leading-9 text-3xl rounded-xl justify-center flex gap-2 items-center"
                  >
                    <img
                      src={CallVector}
                      alt=""
                      className="h-[2.2rem] max-w-[2.5rem]"
                    />{" "}
                    Schedule Call
                  </button>
                </div>
              )}
            </div>
            <div className="relative">
              <ClientDetailsTab setScheduleBtn={setScheduleBtn} scheduleBtn={scheduleBtn} />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default ClientDetail;
