import { useState } from "react";
import ChampionDayCard from "./components/ChampionDayCard";
import CreateButton from "./components/CreateBtn";
import EmptyCard from "./components/EmptyCard";
import { useGetChampionshipsQuery } from "apis/client";
import WonDayModal from "./components/WonDayModal";

const Championships = () => {
  const [isWon, setIsWon] = useState(false);

  const { data: { data } = [] } = useGetChampionshipsQuery(1, { refetchOnMountOrArgChange: true, refetchOnFocus: true });

  const allTasks = data?.tasks
    ?.map((task: any) => {
      return task?.data;
    })
    .flat();

  const renderData = (data: any) => {
    if (!data || data.length === 0) {
      return <EmptyCard />;
    }

    return data.map((el: any) => (
      <ChampionDayCard
        key={el.id}
        id={el.id}
        title={el?.title}
        time={el?.createdAt}
        updated={el?.updatedAt}
        isCompleted={el?.isCompleted}
        wonDayHandler={wonDayHandler}
      />
    ));
  };

  const wonDayHandler = (id: string) => {
    const temp = allTasks?.filter((task: any) => task.id !== id);
    const dayWon = temp?.every((task: any) => task.isCompleted);
    if (dayWon) setIsWon(true);
  };

  return (
    <div>
      <WonDayModal open={isWon} setOpen={setIsWon} />
      <>
        <div className="mt-[5.8rem] mb-[3.8rem] flex flex-row justify-between">
          <p className="text-[#fff] text-[3rem] sm:text-[3.82rem] font-medium">
            For Me
          </p>
          <CreateButton link="/client/tasks" />
        </div>

        <div className="cursor-pointer">
          {data?.tasks?.map((item: any) => {
            if (item.category === "myself") {
              return renderData(item.data);
            }
          })}
        </div>

        <div className="cursor-pointer">
          <p className="text-[#fff] text-[3rem] sm:text-[3.82rem] font-medium leading-[3.82rem] mb-[3.8rem] tablet:mb-[6rem]">
            For Someone Else
          </p>
          {data?.tasks?.map((item: any) => {
            if (item.category === "someone") {
              return renderData(item.data);
            }
          })}
        </div>

        <div className="cursor-pointer">
          <p className="text-[#fff] text-[3rem] sm:text-[3.82rem] font-medium leading-[3.82rem] mb-[3.8rem] tablet:mb-[6rem]">
            For My Business
          </p>
          {data?.tasks?.map((item: any) => {
            if (item.category === "business") {
              return renderData(item.data);
            }
          })}
        </div>
      </>
    </div>
  );
};

export default Championships;
