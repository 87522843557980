import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import bgBulb from "../assets/images/privacybulb.png";
import Layout from "layouts/Layout";
import { useSelector } from "react-redux";

const TermsAndConditions = () => {
  const token = useSelector((state: any) => state?.auth?.token);
  return (
    <div className="">
      {token ? (
        <Layout>
          <Hero
            image={ClientHeroImage}
            isCentered
            heading="Terms & Conditions"
          />
          <div className="container mx-auto p-16 desktop:px-10 overflow-hidden bg-[#202F39]">
            <div className="w-full bg-white rounded-[2rem] pl-[2rem] sm:pl-[8.171rem] pr-[2rem] sm:pr-[7.31rem] pt-[11.377rem] pb-[20.232rem] relative z-4">
              <div className="absolute top-[50%] z-6">
                <img src={bgBulb} alt="bgBulb" className="w-full h-full" />
              </div>
              <h1 className="font-medium text-center sm:text-left text-[3rem] sm:text-[7.08508rem] text-[#2165A2] mb-[4.16rem] ">
                Terms & Conditions
              </h1>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                By downloading or using the app, these terms will automatically
                apply to you – you should make sure therefore that you read them
                carefully before using the app. You’re not allowed to copy or
                modify the app, any part of the app, or our trademarks in any
                way. You’re not allowed to attempt to extract the source code of
                the app, and you also shouldn’t try to translate the app into
                other languages or make derivative versions. The app itself, and
                all the trademarks, copyright, database rights, and other
                intellectual property rights related to it, still belong to
                WinRate Consulting LLC.
              </p>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                WinRate Consulting LLC is committed to ensuring that the app is
                as useful and efficient as possible. For that reason, we reserve
                the right to make changes to the app or to charge for its
                services, at any time and for any reason. We will never charge
                you for the app or its services without making it very clear to
                you exactly what you’re paying for.
              </p>
              <p className="text-[#000000] break-words text-[2rem] sm:text-[3rem] mt-[2rem]">
                The WinRate App app stores and processes personal data that you
                have provided to us, to provide our Service. It’s your
                responsibility to keep your phone and access to the app secure.
                We therefore recommend that you do not jailbreak or root your
                phone, which is the process of removing software restrictions
                and limitations imposed by the official operating system of your
                device. It could make your phone vulnerable to
                malware/viruses/malicious programs, compromise your phone’s
                security features and it could mean that the WinRate App app
                won’t work properly or at all.
              </p>
              <div className="text-[#000000] text-[2rem] sm:text-[3rem]">
                The app does use third-party services that declare their Terms
                and Conditions. Link to Terms and Conditions of third-party
                service providers used by the app
                <div className="flex justify-center">
                  <ul className="list-disc">
                    <li>
                      <a
                        className="text-[#2165A2]"
                        href="https://policies.google.com/privacy"
                        target="_blank"
                      >
                        Google Play Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2165A2]"
                        href="https://www.facebook.com/about/privacy/update/printable"
                        target="_blank"
                      >
                        Facebook
                      </a>
                    </li>
                  </ul>
                </div>
                You should be aware that there are certain things that WinRate
                Consulting LLC will not take responsibility for. Certain
                functions of the app will require the app to have an active
                internet connection. The connection can be Wi-Fi or provided by
                your mobile network provider, but WinRate Consulting LLC cannot
                take responsibility for the app not working at full
                functionality if you don’t have access to Wi-Fi, and you don’t
                have any of your data allowance left.
              </div>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                If you’re using the app outside of an area with Wi-Fi, you
                should remember that the terms of the agreement with your mobile
                network provider will still apply. As a result, you may be
                charged by your mobile provider for the cost of data for the
                duration of the connection while accessing the app, or other
                third-party charges. In using the app, you’re accepting
                responsibility for any such charges, including roaming data
                charges if you use the app outside of your home territory (i.e.
                region or country) without turning off data roaming. If you are
                not the bill payer for the device on which you’re using the app,
                please be aware that we assume that you have received permission
                from the bill payer for using the app.
              </p>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem]">
                Along the same lines, WinRate Consulting LLC cannot always take
                responsibility for the way you use the app i.e. You need to make
                sure that your device stays charged – if it runs out of battery
                and you can’t turn it on to avail the Service, WinRate
                Consulting LLC cannot accept responsibility.
              </p>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                With respect to WinRate Consulting LLC’s responsibility for your
                use of the app, when you’re using the app, it’s important to
                bear in mind that although we endeavor to ensure that it is
                updated and correct at all times, we do rely on third parties to
                provide information to us so that we can make it available to
                you. WinRate Consulting LLC accepts no liability for any loss,
                direct or indirect, you experience as a result of relying wholly
                on this functionality of the app.
              </p>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                At some point, we may wish to update the app. The app is
                currently available on Android & iOS – the requirements for the
                both systems (and for any additional systems we decide to extend
                the availability of the app to) may change, and you’ll need to
                download the updates if you want to keep using the app. WinRate
                Consulting LLC does not promise that it will always update the
                app so that it is relevant to you and/or works with the Android
                & iOS version that you have installed on your device. However,
                you promise to always accept updates to the application when
                offered to you. We may also wish to stop providing the app, and
                may terminate use of it at any time without giving notice of
                termination to you. Unless we tell you otherwise, upon any
                termination, (a) the rights and licenses granted to you in these
                terms will end; (b) you must stop using the app, and (if needed)
                delete it from your device.
              </p>
              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem]">
                Changes to This Terms and Conditions
              </h4>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                We may update our Terms and Conditions from time to time. Thus,
                you are advised to review this page periodically for any
                changes. We will notify you of any changes by posting the new
                Terms and Conditions on this page. These terms and conditions
                are effective as of 2023-03-20
              </p>
              <h4 className=" text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem]">
                Contact Us
              </h4>
              <p className="text-[#000000] mt-[2rem] text-[2rem] sm:text-[3rem] break-words">
                If you have any questions or suggestions about our Terms and
                Conditions, do not hesitate to contact us at{" "}
                <a className="text-[#2165A2]" href="#">
                  support@winrateconsulting.com
                </a>
                .
              </p>
              <p className="text-[#000000] mt-[2rem] text-[2rem] sm:text-[3rem]">
                This Terms and Conditions page was generated by{" "}
                <span className="text-[#2165A2]">
                  <u>
                    <a
                      href="https://app-privacy-policy-generator.nisrulz.com/"
                      target="_blank"
                    >
                      App Privacy Policy Generator
                    </a>
                  </u>
                </span>
              </p>
            </div>
          </div>
        </Layout>
      ) : (
        <div className=" bg-[#202F39]">
          <Hero
            image={ClientHeroImage}
            isCentered
            heading="Terms & Conditions"
          />
          <div className="container mx-auto p-16 desktop:px-10 overflow-hidden bg-[#202F39]">
            <div className="w-full bg-white rounded-[2rem] pl-[2rem] sm:pl-[8.171rem] pr-[2rem] sm:pr-[7.31rem] pt-[11.377rem] pb-[20.232rem] relative z-4">
              <div className="absolute top-[50%] z-6">
                <img src={bgBulb} alt="bgBulb" className="w-full h-full" />
              </div>
              <h1 className="font-medium text-center sm:text-left text-[3rem] sm:text-[7.08508rem] text-[#2165A2] mb-[4.16rem] ">
                Terms & Conditions
              </h1>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                By downloading or using the app, these terms will automatically
                apply to you – you should make sure therefore that you read them
                carefully before using the app. You’re not allowed to copy or
                modify the app, any part of the app, or our trademarks in any
                way. You’re not allowed to attempt to extract the source code of
                the app, and you also shouldn’t try to translate the app into
                other languages or make derivative versions. The app itself, and
                all the trademarks, copyright, database rights, and other
                intellectual property rights related to it, still belong to
                WinRate Consulting LLC.
              </p>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                WinRate Consulting LLC is committed to ensuring that the app is
                as useful and efficient as possible. For that reason, we reserve
                the right to make changes to the app or to charge for its
                services, at any time and for any reason. We will never charge
                you for the app or its services without making it very clear to
                you exactly what you’re paying for.
              </p>
              <p className="text-[#000000] break-words text-[2rem] sm:text-[3rem] mt-[2rem]">
                The WinRate App app stores and processes personal data that you
                have provided to us, to provide our Service. It’s your
                responsibility to keep your phone and access to the app secure.
                We therefore recommend that you do not jailbreak or root your
                phone, which is the process of removing software restrictions
                and limitations imposed by the official operating system of your
                device. It could make your phone vulnerable to
                malware/viruses/malicious programs, compromise your phone’s
                security features and it could mean that the WinRate App app
                won’t work properly or at all.
              </p>
              <div className="text-[#000000] text-[2rem] sm:text-[3rem]">
                The app does use third-party services that declare their Terms
                and Conditions. Link to Terms and Conditions of third-party
                service providers used by the app
                <div className="flex justify-center">
                  <ul className="list-disc">
                    <li>
                      <a
                        className="text-[#2165A2]"
                        href="https://policies.google.com/privacy"
                        target="_blank"
                      >
                        Google Play Services
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2165A2]"
                        href="https://www.facebook.com/about/privacy/update/printable"
                        target="_blank"
                      >
                        Facebook
                      </a>
                    </li>
                  </ul>
                </div>
                You should be aware that there are certain things that WinRate
                Consulting LLC will not take responsibility for. Certain
                functions of the app will require the app to have an active
                internet connection. The connection can be Wi-Fi or provided by
                your mobile network provider, but WinRate Consulting LLC cannot
                take responsibility for the app not working at full
                functionality if you don’t have access to Wi-Fi, and you don’t
                have any of your data allowance left.
              </div>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                If you’re using the app outside of an area with Wi-Fi, you
                should remember that the terms of the agreement with your mobile
                network provider will still apply. As a result, you may be
                charged by your mobile provider for the cost of data for the
                duration of the connection while accessing the app, or other
                third-party charges. In using the app, you’re accepting
                responsibility for any such charges, including roaming data
                charges if you use the app outside of your home territory (i.e.
                region or country) without turning off data roaming. If you are
                not the bill payer for the device on which you’re using the app,
                please be aware that we assume that you have received permission
                from the bill payer for using the app.
              </p>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem]">
                Along the same lines, WinRate Consulting LLC cannot always take
                responsibility for the way you use the app i.e. You need to make
                sure that your device stays charged – if it runs out of battery
                and you can’t turn it on to avail the Service, WinRate
                Consulting LLC cannot accept responsibility.
              </p>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                With respect to WinRate Consulting LLC’s responsibility for your
                use of the app, when you’re using the app, it’s important to
                bear in mind that although we endeavor to ensure that it is
                updated and correct at all times, we do rely on third parties to
                provide information to us so that we can make it available to
                you. WinRate Consulting LLC accepts no liability for any loss,
                direct or indirect, you experience as a result of relying wholly
                on this functionality of the app.
              </p>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                At some point, we may wish to update the app. The app is
                currently available on Android & iOS – the requirements for the
                both systems (and for any additional systems we decide to extend
                the availability of the app to) may change, and you’ll need to
                download the updates if you want to keep using the app. WinRate
                Consulting LLC does not promise that it will always update the
                app so that it is relevant to you and/or works with the Android
                & iOS version that you have installed on your device. However,
                you promise to always accept updates to the application when
                offered to you. We may also wish to stop providing the app, and
                may terminate use of it at any time without giving notice of
                termination to you. Unless we tell you otherwise, upon any
                termination, (a) the rights and licenses granted to you in these
                terms will end; (b) you must stop using the app, and (if needed)
                delete it from your device.
              </p>
              <h4 className="text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem]">
                Changes to This Terms and Conditions
              </h4>
              <p className="text-[#000000] text-[2rem] sm:text-[3rem] mt-[2rem]">
                We may update our Terms and Conditions from time to time. Thus,
                you are advised to review this page periodically for any
                changes. We will notify you of any changes by posting the new
                Terms and Conditions on this page. These terms and conditions
                are effective as of 2023-03-20
              </p>
              <h4 className=" text-center sm:text-left text-[3rem] text-[#2165A2] mt-[2rem] font-bold sm:text-[4.18892rem]">
                Contact Us
              </h4>
              <p className="text-[#000000] mt-[2rem] text-[2rem] sm:text-[3rem] break-words">
                If you have any questions or suggestions about our Terms and
                Conditions, do not hesitate to contact us at{" "}
                <a className="text-[#2165A2]" href="#">
                  support@winrateconsulting.com
                </a>
                .
              </p>
              <p className="text-[#000000] mt-[2rem] text-[2rem] sm:text-[3rem]">
                This Terms and Conditions page was generated by{" "}
                <span className="text-[#2165A2]">
                  <u>
                    <a
                      href="https://app-privacy-policy-generator.nisrulz.com/"
                      target="_blank"
                    >
                      App Privacy Policy Generator
                    </a>
                  </u>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsAndConditions;
