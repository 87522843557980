import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ProfileImg from "assets/images/EmptyProfile.jpg";
import Cross from "assets/icons/cross-white.svg";
import StreakCalendar from "./StreakCalendar";
import { useGetStreaksQuery } from "apis/client";
import EmptyStreakModal from "./EmptyStreakModal";
import { useLocation } from "react-router-dom";

const StreakModal = ({ open, setIsOpen, user }: any) => {
  const { data } = useGetStreaksQuery(1, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
  const streaksData = data?.data?.streak;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const actionType = searchParams.get('action');

  useEffect(() => {
    if(actionType === "streak"){
      setIsOpen(true)
    }
  }, [actionType,setIsOpen]);
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed top-0 right-0 bottom-0 z-[999] h-full"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-200 transform"
            leave="transition ease-in-out duration-200 transform"
            enterFrom="translate-x-full"
            enterTo="-translate-x-0"
            leaveFrom="-translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="flex relative z-10 flex-col h-full bg-white max-w-[40rem]">
              <div className="overflow-y-auto flex-1">
                <div className="bg-[#202F39] px-[3rem] py-[1.2rem] flex items-center justify-between">
                  <div className="flex gap-4 items-center">
                    <img
                      alt=""
                      className="w-[4.6rem] h-[4.6rem]  rounded-lg object-cover"
                      src={
                        user?.profile_photo ? user?.profile_photo : ProfileImg
                      }
                    />
                    <div>
                      <p className="text-white text-3xl truncate_class break-all min-w-[6rem] font-medium">
                        {user?.full_name}
                      </p>
                      {user?.company && (
                        <p className="text-white text-2xl font-medium">
                          {user?.company}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <img
                      alt=""
                      className="min-w-[3rem] ml-[0.5rem] cursor-pointer"
                      onClick={() => setIsOpen(false)}
                      src={Cross}
                    />
                  </div>
                </div>
                <p className="text-5xl font-medium text-[#202F39] text-center mt-[7.3rem]">
                  Current Streak
                  <br />
                  {streaksData?.currentStreak
                    ? streaksData?.currentStreak
                    : "0"}
                  {""} {streaksData?.currentStreak ? streaksData?.currentStreak > 1 ? "Days" : "Day" : ""}
                </p>
                {streaksData ? (
                  <>
                    <div className="text-center mt-[3.6rem] mb-[3rem]"></div>
                    <div className="px-4">
                      <StreakCalendar user={user} streaksData={streaksData} />
                    </div>
                  </>
                ) : (
                  <div className="text-center mt-[3.6rem] mb-[3rem]">
                    {/* <p className="text-5xl font-medium text-[#392020]">No Streak Is <br></br>Started Yet!</p> */}
                    <div className="px-4">
                      <EmptyStreakModal />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
          <Transition.Child
            as={Fragment}
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#000] bg-opacity-50"></Dialog.Overlay>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default StreakModal;
