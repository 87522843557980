import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import dayjs from "dayjs";
import ReactPlayer from "react-player";

export default function PlayPodcastModal({
  isOpen,
  setIsOpen,
  title,
  desc,
  season,
  duration,
  date,
  audio,
}: any) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000] bg-opacity-70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[85rem] transform overflow-hidden rounded-2xl bg-[#18242c] p-8 text-left align-middle shadow-xl transition-all">
                  <div className="">
                    <div className="">
                      <h1 className="text-primary_light capitalize text-4xl font-bold lg:text-left text-center ">
                        {title}
                      </h1>
                      <div className="mt-8">
                        <h1 className="text-2xl text-white font-semibold ">
                          {desc}
                        </h1>
                      </div>

                      <h1 className="text-4xl mt-8 mx-auto text-white  font-light ">
                        {dayjs(date).format("MMM DD, YYYY")} | Season {season} |{" "}
                        {duration}
                      </h1>
                    </div>

                    <div className="w-full mt-8 mb-8 rounded-lg">
                      <ReactPlayer
                        url={audio}
                        width="100%"
                        height="50px"
                        playing={false}
                        controls={true}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}