import ArrowDown from "assets/images/ArrowDown.svg";
import Calendar from "assets/images/Calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CallCalendar = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleFilter,
}: any) => {
  function handleStartDate(date: any) {
    setStartDate(date);
  }
  function handleEndDate(date: any) {
    setEndDate(date);
  }

  return (
    <div className="flex flex-col sm:flex-row gap-10">
      {/* <div className="flex items-center mr-[0.5rem] sm:mr-[2.7rem]"></div> */}
      <div className="flex items-center gap-8 sm:justify-end">
        <div className="relative">
          <img
            className="absolute h-[2rem] left-[1.2rem] sm:left-[1.4rem] top-[1.7rem] sm:top-[1.8rem]"
            src={Calendar}
            alt="Calendar"
          />
          <img
            className="absolute right-[1.8rem] top-[2.3rem]"
            src={ArrowDown}
            alt="ArrowDown"
          />
          <DatePicker
            className="input-call-calendar"
            selected={startDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onChange={handleStartDate}
            maxDate={new Date()}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>
        <h5 className="font-bold text-[1.4rem] mt-[1rem] leading[2.1rem] ml-[1rem] sm:ml-0 text-[#ffffff]">
          To
        </h5>
        <div className="relative">
          <img
            className="absolute h-[2rem] left-[1.2rem] sm:left-[1.4rem] top-[1.7rem] sm:top-[1.8rem]"
            src={Calendar}
            alt="Calendar"
          />
          <img
            className="absolute right-[1.8rem] top-[2.3rem]"
            src={ArrowDown}
            alt="ArrowDown"
          />
          <DatePicker
            className="input-call-calendar"
            // minDate={startDate}
            selected={endDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            onChange={handleEndDate}
            maxDate={new Date()}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      </div>
      <button
        onClick={handleFilter}
        className="mt-[1rem] w-max px-8 h-[3.8rem] bg-primary_blue text-white font-bold tracking-wide text-[2rem] rounded-[0.8rem] disabled:opacity-50 flex items-center border"
      >
        Filter
      </button>
    </div>
  );
};

export default CallCalendar;
