import Layout from "layouts/Layout";
import Hero from "components/Hero";
import ClientHeroImage from "assets/images/client-hero.png";
import Card from "./Components/Card";
import { useGetClientLeaderboardQuery } from "apis/client";

const LeaderBoardClient = () => {
  const { data, isLoading, isFetching } = useGetClientLeaderboardQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <Layout>
        <Hero image={ClientHeroImage} subHeading="Leaderboard" />
        <div className="container mx-auto py-32 px-10 desktop:px-10">
          {isFetching || isLoading ? (
            <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
              <p className="text-5xl text-primary_blue">Loading...</p>
            </div>
          ) : (
            <div className="flex flex-col gap-6">
              {data?.data?.users?.length === 0 && (
                <div className="bg-white w-full my-[3rem] text-center py-16 rounded-lg">
                  <p className="text-4xl sm:text-5xl text-primary_blue">
                    No User Found To Challenge
                  </p>
                </div>
              )}
              {data?.data?.users?.map((user: any, index: number) => {
                return (
                  <div key={user.id}>
                    <Card index={index} user={user} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default LeaderBoardClient;
