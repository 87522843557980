import { useState, useEffect } from "react";
import Hero from "components/Hero";
import Layout from "layouts/Layout";
import HomeBanner from "assets/images/HomeBanner.png";
import AccountBulb from "assets/images/AccountBulb.png";
import PersonIcon from "assets/images/PersonIcon.svg";
import LockIcon from "assets/images/LockIcon.svg";
import LogoutIcon from "assets/images/LogoutIcon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAsync } from "store/reducers/auth.reducer";
import { selectUser } from "store/selectors";
import EmptyProfile from "../../../assets/images/EmptyProfile.jpg";
import tandc from "../../../assets/icons/tandc.svg";
import pp from "../../../assets/icons/pp.svg";
import HuddleCallIcon from "../../../assets/icons/huddle-call.svg";
import { useGetProfileByIdQuery } from "apis/general";
import { RevokeGoogleToken, RevokeOutlookToken } from "apis/auth-apis";
import { toast } from "react-toastify";

const MyAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userId = user.id;
  const [fullName, setFullName] = useState("");
  const [image, setImage] = useState("");

  const { data, isFetching } = useGetProfileByIdQuery(user?.id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data?.data?.user) {
      const { full_name, profile_photo } = data.data.user;
      setFullName(full_name);
      setImage(profile_photo);
    }
  }, [isFetching]);

  const logoutHandler = () => {
    dispatch(logoutAsync());
  };
  const RevokeTokenHandler = () => {
    RevokeGoogleToken(userId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err: any) => {
        if (err && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });
  };
  const RevokeOutlookTokenHandler = () => {
    RevokeOutlookToken(userId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err: any) => {
        if (err && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });
  };

  return (
    <Layout>
      <Hero image={HomeBanner} />
      <div className="container mx-auto py-28 px-10 desktop:px-10">
        <div className="md:flex md:px-20 lg:px-0 md:justify-between md:flex-row flex-col items-center">
          <div className="flex flex-col max-w-[30rem] items-center">
            <h1 className=" text-white text-[4rem] font-bold leading-[4.7rem]">
              Your Account
            </h1>
            <div className="w-[25rem] h-[22rem] rounded-[0.63rem] overflow-hidden mt-[2.8rem]">
              <img
                className="w-full h-full object-cover"
                src={image ? image : EmptyProfile}
                alt="Account Img"
              />
            </div>
            <h1 className=" text-white text-[3.4rem] w-[30rem] text-center break-normal font-medium leading-[4rem] mt-[1.8rem] capitalize">
              {fullName}
            </h1>
            <h1 className=" text-[#969696] text-[3.7rem] font-light leading-[4.4rem] mt-[0.4rem] capitalize">
              {user?.role.includes("client") && user?.role.includes("coach")
                ? "Client/Coach"
                : "Coach"}
            </h1>
          </div>
          <img
            className="max-w-[20.5rem] h-auto mt-[5rem] mx-auto md:mx-0 hidden laptop:block"
            src={AccountBulb}
            alt="Account Bulb"
          />
        </div>
        <div className="mt-[4rem] tablet:mt-[4rem] px-6 md:px-20 lg:px-0">
          <div className="flex gap-5 items-baseline">
            <img className="w-[3rem]" src={PersonIcon} alt="Person Icon" />
            <button
              className=" text-white text-[3.6rem] font-medium leading-[4rem] mt-[1.8rem]"
              onClick={() => navigate("/coach/edit-account")}
            >
              Edit Profile
            </button>
          </div>
          <div className="flex gap-5 items-center mt-[1rem]">
            <img
              className="w-[2.6rem] mt-[1rem]"
              src={LockIcon}
              alt="Lock Icon"
            />
            <button
              className=" text-white text-[3.6rem] font-medium leading-[4rem] mt-[1.8rem]"
              onClick={() => navigate("/coach/account-reset-password")}
            >
              Change Password
            </button>
          </div>

          <div className="flex gap-5 items-center mt-[1rem]">
            <img
              className="w-[3.6rem] mt-[1rem]"
              src={tandc}
              alt="tandc Icon"
            />
            <button
              className=" text-white text-[3.6rem] font-medium leading-[4rem] mt-[1.8rem]"
              onClick={() => navigate("/coach/terms-condition")}
            >
              Terms & Conditions
            </button>
          </div>
          <div className="flex gap-5 items-center mt-[1rem]">
            <img
              className="w-[3.6rem] mt-[1rem]"
              src={HuddleCallIcon}
              alt="ScheduledCallsIcon"
            />
            <button
              className=" text-white text-left text-[3.6rem] break-normal font-medium leading-[4rem] mt-[1.8rem]"
              onClick={() => navigate("/coach/scheduled-calls")}
            >
              Scheduled Huddle/Substitute Calls
            </button>
          </div>

          <div className="flex gap-5 items-center mt-[1rem]">
            <img
              className="w-[3.6rem] mt-[1rem]"
              src={pp}
              alt="Privacy Policy Icon"
            />
            <button
              className=" text-white text-[3.6rem] font-medium leading-[4rem] mt-[1.8rem]"
              onClick={() => navigate("/coach/privacy-policy")}
            >
              Privacy Policy
            </button>
          </div>

          <div className="flex gap-5 items-center mt-[1rem]">
            <img
              className="w-[3rem] h-auto mt-[1.8rem]"
              src={LogoutIcon}
              alt="Logout Icon"
            />
            <button
              className=" text-white text-[3.6rem] font-medium leading-[4rem] mt-[1.8rem] text-left"
              onClick={RevokeTokenHandler}
            >
              Disconnect From Google Calendar
            </button>
          </div>
          <div className="flex gap-5 items-center mt-[1rem] text-left">
            <img
              className="w-[3rem] h-auto mt-[1.8rem]"
              src={LogoutIcon}
              alt="Logout Icon"
            />
            <button
              className=" text-white text-[3.6rem] font-medium leading-[4rem] mt-[1.8rem] text-left"
              onClick={RevokeOutlookTokenHandler}
            >
              Disconnect From Outlook Calendar
            </button>
          </div>
          <div className="flex gap-5 items-center mt-[1rem]">
            <img
              className="w-[3rem] h-auto mt-[1.8rem]"
              src={LogoutIcon}
              alt="Logout Icon"
            />
            <button
              className=" text-white text-[3.6rem] font-medium leading-[4rem] mt-[1.8rem]"
              onClick={logoutHandler}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MyAccount;
